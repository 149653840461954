import React from 'react';

import { ProfileTwoTone } from '@ant-design/icons';

import { theme as antTheme, Layout, Row, Space } from 'antd';

import { useTranslation } from 'react-i18next';

import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';

import { Breadcrumbs } from '@components/Breadcrumbs';
import { HeaderTitle } from '@components/HeaderTitle';

import { useGetWarehouseQuery } from '../../hooks';

import { useWarehouse } from './WarehouseProvider';
import { SettingsControl } from './components/SettingsControl';
import { TABS } from './interfaces';
import { Settings } from './pages/Settings';
import { Container, Tabs as StyledTabs } from './styles';

export const Single: React.FC = () => {
  const navigate = useNavigate();
  const { activeTab, setActiveTab } = useWarehouse();
  const { token } = antTheme.useToken();
  const params = useParams<{ id: string }>();
  const warehouseT = useTranslation(NSKeys.warehouse);

  const { data, isLoading } = useGetWarehouseQuery(params?.id || '');

  const goBack = () => navigate('/dashboard/warehouses');

  return (
    <Layout>
      <Layout.Header style={{ border: 0 }}>
        <Breadcrumbs apiTitles={[{ id: ':warehouseId', value: data?.name }]} />
        <Row justify="space-between" align="middle">
          <HeaderTitle loading={isLoading} title={`${data?.name || params.id}`} onClick={goBack} />
          {activeTab === TABS.SETTINGS && <SettingsControl />}
        </Row>
      </Layout.Header>
      <Layout.Content>
        <StyledTabs
          destroyInactiveTabPane={true}
          activeKey={activeTab}
          onChange={(tab: any) => setActiveTab(tab)}
          items={[
            {
              key: TABS.ACCEPTANCE,
              disabled: true,
              label: (
                <Space>
                  <ProfileTwoTone twoToneColor={token?.colorPrimary} />
                  {warehouseT.t('tabAcceptance')}
                </Space>
              ),
              children: (
                <Container>
                  <div>ACCEPTANCE WIP</div>
                </Container>
              ),
            },
            {
              key: TABS.SHIPMENTS,
              disabled: true,
              label: (
                <Space>
                  <ProfileTwoTone twoToneColor={token?.colorPrimary} />
                  {warehouseT.t('tabShipments')}
                </Space>
              ),
              children: (
                <Container>
                  <div>SHIPMENTS WIP</div>
                </Container>
              ),
            },
            {
              key: TABS.SETTINGS,
              label: (
                <Space>
                  <ProfileTwoTone twoToneColor={token?.colorPrimary} />
                  {warehouseT.t('tabSettings')}
                </Space>
              ),
              children: (
                <Container>
                  <Settings />
                </Container>
              ),
            },
          ]}
          $colorBgBase={token.colorBgBase}
        />
      </Layout.Content>
    </Layout>
  );
};
