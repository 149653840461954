import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';

interface IProps {
  role_id: string;
}

export const useDeleteRoleQuery = () => {
  const catalogsT = useTranslation(NSKeys.catalogs);

  return useMutation({
    mutationFn: async ({ role_id }: IProps) => {
      await Api.users.deleteRole(role_id);
    },
    onSuccess: async () => {
      message.open({
        type: 'success',
        content: catalogsT.t('form.toastEditSuccess'),
      });
    },
    onError: () => {
      message.open({
        type: 'error',
        content: catalogsT.t('errors.putError'),
      });
    },
  });
};
