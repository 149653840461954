import React from 'react';

import { theme as antTheme, Button, Drawer, Flex, Form, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { UpdateProcessAccessSettingsDTO } from '@app/models/orders';
import { useWindowSize } from '@app/services/hooks/useWindowSize';
import { DeleteAccessSettingsGroup } from '@pages/Processes/components/SettingsPopover/components/AccessSettings/componsent/DeleteAccessSettingsGroup';

import { useGetProcessAccessSettingsQuery, useGetProcessQuery, useUpdateAccessSettingsGroup } from '../../../../hooks';

import { AddSettingsModal } from './componsent/AddSettingsModal';
import { SettingsTable } from './componsent/SettingsTable';

interface IProcessSettingsProps {
  open: boolean;
  onClose: () => void;
}

export const AccessSettings: React.FC<IProcessSettingsProps> = ({ open, onClose }) => {
  const { token } = antTheme.useToken();
  const ordersT = useTranslation(NSKeys.orders);
  const commonT = useTranslation(NSKeys.common);
  const params = useParams<{ processId: string }>();
  const [activeSettingsUID, setActiveSettingsUID] = React.useState('');
  const [formFieldsTouched, setIsFormFieldsTouched] = React.useState(false);
  const { data: processData, status: loadingStatus } = useGetProcessQuery(params.processId);
  const { data: processDataAccessSettings, status: accessSettingsLoading } = useGetProcessAccessSettingsQuery(
    params.processId
  );
  const { mutate, status } = useUpdateAccessSettingsGroup();
  const { innerWidth } = useWindowSize();
  const [form] = Form.useForm<UpdateProcessAccessSettingsDTO>();

  React.useEffect(() => {
    if (!open) {
      setActiveSettingsUID('');
      setIsFormFieldsTouched(false);
    }
    if (open && processDataAccessSettings?.length) {
      setActiveSettingsUID(processDataAccessSettings?.[0].uid);
    }
  }, [open]);

  React.useEffect(() => {
    if (processDataAccessSettings?.length) {
      setActiveSettingsUID(processDataAccessSettings?.[0].uid);
    }
  }, [processDataAccessSettings]);

  React.useEffect(() => {
    const policies = processDataAccessSettings?.find((el) => el.uid === activeSettingsUID)?.policies;
    const initialData = { policies: policies || [] };
    form.setFieldsValue(initialData as any);
    setIsFormFieldsTouched(false);
  }, [activeSettingsUID]);

  React.useEffect(() => {
    if (status === 'success') {
      setIsFormFieldsTouched(false);
    }
  }, [status]);

  const renderContent = () =>
    loadingStatus === 'loading' || accessSettingsLoading === 'loading' ? (
      <Flex justify="center" align="center" style={{ minHeight: 300 }}>
        <Spin />
      </Flex>
    ) : (
      <Flex vertical gap={20}>
        <Flex gap={8}>
          {processDataAccessSettings?.map((settings) => (
            <Button
              size="small"
              key={settings.uid}
              type={activeSettingsUID === settings.uid ? 'primary' : 'default'}
              onClick={() => setActiveSettingsUID(settings.uid)}
              iconPosition="end"
              icon={
                <DeleteAccessSettingsGroup group_uid={settings.uid} isActive={activeSettingsUID === settings.uid} />
              }>
              {settings.user_group.name}
            </Button>
          ))}
          {params.processId && (
            <AddSettingsModal
              process_uid={params.processId}
              disabledOptions={processDataAccessSettings?.map((el) => el.user_group.uid)}
            />
          )}
        </Flex>
        {!!processDataAccessSettings?.length && processData && activeSettingsUID && (
          <Form<UpdateProcessAccessSettingsDTO>
            form={form}
            name="access-settings"
            onFinish={(data) => mutate({ uid: activeSettingsUID, data })}
            onFieldsChange={() => setIsFormFieldsTouched(true)}>
            <SettingsTable
              activeSettingsUid={activeSettingsUID}
              processData={processData}
              processDataAccessSettings={processDataAccessSettings}
            />
          </Form>
        )}
      </Flex>
    );

  return (
    <Drawer
      destroyOnClose={true}
      title={
        <Flex justify="space-between">
          <Flex vertical justify={'start'} style={{ marginLeft: 16 }}>
            <Typography.Title level={4} style={{ margin: 0, fontWeight: 800 }}>
              {processData?.name}
            </Typography.Title>
            <Typography.Text type="secondary" style={{ margin: 0 }}>
              {ordersT.t('accessSettings')}
            </Typography.Text>
          </Flex>
          {formFieldsTouched && (
            <Button type="primary" size="middle" form="access-settings" htmlType="submit">
              {commonT.t('buttonSave')}
            </Button>
          )}
        </Flex>
      }
      placement="right"
      size="large"
      width={innerWidth > 1024 ? '75%' : '100%'}
      onClose={onClose}
      styles={{ body: { backgroundColor: token.colorBgLayout } }}
      open={open}>
      {renderContent()}
    </Drawer>
  );
};
