import { Namespace, TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { IFormValues } from '../interfaces';

export const ValidationSchema = (t: TFunction<Namespace<'validation'>>): Yup.SchemaOf<IFormValues> =>
  Yup.object().shape({
    timezones: Yup.number().required(t('required')),
    currencies: Yup.string().required(t('required')),
    mass_units: Yup.string().required(t('required')),
    dimension_units: Yup.string().required(t('required')),
    country_code: Yup.string().required(t('required')),
  });
