import React from 'react';

import { Button, Form, Input, Layout, message, Row, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';

import { NSKeys } from '@app/i18n';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { HeaderTitle } from '@components/HeaderTitle';

import { ConfirmationChain } from '../../components/ConfirmationChain';
import { buildTree } from '../../data/services';
import { useGetOrderApprovalChainQuery, useUpdateChainQuery } from '../../hooks';
import { useConfirmation } from '../../providers/confirmationProvider';

import { ValidationSchema } from './data/validation';
import { IFormValues } from './interfaces';

export const Single: React.FC = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const commonT = useTranslation(NSKeys.common);
  const orderApprovalChainsT = useTranslation(NSKeys.orderApprovalChains);
  const [form] = Form.useForm<IFormValues>();
  const { localNodes, localEdges } = useConfirmation();
  const { onInitByTree } = useConfirmation();
  const validationT = useTranslation(NSKeys.validation);

  const { data, status } = useGetOrderApprovalChainQuery(params?.id || '');
  const { mutate, status: updateStatus } = useUpdateChainQuery();

  React.useEffect(() => {
    if (data) {
      form.setFieldValue('name', data.name);
      onInitByTree(data);
    }
  }, [data, status]);

  const goBack = () => navigate('/dashboard/orderApprovalChains');

  const handleSubmit = async (formValues: IFormValues): Promise<void> => {
    if (localNodes.length === 0) {
      message.error(orderApprovalChainsT.t('errors.nodesEmpty'));
    } else {
      mutate({
        data: { name: formValues.name, head: buildTree(localNodes, localEdges)[0] },
        approval_chain_uid: params.id || '',
      });
    }
  };

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  return (
    <React.Fragment>
      <Layout.Header>
        <Breadcrumbs apiTitles={[{ id: ':id', value: data?.name || '' }]} />
        <Row justify="space-between" align="middle">
          <HeaderTitle title={data?.name || ''} loading={status === 'loading'} onClick={goBack} />
          <Space size="middle">
            <Space size="middle">
              <Button onClick={goBack} size="middle" name="catalog_form" id="catalog_form">
                {commonT.t('buttonCancel')}
              </Button>
              <Button
                disabled={status === 'loading'}
                type="primary"
                size="middle"
                form="catalogForm"
                htmlType="submit"
                onClick={form.submit}>
                {commonT.t('buttonSave')}
              </Button>
            </Space>
          </Space>
        </Row>
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item name="name" rules={[yupSync]}>
            <Input style={{ maxWidth: 230 }} placeholder={orderApprovalChainsT.t('new.namePlaceholder')} />
          </Form.Item>
        </Form>
      </Layout.Header>
      <ReactFlowProvider>{status === 'success' && <ConfirmationChain />}</ReactFlowProvider>
      {status === 'loading' && (
        <Layout.Content style={{ margin: 24, display: 'flex', justifyContent: 'center' }}>
          <Spin />
        </Layout.Content>
      )}
    </React.Fragment>
  );
};
