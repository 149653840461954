import React from 'react';

import { DownloadOutlined } from '@ant-design/icons';
import { Button, theme, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { useLocalSelector } from '@app/services/hooks/useRedux';

import { getHostName } from '@app/services/httpClient';

import { useExportQuery } from '../../../../../../hooks';

export const ExportButton: React.FC = () => {
  const { token } = theme.useToken();
  const ordersT = useTranslation(NSKeys.orders);
  const currentSession = useLocalSelector((state) => state.currentSession);

  const { mutate, status, data } = useExportQuery();

  React.useEffect(() => {
    if (status === 'success' && data?.filename) {
      const link = document.createElement('a');
      link.download = `orders-${new Date().getUTCDate()}`;
      link.href = `${getHostName()}/orders/exported/${data?.filename}`;
      link.click();
    }
  }, [data, status]);

  if (!currentSession.ordersContext?.scope?.$permissions?.export_orders) {
    return null;
  }

  return (
    <Tooltip placement="bottomLeft" title={ordersT.t('exportTooltip')}>
      <Button
        icon={<DownloadOutlined style={{ color: token.colorPrimary }} />}
        loading={status === 'loading'}
        onClick={() => mutate()}
      />
    </Tooltip>
  );
};
