import React, { FC } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row } from 'antd';
import { Namespace, TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { NSKeys } from '@app/i18n';

import { LAYOUT, TAIL_LAYOUT } from '../../constants';
import { IStep2 } from '../../interfaces';

interface IProps {
  initialValues: IStep2;
  onNext: (values: IStep2) => void;
  onPrev: () => void;
}

const MIN_PASSWORD_LENGTH = 8;

export const ValidationSchema = (t: TFunction<Namespace<'validation'>>): Yup.SchemaOf<IStep2> =>
  Yup.object({
    first_pwd: Yup.string()
      .required(t('required'))
      .test(
        'len',
        t('passwordMinLength', { length: MIN_PASSWORD_LENGTH }),
        (val) => (val?.length || 0) >= MIN_PASSWORD_LENGTH
      )
      .matches(/^[^а-яёА-ЯЁ]+$/, t('passwordLetters')),
    second_pwd: Yup.string()
      .required(t('required'))
      .test(
        'len',
        t('passwordMinLength', { length: MIN_PASSWORD_LENGTH }),
        (val) => (val?.length || 0) >= MIN_PASSWORD_LENGTH
      )
      .matches(/^[^а-яёА-ЯЁ]+$/, t('passwordLetters')),
  });

export const Step2: FC<IProps> = (props) => {
  const [form] = Form.useForm();
  const password1 = Form.useWatch('first_pwd', form);
  const password2 = Form.useWatch('second_pwd', form);
  const validationT = useTranslation(NSKeys.validation);
  const registrationT = useTranslation(NSKeys.registration);

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  return (
    <Form
      form={form}
      name="login_form_step2"
      initialValues={props.initialValues}
      onFinish={props.onNext}
      size="large"
      {...LAYOUT}>
      <Form.Item required label={registrationT.t('form.first_pwd')} name="first_pwd" rules={[yupSync]}>
        <Input.Password placeholder={registrationT.t('form.first_pwdPlaceholder')} />
      </Form.Item>
      <Form.Item
        required
        label={registrationT.t('form.second_pwd')}
        name="second_pwd"
        rules={[
          yupSync,
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('first_pwd') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(validationT.t('passwordNotMatch')));
            },
          }),
        ]}>
        <Input.Password placeholder={registrationT.t('form.second_pwdPlaceholder')} />
      </Form.Item>
      <Form.Item {...TAIL_LAYOUT}>
        <Row justify="space-between">
          <Button
            icon={<ArrowLeftOutlined />}
            disabled={!password1 || !password2 || password1 !== password2}
            onClick={props.onPrev}>
            {registrationT.t('btnBack')}
          </Button>
          <Button type="primary" htmlType="submit">
            {registrationT.t('btnNext')}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};
