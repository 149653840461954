import { Namespace, TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { IFormValues } from '../interfaces';

export const ValidationSchema = (t: TFunction<Namespace<'validation'>>): Yup.SchemaOf<IFormValues> =>
  Yup.object().shape({
    name: Yup.string().required(t('required')),
    type: Yup.string().required(t('required')),
    is_required: Yup.boolean().required(t('required')),
  });
