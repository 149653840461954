import { useInfiniteQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

export const useProcessQuery = () => {
  return useInfiniteQuery({
    queryKey: ['getProcessesSelect'],
    queryFn: async () => {
      return await Api.orders.getProcesses();
    },
    getNextPageParam: () => {
      return false;
    },
  });
};
