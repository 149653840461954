import React from 'react';

import { Image, Space, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { SystemSlugs } from '@app/models/dictionaries';
import { ViewProductSchema } from '@app/models/products';
import { isNullish } from '@app/services/isNullish';
import { DictionaryPostfix } from '@components/DictionaryPostfix';

export const BASE_COLUMNS: ColumnsType<ViewProductSchema> = [
  {
    title: 'articul',
    dataIndex: 'code',
    key: 'code',
    width: 100,
    ellipsis: true,
  },
  {
    width: 100,
    title: 'images',
    dataIndex: 'images',
    key: 'images',
    ellipsis: true,
    render: (_, product) => (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}>
        {product?.images.length ? (
          <Image.PreviewGroup items={product.images.map((el) => el.variants.fullscreen?.url || '')}>
            <Image src={product.images?.[0]?.variants?.thumbnailSmall?.url} />
          </Image.PreviewGroup>
        ) : (
          ''
        )}
      </div>
    ),
  },
  {
    width: 100,
    title: 'title',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
  },
  {
    width: 100,
    title: 'brand',
    dataIndex: 'brand',
    key: 'brand',
    ellipsis: true,
    render: (_, product) => product?.brand?.title,
  },
  {
    width: 200,
    title: 'categories',
    dataIndex: 'category',
    key: 'category',
    ellipsis: true,
    render: (_, product) => product.category?.title,
  },
  {
    width: 200,
    title: 'tags',
    dataIndex: 'tags',
    key: 'tags',
    ellipsis: true,
    render: (_, product) => (
      <Space size={10} wrap>
        {product?.tags?.map((el, i) => (
          <Tag key={i} style={{ margin: 0 }}>
            <Typography.Text ellipsis>{el.title}</Typography.Text>
          </Tag>
        ))}
      </Space>
    ),
  },
  {
    width: 100,
    title: 'multiplicity',
    dataIndex: 'package_amount',
    key: 'package_amount',
    ellipsis: true,
  },
  {
    width: 100,
    title: 'price',
    dataIndex: 'price',
    key: 'price',
    ellipsis: true,
    render: (_, product) => (
      <Typography.Text style={{ margin: 0 }}>
        {product.price || 0} <DictionaryPostfix dictionaryType={SystemSlugs.Currencies} />
      </Typography.Text>
    ),
  },
];
