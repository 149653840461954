/** Generates url search params string from object */
export const objectToSearchParams = (query?: object, addQuestion = true): string => {
  if (!query) {
    return '';
  }
  const searchParams = new URLSearchParams();
  Object.entries(query).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((el) => {
        searchParams.append(key, el);
      });
    } else if (typeof value !== 'undefined' && value !== '') {
      searchParams.append(key, value);
    }
  });

  const searchParamsString = searchParams.toString();
  if (!addQuestion) {
    return searchParamsString;
  }
  return searchParamsString ? '?' + searchParamsString : '';
};
