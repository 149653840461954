import { Button, Card, Flex, List } from 'antd';
import styled from 'styled-components';

export const StageContainer = styled(Flex)<{ $colorBgBase: string }>`
  background-color: ${({ $colorBgBase }) => $colorBgBase};
  padding: 12px;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 12px 12px 0 0;
  white-space: nowrap;
`;

export const StatusContainerStyles = styled.div<{ $colorBgBase: string; $statusColor: string }>`
  background-color: ${({ $colorBgBase }) => $colorBgBase};
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 264px;
  position: relative;

  &[aria-disabled='true'] {
    opacity: 0.2;
  }

  &:first-child {
    border-bottom-left-radius: 12px;
  }

  &:last-child {
    border-bottom-right-radius: 12px;
  }

  .header {
    border-top: ${({ $statusColor }) => `5px solid ${$statusColor}`};
    padding: 12px;
    margin: 0;
    text-transform: uppercase;
    background-color: ${({ $colorBgBase }) => $colorBgBase};
    position: sticky;
    z-index: 1;
    top: -24px;
  }
`;

export const CardsContainerStyles = styled.div<{ $colorBgBase: string }>`
  background-color: ${({ $colorBgBase }) => $colorBgBase};
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 300px;
  width: 240px;
  margin: 0 12px 12px 12px;
`;

export const CardStyled = styled(Card)`
  cursor: grab;

  .ant-card-body {
    padding: 12px;
  }
`;

export const GrabButton = styled(Button)`
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
`;

export const ListItemStyled = styled(List.Item)<{ $isColumn?: boolean; $isSmall?: boolean }>`
  &.ant-list-item {
    flex-direction: row;
    align-items: center;
    padding: 2px 0;
  }

  .ant-typography {
    font-family: InterRegular, sans-serif;
  }

  .listItemTitle {
    white-space: nowrap;
    margin-right: 10px;
  }
`;
