import React, { useState } from 'react';

import { EditTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Form, message, Modal, Space, theme, Typography } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';
import { useLocalDispatch, useLocalSelector } from '@app/services/hooks/useRedux';
import { getAppInitialData } from '@app/store/currentSessionReducer';
import { Status } from '@components/Status';

import { LAYOUT } from '../../constants';

import { ChangeModal } from './components/ChangeModal';

export const EmailSection: React.FC = () => {
  const [editOpen, setEditOpen] = useState(false);
  const { token } = theme.useToken();
  const profileT = useTranslation(NSKeys.profileSettings);
  const commonT = useTranslation(NSKeys.common);
  const { data } = useLocalSelector((state) => state.currentSession);
  const [modal, contextHolder] = Modal.useModal();
  const dispatch = useLocalDispatch();

  const handleEmailChange = () => {
    setEditOpen(false);

    modal.confirm({
      title: profileT.t('emailModalTitle2'),
      icon: <ExclamationCircleOutlined />,
      content: profileT.t('emailModalDescription2'),
      okText: commonT.t('confirm'),
      cancelButtonProps: { style: { display: 'none' } },
      afterClose: () => dispatch(getAppInitialData()),
    });
  };

  const handleSendConfirmation = async () => {
    await Api.users
      .postConfirmations()
      .then(() => {
        handleEmailChange();
      })
      .catch(() => {
        message.error(commonT.t('error400'));
      });
  };

  return (
    <Form {...LAYOUT}>
      <Form.Item label={profileT.t('emailLabel')}>
        <Space direction="horizontal" size={4} style={{ lineHeight: '32px', display: 'flex' }}>
          {data?.email}{' '}
          <EditTwoTone
            style={{ cursor: 'pointer' }}
            twoToneColor={token?.colorPrimary}
            onClick={() => setEditOpen(true)}
          />
        </Space>

        <Space direction="vertical" style={{ marginTop: '8px' }}>
          {data?.is_confirmed ? (
            <Space>
              <Status color={token.green} text={profileT.t('emailIsConfirmed')} />
            </Space>
          ) : (
            <Space direction="vertical" size={4}>
              <Status color={token.red} text={profileT.t('emailIsNotConfirmed')} />
              <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                <Typography.Text type="secondary">{profileT.t('emailResendTitle')}</Typography.Text>
                <Button type="link" onClick={handleSendConfirmation}>
                  {profileT.t('emailResendBtn')}
                </Button>
              </div>
            </Space>
          )}
        </Space>
      </Form.Item>
      <ChangeModal open={editOpen} onClose={() => setEditOpen(false)} onDone={handleEmailChange} />
      {contextHolder}
    </Form>
  );
};
