import React, { FC, forwardRef } from 'react';

import { theme } from 'antd';

import { getPhoneInputLanguage } from '@app/services/i18n';

import { Container, InputContainer, InputWrapper } from './styles';

interface IProps {
  value: string;
  name?: string;
  disabled?: boolean;
  hasError?: boolean;
  isHorizontal?: boolean;
  placeholder?: string;
  onChange: (e: string) => void;
}

export const PhoneInputContainer: FC<IProps> = forwardRef<HTMLInputElement, IProps>(
  ({ name, placeholder, disabled, isHorizontal, onChange, hasError, value }) => {
    const { token } = theme.useToken();

    return (
      <Container $isHorizontal={isHorizontal}>
        <InputContainer>
          <InputWrapper
            $colorBorder={token.colorBorder}
            $borderRadiusLG={token.borderRadiusLG}
            $colorTextDisabled={token.colorTextDisabled}
            $colorBgContainerDisabled={token.colorBgContainerDisabled}
            $colorErrorBorder={token.colorErrorBorder}
            $colorPrimaryHover={token.colorPrimaryHover}
            $colorBgContainer={token.colorBgContainer}
            value={value}
            disabled={disabled}
            hasError={hasError}
            onChange={(value) => onChange(value)}
            placeholder={placeholder}
            containerClass="containerClass"
            inputClass="inputClass"
            buttonClass="buttonClass"
            dropdownClass="dropdownClass"
            searchClass="searchClass"
            preferredCountries={['ru', 'us', 'br']}
            country={getPhoneInputLanguage()}
            inputProps={{
              name,
              disabled: disabled,
            }}
          />
        </InputContainer>
      </Container>
    );
  }
);

PhoneInputContainer.displayName = 'PhoneInputContainer';
