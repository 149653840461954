import { Modal } from 'antd';
import styled from 'styled-components';

export const ModalContainer = styled(Modal)<{ colorBorderSecondary: string }>`
  .ant-modal-header {
    margin-bottom: 48px;
  }
  .ant-modal-body {
    padding: 24px;
    margin: -24px;
    border-top: 1px solid ${({ colorBorderSecondary }) => colorBorderSecondary};
    border-bottom: 1px solid ${({ colorBorderSecondary }) => colorBorderSecondary};
  }

  .ant-modal-footer {
    margin-top: 48px;
  }
`;
