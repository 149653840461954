import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';

interface IProps {
  name: string;
}

export const useCreateGroupQuery = () => {
  const client = useQueryClient();
  const commonT = useTranslation(NSKeys.common);

  return useMutation({
    mutationFn: async ({ name }: IProps) => {
      await Api.users.postUserGroups({ name });
    },
    onError: () => {
      message.open({
        type: 'error',
        content: commonT.t('error400'),
      });
    },
    onSuccess: async () => {
      await client.invalidateQueries(['getGroups']);
      message.open({
        type: 'success',
        content: commonT.t('dataAddSuccess'),
      });
    },
  });
};
