import React, { FC, useState } from 'react';

import { MoreOutlined } from '@ant-design/icons';
import { theme as antTheme, Button, Flex, Popover } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { FieldStructure } from '@app/models/interfaces';

import { DeleteField } from './components/DeleteField';
import { EditFieldModal } from './components/EditFieldModal';

interface SettingsPopoverProps {
  field: FieldStructure;
}

export const FieldOptionsPopover: FC<SettingsPopoverProps> = ({ field }) => {
  const commonT = useTranslation(NSKeys.common);
  const [editFieldOpen, setEditFieldOpen] = React.useState(false);
  const [isOpen, setOpen] = useState(false);
  const { token } = antTheme.useToken();

  return (
    <>
      <Popover
        arrow={false}
        trigger="click"
        open={isOpen}
        onOpenChange={setOpen}
        destroyTooltipOnHide
        placement="bottomRight"
        overlayInnerStyle={{ padding: '8px' }}
        content={
          <Flex vertical={true} align="start">
            <Button type="text" onClick={() => setEditFieldOpen(true)}>
              {commonT.t('edit')}
            </Button>
            <DeleteField field_uid={field.uid} />
          </Flex>
        }>
        <Button
          type="text"
          icon={<MoreOutlined style={{ color: token.colorPrimary }} />}
          onClick={() => setOpen(true)}
        />
      </Popover>

      <EditFieldModal field={field} open={editFieldOpen} onClose={() => setEditFieldOpen(false)} />
    </>
  );
};
