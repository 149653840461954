import React from 'react';

import { Table, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';

import ReactDragListView from 'react-drag-listview';
import type { ResizeCallbackData } from 'react-resizable';

import { ResizableTitle } from '@components/ResizableTitle';

import { DRAG_HANDLER_CLASSNAME, DraggableTitleContainer } from './components/DraggableTitleContainer';

interface IProps<T> extends TableProps {
  localStorageName: string;
  showTableMenu?: boolean;
  columns: ColumnsType<T>;
  onColumnsChange: (columns: ColumnsType<T>) => void;
  actionColumn?: ColumnsType<T>;
}

export const TableDnDResizable = <T,>(props: IProps<T>) => {
  const handleResize =
    (index: number) =>
    (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
      const newColumns = [...props.columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width > 20 ? size.width : 20,
      };
      props.onColumnsChange(newColumns);
    };

  const handleDragEnd = (from: number, to: number) => {
    const fromIndex = !!props.rowSelection && from !== 0 ? from - 1 : from;
    const toIndex = !!props.rowSelection && to !== 0 ? to - 1 : to;
    // console.log(fromIndex, toIndex);
    // console.log(props.columns.length, props.columns);
    const copyColumns = JSON.parse(JSON.stringify(props.columns)).filter(Boolean);
    // console.log('copyColumns: ', copyColumns);
    const item = copyColumns.splice(fromIndex, 1)[0];
    // console.log('item: ', item);
    copyColumns.splice(toIndex, 0, item);
    // console.log(copyColumns);
    props.onColumnsChange(copyColumns);
  };

  return (
    <ReactDragListView.DragColumn
      onDragEnd={handleDragEnd}
      nodeSelector="th:not(.ant-table-cell-fix-left,.ant-table-cell-fix-right)"
      handleSelector={`.${DRAG_HANDLER_CLASSNAME}`}
      lineClassName="dragLine"
      ignoreSelector="react-resizable-handle">
      <Table
        {...props}
        columns={[
          ...props.columns.map((el, index) => ({
            ...el,
            onHeaderCell: (column: any) => ({
              width: column.width,
              onResize: handleResize(index) as React.ReactEventHandler<any>,
            }),
            title: () => <DraggableTitleContainer fixed={!!el?.fixed}>{el?.title as any}</DraggableTitleContainer>,
          })),
          ...(props.actionColumn ? props.actionColumn : []),
        ]}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
      />
    </ReactDragListView.DragColumn>
  );
};
