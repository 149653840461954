import React, { FC } from 'react';

import { Form, FormInstance, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { PhoneInput } from '@components/AntFields';
import { FormTitle } from '@components/FormTitle';

import { IFormValues } from './interfaces';
import { ValidationSchema } from './validation';

interface IProps {
  initialValues?: Partial<IFormValues>;
  onFinish: (formValues: IFormValues) => void;
  form: FormInstance;
}

export const FormContact: FC<IProps> = (props) => {
  const validationT = useTranslation(NSKeys.validation);
  const contactsT = useTranslation(NSKeys.contacts);

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  return (
    <Form
      form={props.form}
      name="contact_form"
      id="contactForm"
      initialValues={props.initialValues}
      onFinish={props.onFinish}
      autoComplete="off"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 8 }}>
      <Form.Item<IFormValues> name="name" required label={contactsT.t('form.nameLabel')} rules={[yupSync]}>
        <Input placeholder={contactsT.t('form.namePlaceholder')} />
      </Form.Item>
      <Form.Item<IFormValues> name="phone" required label={contactsT.t('form.phoneLabel')} rules={[yupSync]}>
        <PhoneInput />
      </Form.Item>
      <Form.Item<IFormValues> name="email" label={contactsT.t('form.emailLabel')} rules={[yupSync]}>
        <Input placeholder={contactsT.t('form.emailPlaceholder')} />
      </Form.Item>
    </Form>
  );
};
