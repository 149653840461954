import { useMemo } from 'react';

import { type UniqueIdentifier, useDroppable } from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { Flex, theme, Typography } from 'antd';

import { Task, TaskCard } from './TaskCard';
import { CardsContainerStyles, StatusContainerStyles } from './styles';

export interface Column {
  id: UniqueIdentifier;
  title: string;
  color: string;
}

export type ColumnType = 'Column';

export interface ColumnDragData {
  type: ColumnType;
  column: Column;
}

interface BoardColumnProps {
  column: Column;
  tasks: Task[];
  isOverlay?: boolean;
  isLoading?: boolean;
  isDisabled: boolean;
  onCardClick: (uid: string) => void;
}

export function BoardColumn({ column, tasks, isDisabled, onCardClick }: BoardColumnProps) {
  const { token } = theme.useToken();

  const tasksIds = useMemo(() => {
    return tasks.map((task) => task.id);
  }, [tasks]);

  const { setNodeRef } = useDroppable({
    id: column.id,
    data: {
      type: 'Column',
      column,
    } satisfies ColumnDragData,
  });

  return (
    <SortableContext items={tasksIds}>
      <StatusContainerStyles
        aria-disabled={isDisabled}
        $statusColor={column.color || token.colorPrimary}
        $colorBgBase={token.colorBgBase}
        ref={setNodeRef}>
        <Flex vertical={false} justify="space-between" className="header">
          <Typography.Paragraph style={{ margin: 0 }}>
            {column.title} {tasks.length > 0 && `(${tasks.length})`}
          </Typography.Paragraph>
        </Flex>
        <CardsContainerStyles $colorBgBase={token.colorBgLayout}>
          {tasks.map((task) => (
            <TaskCard key={task.id} task={task} onCardClick={onCardClick} />
          ))}
        </CardsContainerStyles>
      </StatusContainerStyles>
    </SortableContext>
  );
}
