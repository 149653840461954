import React from 'react';

import { Skeleton } from 'antd';

import { Paper } from '@components/Paper';

interface IProps {
  isPaper?: boolean;
  style?: React.CSSProperties;
}

export const FormSkeleton: React.FC<IProps> = ({ isPaper = true, style = { padding: '24px' } }) => {
  return (
    <div style={style}>
      {isPaper ? (
        <Paper>
          <Skeleton active />
        </Paper>
      ) : (
        <Skeleton active />
      )}
    </div>
  );
};
