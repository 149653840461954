import React from 'react';

import type { ColumnsType } from 'antd/es/table';
import { Namespace, TFunction } from 'react-i18next';

import { SystemSlugs } from '@app/models/dictionaries';
import { OrderItemSchema } from '@app/models/orders';
import { DictionaryPostfix } from '@components/DictionaryPostfix';

export const columns = (t: TFunction<Namespace<'order'>>): ColumnsType<OrderItemSchema> => [
  {
    title: '№',
    width: 50,
    dataIndex: 'num',
    render: (_, __, index) => index + 1,
    ellipsis: true,
  },
  {
    title: t('itemsTable.name'),
    dataIndex: 'name',
    sorter: (a, b) => a.product.title.localeCompare(b.product.title),
    ellipsis: true,
    render: (el, record) => <div>{record.product.title}</div>,
  },
  {
    title: t('itemsTable.articul'),
    dataIndex: 'articul',
    // sorter: (a, b) => a.product.code.localeCompare(b.product.code),
    ellipsis: true,
    render: (el, record) => <div>{record.product.code}</div>,
  },
  {
    title: (
      <>
        {t('itemsTable.volume')} <DictionaryPostfix dictionaryType={SystemSlugs.DimensionUnits} cubicVolume />
      </>
    ),
    dataIndex: 'volume',
    align: 'right',
    ellipsis: true,
  },
  {
    title: (
      <>
        {t('itemsTable.weight')} <DictionaryPostfix dictionaryType={SystemSlugs.MassUnits} />
      </>
    ),
    dataIndex: 'weight',
    align: 'right',
    ellipsis: true,
  },
  {
    title: t('itemsTable.count'),
    dataIndex: 'cart_count',
    key: 'cart_count',
    align: 'center',
    ellipsis: true,
    render: (el, record) => <div>{record.quantity}</div>,
  },
  {
    title: (
      <>
        {t('itemsTable.price')} (<DictionaryPostfix dictionaryType={SystemSlugs.Currencies} />)
      </>
    ),
    dataIndex: 'price',
    align: 'right',
    ellipsis: true,
  },
];
