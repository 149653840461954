import React, { useState } from 'react';

import { Select, SelectProps } from 'antd';

import { useWarehouseQuery } from './hooks/useWarehouseQuery';

interface IProps extends SelectProps {
  placeholder: string;
}

export const WarehouseSelect: React.FC<IProps> = ({ placeholder, ...props }) => {
  const { data, hasNextPage, fetchNextPage, isLoading, isFetching } = useWarehouseQuery({
    limit: 10,
  });

  const dataSource = data?.pages?.map((data) => data.items || []).flat() || [];

  return (
    <Select
      placeholder={placeholder}
      style={{ minWidth: '200px' }}
      onPopupScroll={(e) => {
        e.persist();
        const target = e.currentTarget;
        if (target?.scrollTop + target?.offsetHeight === target?.scrollHeight && hasNextPage) {
          fetchNextPage();
        }
      }}
      notFoundContent={null}
      options={dataSource?.map((el) => ({
        data: el,
        label: el.name,
        value: el.uid,
      }))}
      {...props}
    />
  );
};
