import React, { FC } from 'react';

import { Button, Form, Layout, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { useLocalSelector } from '@app/services/hooks/useRedux';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { PageTitle } from '@components/PageTitle';

import { FormRecipient, IFormValues } from '../../components/Form';
import { NEW_UID } from '../../data/contants';
import { useAddRecipientQuery } from '../../hooks';
import { useRecipientContacts } from '../../providers';

export const Add: FC = () => {
  const commonT = useTranslation(NSKeys.common);
  const recipientsT = useTranslation(NSKeys.recipients);
  const navigate = useNavigate();
  const [form] = Form.useForm<IFormValues>();
  const goBack = () => navigate('/dashboard/recipients');
  const { data: currentSession } = useLocalSelector((state) => state.currentSession);
  const { mutate: createRecipient, status, data } = useAddRecipientQuery();
  const { data: contacts, editingUid } = useRecipientContacts();

  const handleSubmit = async (formValues: IFormValues): Promise<void> => {
    createRecipient({
      ...formValues,
      retailer_uid: formValues.retailer_uid || null,
      type_uid: formValues.type_uid || null,
      tags_uids: formValues.tags_uids || [],
      contacts: contacts.filter((el) => el.uid !== NEW_UID),
      address: {
        provider_uid: formValues.provider_uid,
        provider_type: formValues.provider_type as any,
        street_name: formValues.street_name,
        street_number: formValues.street_number,
        place_name: formValues.place_name || null,
      },
    });
  };

  React.useEffect(() => {
    if (status === 'success') {
      goBack();
      form.resetFields();
    }
  }, [status, data]);

  return (
    <>
      <Layout.Header>
        <Breadcrumbs />
        <Row justify="space-between" align="middle">
          <PageTitle title={recipientsT.t('titleAdd')} onClick={goBack} />
          <Space size="middle">
            <Button onClick={goBack} size="middle" name="catalog_form" id="catalog_form">
              {commonT.t('buttonCancel')}
            </Button>
            <Button
              disabled={status === 'loading' || !!editingUid}
              type="primary"
              size="middle"
              form="catalogForm"
              htmlType="submit">
              {commonT.t('buttonSave')}
            </Button>
          </Space>
        </Row>
      </Layout.Header>
      <FormRecipient
        form={form}
        onFinish={handleSubmit}
        initialValues={{ provider_type: currentSession?.scope?.settings?.address_provider }}
      />
    </>
  );
};
