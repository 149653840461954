export enum Theme {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum Currency {
  DOLLAR = 'dollar',
  RUBLE = 'ruble',
  BR_REAL = 'brReal',
}

export enum LengthUnit {
  meter = 'meter',
}

export enum DateFormat {
  shortSlash = 'MM/DD/YYYY',
  shortDot = 'MM.DD.YYYY',
  full = 'D MMMM YYYY',
}

export type WeekStart = 0 | 1 | 2 | 3 | 4 | 5 | 6;
