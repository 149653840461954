import React, { Fragment } from 'react';

import { EditOutlined, IdcardTwoTone, NotificationTwoTone, SecurityScanTwoTone } from '@ant-design/icons';
import { Button, Layout, Row, Space, theme, Typography } from 'antd';
import type { TabsProps } from 'antd/es/tabs';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { Notifications } from './components/Notifications';
import { PersonContainer } from './components/PersonContainer';
import { UpdatePassword } from './components/UpdatePassword';
import { Container, Tabs } from './styles';

export const ProfileSettings: React.FC = () => {
  const [tab, setActiveTab] = React.useState('personalInfo');
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [isPersonalInfoEditing, setIsPersonalInfoEditing] = React.useState(false);
  const { token } = theme.useToken();
  const commonT = useTranslation(NSKeys.common);
  const profileT = useTranslation(NSKeys.profileSettings);

  const ITEMS: TabsProps['items'] = [
    {
      key: 'personalInfo',
      label: (
        <Space>
          <IdcardTwoTone twoToneColor={token?.colorPrimary} />
          {profileT.t('tabId')}
        </Space>
      ),
      children: (
        <Container>
          <PersonContainer
            isEditing={isPersonalInfoEditing}
            onSubmit={() => {
              setFormSubmitting(false);
            }}
          />
        </Container>
      ),
    },
    {
      key: 'password',
      label: (
        <Space>
          <SecurityScanTwoTone twoToneColor={token?.colorPrimary} />
          {profileT.t('tabSecurity')}
        </Space>
      ),
      children: (
        <Container>
          <UpdatePassword />
        </Container>
      ),
    },
    {
      key: 'notification',
      label: (
        <Space>
          <NotificationTwoTone twoToneColor={token?.colorPrimary} />
          {profileT.t('tabNotifications')}
        </Space>
      ),
      children: (
        <Container>
          <Notifications />
        </Container>
      ),
    },
  ];

  const HEADER_BUTTON = React.useCallback((): React.ReactNode => {
    switch (tab) {
      case 'personalInfo':
        return (
          <Space size="middle">
            {isPersonalInfoEditing ? (
              <Fragment>
                <Button
                  disabled={false}
                  type="primary"
                  size="middle"
                  loading={formSubmitting}
                  form="profile_form"
                  key="submit"
                  htmlType="submit">
                  {commonT.t('buttonSave')}
                </Button>
                <Button onClick={() => setIsPersonalInfoEditing(false)} size="middle">
                  {commonT.t('buttonCancel')}
                </Button>
              </Fragment>
            ) : (
              <Button
                icon={<EditOutlined />}
                onClick={() => setIsPersonalInfoEditing(true)}
                size="middle"
                type="primary">
                {commonT.t('edit')}
              </Button>
            )}
          </Space>
        );
      default:
        return null;
    }
  }, [tab, isPersonalInfoEditing]);

  return (
    <Layout>
      <Layout.Header style={{ border: 0 }}>
        <Row justify="space-between" align="middle">
          <Typography.Title level={2} className="title" style={{ marginBottom: 0 }}>
            {profileT.t('title')}
          </Typography.Title>
          {HEADER_BUTTON()}
        </Row>
      </Layout.Header>
      <Layout.Content>
        <Tabs defaultActiveKey={tab} items={ITEMS} onChange={setActiveTab} $colorBgBase={token.colorBgBase} />
      </Layout.Content>
    </Layout>
  );
};
