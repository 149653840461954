import React, { useState } from 'react';

import { Select } from 'antd';

import { SystemDictionarySlugs } from '@app/models/products';
import Api from '@app/services/api';

interface IProps {
  dictionaryName: SystemDictionarySlugs;
  placeholder: string;
  value: string[];
  onSelect: (values: string[]) => void;
}

export const DictionarySelect: React.FC<IProps> = (props) => {
  // const isLoading = useLocalSelector((state) => state.products.isLoading);
  const [options, setOptions] = React.useState<{ label: string; value: number }[]>([]);
  const [fetching, setFetching] = React.useState(false);
  const [error, setError] = useState(false);

  React.useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async (): Promise<void> => {
    setFetching(true);
    await Api.products
      .getDictionaryList(props.dictionaryName)
      .then((res) => {
        const newOptions = [...options, ...res.map((el) => ({ value: el.id, label: el.title }))];
        setOptions(newOptions);
      })
      .catch(() => setError(true))
      .finally(() => setFetching(false));
  };

  return (
    <Select
      disabled={error}
      value={props.value}
      mode="multiple"
      allowClear
      placeholder={props.placeholder}
      loading={fetching}
      options={options}
      dropdownStyle={{ position: 'fixed' }}
      onChange={props.onSelect}
    />
  );
};
