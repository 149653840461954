import React from 'react';

import { ColorPicker, Form, Image, Radio, Space, Typography } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { useLocalSelector } from '@app/services/hooks/useRedux';

interface IProps {
  test?: string;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const BrandInfo: React.FC<IProps> = () => {
  const { scopeTitle, theme, colorPrimary } = useLocalSelector((state) => state.currentSession);
  const commonT = useTranslation(NSKeys.common);
  const spaceT = useTranslation(NSKeys.spaceSettings);
  const currentSession = useLocalSelector((state) => state.currentSession);

  return (
    <React.Fragment>
      <Form {...layout}>
        <Form.Item label={spaceT.t('titleLabel')}>{scopeTitle}</Form.Item>
        <Form.Item label={spaceT.t('colorLabel')}>
          <ColorPicker value={colorPrimary} disabled />
        </Form.Item>
        <Form.Item label={spaceT.t('themeLabel')}>
          <Radio.Group
            options={[
              { label: commonT.t('light'), value: 'light' },
              { label: commonT.t('dark'), value: 'dark' },
            ]}
            disabled={true}
            value={theme}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        <Form.Item label={spaceT.t('logoLabel')} className="formItemAlignedLabel">
          <Space size={20}>
            <Image
              src={currentSession?.data?.scope?.logo?.variants?.medium?.url}
              preview={false}
              style={{ maxHeight: '85px', maxWidth: '85px' }}
            />
          </Space>
        </Form.Item>
        <Form.Item label={spaceT.t('favIconLabel')} className="formItemAlignedLabel">
          <Space size={20}>
            <Image
              src={currentSession?.data?.scope?.favicon?.variants?.small?.url}
              preview={false}
              style={{ maxHeight: '85px', maxWidth: '85px' }}
            />
          </Space>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};
