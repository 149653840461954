import React, { useContext, useEffect, useRef, useState } from 'react';

import { Form, InputRef } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { RetrieveUserGroupSchema } from '@app/models/users';

import { getFormErrorsFromResponse } from '@app/services/getFormErrorsFromResponse';

import { useCreateGroupQuery, useUpdateGroupQuery } from '../../../../hooks';
import { NEW_GROUP_ID } from '../../../../providers/constants';

import { EditableContext } from '../EditableContext';

import { Input } from './styles';

interface IProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof RetrieveUserGroupSchema;
  record: RetrieveUserGroupSchema;
  onSaveSuccess: () => void;
  dataSource: RetrieveUserGroupSchema[];
}

export const EditableCell: React.FC<IProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  onSaveSuccess,
  dataSource,
  ...restProps
}) => {
  // const validationT = useTranslation(NSKeys.validation);
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext);
  const validationT = useTranslation(NSKeys.validation);
  const { mutate: createGroup, status: createStatus, failureReason: createFailureReason } = useCreateGroupQuery();
  const { mutate: updateGroup, status: updateStatus, failureReason: updateFailureReason } = useUpdateGroupQuery();

  useEffect(() => {
    if (record?.uid === NEW_GROUP_ID) {
      toggleEdit();
    }
  }, []);

  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus();
    }
  }, [editing]);

  useEffect(() => {
    if (createStatus === 'success' || updateStatus === 'success') {
      onSaveSuccess();
      toggleEdit();
    }
  }, [createStatus, updateStatus]);

  useEffect(() => {
    if (form && updateFailureReason) {
      const formValues = form.getFieldsValue();
      const { pointerErrors } = getFormErrorsFromResponse(formValues, updateFailureReason, validationT.t);
      if (pointerErrors.length) {
        form.setFields(pointerErrors);
      }
    }
  }, [updateFailureReason]);

  useEffect(() => {
    if (form && createFailureReason) {
      const formValues = form.getFieldsValue();
      const { pointerErrors } = getFormErrorsFromResponse(formValues, createFailureReason, validationT.t);
      if (pointerErrors.length) {
        form.setFields(pointerErrors);
      }
    }
  }, [createFailureReason]);

  const toggleEdit = () => {
    if (form && record && dataIndex) {
      setEditing(!editing);
      form?.setFieldsValue({ [dataIndex]: record[dataIndex] });
    }
  };

  const handleSave = async () => {
    try {
      const formValues = await form?.validateFields();
      if (record.uid === NEW_GROUP_ID) {
        createGroup(formValues);
      } else if (record.name !== formValues.name) {
        updateGroup({ id: record.uid, data: formValues });
      }
    } catch (e) {
      return;
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: validationT.t('required'),
          },
        ]}>
        <Input ref={inputRef} onPressEnter={handleSave} onBlur={handleSave} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
