import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Avatar, Menu } from 'antd';
import { useTranslation } from 'react-i18next';

import { useLocation, useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { useLocalDispatch, useLocalSelector } from '@app/services/hooks/useRedux';
import { resetData, setAuthenticated } from '@app/store/currentSessionReducer';

interface IProps {
  collapsed: boolean;
  userName?: string;
}

enum MenuKeys {
  USER_SUB = 'userMenu',
  ACCOUNT = '/dashboard/account',
  PROFILE = '/dashboard/profile',
  LOGOUT = 'logout',
}

export const UserMenu: React.FC<IProps> = ({ collapsed, userName }) => {
  // const [key, setKey] = React.useState('_');
  const dispatch = useLocalDispatch();
  const [activeKey, setActiveKey] = React.useState<string[]>([]);
  const currentSession = useLocalSelector((state) => state.currentSession);
  const navigate = useNavigate();
  const selectedKey = useLocation().pathname;
  const commonT = useTranslation(NSKeys.common);

  const handleLogOut = () => {
    localStorage.clear();
    dispatch(resetData());
    dispatch(setAuthenticated(false));
    navigate('/login');
  };

  // убирает выделение при выборе из другого меню
  React.useEffect(() => {
    const [_, url1, url2] = selectedKey.split('/');
    const res = '/' + [url1, url2].join('/');

    setActiveKey([[MenuKeys.PROFILE, MenuKeys.ACCOUNT].some((key) => key === res) ? MenuKeys.USER_SUB : '']);
  }, [selectedKey]);

  return (
    <Menu
      activeKey={selectedKey}
      selectedKeys={activeKey}
      mode="vertical"
      style={{ marginBottom: 8 }}
      items={[
        {
          label: !collapsed && userName,
          icon: (
            <React.Fragment>
              <Avatar
                size={32}
                src={currentSession?.data?.avatar?.variants?.big?.url}
                icon={<UserOutlined />}
                style={{ marginLeft: collapsed ? '-7px' : 0, marginRight: '10px' }}
              />
            </React.Fragment>
          ),
          key: MenuKeys.USER_SUB,
          children: [
            {
              label: commonT.t('menuTitleAccount'),
              key: MenuKeys.ACCOUNT,
              style: {
                display:
                  !currentSession?.data?.scope?.$permissions?.view ||
                  !currentSession.data?.scope?.$permissions?.view_roles
                    ? 'none'
                    : undefined,
              },
              onClick: () => navigate(MenuKeys.ACCOUNT),
            },
            {
              label: commonT.t('menuTitleProfile'),
              key: MenuKeys.PROFILE,
              onClick: () => navigate(MenuKeys.PROFILE),
            },
            {
              label: commonT.t('logout'),
              key: MenuKeys.LOGOUT,
              onClick: handleLogOut,
            },
          ],
        },
      ]}
    />
  );
};
