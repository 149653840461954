import React, { useEffect, useState } from 'react';

import { ColProps, Form, Select } from 'antd';

import { ItemSchema, SystemSlugs } from '@app/models/dictionaries';
import Api from '@app/services/api';
import { useLocalSelector } from '@app/services/hooks/useRedux';

interface IProps {
  name: string;
  placeholder: string;
  label: string;
  dictionaryName: SystemSlugs;
  required: boolean;
  withToken?: boolean;
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  pkAsValue?: boolean;
  rules?: any;
}

const LIMIT = 15;

export const DictionarySelector: React.FC<IProps> = ({ withToken = true, pkAsValue = false, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<ItemSchema[]>([]);
  const [offset, setOffset] = useState(0);
  const lang = useLocalSelector((state) => state.currentSession.lang);

  const fetchSelectLists = (): void => {
    setLoading(true);
    Api.dictionaries
      .getList({ name: props.dictionaryName, limit: LIMIT, offset, lang })
      .then((res) => {
        const newOptions = [...options, ...res.items];
        if (newOptions.length < (res.total || 0)) {
          setOffset(offset + LIMIT);
        }
        setOptions(newOptions);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchSelectLists();
  }, [offset]);

  return (
    <Form.Item
      name={props.name}
      labelCol={props.labelCol}
      wrapperCol={props.wrapperCol}
      label={props.label}
      required={props.required}
      rules={props.rules}>
      <Select
        placeholder={props.placeholder}
        options={options.map((el) => ({ label: el['title'], value: pkAsValue ? el.pk : el.value }))}
        loading={loading}
      />
    </Form.Item>
  );
};
