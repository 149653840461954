import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { useCreateFieldGroup } from '../../../../../../hooks';

import { ValidationSchema } from './data/validation';
import { IFormValues } from './interfaces';

interface IAddGroupFieldModalpProps {
  order_structure_uid: string;
}

export const AddGroupFieldModal: React.FC<IAddGroupFieldModalpProps> = ({ order_structure_uid }) => {
  const ordersT = useTranslation(NSKeys.orders);
  const validationT = useTranslation(NSKeys.validation);
  const commonT = useTranslation(NSKeys.common);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm<IFormValues>();
  const { mutate, status } = useCreateFieldGroup();

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  React.useEffect(() => {
    if (status === 'success') {
      setOpen(false);
    }
  }, [status]);

  return (
    <div style={{ display: 'inline-block' }}>
      <Button type="primary" icon={<PlusOutlined />} onClick={() => setOpen(true)} size="middle">
        {ordersT.t('addFieldGroupButton')}
      </Button>
      <Modal
        title={
          <Space align="center">
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
              {ordersT.t('addFieldGroupTitle')}
            </Typography.Title>
          </Space>
        }
        open={open}
        destroyOnClose
        onOk={() => form.submit()}
        okButtonProps={{
          loading: status === 'loading',
        }}
        okText={commonT.t('buttonAdd')}
        confirmLoading={status === 'loading'}
        onCancel={() => setOpen(false)}>
        <Form
          form={form}
          onFinish={(values) => mutate({ order_structure_uid, name: values.name })}
          name="create_group_field"
          layout="vertical">
          <Form.Item<IFormValues>
            name="name"
            label={<Typography.Text type="secondary">{ordersT.t('fieldGroupName')}</Typography.Text>}
            rules={[yupSync]}>
            <Input style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
