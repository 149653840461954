import { IOrderByProcessParams, IOrdersParams } from '@app/models/interfaces';
import {
  ActionKind,
  ApplyActionSchema,
  ApproveOrderParamsDTO,
  CancelOrderParamsDTO,
  ContextSchema,
  CreateAccessSettingsDTO,
  CreateOrderDTO,
  CreateProcessDTO,
  MultipleMoveToStatusSchema,
  OrderExportResponseSchema,
  OrderHistorySchema,
  PartialUpdateStageStatusSchema,
  ProcessAccessSettingsSchema,
  ProcessStageSchema,
  RearrangeFieldDTO,
  RearrangeStagesDTO,
  RecordsListGenericSchemaRetrieveManyProcessSchema,
  RecordsListGenericSchemaRetrieveOrderSchema,
  RetrieveOrderSchema,
  RetrieveProcessSchema,
  UpdateOrderParamsDTO,
  UpdateProcessAccessSettingsDTO,
} from '@app/models/orders';
import { handleRequest, httpClient } from '@app/services/httpClient';
import { objectToSearchParams } from '@app/services/objectToSearchParams';

import { IProps } from '../interfaces';

class Orders {
  private readonly endPoint: string;

  constructor({ baseEndPoint }: IProps) {
    this.endPoint = baseEndPoint;
  }

  async postOrder(cart_uid: string, data: CreateOrderDTO): Promise<RetrieveOrderSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/carts/${cart_uid}/create-order`, data));
  }

  async postProcess(data: CreateProcessDTO): Promise<RetrieveProcessSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/processes`, data));
  }

  async postProcessStage(uid: string, data: CreateProcessDTO): Promise<ProcessStageSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/processes/${uid}/stages`, data));
  }

  async postProcessRearrangeStage(uid: string, data: RearrangeStagesDTO): Promise<RetrieveProcessSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/processes/${uid}/rearrange-stages`, data));
  }

  async updateProcess(process_uid: string, data: CreateProcessDTO): Promise<RetrieveProcessSchema> {
    return handleRequest(httpClient.put(`${this.endPoint}/processes/${process_uid}`, data));
  }

  async getOrders(params: IOrdersParams): Promise<RecordsListGenericSchemaRetrieveOrderSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/orders${objectToSearchParams(params)}`));
  }

  async getOrder(order_uid: string): Promise<RetrieveOrderSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/orders/${order_uid}`));
  }

  async getHistory(order_uid: string): Promise<OrderHistorySchema[]> {
    return handleRequest(httpClient.get(`${this.endPoint}/orders/${order_uid}/history`));
  }

  async cancelOrder(order_uid: string, data: CancelOrderParamsDTO): Promise<RetrieveOrderSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/orders/${order_uid}/cancel`, data));
  }

  async approveOrder(order_uid: string, data: ApproveOrderParamsDTO): Promise<RetrieveOrderSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/orders/${order_uid}/approve`, data));
  }

  async markAsNeedWork(order_uid: string, data: ApproveOrderParamsDTO): Promise<RetrieveOrderSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/orders/${order_uid}/mark-as-needs-work`, data));
  }

  async applyAction(data: ApplyActionSchema, order_uid: string, kind: ActionKind): Promise<string> {
    return handleRequest(httpClient.post(`${this.endPoint}/orders/${order_uid}/actions/${kind}`, data));
  }

  async getContext(): Promise<ContextSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/context`));
  }

  async updateOrder(order_uid: string, data: UpdateOrderParamsDTO): Promise<RetrieveOrderSchema> {
    return handleRequest(httpClient.put(`${this.endPoint}/orders/${order_uid}`, data));
  }

  async exportOrders(): Promise<OrderExportResponseSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/export-orders`));
  }

  async getProcesses(): Promise<RecordsListGenericSchemaRetrieveManyProcessSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/processes`));
  }

  async getProcess(uid: string): Promise<RetrieveProcessSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/processes/${uid}`));
  }

  async getProcessAccessSettings(uid: string): Promise<ProcessAccessSettingsSchema[]> {
    return handleRequest(httpClient.get(`${this.endPoint}/processes/${uid}/access-settings`));
  }

  async getOrdersByProcess({
    process_uid,
    ...rest
  }: IOrderByProcessParams): Promise<RecordsListGenericSchemaRetrieveOrderSchema> {
    return handleRequest(
      httpClient.get(`${this.endPoint}/processes/${process_uid}/orders${objectToSearchParams(rest)}`)
    );
  }

  async postMoveToStatus(order_uid: string, status_uid: string): Promise<null> {
    return handleRequest(
      httpClient.post(`${this.endPoint}/orders/${order_uid}/move-to-status`, {
        to_status_uid: status_uid,
      })
    );
  }

  async postMultipleMoveToStatus(data: MultipleMoveToStatusSchema, process_uid: string): Promise<null> {
    return handleRequest(httpClient.post(`${this.endPoint}/processes/${process_uid}/multiple-move-to-status`, data));
  }

  async putStages(stage_uid: string, name: string): Promise<null> {
    return handleRequest(httpClient.put(`${this.endPoint}/stages/${stage_uid}`, { name }));
  }

  async patchStatus(status_uid: string, data: PartialUpdateStageStatusSchema): Promise<null> {
    return handleRequest(httpClient.patch(`${this.endPoint}/statuses/${status_uid}`, data));
  }

  async deleteFieldGroup(field_group_uid: string): Promise<null> {
    return handleRequest(httpClient.delete(`${this.endPoint}/field-groups/${field_group_uid}`));
  }

  async postRearrangeFieldGroup(field_group_uid: string, data: RearrangeFieldDTO): Promise<null> {
    return handleRequest(httpClient.post(`${this.endPoint}/field-groups/${field_group_uid}/rearrange-field`, data));
  }

  async deleteField(field_uid: string): Promise<null> {
    return handleRequest(httpClient.delete(`${this.endPoint}/fields/${field_uid}`));
  }

  async deleteAccessSettingsGroup(group_uid: string): Promise<null> {
    return handleRequest(httpClient.delete(`${this.endPoint}/access-settings/${group_uid}`));
  }

  async createFieldGroup(order_structure_uid: string, name: string): Promise<null> {
    return handleRequest(
      httpClient.post(`${this.endPoint}/order-structures/${order_structure_uid}/field-groups`, {
        name,
      })
    );
  }

  async createAccessProcessSettings(process_uid: string, data: CreateAccessSettingsDTO): Promise<null> {
    return handleRequest(httpClient.post(`${this.endPoint}/processes/${process_uid}/access-settings`, data));
  }

  async putFieldGroup(field_group_uid: string, name: string): Promise<null> {
    return handleRequest(httpClient.put(`${this.endPoint}/field-groups/${field_group_uid}`, { name }));
  }

  async putAccessSettingsGroup(uid: string, data: UpdateProcessAccessSettingsDTO): Promise<null> {
    return handleRequest(httpClient.put(`${this.endPoint}/access-settings/${uid}`, data));
  }

  async putField(field_uid: string, name: string, type: string, is_required: boolean): Promise<null> {
    return handleRequest(
      httpClient.put(`${this.endPoint}/fields/${field_uid}`, { type, name, settings: null, is_required })
    );
  }

  async createField({
    field_group_uid,
    name,
    type,
    is_required,
  }: {
    field_group_uid: string;
    type: string;
    name: string;
    is_required: boolean;
  }): Promise<null> {
    return handleRequest(
      httpClient.post(`${this.endPoint}/field-groups/${field_group_uid}/fields`, {
        name,
        type,
        is_required,
        settings: null,
      })
    );
  }
}

export default Orders;
