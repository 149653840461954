import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  .title {
    margin: 0;
    white-space: break-spaces;
  }

  .titleSpan {
    margin-left: 12px;
    flex: none;
  }

  .ant-skeleton-element {
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;

export const Actions = styled.div`
  margin-left: 14px;
`;
