import React, { FC } from 'react';

import { DragOutlined } from '@ant-design/icons';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Card, Divider, Flex } from 'antd';

import { ProcessStageSchema } from '@app/models/orders';

import { StageText } from '../StageText';
import { StatusBlock } from '../StatusBlock';

export const CardRender: FC<ProcessStageSchema> = ({ uid, name, statuses }) => {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ id: uid });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? '0.6' : '1',
    zIndex: 100,
  };

  return (
    <Card ref={setNodeRef} key={uid} styles={{ body: { padding: '12px 24px' } }} style={style}>
      <Flex vertical gap={4} justify="start" align="baseline">
        <Flex style={{ width: '100%' }} justify="space-between">
          <StageText name={name} stage_uid={uid} />
          <Button
            type="text"
            icon={<DragOutlined style={{ opacity: 0.6 }} />}
            {...attributes}
            {...listeners}
            style={{ cursor: 'grab' }}
          />
        </Flex>
        <Divider plain style={{ margin: '4px' }} />
        {statuses.map((status) => (
          <StatusBlock name={status.name} status_uid={status.uid} color={status.color} key={status.uid} />
        ))}
      </Flex>
    </Card>
  );
};
