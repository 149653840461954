import styled from 'styled-components';

export const Container = styled.div`
  .ant-collapse-small > .ant-collapse-item > .ant-collapse-header,
  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box,
  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding-block: 0;
    padding: 0;
  }
  .ant-collapse-expand-icon {
    margin-top: 5px;
  }
  //.ant-collapse-content-box {
  //  margin-left: -12px;
  //}
`;
