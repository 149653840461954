import { useQuery } from '@tanstack/react-query';

import { IOrderApprovalChainsParams } from '@app/models/interfaces';
import Api from '@app/services/api';

export const useGetOrderApprovalChainUsageQuery = (order_chain_approval_uid: string) => {
  return useQuery({
    queryKey: ['getOrderApprovalChainUsage', order_chain_approval_uid],
    queryFn: () => Api.users.getOrderApprovalChainUsage(order_chain_approval_uid),
    enabled: !!order_chain_approval_uid,
  });
};
