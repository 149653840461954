import React, { FC } from 'react';

import { Skeleton } from 'antd';

type SizeType = 'l' | 'm';

interface IProps {
  size?: SizeType;
}

export const getHeight = (size: SizeType) => {
  switch (size) {
    case 'm':
      return '254px';
    default:
      return '438px';
  }
};

export const SkeletonImage: FC<IProps> = ({ size = 'l' }) => {
  return <Skeleton.Image active={true} style={{ width: '254px', height: getHeight(size), margin: '0 24px 24px 0' }} />;
};
