import React from 'react';

import { Layout, Row, Skeleton } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { SystemSlugs } from '@app/models/dictionaries';
import { DictionaryPostfix } from '@components/DictionaryPostfix';
import { HeaderTitle } from '@components/HeaderTitle';
import { SingleProductInfo } from '@components/products';

import { DropDownMenu } from '../../components/DropDownMenu';
import { useGetProductQuery } from '../../hooks';

export const SingleProduct: React.FC = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { data, status, isLoading } = useGetProductQuery(params?.id || '');

  React.useEffect(() => {
    if (status === 'error') {
      navigate('/dashboard/nomenclature');
    }
  }, [status]);

  return (
    <Layout>
      <Layout.Header>
        <Row justify="space-between" align="middle">
          <HeaderTitle
            loading={isLoading || !data}
            title={data?.title || ''}
            titleSpan={
              <>
                {data?.price || 0} <DictionaryPostfix dictionaryType={SystemSlugs.Currencies} />
              </>
            }
            onClick={() => navigate('/dashboard/products')}
          />
          <DropDownMenu
            uid={params.id || ''}
            type="primary"
            editable={!!data?.$permissions?.update}
            addToCatalog={!!data?.$permissions?.add_to_catalog}
          />
        </Row>
      </Layout.Header>
      <Layout.Content>
        {isLoading || !data ? <Skeleton active={true} style={{ padding: 24 }} /> : <SingleProductInfo product={data} />}
      </Layout.Content>
    </Layout>
  );
};
