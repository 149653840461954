import { ICatalogsParams, IProductsParams } from '@app/models/interfaces';
import {
  CartSchema,
  CatalogSchema,
  CatalogSettingsSchema,
  ContextSchema,
  CreateCatalogSchema,
  CreateProductSchema,
  DeleteCartItemResponseSchema,
  DictionaryItemSchema,
  DictionarySchema,
  RecordsListGenericSchemaCatalogSchema,
  RecordsListGenericSchemaViewProductInCatalogSchema,
  RecordsListGenericSchemaViewProductSchema,
  RemoveCartItemsDTO,
  SystemDictionarySlugs,
  UpdateCartItemDTO,
  UpdateCartItemResponseSchema,
  UpdateProductSchema,
  ViewProductSchema,
} from '@app/models/products';

import { handleRequest, httpClient } from '@app/services/httpClient';
import { objectToSearchParams } from '@app/services/objectToSearchParams';

import { IProps } from '../interfaces';

class Products {
  private readonly endPoint: string;

  constructor({ baseEndPoint }: IProps) {
    this.endPoint = baseEndPoint;
  }

  async getProducts(params: IProductsParams): Promise<RecordsListGenericSchemaViewProductSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/products${objectToSearchParams(params)}`));
  }

  async getProduct(id: string): Promise<ViewProductSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/products/${id}`));
  }
  async postProduct(data: CreateProductSchema): Promise<ViewProductSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/products`, data));
  }
  async putProduct(data: UpdateProductSchema, id: string): Promise<ViewProductSchema> {
    return handleRequest(httpClient.put(`${this.endPoint}/products/${id}`, data));
  }

  async getDictionaryList(dictionaryName: SystemDictionarySlugs): Promise<DictionaryItemSchema[]> {
    return handleRequest(httpClient.get(`${this.endPoint}/dictionaries/${dictionaryName}/items`));
  }

  async postDictionaryTitle(dictionaryName: SystemDictionarySlugs, title: string): Promise<DictionaryItemSchema[]> {
    return handleRequest(httpClient.post(`${this.endPoint}/dictionaries/${dictionaryName}/items`, { title }));
  }

  async deleteDictionaryItem(id: number): Promise<any> {
    return handleRequest(httpClient.delete(`${this.endPoint}/dictionary-items/${id}`));
  }

  async getCatalogs(params: ICatalogsParams): Promise<RecordsListGenericSchemaCatalogSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/catalogs${objectToSearchParams(params)}`));
  }

  async getCatalog(catalog_id: string): Promise<CatalogSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/catalogs/${catalog_id}`));
  }

  async getCatalogSettings(catalog_id: string): Promise<CatalogSettingsSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/catalogs/${catalog_id}/settings`));
  }

  async postCatalog(catalog: CreateCatalogSchema): Promise<CreateCatalogSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/catalogs`, catalog));
  }

  async putCatalog(id: string, catalog: CreateCatalogSchema): Promise<CreateCatalogSchema> {
    return handleRequest(httpClient.put(`${this.endPoint}/catalogs/${id}`, catalog));
  }

  async putProductToCatalog(catalog_uid: string, product_uid: string): Promise<any> {
    return handleRequest(httpClient.put(`${this.endPoint}/catalogs/${catalog_uid}/products`, { product_uid }));
  }

  async createProductFromCatalog(catalog_uid: string, data: CreateProductSchema): Promise<ViewProductSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/catalogs/${catalog_uid}/products`, data));
  }

  async deleteProductFromCatalog(catalog_uid: string, product_uid: string): Promise<any> {
    return handleRequest(httpClient.delete(`${this.endPoint}/catalogs/${catalog_uid}/products/${product_uid}`));
  }

  async getProductFromCatalog(catalog_uid: string, product_uid: string): Promise<ViewProductSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/catalogs/${catalog_uid}/products/${product_uid}`));
  }

  async getCart(catalog_uid: string, include_items = false): Promise<CartSchema> {
    return handleRequest(
      httpClient.get(`${this.endPoint}/catalogs/${catalog_uid}/cart?include_items=${include_items}`)
    );
  }

  async postUpdateCartItem(catalog_uid: string, data: UpdateCartItemDTO): Promise<UpdateCartItemResponseSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/catalogs/${catalog_uid}/update-cart-item`, data));
  }

  async postRemoveItems(cart_uid: string, data: RemoveCartItemsDTO): Promise<DeleteCartItemResponseSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/carts/${cart_uid}/remove-items`, data));
  }

  async getContext(): Promise<ContextSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/context`));
  }

  async getDictionaries(): Promise<DictionarySchema[]> {
    return handleRequest(httpClient.get(`${this.endPoint}/dictionaries`));
  }

  async getCatalogProducts(
    params: IProductsParams,
    catalog_uid: string
  ): Promise<RecordsListGenericSchemaViewProductInCatalogSchema> {
    return handleRequest(
      httpClient.get(`${this.endPoint}/catalogs/${catalog_uid}/products${objectToSearchParams(params)}`)
    );
  }
}

export default Products;
