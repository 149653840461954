import React, { useState } from 'react';

import { DownCircleOutlined } from '@ant-design/icons';

import { Button, Divider, Flex, Popover, Skeleton, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';

import { StatusTag } from '@components/StatusTag';

import { useGetProcessQuery, useMultipleMoveToStatusQuery } from '../../../../../../../../../../hooks';

interface IProps {
  ordersUid: string[];
  onDone: () => void;
}

export const MultipleChangeStatus: React.FC<IProps> = ({ ordersUid, onDone }) => {
  const ordersT = useTranslation(NSKeys.orders);
  const params = useParams<{ processId: string; orderId: string }>();
  const [isOpen, setOpen] = useState(false);
  const { data: processData, status: loadingStatus } = useGetProcessQuery(params.processId);
  const { mutate, status: mutateStatus } = useMultipleMoveToStatusQuery();

  React.useEffect(() => {
    if (mutateStatus === 'success') {
      setOpen(false);
      onDone();
    }
  }, [mutateStatus]);

  const handleSelect = (id: string) => {
    if (params.processId) {
      mutate({
        data: { to_status_uid: id, orders: ordersUid.map((el) => ({ uid: el })) },
        processUid: params.processId,
      });
    }
  };

  const renderContent = () =>
    loadingStatus === 'loading' || mutateStatus === 'loading' ? (
      <Flex justify="center" align="center" style={{ minHeight: 300 }}>
        <Spin />
      </Flex>
    ) : (
      <Flex vertical>
        {processData?.stages.map((stage) => (
          <Flex vertical key={stage.uid}>
            <Typography.Text type="secondary" style={{ margin: '4px 0' }}>
              {stage.name}
            </Typography.Text>
            <Flex vertical gap={4} justify="start" align="baseline">
              {stage.statuses.map((status) => (
                <Button
                  size="small"
                  style={{ textAlign: 'left' }}
                  key={status.uid}
                  onClick={(e) => {
                    handleSelect(status.uid);
                  }}>
                  <StatusTag color={status.color} name={status.name} />
                </Button>
              ))}
            </Flex>
            <Divider plain style={{ margin: '8px 0 4px 0' }} />
          </Flex>
        ))}
      </Flex>
    );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Popover
        open={isOpen}
        placement="bottomRight"
        onOpenChange={setOpen}
        arrow={false}
        trigger="click"
        overlayInnerStyle={{ width: '313px', padding: 8 }}
        content={renderContent}>
        <Button
          size="small"
          onClick={(e) => {
            setOpen(true);
          }}>
          {loadingStatus === 'loading' ? (
            <Skeleton.Input size="small" />
          ) : (
            <Button size="small" type="link" icon={<DownCircleOutlined />}>
              {ordersT.t('tableColumns.status')}
            </Button>
          )}
        </Button>
      </Popover>
    </div>
  );
};
