import React from 'react';

import { Select, SelectProps } from 'antd';

import { useGetCatalogsQuery } from './hooks/useGetCatalogsQuery';

interface IProps extends SelectProps {
  placeholder: string;
}

export const CatalogsSelect: React.FC<IProps> = ({ placeholder, ...props }) => {
  const { data, hasNextPage, isFetching, fetchNextPage } = useGetCatalogsQuery({
    limit: 10,
  });

  const dataSource = data?.pages?.map((data) => data.items || []).flat() || [];

  return (
    <Select
      placeholder={placeholder}
      options={dataSource?.map((el) => ({
        label: el.name,
        value: el.uid,
      }))}
      dropdownStyle={{ position: 'fixed' }}
      onPopupScroll={(e: any) => {
        const { target } = e;
        if (target?.scrollTop + 10 + target?.offsetHeight > target?.scrollHeight && hasNextPage && !isFetching) {
          fetchNextPage();
        }
      }}
      notFoundContent={null}
      filterOption={false}
      {...props}
    />
  );
};
