import { Namespace, TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { IFormValues } from '../interfaces';

export const ValidationSchema = (t: TFunction<Namespace<'validation'>>): Yup.SchemaOf<IFormValues> =>
  Yup.object().shape({
    title: Yup.string().required(t('required')),
    primary_color: Yup.mixed().required(t('required')),
    theme: Yup.string().required(t('required')),
  });
