import React, { FC } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form, Row } from 'antd';
import { Namespace, TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { NSKeys } from '@app/i18n';
import { SystemSlugs } from '@app/models/dictionaries';
import { DictionarySelector } from '@components/AntFields';

import { LAYOUT, TAIL_LAYOUT } from '../../constants';
import { IStep3 } from '../../interfaces';

interface IProps {
  initialValues: IStep3;
  onNext: (values: IStep3) => void;
  onPrev: () => void;
}

const ValidationSchema = (t: TFunction<Namespace<'validation'>>): Yup.SchemaOf<IStep3> =>
  Yup.object().shape({
    sector: Yup.string().required(t('required')),
  });

export const Step3: FC<IProps> = (props) => {
  const [form] = Form.useForm();
  const registrationT = useTranslation(NSKeys.registration);
  const validationT = useTranslation(NSKeys.validation);
  const sector = Form.useWatch('sector', form);

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  return (
    <Form
      form={form}
      name="login_form_step3"
      initialValues={props.initialValues}
      onFinish={props.onNext}
      size="large"
      {...LAYOUT}>
      <DictionarySelector
        required
        name="sector"
        // labelCol={{ sm: 24 }}
        label={registrationT.t('form.sector')}
        dictionaryName={SystemSlugs.Industries}
        rules={[yupSync]}
        withToken={false}
        placeholder={registrationT.t('form.sectorPlaceholder')}
      />

      <Form.Item {...TAIL_LAYOUT}>
        <Row justify="space-between">
          <Button icon={<ArrowLeftOutlined />} disabled={sector === ''} onClick={props.onPrev}>
            {registrationT.t('btnBack')}
          </Button>
          <Button type="primary" htmlType="submit">
            {registrationT.t('btnNext')}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};
