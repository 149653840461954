import React from 'react';

import { useSpace } from '../../SpaceSettingsProvider';

import { BrandForm } from './components/BrandForm';
import { BrandInfo } from './components/BrandInfo';

export const BrandContainer: React.FC = () => {
  const { isEditing } = useSpace();

  return <React.Fragment>{isEditing ? <BrandForm /> : <BrandInfo />}</React.Fragment>;
};
