import React, { FC, useState } from 'react';

import { EditTwoTone, PlusOutlined } from '@ant-design/icons';
import { theme as antTheme, Button, Layout, Row, Space, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { useLocalSelector } from '@app/services/hooks/useRedux';
import { useWindowSize } from '@app/services/hooks/useWindowSize';
import { HeaderTitle } from '@components/HeaderTitle';

import { useGetOrderApprovalChainsQuery } from '../../hooks';

import { CopyModal } from './components/CopyModal';
import { SearchControls } from './components/SearchControls';
import { TableRemoveButton } from './components/TableRemoveButton';

import { baseColumns } from './data/constants';
import { TableParams } from './interfaces';
import { Container } from './styles';

const INITIAL_TABLE_PARAMS = {
  pagination: {
    current: 1,
    pageSize: 10,
  },
};

export const List: FC = () => {
  const navigate = useNavigate();
  const [tableParams, setTableParams] = useState<TableParams>(INITIAL_TABLE_PARAMS);
  const [selectedRows, setSelectedRows] = React.useState<React.Key[]>([]);
  const [searchString, setSearchString] = useState('');
  const commonT = useTranslation(NSKeys.common);
  const orderApprovalChainsT = useTranslation(NSKeys.orderApprovalChains);
  const { innerHeight } = useWindowSize();
  const { token } = antTheme.useToken();
  const { data: currentSessionData } = useLocalSelector((state) => state.currentSession);

  const { data, isLoading } = useGetOrderApprovalChainsQuery({
    offset:
      ((tableParams.pagination?.current || INITIAL_TABLE_PARAMS.pagination.current) - 1) *
      (tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize),
    limit: tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize,
    name: searchString,
  });

  return (
    <>
      <Layout.Header>
        <Row justify="space-between" align="middle">
          <HeaderTitle title={orderApprovalChainsT.t('list.title')} />
          <Space size="middle">
            {currentSessionData?.scope?.$permissions?.create_order_approval_chains && (
              <Button type="primary" icon={<PlusOutlined />} onClick={() => navigate('add')} size="middle">
                {commonT.t('buttonAdd')}
              </Button>
            )}
          </Space>
        </Row>
        <SearchControls
          isLoading={isLoading}
          placeholder={commonT.t('search')}
          onSearchClick={(value) => {
            setTableParams(INITIAL_TABLE_PARAMS);
            setSearchString(value);
          }}
        />
      </Layout.Header>

      <Container>
        <TableRemoveButton
          uid={selectedRows[0] as string}
          hidden={!selectedRows.length}
          onRemove={() => setSelectedRows([])}
        />

        <Table
          loading={isLoading}
          dataSource={data?.items}
          rowKey="uid"
          size="large"
          columns={[
            ...baseColumns.map((el) => ({ ...el, title: orderApprovalChainsT.t(el.title as any) })),
            {
              key: 'action',
              align: 'center',
              width: 90,
              render: (_, cell) => (
                <Space>
                  {cell.$permissions?.update && (
                    <Button
                      size="small"
                      type="link"
                      icon={<EditTwoTone twoToneColor={token?.colorPrimary} />}
                      onClick={(e) => navigate(`${cell.uid}`)}
                    />
                  )}
                  {currentSessionData?.scope?.$permissions?.create_order_approval_chains && (
                    <CopyModal approval_chain_uid={cell.uid} title={cell.name} />
                  )}
                </Space>
              ),
            },
          ]}
          pagination={{
            ...tableParams.pagination,
            total: data?.total,
            showQuickJumper: true,
            showSizeChanger: true,
            position: ['bottomRight'],
            showTotal: (total, range) => `${range[0]}-${range[1]} ${commonT.t('paginationOf')} ${total}`,
          }}
          onChange={(params) => setTableParams({ pagination: { ...params } })}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: selectedRows,
            renderCell: (value, record, index, originNode) =>
              record?.$permissions?.delete ? <div onClick={(e) => e.stopPropagation()}>{originNode}</div> : null,
            onChange: (selectedRowKeys) => setSelectedRows(selectedRowKeys),
          }}
          scroll={{ y: innerHeight - (selectedRows.length ? 340 : 290) }}
        />
      </Container>
    </>
  );
};
