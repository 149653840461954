import { message } from 'antd';
import { AxiosError } from 'axios';
import { FieldData } from 'rc-field-form/es/interface';

import { Namespace, TFunction } from 'react-i18next';

import { IErrorResponse } from '@app/models/error';

const pathToJsonPointer = (path: string) => path.replace('.', '/').replace(/^/, '/');

export const getFormErrorsFromResponse = (
  formValues: any,
  err: any,
  t: TFunction<Namespace<'validation'>>
): { pointerErrors: FieldData[]; formMessages: string[] } => {
  const errors = err as AxiosError<IErrorResponse>;
  const formMessages: string[] = [];
  let pointerErrors: FieldData[] = [];
  const formKeys = Object.keys(formValues);
  if (errors.response?.data?.errors) {
    pointerErrors = errors.response?.data?.errors.reduce((acc, err) => {
      const formKey = formKeys.find((el) => pathToJsonPointer(el) === err?.source?.pointer);
      // console.log(err?.source?.pointer, formKey);
      if (formKey) {
        acc.push({ name: formKey, errors: [t(err.code)] });
      } else {
        formMessages.push(t(err.code));
      }
      return acc;
    }, [] as FieldData[]);
  }

  return { pointerErrors, formMessages };
};
