import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';

import { Form, Input, Modal, Space, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { generatePath, useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { getFormErrorsFromResponse } from '@app/services/getFormErrorsFromResponse';

import { useCreateProcessQuery } from '../../../../hooks';

import { IFormValues } from './interfaces';
import { ValidationSchema } from './validation';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const CreateProcessModal: React.FC<IProps> = ({ open, onClose }) => {
  // const employeesT = useTranslation(NSKeys.employees);
  const validationT = useTranslation(NSKeys.validation);
  const commonT = useTranslation(NSKeys.common);
  const ordersT = useTranslation(NSKeys.orders);
  const navigate = useNavigate();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm<IFormValues>();
  const { token } = theme.useToken();

  const { mutate, status, data, failureReason } = useCreateProcessQuery();

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  React.useEffect(() => {
    if (data?.uid) {
      const { search } = location;
      navigate(generatePath('/dashboard/processes/:processId/orders' + (search ?? ''), { processId: data.uid }));
      form.resetFields();
      onClose();
    }
  }, [data]);

  React.useEffect(() => {
    if (status === 'error' && form && failureReason) {
      const formValues = form.getFieldsValue();
      const { pointerErrors } = getFormErrorsFromResponse(formValues, failureReason, validationT.t);
      if (pointerErrors.length) {
        form.setFields(pointerErrors);
      }
    }
  }, [status, failureReason]);

  return (
    <Modal
      style={{ zIndex: 3333333 }}
      title={
        <Space align="center">
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            {ordersT.t('createProcessModalTitle')}
          </Typography.Title>
        </Space>
      }
      open={open}
      destroyOnClose
      onOk={() => form.submit()}
      okButtonProps={{
        icon: <PlusOutlined />,
        loading: status === 'loading',
      }}
      okText={commonT.t('create')}
      confirmLoading={confirmLoading}
      onCancel={onClose}>
      <Form
        form={form}
        onFinish={mutate}
        name="email_invite"
        layout="vertical"
        style={{
          border: `1px solid ${token.colorBorder}`,
          margin: '20px -24px',
          padding: 25,
          borderLeft: 'none',
          borderRight: 'none',
        }}>
        <Form.Item<IFormValues>
          name="name"
          label={<Typography.Text type="secondary">{ordersT.t('processFieldsForm.name')}</Typography.Text>}
          rules={[yupSync]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
