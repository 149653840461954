import { useQuery } from '@tanstack/react-query';

import { IProductsParams } from '@app/models/interfaces';
import Api from '@app/services/api';

export const useGetProductsQueryTable = ({ limit, category_id, offset, tag_id, q, ...params }: IProductsParams) => {
  return useQuery({
    queryKey: ['getProductsTable', category_id, tag_id, q, offset, limit],
    queryFn: () => Api.products.getProducts({ offset, category_id, tag_id, q, limit, ...params }),
  });
};
