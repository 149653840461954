import React from 'react';

import { Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { useLocalSelector } from '@app/services/hooks/useRedux';

interface IProps {
  handleResetClick: () => void;
}

export const NotFound: React.FC<IProps> = (props) => {
  const filterSearchString = useLocalSelector((state) => state.products.filterSearchString);
  const filterTags = useLocalSelector((state) => state.products.filterTags);
  const filterCategories = useLocalSelector((state) => state.products.filterCategories);
  const hasFilter = !!filterSearchString || !!filterCategories.length || !!filterTags.length;

  const nomenclatureListT = useTranslation(NSKeys.nomenclatureList);

  return (
    <Row style={{ padding: '100px 0', textAlign: 'center' }}>
      {hasFilter ? (
        <Typography.Text>
          {nomenclatureListT.t('notFoundFirstPart')} <br />
          {nomenclatureListT.t('notFoundSecondPart')}{' '}
          <Typography.Link onClick={props.handleResetClick}>{nomenclatureListT.t('notFoundThirdPart')}</Typography.Link>
        </Typography.Text>
      ) : (
        <Typography.Text>{nomenclatureListT.t('listIsEmpty')}</Typography.Text>
      )}
    </Row>
  );
};
