import { useInfiniteQuery } from '@tanstack/react-query';

import { ICatalogsParams } from '@app/models/interfaces';
import Api from '@app/services/api';

export const useGetCatalogsQuery = ({ limit }: ICatalogsParams) => {
  return useInfiniteQuery({
    queryKey: ['getCatalogs'],
    queryFn: async ({ pageParam = 0 }) => {
      return await Api.products.getCatalogs({ offset: pageParam, limit });
    },
    getNextPageParam: (current, allPages) => {
      const count = allPages.length * (limit || 1);
      return count >= (current.total || 0) ? undefined : count;
    },
  });
};
