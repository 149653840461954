import { FC, Fragment, ReactNode } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Skeleton, theme, Typography } from 'antd';

import { Actions, Container } from './styles';

interface IProps {
  title: string;
  loading?: boolean;
  titleSpan?: string | number | ReactNode;
  children?: ReactNode;
  onClick?: () => void;
}

export const HeaderTitle: FC<IProps> = ({ titleSpan, loading, title, children, onClick }) => {
  const { token } = theme.useToken();

  return (
    <Container>
      {!!onClick && <ArrowLeftOutlined onClick={onClick} style={{ marginRight: '12px', cursor: 'pointer' }} />}
      {loading ? (
        <Skeleton.Input active={true} />
      ) : (
        <Fragment>
          <Typography.Title level={2} className="title" ellipsis={{ tooltip: { title } }}>
            {title}
          </Typography.Title>
          {(titleSpan || titleSpan === 0) && (
            <Typography className="titleSpan" style={{ color: token.colorPrimary }}>
              {titleSpan}
            </Typography>
          )}
          {children && <Actions>{children}</Actions>}
        </Fragment>
      )}
    </Container>
  );
};
