import { Namespace, TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { IFormValues } from '../../../interfaces';

export const ValidationSchema = (t: TFunction<Namespace<'validation'>>): Yup.SchemaOf<IFormValues> =>
  Yup.object().shape({
    phone: Yup.string().required(t('required')),
    first_name: Yup.string().required(t('required')),
    last_name: Yup.string().required(t('required')),
    title: Yup.string().required(t('required')),
    email: Yup.string().required(t('required')).email(t('emailFormat')),
    role_pk: Yup.number().required(t('required')),
  });
