import { List } from 'antd';
import styled from 'styled-components';

export const Container = styled(List.Item)`
  &.ant-list-item {
    padding: 8px 0;
  }

  .ant-typography {
    font-family: InterRegular, sans-serif;
  }

  .listItemTitle {
    white-space: nowrap;
    margin-right: 15px;
  }
`;
