import {
  CommonDictionariesSlugs,
  ContextSchema,
  CreateContactSchema,
  CreateRecipientSchema,
  DictionaryItemSchema,
  PartialUpdateContactSchema,
  RecordsListGenericSchemaItemSchema,
  RecordsListGenericSchemaRetrieveContactsSchema,
  RecordsListGenericSchemaRetrieveRecipientsSchema,
  RetrieveContactSchema,
  RetrieveRecipientSchema,
  SystemSlugs,
  UpdateContactSchema,
  UpdateRecipientSchema,
} from '@app/models/dictionaries';

import { IContactsParams, IRecipientsParams } from '@app/models/interfaces';
import { handleRequest, httpClient } from '@app/services/httpClient';
import { objectToSearchParams } from '@app/services/objectToSearchParams';

import { IProps } from '../interfaces';

export type Dump = {
  [key in SystemSlugs]?: {
    [key: string]: { label: string; title: string };
  };
};

interface IParams {
  name: SystemSlugs;
  offset: number;
  limit: number;
  lang?: string; // 'EN' | 'RU' | 'PT';
}

class Dictionaries {
  private readonly endPoint: string;

  constructor({ baseEndPoint }: IProps) {
    this.endPoint = baseEndPoint;
  }

  async getList(params: IParams): Promise<RecordsListGenericSchemaItemSchema> {
    const { name, ...rest } = params;
    return handleRequest(httpClient.get(`${this.endPoint}/system-dictionaries/${name}${objectToSearchParams(rest)}`));
  }

  async getDump(data: { dictionary: SystemSlugs; value: string | number }[], lang: string): Promise<Dump> {
    return handleRequest(httpClient.post(`${this.endPoint}/system-dictionaries/dump?lang=${lang || 'EN'}`, data));
  }

  async getContext(): Promise<ContextSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/context`));
  }

  async getItems(slug: CommonDictionariesSlugs): Promise<DictionaryItemSchema[]> {
    return handleRequest(httpClient.get(`${this.endPoint}/dictionaries/${slug}/items`));
  }

  async postItem(slug: CommonDictionariesSlugs, title: string): Promise<DictionaryItemSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/dictionaries/${slug}/items`, { title: title }));
  }

  async getRecipients(params: IRecipientsParams): Promise<RecordsListGenericSchemaRetrieveRecipientsSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/recipients${objectToSearchParams(params)}`));
  }

  async getRecipient(uid: string): Promise<RetrieveRecipientSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/recipients/${uid}`));
  }

  async postRecipient(data: CreateRecipientSchema): Promise<RetrieveRecipientSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/recipients`, data));
  }

  async putRecipient(uid: string, data: UpdateRecipientSchema): Promise<RetrieveRecipientSchema> {
    return handleRequest(httpClient.put(`${this.endPoint}/recipients/${uid}`, data));
  }

  async getContacts(params: IContactsParams): Promise<RecordsListGenericSchemaRetrieveContactsSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/contacts${objectToSearchParams(params)}`));
  }

  async getContact(uid: string): Promise<RetrieveContactSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/contacts/${uid}`));
  }

  async postContact(data: CreateContactSchema): Promise<RetrieveContactSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/contacts`, data));
  }

  async putContact(uid: string, data: UpdateContactSchema): Promise<RetrieveContactSchema> {
    return handleRequest(httpClient.put(`${this.endPoint}/contacts/${uid}`, data));
  }

  async patchContact(uid: string, data: PartialUpdateContactSchema): Promise<RetrieveContactSchema> {
    return handleRequest(httpClient.patch(`${this.endPoint}/contacts/${uid}`, data));
  }
}

export default Dictionaries;
