import styled, { createGlobalStyle } from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-phone-input-2/lib/style.css';
import 'antd/dist/reset.css';
import 'reactflow/dist/style.css';

import InterMedium from './assets/fonts/Inter-Medium.ttf';
import InterRegular from './assets/fonts/Inter-Regular.ttf';

export const GlobalStyle = createGlobalStyle<{ $colorPrimary: string }>`
    @font-face {
        font-family: 'InterMedium';
        src: local('InterMedium'),
        url(${InterMedium}) format('truetype');
        font-style: normal;
        font-display: fallback;
    }

    @font-face {
        font-family: 'InterRegular';
        src: local('InterRegular'),
        url(${InterRegular}) format('truetype');
        font-style: normal;
        font-display: fallback;
    }

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
        //table,
    caption,
        //tbody,
        //tfoot,
        //thead,
        //tr,
        //th,
        //td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        vertical-align: baseline;
        font-weight: 400;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section {
        display: block;
    }

    body {
        font-family: 'InterRegular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        line-height: 1;
        background-color: rgb(245, 245, 245);
    }

    ol,
    ul {
        list-style: none;
    }

    blockquote,
    q {
        quotes: none;
    }

    blockquote::before,
    blockquote::after,
    q::before,
    q::after {
        content: '';
        content: none;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    input,
    label,
    select,
    button,
    textarea {
        margin: 0;
        border: 0;
        padding: 0;
        display: inline-block;
        vertical-align: middle;
        white-space: normal;
        background: none;
        line-height: 1;
        font-size: 14px;
    }

    input,
    textarea {
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
    }

    a:focus,
    input:focus {
        outline: 0;
    }

    button,
    input[type='reset'],
    input[type='button'],
    input[type='submit'],
    input[type='checkbox'],
    input[type='radio'],
    select {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    input[type='checkbox'],
    input[type='radio'] {
        width: 13px;
        height: 13px;
    }

    input[type='search'] {
        -webkit-appearance: textfield;
        -webkit-box-sizing: content-box;
    }

    ::-webkit-search-decoration {
        display: none;
    }

    button,
    input[type='reset'],
    input[type='button'],
    input[type='submit'] {
        /* Fix IE7 display bug */
        overflow: visible;
        width: auto;
    }

    ::-webkit-file-upload-button {
        padding: 0;
        border: 0;
        background: none;
    }

    textarea {
        vertical-align: top;
        overflow: auto;
    }

    select[multiple] {
        vertical-align: top;
    }

    a {
        text-decoration: none;
    }

    #root {
        min-height: 100vh;
        display: flex;
    }

    .ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .ant-upload-list-item-action {
        opacity: 1;
    }

    .ant-input-number-group .ant-input-number-group-addon {
        min-width: 45px;
    }

    .react-flow__controls-interactive, .react-flow__panel.react-flow__attribution {
        display: none;
    }

    .tableClassname {
        .cursorPointer {
            cursor: pointer;
        }
    }

    .formItemAlignedLabel {
        .ant-form-item-label {
            display: flex;
            align-items: center;
            justify-content: end;
        }
    }

    .ant-typography ul li {
        margin-inline: 0;
        padding-inline: 0;
    }

    .ant-input {
        border-width: 1px;
    }

    .react-resizable {
        position: relative;
        user-select: none;
    }

    .react-resizable-handle {
        position: absolute;
        cursor: col-resize;
        top: 50%;
        inset-inline-end: 0;
        width: 5px;
        height: 1.6em;
        background-color: #f0f0f000;
        transform: translateY(-50%);
        transition: all 200ms ease-out 0s;
        z-index: 1;

        &.active {
            background-color: #939393;
            width: 5px;
            height: 60%;   
        }
        
        &:hover {
            background-color: #939393;
            width: 5px;
            height: 60%;
        }
    }
    
    .dragLine {
        border-color: ${({ $colorPrimary }) => $colorPrimary}!important;
    }
    
    textarea {
        white-space: pre-wrap;
    }
`;

export const SuspenseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;
