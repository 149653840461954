import React, { FC } from 'react';

import { DeleteTwoTone, UserOutlined } from '@ant-design/icons';
import { theme as antTheme, Avatar, Button, Row, Space, Spin, Typography } from 'antd';

import { RetrieveUserGroupSchema } from '@app/models/users';

import { InfiniteScroll } from '@components/InfiniteScroll';
import { SkeletonImage } from '@components/products';

import { useDeleteUserQuery, useGetGroupUsersQuery } from '../../../../hooks';

import { AddUserModal } from './components/AddUserModal';

import { Container, List } from './styles';

interface IProps {
  record: RetrieveUserGroupSchema;
}

export const UserList: FC<IProps> = ({ record }) => {
  const { data, hasNextPage, fetchNextPage, isFetching } = useGetGroupUsersQuery({
    user_group_uid: record.uid,
    limit: 10,
  });
  const { mutate: deleteUser, status: deleteStatus } = useDeleteUserQuery();
  const { token } = antTheme.useToken();

  const handleDelete = (user_id: number) => {
    deleteUser({ user_group_uid: record.uid, user_id });
  };

  const dataSource = React.useMemo(() => {
    return data?.pages?.map((data) => data.items || []).flat() || [];
  }, [data]);

  return (
    <Container>
      <List>
        {dataSource?.map((el, index) => (
          <Row key={index} justify="space-between" align="middle">
            <Space style={{ maxWidth: '80%' }}>
              <Avatar
                size={32}
                src={el.avatar?.variants?.small?.url}
                icon={<UserOutlined />}
                style={{ marginLeft: 0, marginRight: '10px' }}
              />
              <Typography.Text ellipsis>{`${el.name}`}</Typography.Text>
            </Space>
            {record.$permissions?.update && (
              <Button
                disabled={deleteStatus === 'loading'}
                size="small"
                type="link"
                icon={<DeleteTwoTone twoToneColor={token.colorPrimary} />}
                onClick={() => handleDelete(el.id)}
              />
            )}
          </Row>
        ))}

        {isFetching && (
          <Row justify="center">
            <Spin />
          </Row>
        )}
        {hasNextPage && (
          <InfiniteScroll key={data?.pages?.length || 0} next={fetchNextPage}>
            <SkeletonImage />
          </InfiniteScroll>
        )}
      </List>
      {record.$permissions?.update && <AddUserModal record={record} />}
    </Container>
  );
};
