import { ColumnTypes } from './interfaces';

export const NEW_GROUP_ID = 'temprary';

export const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
  {
    title: 'table.colName',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    editable: true,
  },
  {
    title: 'table.colTotal',
    dataIndex: 'total_users',
    key: 'total_users',
    ellipsis: true,
  },
];
