import React from 'react';

import { Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { OrderHistorySchema } from '@app/models/orders';
import { Status } from '@components/Status';

import { useGetHistoryQuery } from '../../../../../../hooks';

import { BASE_COLUMNS } from './constants';

interface IProps {
  order_uid: string;
  statuses?: { uid: string; name: string; color: string }[];
}

export const HistoryTable: React.FC<IProps> = ({ order_uid, statuses }) => {
  const orderT = useTranslation(NSKeys.order);

  const { data, isLoading, status } = useGetHistoryQuery(order_uid);

  const renderStatus = (value?: string) => {
    const status = statuses?.find((el) => el.uid === value);
    if (!value || !status) {
      return <></>;
    }
    return <Status text={status.name} color={status.color} />;
  };

  return (
    <React.Fragment>
      <Typography.Title level={3} style={{ marginBottom: 20 }}>
        {orderT.t('historyTable.title')}
      </Typography.Title>
      <Table
        loading={isLoading}
        columns={[
          ...BASE_COLUMNS.map((el) => ({
            ...el,
            title: orderT.t(el.title as any),
            render:
              el.key === 'kind'
                ? (_: any, data: OrderHistorySchema) => <>{orderT.t(`historyKind.${data?.info.kind}` as any)}</>
                : el.key === 'order_status_uid' && !!statuses?.length
                ? (value: any) => renderStatus(value)
                : el.render,
          })),
        ]}
        scroll={{ x: 'max-content' }}
        dataSource={data}
        rowKey="uid"
        pagination={false}
      />
    </React.Fragment>
  );
};
