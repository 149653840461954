import React from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal as AntModal, Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { useRoles } from '../../../../RolesProvider';

interface IProps {
  onOk: () => void;
}

export const Modal: React.FC<IProps> = (props) => {
  const commonT = useTranslation(NSKeys.common);
  const spaceT = useTranslation(NSKeys.spaceSettings);

  const { alertOpen, setAlertOpen, setIsFormFieldsTouched, setNextRoleId } = useRoles();

  const handleCancel = () => {
    setAlertOpen(false);
  };

  const handleOk = () => {
    setAlertOpen(false);
    setNextRoleId(undefined);
    setIsFormFieldsTouched(false);
    props.onOk();
  };

  return (
    <AntModal
      open={alertOpen}
      onOk={handleOk}
      centered
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {commonT.t('buttonCancel')}
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          {commonT.t('buttonDontSave')}
        </Button>,
      ]}>
      <Row style={{ paddingBottom: 24, paddingTop: 32 }}>
        <Col span={3}>
          <ExclamationCircleOutlined style={{ color: '#FAAD14', fontSize: 25, margin: '0 16px 0 8px' }} />
        </Col>
        <Col span={21}>
          <Row style={{ marginBottom: 10 }}>
            <Typography.Title level={4}>{spaceT.t('alertTitleDataNotSaved')}</Typography.Title>
          </Row>
          <Row>
            <Typography.Text type="secondary">{spaceT.t('alertDescriptionDataNotSaved')}</Typography.Text>
          </Row>
        </Col>
      </Row>
    </AntModal>
  );
};
