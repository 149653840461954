import { useMutation, useQueryClient } from '@tanstack/react-query';

import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { CreateProcessDTO } from '@app/models/orders';
import Api from '@app/services/api';

interface IParams {
  process_uid: string;
  data: CreateProcessDTO;
}

export const useUpdateProcessQuery = () => {
  const client = useQueryClient();
  const commonT = useTranslation(NSKeys.common);

  return useMutation({
    mutationFn: async ({ process_uid, data }: IParams) => {
      await Api.orders.updateProcess(process_uid, data);
    },
    onSuccess: async (_) => {
      message.open({
        type: 'success',
        content: commonT.t('dataPatchSuccess'),
      });
      await client.invalidateQueries(['getProcess']);
      await client.invalidateQueries(['getProcesses']);
    },
    onError: async () => {
      message.open({
        type: 'error',
        content: commonT.t('error400'),
      });
      await client.invalidateQueries(['getProcess']);
    },
  });
};
