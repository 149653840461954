import { useQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

export const useGetWarehouseQuery = (uid: string) => {
  return useQuery({
    queryKey: ['getWarehouse', uid],
    queryFn: () => Api.wms.getWarehouse(uid),
    enabled: !!uid,
  });
};
