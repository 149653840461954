import React from 'react';

import { Checkbox, Form, Input, Select, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { FieldType } from '@app/models/interfaces';

import { RenderFieldByType } from '@components/RenderFieldByType';

import { ValidationSchema } from './data/validation';
import { IFormValues } from './interfaces';

interface IProps {
  isEditing?: boolean;
  initialValues?: Partial<IFormValues>;
  onSubmit: (values: IFormValues) => void;
}

const OPTIONS: { label: string; value: FieldType }[] = [
  { label: 'string', value: 'string' },
  { label: 'text', value: 'text' },
  { label: 'num', value: 'num' },
  // { label: 'address', value: 'address' },
  { label: 'dateTime', value: 'datetime' },
  { label: 'date', value: 'date' },
  { label: 'user', value: 'user' },
  { label: 'recipient', value: 'recipient' },
  { label: 'contact', value: 'contact' },
  { label: 'bool', value: 'bool' },
  { label: 'multi_image', value: 'multi_image' },
];

export const FieldForm: React.FC<IProps> = ({ onSubmit, initialValues, isEditing = false }) => {
  const { token } = theme.useToken();
  const ordersT = useTranslation(NSKeys.orders);
  const validationT = useTranslation(NSKeys.validation);
  const [form] = Form.useForm<IFormValues>();
  const nameValue = Form.useWatch('name', form);
  const typeValue = Form.useWatch('type', form);
  const isRequired = Form.useWatch('is_required', form);

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onSubmit}
        name="create_field"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={initialValues || { type: OPTIONS[0].value }}>
        <Form.Item<IFormValues>
          name="type"
          required
          label={<Typography.Text type="secondary">{ordersT.t('fieldType')}</Typography.Text>}
          rules={[yupSync]}>
          <Select
            disabled={isEditing}
            options={OPTIONS.map((el) => ({ label: ordersT.t(`fieldsForm.${el.label}` as any), value: el.value }))}
          />
        </Form.Item>
        <Form.Item<IFormValues>
          name="name"
          required
          label={<Typography.Text type="secondary">{ordersT.t('fieldName')}</Typography.Text>}
          rules={[yupSync]}>
          <Input />
        </Form.Item>
        <Form.Item<IFormValues> name="is_required" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
          <Checkbox>{ordersT.t('fieldRequired')}</Checkbox>
        </Form.Item>
      </Form>

      <div
        style={{
          backgroundColor: token.colorBgLayout,
          padding: '20px',
          border: `1px dashed ${token.colorBorder}`,
          borderRadius: 10,
        }}>
        <Typography.Paragraph type="secondary">{ordersT.t('addFieldDescription')}:</Typography.Paragraph>
        <RenderFieldByType
          preview={true}
          form={form}
          required={isRequired}
          disabled={false}
          type={typeValue as FieldType}
          label={nameValue}
          name={[nameValue]}
        />
      </div>
    </>
  );
};
