import { useQuery } from '@tanstack/react-query';

import { IOrderByProcessParams } from '@app/models/interfaces';

import Api from '@app/services/api';

export const useGetOrdersByProcessQuery = ({ process_uid, offset, limit }: IOrderByProcessParams) => {
  return useQuery({
    queryKey: ['getOrdersByProcess', process_uid, limit, offset],
    queryFn: () => Api.orders.getOrdersByProcess({ process_uid, offset, limit }),
    enabled: !!process_uid,
  });
};
