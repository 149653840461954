import { Space } from 'antd';
import styled from 'styled-components';

export const Container = styled(Space.Compact)<{ $isSmall?: boolean; $borderColor: string }>`
  .ant-btn,
  .ant-input-number {
    width: ${({ $isSmall }) => ($isSmall ? '' : '65px')};
    border: ${({ $borderColor }) => `1px solid ${$borderColor}`};
  }

  .ant-btn {
    width: ${({ $isSmall }) => ($isSmall ? '' : '65px')};
  }

  .ant-input-number {
    width: 65px;

    input {
      text-align: center;
    }
  }
`;
