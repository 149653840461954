import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './translations/en.json';
import pt from './translations/pt.json';
import ru from './translations/ru.json';

export const resources = {
  en,
  ru,
  pt,
};

export const NSKeys = (() =>
  ({
    ...Object.keys(en)
      .filter((k) => isNaN(Number(k)))
      .reduce(
        (acc, cur) => ({
          ...acc,
          [cur]: cur,
        }),
        {}
      ),
  } as {
    [k in keyof typeof en]: k;
  }))();

export const availableLanguages = Object.keys(resources);
export const defaultNS = NSKeys.common;

// TODO: вернуть .use(LanguageDetector)
i18n.use(initReactI18next).init({
  ns: Object.keys(NSKeys),
  defaultNS,
  resources,
  fallbackLng: 'ru',
});
