import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';

export const Container = styled.div<{ $isHorizontal?: boolean }>`
  display: flex;
  flex-direction: ${({ $isHorizontal }) => ($isHorizontal ? 'row' : 'column')};
  align-items: ${({ $isHorizontal }) => ($isHorizontal ? 'center' : 'flex-start')};
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  //min-width: 260px;
`;

export const InputWrapper = styled(PhoneInput)<{
  hasError?: boolean;
  $colorBorder: string;
  $borderRadiusLG: number;
  $colorTextDisabled: string;
  $colorBgContainerDisabled: string;
  $colorErrorBorder: string;
  $colorPrimaryHover: string;
  $colorBgContainer: string;
}>`
  //margin-bottom: 8px;

  .flag-dropdown.open .selected-flag {
    border-radius: ${({ $borderRadiusLG }) => $borderRadiusLG}px 0 0 ${({ $borderRadiusLG }) => $borderRadiusLG}px;
  }

  .inputClass {
    font-size: 14px;
    width: 100%;
    height: 32px;
    border-radius: ${({ $borderRadiusLG }) => $borderRadiusLG}px;
    background-color: ${({ $colorBgContainer }) => $colorBgContainer};
    border: 1px solid
      ${({ hasError, $colorBorder, $colorErrorBorder }) => (hasError ? $colorErrorBorder : $colorBorder)};

    :disabled {
      color: ${({ $colorTextDisabled }) => $colorTextDisabled};
      background-color: ${({ $colorBgContainerDisabled }) => $colorBgContainerDisabled};
      border-color: ${({ $colorBorder }) => $colorBorder};
      box-shadow: none;
      cursor: not-allowed;
      opacity: 1;
    }
    :focus,
    :hover {
      border-color: ${({ $colorPrimaryHover }) => $colorPrimaryHover};
    }

    ::placeholder {
      color: ${({ $colorBorder }) => $colorBorder};
    }
  }

  .selected-flag:hover,
  .selected-flag:focus,
  .flag-dropdown.open .selected-flag,
  .country-list,
  .country-list .country:hover,
  .country-list .country.highlight {
    background-color: ${({ $colorBgContainer }) => $colorBgContainer};
  }

  .buttonClass {
    border-radius: ${({ $borderRadiusLG }) => $borderRadiusLG}px 0 0 ${({ $borderRadiusLG }) => $borderRadiusLG}px;
    border: 0;
    top: 1px;
    bottom: 1px;
    left: 1px;
    background-color: transparent;

    ::after {
      content: '';
      position: absolute;
      top: 8px;
      right: 0;
      bottom: 6px;
      width: 1px;
      background-color: ${({ $colorBorder }) => $colorBorder};
    }

    &.open {
      border-radius: ${({ $borderRadiusLG }) => $borderRadiusLG}px 0 0 ${({ $borderRadiusLG }) => $borderRadiusLG}px;
    }

    .selected-flag {
      padding: 0 0 0 12px;
      border-radius: ${({ $borderRadiusLG }) => $borderRadiusLG}px 0 0 ${({ $borderRadiusLG }) => $borderRadiusLG}px;
    }

    .arrow {
      display: none;
    }
  }
`;
