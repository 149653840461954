import styled from 'styled-components';

export const Container = styled.div<{ $height?: number; $colorBgBase: string }>`
  background-color: ${(props) => props.$colorBgBase};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: inherit;
  min-height: ${({ $height }) => `${$height || 150}px`};
  border-radius: 12px;
`;
