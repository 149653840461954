import React from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Form, message, Upload } from 'antd';
import type { UploadProps } from 'antd/es/upload';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { useLocalSelector } from '@app/services/hooks/useRedux';
import { TOKEN_KEY_NAME } from '@app/services/httpClient';

export const LogoUpload: React.FC = () => {
  const [apiToken, setApiToken] = React.useState(localStorage.getItem(TOKEN_KEY_NAME));
  const commonT = useTranslation(NSKeys.common);
  const spaceT = useTranslation(NSKeys.spaceSettings);
  const [fileList, setFileList] = React.useState<any>([]);
  const currentSession = useLocalSelector((state) => state.currentSession);

  React.useEffect(() => {
    if (currentSession?.dataScope?.logo) {
      setFileList([
        {
          uid: currentSession?.dataScope?.logo?.uid,
          name: currentSession?.dataScope?.logo?.uid,
          status: 'done',
          url: currentSession?.dataScope?.logo?.variants?.medium?.url,
        },
      ]);
    }
  }, []);

  const handleChange: UploadProps['onChange'] = (info) => {
    const status = info.file.status;

    setFileList(info?.fileList);
    if (status === 'error') {
      setFileList([]);
      message.error(commonT.t('error400'));
    }
  };

  return (
    <Form.Item name="logo_uid" label={spaceT.t('logoLabel')} getValueFromEvent={(e) => e?.file?.response?.uid}>
      <Upload
        name="logo"
        listType="picture-card"
        beforeUpload={() => setApiToken(localStorage.getItem(TOKEN_KEY_NAME))}
        headers={{ Authorization: `Bearer ${apiToken}` }}
        showUploadList={{ showPreviewIcon: false, showDownloadIcon: false }}
        action={`${process.env.REACT_APP_API_HOST}/users/scopes/logo`}
        method="PUT"
        accept="image/*"
        maxCount={1}
        fileList={fileList}
        onChange={handleChange}>
        {fileList.length < 1 && <UploadOutlined />}
      </Upload>
    </Form.Item>
  );
};
