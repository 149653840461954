import styled from 'styled-components';

export const Container = styled.div`
  .ant-table-wrapper {
    .editable-cell {
      position: relative;
    }

    .editable-cell-value-wrap {
      padding: 5px 12px;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 25px;
    }

    .editable-row:hover .editable-cell-value-wrap {
      padding: 4px 11px;
      border: 1px dashed #d9d9d9;
      border-radius: 2px;
    }
  }
`;
