import React, { FC, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { NodeProps } from 'reactflow';

import { NSKeys } from '@app/i18n';
import { GroupModal } from '@pages/Groups';

import { useConfirmation } from '../../../../providers/confirmationProvider';

export const EmptyNode: FC<NodeProps> = () => {
  const { onAddFirst } = useConfirmation();
  const [open, setOpen] = useState(false);
  const orderApprovalChainsT = useTranslation(NSKeys.orderApprovalChains);

  const handleOk = (uid: string) => {
    setOpen(false);
    onAddFirst(uid);
  };

  return (
    <React.Fragment>
      <Button icon={<PlusOutlined />} type="primary" onClick={() => setOpen(true)}>
        {orderApprovalChainsT.t('new.btnAddFirstGroup')}
      </Button>
      <GroupModal open={open} onOk={handleOk} onCancel={() => setOpen(false)} />
    </React.Fragment>
  );
};
