import { useMutation } from '@tanstack/react-query';

import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';

export const useCheckEmail = () => {
  const commonT = useTranslation(NSKeys.common);

  return useMutation({
    mutationFn: async (email: string) => {
      return await Api.users.getCheckEmailOccupancy(email);
    },
    onError: () => {
      message.open({
        type: 'error',
        content: commonT.t('error400'),
      });
    },
  });
};
