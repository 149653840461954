import React from 'react';

import { Form, message, Table, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { Paper } from '@components/Paper';

import { ValidateTableValuesAndChnageErrors } from '@pages/SpaceSettings/components/RolesContainer/data/validateTableValuesAndChnageErrors';

import { useSpace } from '../../SpaceSettingsProvider';

import { useRoles } from './RolesProvider';
import { Tabs } from './components/Tabs';
import { getInitialValues } from './data';
import { columnsNew } from './data/constants';
import { IFormValues } from './interfaces';
import { Container } from './styles';

export const RolesContainer: React.FC = () => {
  const [form] = Form.useForm<IFormValues>();
  const { token } = theme.useToken();
  const spaceT = useTranslation(NSKeys.spaceSettings);
  const commonT = useTranslation(NSKeys.common);
  const { loading, roles, selectedRole, putRole, newRole, postRole, setIsFormFieldsTouched, config } = useRoles();
  const selectedRolePermissions = roles?.find((role) => role.pk === selectedRole?.pk)?.permissions;
  const { isEditing } = useSpace();
  const formValues = Form.useWatch([], form);

  React.useEffect(() => {
    if (config && formValues) {
      ValidateTableValuesAndChnageErrors({
        config,
        formValues,
        setFieldValue: (name, value) => form.setFieldValue(name, value),
      });
    }
  }, [selectedRole, formValues, config]);

  const resetFormValues = () => {
    setIsFormFieldsTouched(false);
    form.setFieldsValue(getInitialValues(selectedRolePermissions));
    form.setFieldValue('name', selectedRole?.name);
    form.setFieldValue('newRoleName', '');
  };

  React.useEffect(() => {
    resetFormValues();
  }, [selectedRolePermissions]);

  React.useEffect(() => {
    if (!isEditing) {
      resetFormValues();
    }
  }, [isEditing, selectedRolePermissions]);

  const handleError = () => message.error(commonT.t('error400'));

  const handleSubmit = async (formValues: IFormValues) => {
    // console.log(formValues);
    if (newRole) {
      await postRole(formValues)
        .then(() => {
          message.success(commonT.t('dataAddSuccess'));
        })
        .catch(() => handleError());
    } else {
      await putRole(formValues)
        .then(() => {
          message.success(commonT.t('dataPatchSuccess'));
        })
        .catch(() => handleError());
    }
  };

  return (
    <Form
      disabled={loading}
      form={form}
      name="roles_form"
      onFieldsChange={() => setIsFormFieldsTouched(true)}
      onFinish={handleSubmit}>
      <Container $summaryBgColor={token.colorBgLayout} direction="vertical" size={24}>
        <Tabs />
        <Paper>
          <Typography.Paragraph>{spaceT.t('roleTableTitle')}</Typography.Paragraph>
          <Table
            loading={loading}
            className="tableClassname"
            columns={columnsNew({
              t: spaceT.t,
              isEditing,
              config,
              formValues,
            })}
            size="small"
            dataSource={config}
            pagination={false}
            rowKey="id"
          />

          {/* <Table*/}
          {/*  loading={loading}*/}
          {/*  className="tableClassname"*/}
          {/*  columns={getColumns(spaceT.t, isEditing)}*/}
          {/*  dataSource={getDataSource(selectedRolePermissions)}*/}
          {/*  pagination={false}*/}
          {/*  rowKey="id"*/}
          {/* />*/}
        </Paper>
      </Container>
    </Form>
  );
};
