import styled, { keyframes } from 'styled-components';

const floating = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  45% {
    transform: translate3d(0, -10%, 0);
  }
  55% {
    transform: translate3d(0, -10%, 0); 
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`;

const floatingShadow = keyframes`
  0% { 
    transform: scale(1);
  }
  45% {
    transform: scale(0.85);
  }
  55% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
`;

export const Container = styled.div<{ $colorBgBase: string; $colorBorder: string; $colorText: string }>`
  color: ${({ $colorBorder }) => $colorBorder};
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;

  h1 {
    margin: 32px 0 16px 0;
  }

  .boo {
    width: 160px;
    height: 184px;
    background-color: ${(props) => props.$colorBgBase};
    margin-left: auto;
    margin-right: auto;
    border: 3px solid ${(props) => props.$colorText};
    border-bottom: 0;
    overflow: hidden;
    border-radius: 80px 80px 0 0;
    box-shadow: -16px 0 0 2px rgba(234, 234, 234, 0.5) inset;
    position: relative;
    padding-bottom: 32px;
    animation: ${floating} 3s ease-in-out infinite;
  }
  .boo::after {
    content: '';
    display: block;
    position: absolute;
    left: -18.8235294118px;
    bottom: -8.3116883117px;
    width: calc(100% + 32px);
    height: 32px;
    background-repeat: repeat-x;
    background-size: 32px 32px;
    background-position: left bottom;
    background-image: linear-gradient(-45deg, ${(props) => props.$colorBgBase} 16px, transparent 0),
      linear-gradient(45deg, ${(props) => props.$colorBgBase} 16px, transparent 0),
      linear-gradient(-45deg, ${(props) => props.$colorText} 18.8235294118px, transparent 0),
      linear-gradient(45deg, ${(props) => props.$colorText} 18.8235294118px, transparent 0);
  }
  .boo .face {
    width: 24px;
    height: 3.2px;
    border-radius: 5px;
    background-color: ${(props) => props.$colorText};
    position: absolute;
    left: 50%;
    bottom: 56px;
    transform: translateX(-50%);
  }
  .boo .face::before,
  .boo .face::after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background-color: ${(props) => props.$colorText};
    border-radius: 50%;
    position: absolute;
    bottom: 40px;
  }
  .boo .face::before {
    left: -24px;
  }
  .boo .face::after {
    right: -24px;
  }

  .shadow {
    width: 128px;
    height: 16px;
    background-color: ${({ $colorBorder }) => $colorBorder};
    margin-top: 40px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 50%;
    animation: ${floatingShadow} 3s ease-in-out infinite;
  }

  button {
    margin-top: 16px;
  }
`;
