import React, { useEffect, useState } from 'react';

import { Form, Select, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';

interface IProps {
  name?: string;
  rules?: any;
}

export const RolesSelect: React.FC<IProps> = (props) => {
  const employeesT = useTranslation(NSKeys.employees);
  const [rolesLoading, setRolesLoading] = useState(true);
  const [rolesList, setRolesList] = useState<{ label: string; value: number }[]>([]);

  const fetchSelectLists = (): void => {
    Api.users
      .getRoles()
      .then((resp) => {
        setRolesList(resp.items.map((el) => ({ value: el.pk, label: el.name })));
      })
      .finally(() => setRolesLoading(false));
  };

  useEffect(() => {
    fetchSelectLists();
  }, []);

  if (rolesLoading) {
    return (
      <Form.Item label={employeesT.t('form.role_idLabel')}>
        <Skeleton.Input active />
      </Form.Item>
    );
  }

  return (
    <Form.Item name={props.name || 'title'} label={employeesT.t('form.role_idLabel')} rules={props.rules}>
      <Select loading={rolesLoading} options={rolesList} placeholder={employeesT.t('form.role_idPlaceholder')} />
    </Form.Item>
  );
};
