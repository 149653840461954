import React from 'react';

import { Button, Layout, Row, Skeleton, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';

import { Breadcrumbs } from '@components/Breadcrumbs';
import { HeaderTitle } from '@components/HeaderTitle';
import { Paper } from '@components/Paper';
import { ChangeStatus } from '@pages/Processes/pages/Orders/components/ChangeStatus';

import { useGetOrderQuery, useGetProcessQuery, useUpdateQuery } from '../../../../hooks';

import { OrderForm } from './components/OrderForm';

export const Edit: React.FC = () => {
  const navigate = useNavigate();
  const orderT = useTranslation(NSKeys.order);
  const commonT = useTranslation(NSKeys.common);
  const params = useParams<{ orderId: string; processId: string }>();
  const { data: orderData, isLoading } = useGetOrderQuery(params?.orderId || '');
  const { data: processData, isLoading: isProcessLoading } = useGetProcessQuery(params?.processId || '');
  const { mutate, status: mutateStatus } = useUpdateQuery();

  const goBack = () => navigate(`/dashboard/processes/${params.processId}/orders/${params.orderId}`);

  React.useEffect(() => {
    if (mutateStatus === 'success') {
      goBack();
    }
  }, [mutateStatus]);

  const handleFinish = (data: any) => {
    mutate(data);
  };

  return (
    <Layout>
      <Layout.Header style={{ borderBottom: 'none' }}>
        <Breadcrumbs apiTitles={[{ id: ':ordersId', value: `${orderT.t('breadCrumbPrefix')} ${orderData?.num}` }]} />
        <Row justify="space-between" align="middle" style={{ minHeight: 64 }}>
          <HeaderTitle loading={isLoading} title={`${orderData?.num || params.orderId}`} onClick={goBack}>
            {orderData && params.orderId && (
              <ChangeStatus
                processLoading={isProcessLoading}
                processData={processData}
                moveAvailable={!!orderData?.$permissions?.move_to_status}
                statusUid={orderData?.current_status_uid}
                orderUid={params.orderId}
                available_statuses_uids={orderData?.$available_statuses?.map((el) => el.uid)}
              />
            )}
          </HeaderTitle>
          <Space>
            <Button
              type="primary"
              size="middle"
              form="updateOrder"
              key="submit"
              htmlType="submit"
              loading={mutateStatus === 'loading'}>
              {commonT.t('buttonSave')}
            </Button>
            <Button onClick={goBack} size="middle">
              {commonT.t('buttonCancel')}
            </Button>
          </Space>
        </Row>
      </Layout.Header>
      <Paper style={{ margin: '24px', padding: '24px 24px' }}>
        <Typography.Title level={3}>{orderT.t('blockInfo.title')}</Typography.Title>
        {params.orderId && params.processId ? (
          <OrderForm onFinish={handleFinish} />
        ) : (
          <Skeleton.Input style={{ margin: '20px 0' }} />
        )}
      </Paper>
    </Layout>
  );
};
