import React from 'react';

import { CheckOutlined, CloseOutlined, DisconnectOutlined, EditTwoTone, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, message, Row, Space, Table, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { CreateOrLinkContactSchema } from '@app/models/dictionaries';

import { NEW_UID } from '../../../../data/contants';
import { useUpdateContactQuery } from '../../../../hooks';
import { useRecipientContacts } from '../../../../providers';

import { EditableCell } from './components/EditableCell';
import { Container } from './styles';

interface IProps {
  title: string;
  recipientUid?: string;
}
type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export const ContactsTable: React.FC<IProps> = (props) => {
  const { token } = theme.useToken();
  const recipientsT = useTranslation(NSKeys.recipients);
  const [form] = Form.useForm<CreateOrLinkContactSchema>();
  const {
    data: dataSource,
    setData: setDataSource,
    setEditingUid,
    editingUid,
    loading,
    createContact,
  } = useRecipientContacts();
  const { mutate, isLoading } = useUpdateContactQuery();

  const isEditing = (record: CreateOrLinkContactSchema) => record.uid === editingUid;

  const handleAdd = () => {
    const newData: CreateOrLinkContactSchema = {
      uid: NEW_UID,
      name: '',
      email: '',
      phone: '',
    };
    form.setFieldValue('uid', NEW_UID);
    setDataSource([...dataSource, newData]);
    setEditingUid(NEW_UID);
  };

  const handleEdit = (record: Partial<CreateOrLinkContactSchema>) => {
    form.setFieldsValue({ name: '', phone: '', email: '', ...record });
    setEditingUid(record?.uid || '');
  };

  const handleCancel = async () => {
    if (editingUid === NEW_UID) {
      setDataSource(dataSource.filter((el) => el.uid !== NEW_UID));
    }
    setEditingUid('');
    form.resetFields();
  };

  const handleContactSelect = (option: any, value: string) => {
    if (option?.data) {
      form.setFieldValue('email', option?.data?.email || '');
      form.setFieldValue('phone', option?.data?.phone || '');
    }
    form.setFieldValue('name', option?.data?.name || value);
    form.setFieldValue('uid', option?.data?.uid || NEW_UID);
  };

  const handleSave = async () => {
    try {
      const row = (await form.validateFields()) as CreateOrLinkContactSchema;
      if (row.uid === NEW_UID) {
        // добавление новго контакта
        createContact({
          name: row?.name || '',
          email: row.email || null,
          phone: row.phone || '',
          recipients: [],
        }).then(() => form.resetFields());
      } else if (editingUid === NEW_UID) {
        // добавление существующего контакта
        setDataSource([...dataSource.filter((el) => el.uid !== NEW_UID), row]);
        setEditingUid('');
        form.resetFields();
      } else if (props.recipientUid) {
        mutate({
          uid: row.uid || '',
          recipientUid: props.recipientUid,
          data: { name: row.name, phone: row.phone, email: row.email },
        });
        setEditingUid('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:');
    }
  };

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
    inputType?: string;
    onContactSelect?: (option: any, value: string) => void;
  })[] = [
    {
      title: 'tableContacts.name',
      dataIndex: 'name',
      editable: true,
      inputType: editingUid === NEW_UID ? 'contactSelect' : 'input',
      onContactSelect: handleContactSelect,
    },
    {
      title: 'tableContacts.phone',
      dataIndex: 'phone',
      editable: true,
      inputType: 'phone',
    },
    {
      title: 'tableContacts.email',
      dataIndex: 'email',
      editable: true,
      inputType: 'input',
    },
    {
      title: '',
      dataIndex: 'operation',
      width: '90px',
      fixed: 'right',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Button type="link" icon={<CheckOutlined />} onClick={handleSave} />
            <Button type="link" icon={<CloseOutlined />} onClick={handleCancel} />
          </Space>
        ) : (
          <Space>
            <Button
              type="link"
              icon={<EditTwoTone twoToneColor={token.colorPrimary} />}
              disabled={editingUid !== ''}
              onClick={() => handleEdit(record)}
            />
            <Button
              type="link"
              icon={<DisconnectOutlined twoToneColor={token.colorPrimary} />}
              disabled={editingUid !== ''}
              onClick={(e) => {
                setDataSource(dataSource.filter((el) => el.uid !== record.uid));
              }}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <Form form={form} component={false}>
      <Container>
        <Row justify="space-between" style={{ padding: '16px 0', marginBottom: 8 }}>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {props.title}
          </Typography.Title>
          <Button
            onClick={handleAdd}
            disabled={!!editingUid}
            icon={<PlusCircleOutlined style={{ color: token.colorPrimary }} />}>
            {recipientsT.t('newContactButton')}
          </Button>
        </Row>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          loading={loading}
          rowClassName="editable-row"
          rowKey="uid"
          bordered
          pagination={false}
          scroll={{ x: 700 }}
          dataSource={dataSource}
          columns={
            defaultColumns
              .map((el) => ({ ...el, title: recipientsT.t(el.title as any) }))
              .map((col) => {
                if (!col.editable) {
                  return col;
                }
                return {
                  ...col,
                  onCell: (record: CreateOrLinkContactSchema) => ({
                    record,
                    inputType: col.inputType,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    onContactSelect: col.onContactSelect,
                    editing: isEditing(record),
                  }),
                };
              }) as ColumnTypes
          }
        />
      </Container>
    </Form>
  );
};
