import React from 'react';

import { Form, Input } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { CommonDictionariesSlugs } from '@app/models/dictionaries';
import { CreatedSelect, PhoneInput, RecipientsSelect } from '@components/AntFields';

import { useContactRecipients } from '../../../../../../providers';
import { ValidationSchema } from '../../validations';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: string;
  children: React.ReactNode;
  onRecipientSelect?: (options: any, value: string) => void;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  onRecipientSelect,
  ...restProps
}) => {
  const validationT = useTranslation(NSKeys.validation);
  const recipientsT = useTranslation(NSKeys.recipients);
  const { data: recipients } = useContactRecipients();

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  const inputNode = (): React.ReactNode => {
    switch (inputType) {
      case 'recipientSelect':
        return (
          <Form.Item name={dataIndex} rules={[yupSync]}>
            <RecipientsSelect
              showSearch
              placeholder={title}
              disableValues={recipients.map((el) => el.uid || '')}
              onSelect={(value, opt) => {
                onRecipientSelect && onRecipientSelect(opt, value);
              }}
            />
          </Form.Item>
        );
      case 'retailerSelect':
        return (
          <CreatedSelect
            removable
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            required={false}
            rules={[yupSync]}
            name="retailer_uid"
            dictionaryName={CommonDictionariesSlugs.Retailers}
            placeholder={recipientsT.t('form.retailerPlaceholder')}
          />
        );
      case 'type':
        return (
          <CreatedSelect
            removable
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            required={false}
            rules={[yupSync]}
            name="type_uid"
            dictionaryName={CommonDictionariesSlugs.RecipientTypes}
            placeholder={recipientsT.t('form.typePlaceholder')}
          />
        );
      case 'tags':
        return (
          <CreatedSelect
            removable
            mode="multiple"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            required={false}
            rules={undefined}
            name="tags_uids"
            dictionaryName={CommonDictionariesSlugs.RecipientTags}
            placeholder={recipientsT.t('form.tagsPlaceholder')}
          />
        );
      default:
        return (
          <Form.Item name={dataIndex} rules={[yupSync]}>
            <Input />
          </Form.Item>
        );
    }
  };

  return (
    <td {...restProps}>
      {editing ? (
        <>
          {dataIndex === 'name' && (
            <Form.Item name="uid" style={{ display: 'none' }}>
              <Input />
            </Form.Item>
          )}
          {inputNode()}
        </>
      ) : (
        children
      )}
    </td>
  );
};
