import React, { memo } from 'react';

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, InputNumber, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import { debounce } from 'throttle-debounce';

import { NSKeys } from '@app/i18n';

import { Container } from './styles';

const MAX_COUNT = 999999999;

interface IProps {
  max?: number;
  value: number;
  isSmall?: boolean;
  title?: string;
  onInputChange: (count: number) => void;
}

export const AddRemoveControl: React.FC<IProps> = ({ max = MAX_COUNT, ...props }) => {
  const [value, setValue] = React.useState(props.value);
  const [focused, setFocused] = React.useState(false);
  const { token } = theme.useToken();
  const nomenclatureAddT = useTranslation(NSKeys.nomenclatureAdd);

  const handleInputChange = (count: number | null): void => {
    props.onInputChange(count || 0);
  };

  const debounceOnChange = React.useCallback(debounce(300, handleInputChange), []);

  const handleValueChange = (value: number): void => {
    if (value >= 0) {
      debounceOnChange(value);
      setValue(value);
    }
  };

  if (!value && !focused) {
    return (
      <Button
        type="primary"
        onClick={() => {
          handleValueChange(1);
        }}
        style={{ width: props.isSmall ? '155px' : '100%' }}
        disabled={max === 0}>
        {props.title || nomenclatureAddT.t('addToCart')}
      </Button>
    );
  }

  return (
    <Container $isSmall={props.isSmall} $borderColor={token.colorBorder}>
      <Button
        onClick={() => {
          handleValueChange(value - 1);
        }}>
        <MinusOutlined />
      </Button>
      <InputNumber
        min={0}
        controls={false}
        max={max}
        value={value}
        onChange={(value) => {
          handleValueChange(value || 0);
        }}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      <Button
        onClick={() => {
          handleValueChange(value + 1);
        }}
        disabled={value >= max}>
        <PlusOutlined />
      </Button>
    </Container>
  );
};

export default memo(AddRemoveControl);
