import { Typography } from 'antd';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div<{ $colorBgBase: string; $colorBorder: string; $colorText: string }>`
  color: ${({ $colorBorder }) => $colorBorder};
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
`;

export const Label = styled(Typography.Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgb(108 108 108);
`;
