import { Input as AntInput } from 'antd';
import styled from 'styled-components';

export const Input = styled(AntInput)`
  border: none;
  box-shadow: none;

  &:hover {
    border-style: dashed;
  }
`;
