import { Form, Radio, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Namespace, TFunction } from 'react-i18next';

import { Action, ActionConfig, Level, PermissionScope, ScopeConfig } from '@app/models/users';

import { IFormValues } from '../interfaces';

const isCellEnabled = (params: {
  level: Level;
  config?: ScopeConfig[];
  actionKey: Action;
  formValues: IFormValues;
  permissionScope: PermissionScope;
}) => {
  const scopeConfig = params.config?.find((el) => el.scope === params.permissionScope);
  const scopeConfigAction = scopeConfig?.actions?.find((el) => el.action === params.actionKey);
  const scopeLevelRestrictions = scopeConfigAction?.restrictions?.filter((restriction) =>
    restriction.levels.some((level) => level === params.level)
  );

  if (scopeLevelRestrictions?.length && scopeConfig) {
    const res = scopeLevelRestrictions.map((scopeRestriction) => {
      const data = scopeRestriction.rules?.map((scopeRestrictionRule) => {
        const resByFormValue = scopeRestrictionRule.levels
          .map((scopeRestrictionRuleLevel) => {
            const valueInTable = params.formValues?.[scopeRestrictionRule.scope]?.[scopeRestrictionRule.action];
            const ruleComplete = scopeRestrictionRule.levels.some((el) => el === valueInTable);
            // if (scopeConfig.scope === 'catalogs') {
            //   console.log('scopeLevelRestrictions:', scopeConfig.scope, scopeLevelRestrictions);
            //   console.log('scopeRestrictionRule:', scopeRestrictionRule);
            //   console.log('valueInTable:', valueInTable);
            // }
            return ruleComplete;
          })
          .every((el) => el);
        return resByFormValue;
      });
      return data.every((el) => !!el);
    });
    return res.every((el) => el);
  } else {
    return true;
  }
};

export const columnsNew = ({
  isEditing,
  config,
  formValues,
  t,
}: {
  t: TFunction<Namespace<'spaceSettings'>>;
  isEditing: boolean;
  config?: ScopeConfig[];
  formValues: IFormValues;
}): ColumnsType<ScopeConfig> => {
  const actionColumns: ColumnsType<ScopeConfig> = (['View', 'Manage', 'Export'] as Array<keyof typeof Action>).map(
    (key) => ({
      title: t(`role-action.${Action[key]}`),
      dataIndex: 'actions',
      align: 'left',
      ellipsis: true,
      render: (value: ActionConfig[], record, i) => {
        const cellAction = value.find((el) => el.action === Action[key]);

        return (
          <Form.Item noStyle name={[record.scope, Action[key]]}>
            <Radio.Group>
              <Space direction="vertical" align="start">
                {cellAction?.levels?.map((level) => (
                  <Radio
                    value={level}
                    key={level}
                    style={{ textAlign: 'left' }}
                    disabled={
                      isEditing
                        ? !isCellEnabled({
                            level,
                            config,
                            actionKey: Action[key],
                            permissionScope: record.scope,
                            formValues,
                          })
                        : true
                    }>
                    <>{t(`role-level.${level}`)}</>
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        );
      },
    })
  );

  return [
    {
      title: '',
      dataIndex: 'scope',
      ellipsis: true,
      render: (el, record) => t(`role-scope.${record.scope}` as any),
    },
    ...actionColumns,
  ];
};
