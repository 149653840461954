import { useQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

export const useGetCatalogSettingsQuery = (id?: string) => {
  return useQuery({
    queryKey: ['getCatalogSettings', id],
    queryFn: () => Api.products.getCatalogSettings(id || ''),
  });
};
