import { useQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

export const useGetProcessesQuery = () => {
  return useQuery({
    queryKey: ['getProcesses'],
    queryFn: () => Api.orders.getProcesses(),
  });
};
