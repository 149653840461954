import dayjs from 'dayjs';

import en from 'dayjs/locale/en';
import ptBr from 'dayjs/locale/pt-br';
import ru from 'dayjs/locale/ru';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { getLanguage } from '@app/services/i18n';

import { TIME_ZONES } from './constants';
export { TIME_ZONES } from './constants';

export const formatDate = (date?: string | Date): string => (date ? dayjs(date).format('DD.MM.YYYY') : '-');
export const formatDateAndTime = (date?: string | Date): string => {
  return date ? dayjs(date).format('D MMMM YYYY / HH:mm') : '—';
};
export const formatDateAndTimeShort = (date?: string | Date): string =>
  date ? dayjs(date).format('DD.MM.YY HH:mm') : '—';

export const formatTimeInterval = (date1?: string | Date, date2?: string | Date): string => {
  if (!date1 || !date2) {
    return '';
  } else return `(${dayjs(date1).format('HH:mm')} - ${dayjs(date2).format('HH:mm')})`;
};

export const addMonths = (date: any, months: number) => {
  date.setMonth(date.getMonth() + months);
  return new Date(date).toISOString();
};

export const getCurrentTimeZone = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.guess();
  const guess = dayjs.tz.guess();
  const filteredZone = TIME_ZONES.find((el) => el.value === guess);
  const language = getLanguage();
  if (filteredZone?.value) {
    return filteredZone.value;
  } else if (language === 'ru') {
    return 'Europe/Moscow';
  } else if (language === 'pt') {
    return 'America/Buenos_Aires';
  } else {
    return 'Europe/London';
  }
};

export const setupDayJs = (value: string) => {
  if (value === 'ru') {
    return dayjs.locale(ru);
  } else if (value === 'pt') {
    return dayjs.locale(ptBr);
  } else {
    return dayjs.locale(en);
  }
};
