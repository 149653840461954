import { FC } from 'react';

import { PhoneInput as PhoneInputUI } from '@components/PhoneInput';

export interface IProps {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  isHorizontal?: boolean;
  'aria-invalid'?: boolean;
}

export const PhoneInput: FC<IProps> = (props) => {
  const handleInputChange = (value: string): void => {
    if (props.onChange) {
      props?.onChange(value);
    }
  };

  return (
    <PhoneInputUI
      onChange={handleInputChange}
      value={props?.value || ''}
      isHorizontal={props.isHorizontal}
      disabled={props.disabled}
      placeholder={props.placeholder}
      hasError={!!props?.['aria-invalid']}
    />
  );
};
