import React from 'react';

import { message } from 'antd';
import type { UploadProps } from 'antd/es/upload';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { CatalogCoverSchema } from '@app/models/products';
import { Upload } from '@components/AntFields';

interface IProps {
  id?: string;
  disabled?: boolean;
  value?: CatalogCoverSchema[];
  onChange?: (value: any) => void;
}

export const MultiImage: React.FC<IProps> = (props) => {
  const commonT = useTranslation(NSKeys.common);
  const [fileList, setFileList] = React.useState<any>([]);

  React.useEffect(() => {
    if (props.value?.length && fileList.length === 0) {
      console.log(props.value);
      setFileList(
        props.value
          .filter((el) => el?.variants?.thumbnail?.url)
          .map((el) => ({
            uid: el.uid,
            name: el.uid,
            status: 'done',
            url: el.variants.thumbnail?.url,
          }))
      );
    }
  }, [props.value]);

  const handleChange: UploadProps['onChange'] = (info) => {
    const status = info.file.status;

    if (status === 'removed') {
      const data = info?.fileList.filter((el) => {
        const initUid = el?.response?.uid || el?.uid;
        const delUid = info.file?.response?.uid || info.file?.uid;
        return initUid !== delUid;
      });
      props.onChange?.(data);
    }
    if (status === 'done') {
      const data = info?.fileList.map((el) => ({ uid: el?.response?.uid || el.uid }));
      props.onChange?.(data);
    }
    setFileList(info?.fileList);
    if (status === 'error') {
      setFileList([]);
      message.error(commonT.t('error400'));
    }
  };

  return (
    <Upload
      fileList={fileList}
      apiPath={'orders/order-images/upload'}
      listType="picture-card"
      name="media"
      disabled={!!props?.disabled}
      multiple={false}
      maxCount={10}
      onChange={handleChange}
    />
  );
};
