import React from 'react';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { Status } from '@components/Status';

interface IProps {
  isActive?: boolean;
}

export const UserStatus: React.FC<IProps> = ({ isActive = false }) => {
  const employeesT = useTranslation(NSKeys.employees);

  const returnStatus = (color: string, text: string): React.ReactNode => <Status color={color} text={text} />;

  return (
    <React.Fragment>
      {isActive
        ? returnStatus('rgb(50, 205,50)', employeesT.t('status.Active'))
        : returnStatus('rgb(105, 105, 105)', employeesT.t('status.Disabled'))}
    </React.Fragment>
  );
};
