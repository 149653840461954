import { Namespace, TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { IFormValues, IKind } from '../interfaces';

export const ValidationSchema = (t: TFunction<Namespace<'validation'>>): Yup.SchemaOf<IFormValues> =>
  Yup.object().shape({
    name: Yup.string().required(t('required')),
    kind: Yup.mixed<IKind>().required(t('required')),
  });
