import { Breadcrumb as AntBreadcrumb } from 'antd';
import styled from 'styled-components';

export const Breadcrumb = styled(AntBreadcrumb)<{ $colorText: string }>`
  margin-top: 16px;

  &.ant-breadcrumb li:last-child {
    a span {
      color: ${({ $colorText }) => $colorText};
    }
  }
`;
