import { useInfiniteQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

interface IProps {
  limit?: number;
  filters: {
    group_uid_ne?: string;
  };
}

export const useUsersQuery = ({ limit, filters }: IProps) => {
  return useInfiniteQuery({
    queryKey: ['getUsers'],
    queryFn: async ({ pageParam = 0 }) => {
      return await Api.users.getUsers({
        offset: pageParam,
        limit: limit || 0,
        group_uid_ne: filters.group_uid_ne,
      });
    },
    getNextPageParam: (current, allPages) => {
      const count = allPages.length * (limit || 1);
      return count >= (current.total || 0) ? undefined : count;
    },
  });
};
