import React, { FC } from 'react';

import { Button, Form, Layout, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { HeaderTitle } from '@components/HeaderTitle';

import { FormContact, IFormValues } from '../../components/Form';
import { useGetContact, useUpdateContactQuery } from '../../hooks';
import { useContactRecipients } from '../../providers';

export const Edit: FC = () => {
  const commonT = useTranslation(NSKeys.common);
  const navigate = useNavigate();
  const [form] = Form.useForm<IFormValues>();
  const params = useParams<{ id: string }>();
  const goBack = () => navigate('/dashboard/contacts');
  const { setData: setRecipients, data: recipients } = useContactRecipients();
  const { data: contact, isLoading: isContactLoading } = useGetContact(params?.id || '');
  const { mutate: updateContact, isLoading: isMutating, status: mutateStatus } = useUpdateContactQuery();

  const handleSubmit = async (formValues: IFormValues): Promise<void> => {
    if (params.id) {
      updateContact({
        uid: params.id,
        data: {
          ...formValues,
          email: formValues.email || null,
          recipients: recipients.map((el) => ({ uid: el.uid || '' })),
        },
      });
    }
  };

  React.useEffect(() => {
    if (mutateStatus === 'success') {
      form.resetFields();
      navigate(`/dashboard/contacts/${params.id}`);
    }
  }, [mutateStatus]);

  React.useEffect(() => {
    if (contact?.recipients?.length) {
      setRecipients(contact.recipients);
    }
  }, [contact]);

  return (
    <>
      <Layout.Header>
        <Breadcrumbs apiTitles={[{ id: ':contactId', value: contact?.name }]} />
        <Row justify="space-between" align="middle">
          <HeaderTitle loading={isContactLoading} title={`${contact?.name || params.id}`} onClick={goBack} />

          <Space size="middle">
            <Button onClick={goBack} size="middle" name="catalog_form" id="catalog_form">
              {commonT.t('buttonCancel')}
            </Button>
            <Button
              type="primary"
              size="middle"
              form="contactForm"
              htmlType="submit"
              disabled={isMutating || isContactLoading}>
              {commonT.t('buttonSave')}
            </Button>
          </Space>
        </Row>
      </Layout.Header>
      {!isContactLoading && (
        <FormContact
          form={form}
          onFinish={handleSubmit}
          initialValues={{ name: contact?.name, email: contact?.email || '', phone: contact?.phone }}
        />
      )}
    </>
  );
};
