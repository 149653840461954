import React, { createContext, ReactNode, useContext, useState } from 'react';

import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { CreateRecipientSchema } from '@app/models/dictionaries';
import Api from '@app/services/api';

import { NEW_UID } from '../data/contants';

import { RecipientSchema } from './interfaces';

interface IContext {
  data: RecipientSchema[];
  setData: (data: RecipientSchema[]) => void;
  setEditingUid: (uid: string) => void;
  editingUid: string;
}

const ContactRecipientsContext = createContext<IContext>(null!);

function ContactRecipientsProvider({ children }: { children: ReactNode }) {
  const [data, setData] = useState<RecipientSchema[]>([]);
  const [editingUid, setEditingUid] = useState('');

  const value = {
    data,
    setData,
    editingUid,
    setEditingUid,
  };

  return <ContactRecipientsContext.Provider value={value}>{children}</ContactRecipientsContext.Provider>;
}

const useContactRecipients = () => {
  const context = useContext(ContactRecipientsContext);

  if (context === undefined) {
    throw new Error('useRecipientContacts must be used within a ContactRecipientsProvider');
  }

  return context;
};

export { ContactRecipientsContext, ContactRecipientsProvider, useContactRecipients };
