import React, { FC } from 'react';

import { Button, Form, Input, Layout, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { PageTitle } from '@components/PageTitle';
import { Paper } from '@components/Paper';

import { useCreateWarehouseQuery } from '../../hooks';

import { IFormValues } from './interfaces';
import { ValidationSchema } from './validations';

export const Add: FC = () => {
  const [form] = Form.useForm<IFormValues>();
  const navigate = useNavigate();
  const commonT = useTranslation(NSKeys.common);
  const warehousesT = useTranslation(NSKeys.warehouses);
  const validationT = useTranslation(NSKeys.validation);
  const { mutate: create, status: createStatus, data } = useCreateWarehouseQuery();

  React.useEffect(() => {
    if (createStatus === 'success' && data?.uid) {
      navigate(`/dashboard/warehouses/${data?.uid}`);
    }
  }, [createStatus, data]);

  const goBack = () => navigate('/dashboard/warehouses');

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  return (
    <>
      <Layout.Header>
        <Breadcrumbs />
        <Row justify="space-between" align="middle">
          <PageTitle title={warehousesT.t('titleAdd')} onClick={goBack} />

          <Space size="middle">
            <Button onClick={goBack} size="middle" name="catalog_form" id="catalog_form">
              {commonT.t('buttonCancel')}
            </Button>
            <Button
              type="primary"
              size="middle"
              form="createWarehouse"
              htmlType="submit"
              loading={createStatus === 'loading'}>
              {commonT.t('buttonSave')}
            </Button>
          </Space>
        </Row>
      </Layout.Header>

      <Paper style={{ padding: 24, margin: 24 }}>
        <Form<IFormValues>
          form={form}
          name="createWarehouse"
          id="createWarehouse"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          onFinish={create}>
          <Form.Item<IFormValues> name="name" required label={warehousesT.t('name')} rules={[yupSync]}>
            <Input placeholder={warehousesT.t('namePlaceholder')} />
          </Form.Item>
        </Form>
      </Paper>
    </>
  );
};
