import React, { FC, useState } from 'react';

import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { theme as antTheme, Button, Divider, Flex, Popover, Skeleton, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { useGetProcessesQuery } from '../../hooks';

import { controlContainerStyles } from './styles';

interface ControlReportProps {
  activeProcessId?: string;
  onSelect: (reportId: string) => void;
  onCreate?: () => void;
}

export const ControlProcess: FC<ControlReportProps> = (props) => {
  const commonT = useTranslation(NSKeys.common);
  const { activeProcessId, onSelect } = props;
  const { token } = antTheme.useToken();
  const { data, isLoading } = useGetProcessesQuery();
  const [isOpen, setOpen] = useState(false);

  const handleSelect = (id: string) => {
    onSelect(id);
    setOpen(false);
  };

  const renderButton = (reportId: string, name: string) => (
    <Button
      key={reportId}
      type="text"
      className={reportId === activeProcessId ? 'active' : ''}
      disabled={reportId === activeProcessId}
      onClick={() => handleSelect(reportId.toString())}>
      {name}
    </Button>
  );

  return (
    <Flex align="center" gap={4}>
      {isLoading ? (
        <Skeleton.Input style={{ display: 'block' }} />
      ) : (
        <Typography.Title level={2} className="title" style={{ marginBottom: 0, wordBreak: 'normal' }}>
          {data?.items?.find((el) => el.uid === activeProcessId)?.name}
        </Typography.Title>
      )}
      <Popover
        arrow={false}
        trigger="click"
        open={isOpen}
        onOpenChange={setOpen}
        destroyTooltipOnHide
        placement="bottom"
        overlayInnerStyle={{ width: '313px', padding: '5px' }}
        content={
          <Flex vertical={true} className={controlContainerStyles}>
            <Flex vertical className="scrolledContent">
              {data?.items?.map(({ uid, name }) => renderButton(uid, name))}
            </Flex>
            {props.onCreate && (
              <>
                <Divider style={{ margin: 0 }} />
                <Button
                  icon={<PlusOutlined />}
                  type="text"
                  onClick={() => {
                    setOpen(false);
                    props.onCreate?.();
                  }}>
                  {commonT.t('create')}
                </Button>
              </>
            )}
          </Flex>
        }>
        <Button
          shape="circle"
          type="link"
          loading={isLoading}
          disabled={!data?.items?.length}
          icon={<DownOutlined style={{ color: token.colorPrimary }} />}
          onClick={() => setOpen(true)}
        />
      </Popover>
    </Flex>
  );
};
