import React, { FC } from 'react';

import { Button, Form, Layout, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { PageTitle } from '@components/PageTitle';

import { FormContact, IFormValues } from '../../components/Form';
import { useAddContactQuery } from '../../hooks';
import { useContactRecipients } from '../../providers';

export const Add: FC = () => {
  const commonT = useTranslation(NSKeys.common);
  const contactsT = useTranslation(NSKeys.contacts);
  const navigate = useNavigate();
  const [form] = Form.useForm<IFormValues>();

  const goBack = () => navigate('/dashboard/contacts');
  const { mutate: createContact, status, data } = useAddContactQuery();
  const { data: recipients } = useContactRecipients();

  const handleSubmit = async (formValues: IFormValues): Promise<void> => {
    createContact({
      ...formValues,
      email: formValues.email || null,
      recipients: recipients.map((el) => ({ uid: el.uid || '' })),
    });
  };

  React.useEffect(() => {
    if (status === 'success') {
      goBack();
      form.resetFields();
    }
  }, [status, data]);

  return (
    <>
      <Layout.Header>
        <Breadcrumbs />
        <Row justify="space-between" align="middle">
          <PageTitle title={contactsT.t('titleAdd')} onClick={goBack} />
          <Space size="middle">
            <Button onClick={goBack} size="middle" name="catalog_form" id="catalog_form">
              {commonT.t('buttonCancel')}
            </Button>
            <Button type="primary" size="middle" form="contactForm" htmlType="submit">
              {commonT.t('buttonSave')}
            </Button>
          </Space>
        </Row>
      </Layout.Header>
      <FormContact form={form} onFinish={handleSubmit} />
    </>
  );
};
