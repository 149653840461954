import React from 'react';

import { Modal, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { FieldStructure } from '@app/models/interfaces';

import { useUpdateField } from '../../../../../../../../../../../../hooks';
import { FieldForm } from '../../../../../../../FieldForm';

interface IProps {
  open: boolean;
  onClose: () => void;
  field: FieldStructure;
}

export const EditFieldModal: React.FC<IProps> = ({ field, open, onClose }) => {
  const ordersT = useTranslation(NSKeys.orders);
  const commonT = useTranslation(NSKeys.common);
  const { mutate, status } = useUpdateField();

  React.useEffect(() => {
    if (status === 'success') {
      onClose();
    }
  }, [status]);

  return (
    <Modal
      title={
        <Space align="center">
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            {ordersT.t('editFieldTitle')}
          </Typography.Title>
        </Space>
      }
      open={open}
      destroyOnClose
      okButtonProps={{
        form: 'create_field',
        htmlType: 'submit',
        loading: status === 'loading',
      }}
      okText={commonT.t('buttonSave')}
      confirmLoading={status === 'loading'}
      onCancel={onClose}>
      <div style={{ margin: '40px 0' }}>
        <FieldForm
          isEditing={true}
          onSubmit={(values) => {
            mutate({ field_uid: field.uid, ...values });
          }}
          initialValues={{ name: field.name, type: field.type, is_required: field.is_required }}
        />
      </div>
    </Modal>
  );
};
