import React from 'react';

import { FilterTwoTone } from '@ant-design/icons';
import { Button, CheckboxOptionType, Dropdown, theme } from 'antd';

import { Filters } from '../../interfaces';

import { CheckboxGroup } from './styles';

interface IProps {
  selectedFilters: Filters[];
  filters: CheckboxOptionType[];
  onChange: (e: Filters[]) => void;
}

export const FilterEditMenu: React.FC<IProps> = (props) => {
  const { token } = theme.useToken();

  return (
    <Dropdown
      trigger={['click']}
      placement="bottomRight"
      overlayStyle={{ position: 'fixed' }}
      dropdownRender={() => (
        <div
          style={{
            backgroundColor: token.colorBgElevated,
            borderRadius: token.borderRadiusLG,
            boxShadow: token.boxShadowSecondary,
          }}>
          <CheckboxGroup
            onChange={(opt) => props.onChange(opt as Filters[])}
            options={props.filters}
            value={props.selectedFilters}
          />
        </div>
      )}>
      <Button
        onClick={(e) => e.preventDefault()}
        icon={<FilterTwoTone twoToneColor={token.colorPrimary} />}
        size="middle"
      />
    </Dropdown>
  );
};
