import React from 'react';

import { Checkbox, Divider, Flex, Form, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { UserNotificationSettings } from '@app/models/users';
import { useLocalDispatch, useLocalSelector } from '@app/services/hooks/useRedux';
import { setNotifications } from '@app/store/currentSessionReducer';

import { useUpdateUserNotifications } from '../../hooks';

interface IProps {
  test?: string;
}
interface IFormValues {
  orders_change_status: boolean;
  orders_for_approval: boolean;
}

interface DataType {
  key: string;
  description: string;
}

const DATA_SOURCE: DataType[] = [
  { key: 'orders_change_status', description: 'changeStatusTitle' },
  { key: 'orders_for_approval', description: 'needApproveTitle' },
];

export const Notifications: React.FC<IProps> = (props) => {
  const [form] = Form.useForm();

  const notificationsT = useTranslation(NSKeys.profileSettingsNotifications);
  const data = useLocalSelector((state) => state.currentSession.data);
  const { mutate, status, data: response } = useUpdateUserNotifications();
  const dispatch = useLocalDispatch();

  const onToggleStatus = (value: UserNotificationSettings) => {
    if (data?.pk) {
      mutate({ id: data?.pk.toString(), data: value });
    }
  };

  React.useEffect(() => {
    if (response && status === 'success') {
      dispatch(setNotifications(response));
    }
  }, [response, status]);

  const handleValuesChange = (values: IFormValues) => {
    if (data?.pk) {
      mutate({
        id: data?.pk.toString(),
        data: {
          orders_for_approval: { email: values.orders_for_approval },
          orders_change_status: { email: values.orders_change_status },
        },
      });
    }
  };

  return (
    <Flex vertical style={{ marginBottom: 40 }}>
      <Divider orientation="left">{notificationsT.t('ordersTitle')}</Divider>

      <Form<IFormValues>
        form={form}
        name="notifications_form"
        onFinish={(e) => console.log(e)}
        onValuesChange={(changedValues, values) => handleValuesChange(values)}
        initialValues={{
          orders_change_status: !!data?.settings?.notifications?.orders_change_status?.email,
          orders_for_approval: !!data?.settings?.notifications?.orders_for_approval?.email,
        }}>
        <Table<DataType>
          pagination={false}
          columns={[
            { dataIndex: 'key', render: (value, record) => notificationsT.t(record.description as any) },
            {
              dataIndex: 'key',
              align: 'center',
              title: notificationsT.t('emailNotifications'),
              render: (value) => (
                <Form.Item name={value} valuePropName="checked" noStyle>
                  <Checkbox disabled={status === 'loading'} />
                </Form.Item>
              ),
            },
          ]}
          dataSource={DATA_SOURCE}
        />
      </Form>
    </Flex>
  );
};
