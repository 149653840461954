import React from 'react';

import { AvatarUpload } from './components/AvatarUpload';
import { EmailSection } from './components/EmailSection';
import { Info } from './components/Info';
import { InfoForm } from './components/InfoForm';

interface IProps {
  isEditing: boolean;
  onSubmit: () => void;
}

export const PersonContainer: React.FC<IProps> = (props) => {
  return (
    <React.Fragment>
      <AvatarUpload />

      {props.isEditing ? <InfoForm onSubmit={props.onSubmit} /> : <Info />}

      <EmailSection />
    </React.Fragment>
  );
};
