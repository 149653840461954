import React, { createContext, ReactNode, useContext } from 'react';

import { TABS } from './interfaces';

interface WarehouseType {
  activeTab: TABS;
  isEditing: boolean;
  setActiveTab: (tab: TABS) => void;
  setEditing: (value: boolean) => void;
  editDisabled: boolean;
  setEditDisabled: (value: boolean) => void;
}

const WarehouseContextType = createContext<WarehouseType>(null!);

function WarehouseProvider({ children }: { children: ReactNode }) {
  const [activeTab, setActiveTab] = React.useState(TABS.SETTINGS);
  const [isEditing, setEditing] = React.useState(false);
  const [editDisabled, setEditDisabled] = React.useState(false);

  const value = {
    activeTab,
    isEditing,
    setActiveTab,
    setEditing,
    editDisabled,
    setEditDisabled,
  };

  React.useEffect(() => {
    setEditing(false);
  }, [activeTab]);

  return <WarehouseContextType.Provider value={value}>{children}</WarehouseContextType.Provider>;
}

const useWarehouse = () => {
  const context = useContext(WarehouseContextType);

  if (context === undefined) {
    throw new Error('useRolesState must be used within a RolesProvider');
  }

  return context;
};

export { WarehouseContextType, useWarehouse, WarehouseProvider };
