import React, { FC } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row } from 'antd';
import { Namespace, TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { NSKeys } from '@app/i18n';

import { LAYOUT, TAIL_LAYOUT } from '../../constants';
import { IStep5 } from '../../interfaces';

interface IProps {
  initialValues: IStep5;
  onNext: (values: IStep5) => void;
  onPrev: () => void;
}

const ValidationSchema = (t: TFunction<Namespace<'validation'>>): Yup.SchemaOf<IStep5> =>
  Yup.object().shape({
    company_name: Yup.string().required(t('required')),
  });

export const Step5: FC<IProps> = (props) => {
  const [form] = Form.useForm();
  const registrationT = useTranslation(NSKeys.registration);
  const validationT = useTranslation(NSKeys.validation);
  const company_name = Form.useWatch('company_name', form);

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  return (
    <Form
      form={form}
      name="login_form_step1"
      initialValues={props.initialValues}
      onFinish={props.onNext}
      size="large"
      {...LAYOUT}>
      <Form.Item required label={registrationT.t('form.company')} name="company_name" rules={[yupSync]}>
        <Input placeholder={registrationT.t('form.companyPlaceholder')} />
      </Form.Item>

      <Form.Item {...TAIL_LAYOUT}>
        <Row justify="space-between">
          <Button icon={<ArrowLeftOutlined />} disabled={company_name === ''} onClick={props.onPrev}>
            {registrationT.t('btnBack')}
          </Button>
          <Button type="primary" htmlType="submit">
            {registrationT.t('btnNext')}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};
