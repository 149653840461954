import { Namespace, TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { IFormValues } from '../interfaces';

export const ValidationSchema = (t: TFunction<Namespace<'validation'>>): Yup.SchemaOf<IFormValues> =>
  Yup.object().shape({
    title: Yup.string().required(t('required')),
    code: Yup.string(),
    description: Yup.string().nullable(),
    category_id: Yup.number(),
    brand_id: Yup.number(),
    tags_ids: Yup.array(),
    weight: Yup.number().nullable(),
    length: Yup.number().nullable(),
    height: Yup.number().nullable(),
    width: Yup.number().nullable(),
    price: Yup.number().nullable(),
    package_amount: Yup.number().nullable(),
    is_active: Yup.boolean(),
    image_uids: Yup.array(),
  });
