import React from 'react';

import { Typography } from 'antd';
import { ButtonType } from 'antd/es/button/buttonHelpers';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { DropDownMenuButton } from '@components/DropDownMenuButton';
import { CatalogsModal } from '@pages/Catalogs/components/CatalogsModal';

interface IProps {
  uid: string;
  type?: ButtonType;
  editable: boolean;
  addToCatalog: boolean;
}

export const DropDownMenu: React.FC<IProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const nomenclatureListT = useTranslation(NSKeys.nomenclatureList);

  const handleAddToCatalogFinish = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <DropDownMenuButton
        type={props.type || 'link'}
        menu={[
          ...(props.editable
            ? [
                {
                  label: <Typography.Text>{nomenclatureListT.t('cardMenu.edit')}</Typography.Text>,
                  key: 'edit',
                  onClick: () => {
                    navigate(`/dashboard/products/edit/${props.uid}`);
                  },
                },
              ]
            : []),
          ...(props.addToCatalog
            ? [
                {
                  label: <Typography.Text>{nomenclatureListT.t('cardMenu.addToCatalog')}</Typography.Text>,
                  key: 'addToCatalog',
                  onClick: () => setOpen(true),
                },
              ]
            : []),
        ]}
        placement="bottomRight"
      />
      <CatalogsModal
        title={nomenclatureListT.t('catalogMenu.title')}
        open={open}
        product_uid={props.uid}
        onCancel={() => setOpen(false)}
        onFinish={handleAddToCatalogFinish}
      />
    </React.Fragment>
  );
};
