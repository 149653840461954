import React, { FC } from 'react';

import { Spin } from 'antd';

import { useLocalDispatch, useLocalSelector } from '@app/services/hooks/useRedux';
import { TOKEN_KEY_REMEMBER } from '@app/services/httpClient';
import { getAppInitialData } from '@app/store/currentSessionReducer';

interface IProps {
  children?: JSX.Element | null;
}

export const AppLayout: FC<IProps> = ({ children }) => {
  const dispatch = useLocalDispatch();
  const { isLoading } = useLocalSelector((state) => state.currentSession);

  React.useEffect(() => {
    const tokenRemember = localStorage.getItem(TOKEN_KEY_REMEMBER);
    if (tokenRemember === 'false' || tokenRemember === null) {
      localStorage.clear();
    }
    if (
      location.pathname.includes('/reset/') ||
      location.pathname.includes('/invite/') ||
      location.pathname.includes('/confirm/')
    ) {
      return;
    } else {
      dispatch(getAppInitialData());
    }
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          verticalAlign: 'middle',
          width: '100%',
        }}>
        <Spin />
      </div>
    );
  }

  return <>{children}</>;
};
