import { useQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

export const useGetProcessQuery = (uid?: string) => {
  return useQuery({
    queryKey: ['getProcess', uid],
    queryFn: () => Api.orders.getProcess(uid || ''),
    enabled: !!uid,
  });
};
