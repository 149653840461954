import { theme } from 'antd';
import { ThemeConfig } from 'antd/es/config-provider/context';

export const GET_THEME_CONFIG = (themeAlgorithm: 'dark' | 'light' = 'light', colorPrimary = '#2884DA'): ThemeConfig => {
  const common = {
    colorPrimary: colorPrimary,
    fontFamily: 'InterRegular',
  };

  if (themeAlgorithm === 'dark') {
    return {
      token: {
        ...common,
      },
      algorithm: theme.darkAlgorithm,
    };
  } else
    return {
      token: {
        ...common,
        colorText: 'rgba(11, 21, 57, 0.88)',
        colorError: '#ff3029',
        // colorBorder: '#d9d9d9',
        // colorPrimaryHover: '#4096ff',
        colorTextDescription: 'rgba(11, 21, 57, 0.45)',
      },
      components: {
        Select: {
          colorFillSecondary: '#EEF7FF',
        },
        Badge: {
          colorError: '#2884DA',
        },
        Button: {
          colorBorder: '',
          colorBgContainer: '#EEF7FF',
        },
        Typography: {
          fontWeightStrong: 500,
        },
        Upload: {
          colorFillAlter: '#FFFFFF',
        },
      },
    };
};
