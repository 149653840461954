import { useQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

export const useGetProductQuery = (id: string) => {
  return useQuery({
    queryKey: ['getProduct', id],
    queryFn: () => Api.products.getProduct(id),
  });
};
