import { Row } from 'antd';
import styled from 'styled-components';

export const SpinnerContainer = styled(Row)`
  transition: all 0.3s;
  height: 65px;
  border-top: none;
  border-radius: 0 0 8px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
