import React from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Button, Layout, List, Row, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';

import { Breadcrumbs } from '@components/Breadcrumbs';
import { HeaderTitle } from '@components/HeaderTitle';
import { ListItem } from '@components/ListItem';
import { Paper } from '@components/Paper';

import { useGetContact } from '../../hooks';

const defaultColumns: ColumnsType<any> = [
  {
    title: 'tableRecipients.name',
    dataIndex: 'name',
  },
  {
    title: 'tableRecipients.code',
    dataIndex: 'code',
  },
];

export const Single: React.FC = () => {
  const navigate = useNavigate();
  const contactT = useTranslation(NSKeys.contact);
  const contactsT = useTranslation(NSKeys.contacts);
  const commonT = useTranslation(NSKeys.common);
  const params = useParams<{ id: string }>();

  const { data, isLoading } = useGetContact(params.id || '');

  const goBack = () => navigate('/dashboard/contacts');

  return (
    <Layout>
      <Layout.Header style={{ borderBottom: 'none' }}>
        <Breadcrumbs apiTitles={[{ id: ':contactId', value: data?.name }]} />
        <Row justify="space-between" align="middle" style={{ minHeight: 64 }}>
          <HeaderTitle loading={isLoading} title={`${data?.name || params.id}`} onClick={goBack} />

          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => navigate(`/dashboard/contacts/edit/${params?.id}`)}
            size="middle">
            {commonT.t('edit')}
          </Button>
        </Row>
      </Layout.Header>
      <Paper style={{ margin: '24px', padding: '24px 24px' }}>
        <Typography.Title level={3}>{contactT.t('title')}</Typography.Title>
        <List style={{ maxWidth: 600, margin: '20px 0' }} loading={isLoading}>
          <ListItem title={contactT.t('name')} description={data?.name} />
          <ListItem title={contactT.t('email')} description={data?.email} />
          <ListItem title={contactT.t('phone')} description={data?.phone} />
        </List>

        <Space direction="vertical" style={{ width: '100%' }} size={24}>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {contactsT.t('form.titleRecipients')}
          </Typography.Title>
          <Table
            loading={isLoading}
            rowKey="uid"
            className="tableClassname"
            bordered
            pagination={false}
            dataSource={data?.recipients || []}
            columns={defaultColumns.map((el) => ({ ...el, title: contactsT.t(el.title as any) }))}
            rowClassName={(row) => (row?.$permissions?.view ? 'cursorPointer' : '')}
            onRow={(row) => {
              return {
                onClick: () => !!row?.$permissions?.view && navigate(`/dashboard/recipients/${row.uid}`),
              };
            }}
          />
        </Space>
      </Paper>
    </Layout>
  );
};
