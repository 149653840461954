import React from 'react';

import { DragOutlined } from '@ant-design/icons';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Divider, Flex, Form } from 'antd';

import { FieldStructure } from '@app/models/interfaces';

import { ArtifactItemSchema, FieldGroupItemSchema } from '@app/models/orders';
import { Paper } from '@components/Paper';

import { useFieldGroupReorderQuery } from '../../../../../../hooks';

import { AddFieldModal } from './components/AddFieldModal';
import { DeleteGroup } from './components/DeleteGroup';
import { FieldRender } from './components/FieldRender';
import { GroupFieldName } from './components/GroupFieldName';

interface IProps {
  group: FieldGroupItemSchema;
}

export const CardRender: React.FC<IProps> = ({ group }) => {
  const [fields, setFields] = React.useState<FieldStructure[]>([]);

  const [form] = Form.useForm();
  const { mutate, status } = useFieldGroupReorderQuery();
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ id: group.data.uid });

  React.useEffect(() => {
    setFields(group.data.fields);
  }, [group]);

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? '0.6' : '1',
    zIndex: 100,
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    // const overIsSystem = fields?.find((el) => el.uid === over?.id)?.is_system;
    // if (overIsSystem) return;

    const newFieldIndex = group.data.fields.findIndex((item) => item.uid === over?.id);
    const groupUid = group.data.uid;

    if (over && active.id !== over?.id) {
      mutate({ field_group_uid: groupUid, data: { uid: active.id.toString(), position: newFieldIndex } });
      setFields((groups) => {
        const oldIndex = groups.findIndex((item) => item.uid === active.id);
        const newIndex = groups.findIndex((item) => item.uid === over.id);

        return arrayMove(groups, oldIndex, newIndex);
      });
    }
  };

  return (
    <div ref={setNodeRef}>
      <Paper key={group.data.uid} style={style}>
        <Flex justify="space-between">
          <GroupFieldName field_group_uid={group.data.uid} name={group.data.name} />
          <Flex gap={8}>
            {!!group?.data?.fields && group?.data?.fields?.length === 0 && (
              <DeleteGroup field_group_uid={group.data.uid} />
            )}
            {/* <Button */}
            {/*   type="text" */}
            {/*   icon={<DragOutlined style={{ opacity: 0.6 }} />} */}
            {/*   {...attributes} */}
            {/*   {...listeners} */}
            {/*   style={{ cursor: 'grab' }} */}
            {/* /> */}
          </Flex>
        </Flex>
        <Divider />
        <Form form={form} name="basic1" labelCol={{ span: 6 }} wrapperCol={{ span: 15 }}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            // onDragOver={(e) => fields.find((el) => el.uid === e.over?.id)?.is_system && return}
            modifiers={[restrictToVerticalAxis]}>
            <Flex vertical gap={0}>
              <SortableContext items={fields.map((el) => el.uid) || []} strategy={verticalListSortingStrategy}>
                {group.data.fields.map((field, i) => (
                  <FieldRender form={form} field={field} key={i} />
                ))}
              </SortableContext>
              <AddFieldModal field_group_uid={group.data.uid} />
            </Flex>
          </DndContext>
        </Form>
      </Paper>
    </div>
  );
};
