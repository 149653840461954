import { FC, ReactNode } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

import { Actions, Container } from './styles';

interface IProps {
  title: string;
  children?: ReactNode;
  onClick?: () => void;
}

export const PageTitle: FC<IProps> = ({ title, children, onClick }) => (
  <Container>
    {!!onClick && <ArrowLeftOutlined onClick={onClick} style={{ marginRight: 12 }} />}
    <Typography.Title level={2} style={{ margin: 0 }}>
      {title}
    </Typography.Title>
    {children && <Actions>{children}</Actions>}
  </Container>
);
