import { Row } from 'antd';
import styled from 'styled-components';

export const RowOption = styled(Row)`
  .anticon-close {
    transition: all 0.3s ease;
    opacity: 0;
  }

  &:hover {
    .anticon-close {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
`;
