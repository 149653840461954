import React, { useState } from 'react';

import { Form, Input, Row } from 'antd';

interface IProps {
  searchValue: string;
  placeholder: string;
  onSearchClick: (value: string) => void;
  isLoading: boolean;
}

export const SearchControls: React.FC<IProps> = (props) => {
  const [searchString, setSearchString] = useState('');

  return (
    <Row style={{ gap: 16 }} justify="end">
      <Form.Item>
        <Input.Search
          size="middle"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          placeholder={props.placeholder}
          enterButton
          disabled={props.isLoading}
          onSearch={() => props.onSearchClick(searchString)}
        />
      </Form.Item>
    </Row>
  );
};
