import { ColumnsType } from 'antd/es/table';

import { RetrieveRecipientsSchema } from '@app/models/dictionaries';

export const BASE_COLUMNS_WIP: ColumnsType<RetrieveRecipientsSchema> = [
  {
    title: 'tableColumns.name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
  },
  {
    title: 'tableColumns.retailerTitle',
    dataIndex: 'retailer.title',
    key: 'retailer.title',
    ellipsis: true,
    render: (_, { retailer }) => retailer?.title,
  },
  {
    title: 'tableColumns.type',
    dataIndex: 'type',
    key: 'type',
    ellipsis: true,
    render: (_, { type }) => type?.title,
  },
  {
    title: 'tableColumns.address',
    dataIndex: 'address',
    key: 'address',
    ellipsis: true,
    render: (_, { address }) => address?.as_string,
  },
  {
    title: 'tableColumns.code',
    dataIndex: 'code',
    key: 'code',
    ellipsis: true,
  },
];
