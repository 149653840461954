import { useMutation, useQueryClient } from '@tanstack/react-query';

import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { PartialUpdateContactSchema } from '@app/models/dictionaries';
import Api from '@app/services/api';

interface IParams {
  uid: string;
  recipientUid: string;
  data: PartialUpdateContactSchema;
}

export const useUpdateContactQuery = () => {
  const client = useQueryClient();
  const commonT = useTranslation(NSKeys.common);

  return useMutation({
    mutationFn: async ({ uid, data }: IParams) => {
      const resp = await Api.dictionaries.patchContact(uid, data);
      return resp;
    },
    onSuccess: async (resp, { recipientUid }) => {
      console.log(recipientUid);
      await client.invalidateQueries(['getRecipient', recipientUid]);
      message.open({
        type: 'success',
        content: commonT.t('dataPatchSuccess'),
      });
    },
    onError: () => {
      message.open({
        type: 'error',
        content: commonT.t('error400'),
      });
    },
  });
};
