import React from 'react';

import { PlusOutlined } from '@ant-design/icons';

import { Button, Flex, Select, SelectProps, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { RetrieveRecipientSchema } from '@app/models/dictionaries';

import { Create } from './components/Create';
import { useGetRecipient, useRecipientsQuery } from './hooks';

interface IProps extends SelectProps {
  placeholder: string;
  group_uid_ne?: string;
  disableValues?: string[];
  creatable?: boolean;
  onAddRecipient?: (values: RetrieveRecipientSchema) => void;
}

interface IOption {
  label: string;
  value: string;
  data: any;
  disabled: boolean;
}

export const RecipientsSelect: React.FC<IProps> = ({
  placeholder,
  group_uid_ne,
  disableValues = [],
  creatable = false,
  onAddRecipient,
  ...props
}) => {
  const [search, setSearch] = React.useState('');
  const [initialValue, setInitialValue] = React.useState(props.value);
  const [options, setOptions] = React.useState<IOption[]>([]);
  const commonT = useTranslation(NSKeys.common);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { data: initialRecipient } = useGetRecipient(initialValue);

  const { data, hasNextPage, fetchNextPage, isFetching } = useRecipientsQuery({
    limit: 10,
    search: search,
  });

  React.useEffect(() => {
    if (data) {
      setOptions(
        data?.pages
          ?.map((data) => data.items || [])
          .flat()
          ?.map((el) => ({
            data: el,
            label: el.name,
            value: el.uid,
            disabled: disableValues.some((disableEl) => disableEl === el.uid),
          }))
      );
    } else if (initialRecipient) {
      setOptions([
        {
          data: initialRecipient,
          label: initialRecipient.name,
          value: initialRecipient.uid,
          disabled: disableValues.some((disableEl) => disableEl === initialRecipient.uid),
        },
      ]);
    }
  }, [data, initialRecipient]);

  const handleAdd = (data: RetrieveRecipientSchema) => {
    setIsModalOpen(false);
    onAddRecipient?.(data);
    setOptions([
      {
        data: data,
        label: data.name,
        value: data.uid,
        disabled: false,
      },
    ]);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const renderAddButton = () => (
    <Button
      type="text"
      icon={<PlusOutlined />}
      onClick={() => setIsModalOpen(true)}
      size="small"
      style={{ padding: 12 }}>
      <Flex gap={12}>
        <Typography.Text type="secondary" style={{ margin: 0, fontSize: 12 }}>
          {commonT.t('buttonAdd')}:
        </Typography.Text>
        <Typography.Text style={{ margin: 0, fontSize: 12 }}>{search}</Typography.Text>
      </Flex>
    </Button>
  );

  return (
    <>
      <Select<IOption>
        allowClear
        onPopupScroll={(e: any) => {
          const { target } = e;
          if (target?.scrollTop + 10 + target?.offsetHeight > target?.scrollHeight && hasNextPage && !isFetching) {
            fetchNextPage();
          }
        }}
        onSearch={setSearch}
        filterOption={false}
        options={options}
        // notFoundContent={isFetching ? <Spin size="small" /> : creatable && search ? renderAddButton() : null}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <>{creatable && search ? renderAddButton() : null}</>
          </div>
        )}
        optionRender={(option) => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography.Text role="img" style={{ margin: 0 }}>
              {option.label}
            </Typography.Text>
            <Typography.Text type="secondary" style={{ margin: 0, fontSize: 12 }}>
              {option.data?.data?.address?.as_string}
            </Typography.Text>
          </div>
        )}
        {...props}
      />

      {isModalOpen && (
        <Create onAdd={handleAdd} onCancel={handleCancel} isModalOpen={isModalOpen} initialValue={search} />
      )}
    </>
  );
};
