import { useInfiniteQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

interface IProps {
  size?: number;
}

export const useGroupsQuery = ({ size }: IProps) => {
  return useInfiniteQuery({
    queryKey: ['getGroupsSelect'],
    queryFn: async ({ pageParam = 0 }) => {
      const res = await Api.users.getUserGroups({
        offset: pageParam,
        limit: size || 0,
      });
      return res;
    },
    getNextPageParam: (current, allPages) => {
      const count = allPages.length * (size || 1);
      return count >= (current.total || 0) ? undefined : count;
    },
  });
};
