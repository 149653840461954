import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ContextSchema as DictionariesContextSchema, SystemSlugs } from '@app/models/dictionaries';
import { ContextSchema as OrdersContextSchema } from '@app/models/orders';
import { DictionarySchema, ContextSchema as ProductsContextSchema } from '@app/models/products';
import { RetrieveScopeSchema, RetrieveSelfUserSchema, UserNotificationSettings } from '@app/models/users';
import { ContextSchema as WarehousesContextSchema } from '@app/models/wms';
import Api from '@app/services/api';
import { Dump } from '@app/services/api/dictionaries';

import { getLanguage } from '@app/services/i18n';
import { stringToBoolean } from '@app/services/stringToBoolean';
import { TRootState } from '@app/store';

import { DateFormat, LengthUnit, Theme, WeekStart } from './interfaces';

export interface SessionState {
  isLoading: boolean;
  isAuthenticated: boolean;
  is12HoursFormat: boolean;
  theme: Theme;
  colorPrimary: string;
  // TODO: replace to dimensionUnit
  lengthUnit: LengthUnit;
  scopeTitle: string;
  weekStart: WeekStart;
  dateFormat: DateFormat;
  data?: RetrieveSelfUserSchema;
  // dictionaries
  currency: string;
  massUnit: string;
  lang: string;
  dimensionUnit: string;
  timeZone: string;
  dictionaryDump?: Dump;
  dataScope?: RetrieveScopeSchema;
  productsContext?: ProductsContextSchema;
  warehousesContext?: WarehousesContextSchema;
  ordersContext?: OrdersContextSchema;
  dictionariesContext?: DictionariesContextSchema;
  dictionariesData?: DictionarySchema[];
}

const initialState: SessionState = {
  isLoading: false,
  isAuthenticated: false,
  is12HoursFormat: false,
  theme: Theme.LIGHT,
  lengthUnit: LengthUnit.meter,
  dateFormat: DateFormat.shortDot,
  colorPrimary: '#2884DA',
  scopeTitle: '',
  weekStart: 0,
  // dictionaries
  currency: '',
  massUnit: '',
  lang: getLanguage().toUpperCase(),
  dimensionUnit: '',
  timeZone: '',
};

export const getScope = createAsyncThunk<void, VoidFunction | undefined>(
  'GET_USER_SCOPE',
  async (payload, { dispatch }) => {
    const scope = await Api.users.getScopes();
    dispatch(setValue({ dataScope: scope }));
  }
);

export const getScopesSettings = createAsyncThunk<void, VoidFunction | undefined>(
  'GET_USER_SCOPES_SETTINGS',
  async (payload, { dispatch }) => {
    const productsContext = await Api.products.getContext();
    const ordersContext = await Api.orders.getContext();
    const warehousesContext = stringToBoolean(process.env.REACT_APP_FEATURE_WAREHOUSES)
      ? await Api.wms.getContext()
      : null;
    dispatch(getScopesSettingsDictionaries());
    dispatch(
      setValue({ ordersContext, productsContext, ...(warehousesContext && { warehousesContext: warehousesContext }) })
    );
  }
);

export const getScopesSettingsDictionaries = createAsyncThunk<void, VoidFunction | undefined>(
  'GET_USER_SCOPES_SETTINGS_DICTIONARIES',
  async (payload, { dispatch }) => {
    const dictionariesContext = await Api.dictionaries.getContext();
    dispatch(setValue({ dictionariesContext }));
  }
);

export const getDump = createAsyncThunk<void, VoidFunction | undefined>(
  'GET_DICTIONARY_DUMP',
  async (payload, { dispatch, getState }) => {
    const { currentSession } = getState() as TRootState;

    const dictionaries = await Api.products.getDictionaries();
    const dump = await Api.dictionaries.getDump(
      [
        { dictionary: SystemSlugs.Langs, value: currentSession.lang },
        { dictionary: SystemSlugs.Currencies, value: currentSession.currency },
        { dictionary: SystemSlugs.MassUnits, value: currentSession.massUnit },
        { dictionary: SystemSlugs.DimensionUnits, value: currentSession.dimensionUnit },
        { dictionary: SystemSlugs.Timezones, value: currentSession.timeZone },
      ],
      currentSession.lang
    );

    dispatch(setValue({ dictionaryDump: dump, dictionariesData: dictionaries }));
  }
);

export const getAppInitialData = createAsyncThunk<void, VoidFunction | undefined>(
  'GET_CURRENT_SESSION',
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const currentSession = await Api.users.getAuth();
      dispatch(setCurrentSession(currentSession));
      dispatch(getDump());
      dispatch(setAuthenticated(true));
      if (payload) {
        payload();
      }
    } catch {
      dispatch(setAuthenticated(false));
    } finally {
      dispatch(getScopesSettings());
      dispatch(setLoading(false));
    }
  }
);

export const refreshUserData = createAsyncThunk<void, VoidFunction | undefined>(
  'REFRESH_CURRENT_SESSION',
  async (payload, { dispatch }) => {
    try {
      const currentSession = await Api.users.getAuth();
      dispatch(setCurrentSession(currentSession));
    } catch {
      dispatch(setAuthenticated(false));
    }
  }
);

export const currentSession = createSlice({
  name: 'currentSession',
  initialState,
  reducers: {
    setAuthenticated(state, { payload }: PayloadAction<boolean>) {
      state.isAuthenticated = payload;
    },
    setLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
    setCurrentSession(state, { payload }: PayloadAction<RetrieveSelfUserSchema>) {
      state.theme = payload.scope.settings.theme === Theme.DARK ? Theme.DARK : Theme.LIGHT;
      state.colorPrimary = payload.scope.settings.primary_color || '#2884DA';
      state.scopeTitle = payload.scope.title;
      state.massUnit = payload.scope.settings.mass_unit || '';
      state.dimensionUnit = payload.scope.settings.dimension_unit || '';
      state.timeZone = `${(payload.scope.settings.timezone || 0) >= 0 ? '+' : ''}${payload.scope.settings.timezone}`;
      state.currency = payload.scope.settings.currency || '';
      state.lang = payload.settings.lang || '';
      state.data = payload;
    },
    setValue(state, { payload }: PayloadAction<Partial<SessionState>>) {
      Object.assign(state, payload);
    },
    setNotifications(state, { payload }: PayloadAction<UserNotificationSettings>) {
      if (state.data) {
        return {
          ...state,
          data: {
            ...state.data,
            settings: {
              ...state.data.settings,
              notifications: payload,
            },
          },
        };
      }
    },
    resetData(state) {
      state.colorPrimary = initialState.colorPrimary;
      state.theme = initialState.theme;
    },
  },
});

export const {
  reducer,
  actions: { setCurrentSession, setLoading, setAuthenticated, setValue, resetData, setNotifications },
} = currentSession;

const selectScopeSettings = (state: SessionState) => state.data?.scope.settings;
const selectDictionaryDump = (state: SessionState) => state.dictionaryDump;

export const selectMassUnitTranslation = createSelector(
  [selectScopeSettings, selectDictionaryDump],
  (scopeSettings, dictionaryDump) => {
    return dictionaryDump?.['mass_units']?.[scopeSettings?.mass_unit || '']?.label || '-';
  }
);

export const selectDimensionUnitTranslation = createSelector(
  [selectScopeSettings, selectDictionaryDump],
  (scopeSettings, dictionaryDump) => {
    return dictionaryDump?.['dimension_units']?.[scopeSettings?.dimension_unit || '']?.label || '-';
  }
);

export const selectCurrencyTranslation = createSelector(
  [selectScopeSettings, selectDictionaryDump],
  (scopeSettings, dictionaryDump) => {
    return dictionaryDump?.['currencies']?.[scopeSettings?.currency || '']?.label || '-';
  }
);
export default reducer;
