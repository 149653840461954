import { useInfiniteQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

interface IProps {
  size: number;
  name: string;
}

export const useGetGroupsQuery = ({ size, name }: IProps) => {
  return useInfiniteQuery({
    queryKey: ['getGroups', name],
    queryFn: async ({ pageParam = 0 }) => {
      const res = await Api.users.getUserGroups({
        offset: pageParam,
        limit: size || 0,
        name,
      });
      return res;
    },
    getNextPageParam: (current, allPages) => {
      const count = allPages.length * (size || 1);
      return count >= (current.total || 0) ? undefined : count;
    },
  });
};
