import { Checkbox } from 'antd';

import styled from 'styled-components';

export const CheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-height: 264px;
  overflow-y: auto;

  .ant-checkbox-wrapper {
    margin: 0;
    padding: 5px 12px;
  }
`;
