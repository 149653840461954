import { Action, CreateRoleSchema, PermissionScope, UpdateRoleSchema } from '@app/models/users';

import { IFormValues } from '../interfaces';

const getRules = (formValues: IFormValues, perm: PermissionScope) =>
  (Object.keys(Action) as Array<keyof typeof Action>).map((key) => ({
    action: Action[key],
    level: formValues[perm][Action[key]],
  }));

const getPermissions = (formValues: IFormValues) =>
  (Object.keys(PermissionScope) as Array<keyof typeof PermissionScope>).map((key) => ({
    permission_scope: PermissionScope[key],
    rules: [...getRules(formValues, PermissionScope[key])],
  }));

export const getPOSTRequestDataFromFormValues = (formValues: IFormValues): CreateRoleSchema | UpdateRoleSchema => ({
  name: formValues.newRoleName || '',
  permissions: [...getPermissions(formValues)]?.map((perm) => {
    return { ...perm, rules: perm.rules.filter((rule) => rule.action !== 'delete' && rule.action !== 'create') };
  }),
});

export const getPUTRequestDataFromFormValues = (formValues: IFormValues): CreateRoleSchema | UpdateRoleSchema => ({
  name: formValues.name || '',
  permissions: [...getPermissions(formValues)],
});
