import React, { FC } from 'react';

import { FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { FormTitle } from '@components/FormTitle';
import { Paper } from '@components/Paper';
import { FormContact as CommonContactForm } from '@components/contacts';

import { RecipientsTable } from './components/Table';
import { IFormValues } from './interfaces';

interface IProps {
  initialValues?: Partial<IFormValues>;
  onFinish: (formValues: IFormValues) => void;
  form: FormInstance;
}

export const FormContact: FC<IProps> = (props) => {
  const contactsT = useTranslation(NSKeys.contacts);

  return (
    <Paper style={{ margin: 24, padding: '20px 24px' }}>
      <FormTitle title={contactsT.t('form.titleMain')} />
      <CommonContactForm form={props.form} onFinish={props.onFinish} initialValues={props.initialValues} />
      <RecipientsTable title={contactsT.t('form.titleRecipients')} />
    </Paper>
  );
};
