import { useQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

export const useGetContact = (uid: string) => {
  return useQuery({
    queryKey: ['getContact', uid],
    queryFn: () => Api.dictionaries.getContact(uid),
    enabled: !!uid,
  });
};
