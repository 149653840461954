import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, Typography } from 'antd';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { CreateOrderApprovalChainSchema } from '@app/models/users';
import Api from '@app/services/api';

export const useCreateChainQuery = () => {
  const navigate = useNavigate();
  const client = useQueryClient();
  const commonT = useTranslation(NSKeys.common);

  return useMutation({
    mutationFn: async (data: CreateOrderApprovalChainSchema) => {
      const res = await Api.users.createOrderApprovalChain(data);
      return res;
    },
    onError: () => {
      message.open({
        type: 'error',
        content: commonT.t('error400'),
      });
    },
    onSuccess: async (resp) => {
      await client.invalidateQueries(['getOrderApprovalChain']);
      message.open({
        type: 'success',
        content: (
          <>
            {commonT.t('dataAddSuccess')}&nbsp;
            <Typography.Link onClick={() => navigate(`/dashboard/orderApprovalChains/${resp.uid}`)}>
              {commonT.t('buttonOpen')}
            </Typography.Link>
          </>
        ),
      });
    },
  });
};
