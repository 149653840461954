import { Upload } from 'antd';
import styled from 'styled-components';

export const Container = styled.div<{ $colorPrimary: string }>`
  margin-bottom: 24px;

  .ant-upload-list-item-name {
    color: ${({ $colorPrimary }) => $colorPrimary};
  }
`;

export const Uploader = styled(Upload.Dragger)`
  &.ant-upload-wrapper {
    .ant-upload-drag {
      margin-bottom: 24px;
      .ant-upload {
        padding: 40px 24px;
        p.ant-upload-drag-icon .anticon {
          font-size: 28px;
        }
      }
    }
    .ant-upload-list {
      .ant-upload-list-item-actions a {
        display: none;
      }
    }
    .ant-upload-text {
      font-size: 14px;
    }
  }
`;
