import React, { Fragment, useState } from 'react';

import { CheckCircleOutlined, CopyTwoTone } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { CloneOrderApprovalChainSchema } from '@app/models/users';

import { useLocalSelector } from '@app/services/hooks/useRedux';

import { useCloneChainQuery } from '../../../../hooks';

import { Container } from './styles';

interface IProps {
  approval_chain_uid: string;
  title: string;
}

interface IFormValues {
  name: string;
}

export const CopyModal: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const [mainOpen, setMainOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const commonT = useTranslation(NSKeys.common);
  // const validationT = useTranslation(NSKeys.validation);
  const orderApprovalChainsT = useTranslation(NSKeys.orderApprovalChains);
  const { token } = theme.useToken();
  const [form] = Form.useForm<CloneOrderApprovalChainSchema>();
  const name = Form.useWatch('name', form);
  const { mutate, status, failureReason, data } = useCloneChainQuery();

  React.useEffect(() => {
    if (status === 'success') {
      setMainOpen(false);
      setSuccessOpen(true);
    } else if (failureReason && form) {
      // TODO: бек не возвращает pointer
      // const formValues = form.getFieldsValue();
      // const errors = getFormErrorsFromResponse(formValues, failureReason, validationT.t);
      // if (errors.length) {
      //   form.setFields(errors);
      // }
    }
  }, [status, failureReason]);

  const handleFinish = async (formValues: IFormValues): Promise<void> => {
    mutate({ approval_chain_uid: props.approval_chain_uid, name: formValues.name });
  };

  const handleOpenCloned = () => {
    if (data?.uid) {
      navigate(data.uid);
    }
    setSuccessOpen(false);
  };

  return (
    <Fragment>
      <Container
        title={`${orderApprovalChainsT.t('copyModal.description')}: ${props.title}`}
        centered
        open={mainOpen}
        onCancel={() => setMainOpen(false)}
        colorBorderSecondary={token.colorBorderSecondary}
        cancelText={commonT.t('cancel')}
        okText={commonT.t('create')}
        okButtonProps={{ disabled: !name, loading: status === 'loading', onClick: () => form.submit() }}
        cancelButtonProps={{ onClick: () => setMainOpen(false) }}>
        <Form form={form} onFinish={handleFinish}>
          <Form.Item name="name">
            <Input
              placeholder={orderApprovalChainsT.t('copyModal.newChainLabel')}
              size="large"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Form>
      </Container>
      <Modal
        centered
        style={{ maxWidth: 416 }}
        open={successOpen}
        closeIcon={false}
        onCancel={() => setSuccessOpen(false)}
        cancelText={orderApprovalChainsT.t('copyModal.successBack')}
        okText={orderApprovalChainsT.t('copyModal.successSubmit')}
        okButtonProps={{ onClick: handleOpenCloned }}
        cancelButtonProps={{ onClick: () => setSuccessOpen(false) }}>
        <Row>
          <Col sm={3}>
            <CheckCircleOutlined style={{ fontSize: 22, color: token.colorSuccess }} />
          </Col>
          <Col sm={21}>
            <Typography.Title level={5}>{orderApprovalChainsT.t('copyModal.successModal')}</Typography.Title>
            <Typography.Text>{orderApprovalChainsT.t('copyModal.successDescription')}</Typography.Text>
          </Col>
        </Row>
      </Modal>
      <Button
        size="small"
        type="link"
        icon={<CopyTwoTone twoToneColor={token?.colorPrimary} />}
        onClick={(e) => setMainOpen(true)}
      />
    </Fragment>
  );
};
