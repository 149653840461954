import { IWarehousesParams } from '@app/models/interfaces';
import {
  ContextSchema,
  CreateWarehouseSchema,
  RecordsListGenericSchemaRetrieveWarehouseSchema,
  RetrieveWarehouseSchema,
  RetrieveWarehouseSettingsSchema,
  UpdateWarehouseSchema,
} from '@app/models/wms';
import { handleRequest, httpClient } from '@app/services/httpClient';
import { objectToSearchParams } from '@app/services/objectToSearchParams';

import { IProps } from '../interfaces';

class WMS {
  private readonly endPoint: string;

  constructor({ baseEndPoint }: IProps) {
    this.endPoint = baseEndPoint;
  }

  async postWarehouse(data: CreateWarehouseSchema): Promise<RetrieveWarehouseSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/warehouses`, data));
  }

  async getWarehouses(params: IWarehousesParams): Promise<RecordsListGenericSchemaRetrieveWarehouseSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/warehouses${objectToSearchParams(params)}`));
  }

  async getWarehouse(uid: string): Promise<RetrieveWarehouseSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/warehouses/${uid}`));
  }

  async getWarehouseSettings(uid: string): Promise<RetrieveWarehouseSettingsSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/warehouses/${uid}/settings`));
  }

  async putWarehouse(data: UpdateWarehouseSchema, uid: string): Promise<RetrieveWarehouseSettingsSchema> {
    return handleRequest(httpClient.put(`${this.endPoint}/warehouses/${uid}`, data));
  }

  async getContext(): Promise<ContextSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/context`));
  }
}

export default WMS;
