import { Button, Input } from 'antd';
import styled from 'styled-components';

export const TagInput = styled(Input)<{ $colorError: string }>`
  height: 28px;
  width: 120px;
  margin-inline-end: 8px;
  vertical-align: top;
  margin-right: 24px;
  font-size: 12px;
  border-style: dashed;

  &[aria-invalid] {
    border-color: ${({ $colorError }) => $colorError};
  }
`;

export const AddButton = styled(Button)<{ $colorBgContainer: string }>`
  background-color: ${({ $colorBgContainer }) => $colorBgContainer};
  height: 22px;
`;
