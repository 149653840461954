import React, { FC } from 'react';

import { Form, FormInstance, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { CommonDictionariesSlugs } from '@app/models/dictionaries';
import { CreatedSelect, GoogleAutocomplete } from '@components/AntFields';
import { FormTitle } from '@components/FormTitle';
import { Paper } from '@components/Paper';

import { ContactsTable } from './components/Table';
import { IFormValues } from './interfaces';
import { ValidationSchema } from './validation';

interface IProps {
  initialValues?: Partial<IFormValues>;
  onFinish: (formValues: IFormValues) => void;
  recipientUid?: string;
  form: FormInstance;
}

export const FormRecipient: FC<IProps> = (props) => {
  const [autoCompleteError, setAutocompleteError] = React.useState<boolean>(false);
  const validationT = useTranslation(NSKeys.validation);
  const recipientsT = useTranslation(NSKeys.recipients);

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  // const handleFormChange = () => {
  //   const AUTOCOMPLETE_FIELDS = ['provider_uid', 'street_name', 'street_number', 'place_name'];
  //   const autocompleteFieldsErrors = props.form
  //     .getFieldsError()
  //     .filter(
  //       (field) => field.name.some((compare) => AUTOCOMPLETE_FIELDS.includes(`${compare}`)) && !!field.errors.length
  //     );
  //   setAutocompleteError(!!autocompleteFieldsErrors?.length);
  // };

  return (
    <Paper style={{ margin: 24, padding: '20px 24px' }}>
      <Form
        form={props.form}
        name="catalog_form"
        id="catalogForm"
        initialValues={props.initialValues}
        onFinish={props.onFinish}
        // onFieldsChange={handleFormChange}
        autoComplete="off"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}>
        <FormTitle title={recipientsT.t('form.titleMain')} />
        <Form.Item<IFormValues> name="name" required label={recipientsT.t('form.nameLabel')} rules={[yupSync]}>
          <Input placeholder={recipientsT.t('form.namePlaceholder')} />
        </Form.Item>
        <Form.Item<IFormValues> name="code" required label={recipientsT.t('form.codeLabel')} rules={[yupSync]}>
          <Input placeholder={recipientsT.t('form.codePlaceholder')} />
        </Form.Item>
        <CreatedSelect
          removable
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          required={false}
          rules={[yupSync]}
          name="retailer_uid"
          dictionaryName={CommonDictionariesSlugs.Retailers}
          label={recipientsT.t('form.retailerLabel')}
          placeholder={recipientsT.t('form.retailerPlaceholder')}
        />
        <CreatedSelect
          removable
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          required={false}
          rules={[yupSync]}
          name="type_uid"
          dictionaryName={CommonDictionariesSlugs.RecipientTypes}
          label={recipientsT.t('form.typeLabel')}
          placeholder={recipientsT.t('form.typePlaceholder')}
        />
        <CreatedSelect
          removable
          mode="multiple"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          required={false}
          rules={yupSync}
          name="tags_uids"
          dictionaryName={CommonDictionariesSlugs.RecipientTags}
          label={recipientsT.t('form.tagsLabel')}
          placeholder={recipientsT.t('form.tagsPlaceholder')}
        />
        <GoogleAutocomplete
          form={props.form}
          rules={[yupSync]}
          hasError={autoCompleteError}
          // onTextChange={handleFormChange}
        />
      </Form>
      <ContactsTable title={recipientsT.t('form.titleContacts')} recipientUid={props.recipientUid} />
    </Paper>
  );
};
