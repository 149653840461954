import { ColumnsType } from 'antd/es/table';

import { RetrieveContactsSchema } from '@app/models/dictionaries';

export const BASE_COLUMNS_WIP: ColumnsType<RetrieveContactsSchema> = [
  {
    title: 'tableColumns.name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
  },
  {
    title: 'tableColumns.email',
    dataIndex: 'email',
    key: 'email',
    ellipsis: true,
  },
  {
    title: 'tableColumns.phone',
    dataIndex: 'phone',
    key: 'phone',
    ellipsis: true,
  },
];
