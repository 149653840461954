import React, { Fragment, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, theme } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { RetrieveUserGroupSchema } from '@app/models/users';
import { UsersSelect } from '@components/AntFields';

import { useAddUsersQuery } from '../../../../../../hooks';

import { ModalContainer } from './styles';

interface IProps {
  record: RetrieveUserGroupSchema;
}

export const AddUserModal: React.FC<IProps> = ({ record }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState<number[]>([]);
  const { mutate, status, failureReason } = useAddUsersQuery();

  const groupsT = useTranslation(NSKeys.groups);
  const commonT = useTranslation(NSKeys.common);
  const { token } = theme.useToken();

  React.useEffect(() => {
    if (status === 'success') {
      setOpen(false);
      setValues([]);
    }
  }, [status]);

  return (
    <Fragment>
      <ModalContainer
        title={`${groupsT.t('userModal.title')} (${values.length})`}
        centered
        destroyOnClose
        open={open}
        onCancel={() => setOpen(false)}
        colorBorderSecondary={token.colorBorderSecondary}
        cancelText={commonT.t('buttonClear')}
        okText={commonT.t('buttonAdd')}
        onOk={() => mutate({ user_group_uid: record.uid, data: { users: values.map((el) => ({ id: el })) } })}
        okButtonProps={{ disabled: !values.length || status === 'loading' }}
        cancelButtonProps={{ onClick: () => setValues([]), disabled: !values.length }}>
        <UsersSelect
          mode="multiple"
          value={values}
          size="large"
          style={{ width: '100%' }}
          onChange={setValues}
          group_uid_ne={record.uid}
          placeholder={groupsT.t('userModal.selectPlaceholder')}
        />
      </ModalContainer>

      <Button icon={<PlusOutlined />} onClick={() => setOpen(true)} size="middle" style={{ marginTop: '16px' }}>
        {groupsT.t('userModal.submitBtn')}
      </Button>
    </Fragment>
  );
};
