import React, { FC } from 'react';

import { DeleteTwoTone, DownOutlined, ExclamationCircleOutlined, UpOutlined } from '@ant-design/icons';
import { theme as antTheme, message, Modal, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { InfiniteScroll } from '@app/components/InfiniteScroll';
import { NSKeys } from '@app/i18n';
import { RetrieveUserGroupSchema } from '@app/models/users';
import { useWindowSize } from '@app/services/hooks/useWindowSize';

import { useDeleteGroupQuery, useGetGroupsQuery } from '../../hooks';
import { useGroups } from '../../providers';

import { EditableCell } from './components/EditableCell';
import { EditableRow } from './components/EditableRow';
import { UserList } from './components/UserList';

import { Container } from './styles';

interface IProps {
  selectable?: boolean;
  searchString?: string;
  disabledRows?: string[];
}

export const GroupTable: FC<IProps> = (props) => {
  const { data, hasNextPage, fetchNextPage, status } = useGetGroupsQuery({
    size: 10,
    name: props.searchString || '',
  });
  const { innerHeight } = useWindowSize();
  const { token } = antTheme.useToken();
  const [modal, contextHolder] = Modal.useModal();
  const commonT = useTranslation(NSKeys.common);
  const { columns, expandedRowKeys, setExpandedRowKeys, setSelectedRows, newGroups } = useGroups();
  const groupsT = useTranslation(NSKeys.groups);

  const dataSource = React.useMemo(() => {
    return data?.pages?.map((data) => data.items || []).flat() || [];
  }, [data]);

  const { mutate, status: deleteStatus, failureReason } = useDeleteGroupQuery();

  const handleDelete = (data: RetrieveUserGroupSchema) => {
    modal.confirm({
      title: groupsT.t('deleteModal.title'),
      centered: true,
      icon: <ExclamationCircleOutlined />,
      onOk: (e) => {
        mutate({ user_group_uid: data.uid });
        Modal.destroyAll();
      },
      content: groupsT.t('deleteModal.content', { name: data.name }),
      okText: commonT.t('buttonOk'),
      okButtonProps: { disabled: deleteStatus === 'loading' },
      cancelText: commonT.t('cancel'),
    });
  };

  React.useEffect(() => {
    if (deleteStatus === 'success' || deleteStatus === 'error') {
      Modal.destroyAll();
    }
    if (failureReason && deleteStatus === 'error') {
      const error = failureReason as any;
      const chanis = error?.response?.data?.errors?.[0]?.meta?.order_approval_chains as any[];
      const chainNames = chanis.map((el) => el?.name).join(', ');
      message.open({
        type: 'error',
        duration: 5,
        onClick: () => message.destroy(),
        content: chainNames ? `${groupsT.t('errorDeleteExistInChains')} ${chainNames}` : commonT.t('error400'),
      });
    }
  }, [deleteStatus, failureReason]);

  return (
    <Container>
      {contextHolder}
      <Table<RetrieveUserGroupSchema>
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          },
        }}
        loading={status === 'loading'}
        dataSource={[...newGroups, ...dataSource]}
        rowKey="uid"
        size="large"
        rowClassName="editable-row"
        columns={[
          ...columns.map((el) => ({ ...el, title: groupsT.t(el.title as any) })),
          {
            fixed: 'right',
            key: 'action',
            align: 'center',
            width: 40,
            render: (_, cell) => {
              return cell.uid !== 'temprary' && cell?.$permissions.delete ? (
                <DeleteTwoTone
                  twoToneColor={token?.colorPrimary}
                  onClick={(e) => {
                    handleDelete(cell);
                  }}
                />
              ) : null;
            },
          },
        ]}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => <UserList record={record} />,
          expandedRowKeys: expandedRowKeys,
          onExpand: (expanded, { uid }) => {
            setExpandedRowKeys(expanded ? [uid] : []);
          },
          expandIcon: ({ expanded, onExpand, record }) => {
            if (!record?.$permissions?.view) {
              return null;
            }
            if (expanded) {
              return (
                <UpOutlined
                  style={{ width: '10px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onExpand(record, e);
                  }}
                />
              );
            }
            return (
              <DownOutlined
                style={{ width: '10px' }}
                onClick={(e) => {
                  e.stopPropagation();
                  onExpand(record, e);
                }}
              />
            );
          },
        }}
        {...(props.selectable && {
          rowSelection: {
            getCheckboxProps: (record) => ({
              disabled: props.disabledRows?.some((el) => el === record.uid),
            }),
            type: 'radio',
            onChange: (key) => {
              setSelectedRows(key);
            },
          },
        })}
        scroll={{ y: innerHeight - 350 }}
        style={{ overflow: 'auto' }}
      />
      {hasNextPage && <InfiniteScroll key={dataSource.length || 0} next={fetchNextPage} />}
    </Container>
  );
};
