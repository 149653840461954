import { useMutation, useQueryClient } from '@tanstack/react-query';

import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { PartialUpdateStageStatusSchema } from '@app/models/orders';
import Api from '@app/services/api';

interface IParams {
  status_uid: string;
  data: PartialUpdateStageStatusSchema;
}

export const useUpdateStatuseQuery = () => {
  const client = useQueryClient();
  const commonT = useTranslation(NSKeys.common);

  return useMutation({
    mutationFn: async ({ status_uid, data }: IParams) => {
      await Api.orders.patchStatus(status_uid, data);
    },
    onSuccess: async (_) => {
      message.open({
        type: 'success',
        content: commonT.t('dataPatchSuccess'),
      });
      await client.invalidateQueries(['getProcess']);
    },
    onError: async () => {
      message.open({
        type: 'error',
        content: commonT.t('error400'),
      });
      await client.invalidateQueries(['getProcess']);
    },
  });
};
