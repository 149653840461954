import React from 'react';

import { Flex, Skeleton, Typography } from 'antd';

import { useUpdateProcessQuery } from '../../../../../../hooks';

interface IProps {
  name: string;
  process_uid: string;
}

export const Title: React.FC<IProps> = ({ name, process_uid }) => {
  const [editableStr, setEditableStr] = React.useState(name);

  React.useEffect(() => {
    setEditableStr(name);
  }, [name]);

  const { mutate, status } = useUpdateProcessQuery();

  const onEdit = (value: string) => {
    if (name !== value) {
      mutate({ data: { name: value }, process_uid });
    }
  };

  return (
    <Flex style={{ marginBottom: 4, width: '100%' }} align="center">
      {status === 'loading' ? (
        <Skeleton.Input active />
      ) : (
        <Typography.Title
          level={4}
          editable={{ onChange: onEdit }}
          style={{ width: '100%', margin: 0, fontWeight: 800 }}>
          {editableStr}
        </Typography.Title>
      )}
    </Flex>
  );
};
