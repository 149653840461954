import { Action, Level, PermissionOutput, PermissionScope } from '@app/models/users';

import { IFormValues } from '../interfaces';

import { getRuleValue } from './index';

const getRules = (
  permission: PermissionScope,
  roles?: PermissionOutput[]
): {
  [key in Action]: Level;
} =>
  (Object.keys(Action) as Array<keyof typeof Action>).reduce(
    (acc, curr) => ({ ...acc, [Action[curr]]: getRuleValue(permission, Action[curr], roles) }),
    {} as {
      [key in Action]: Level;
    }
  );

export const getInitialValues = (roles?: PermissionOutput[]): IFormValues =>
  (Object.keys(PermissionScope) as Array<keyof typeof PermissionScope>).reduce(
    (acc, curr) => ({ ...acc, [PermissionScope[curr]]: getRules(PermissionScope[curr], roles) }),
    {} as IFormValues
  );
