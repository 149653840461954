import { useInfiniteQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

interface IProps {
  limit?: number;
  search?: string;
  recipient_uid?: string;
}

export const useContactsQuery = ({ limit, search, recipient_uid }: IProps) => {
  return useInfiniteQuery({
    queryKey: ['getContacts', search],
    queryFn: async ({ pageParam = 0 }) => {
      return await Api.dictionaries.getContacts({
        offset: pageParam,
        limit: limit || 0,
        search,
        recipient_uid: search ? '' : recipient_uid,
      });
    },
    getNextPageParam: (current, allPages) => {
      const count = allPages.length * (limit || 1);
      return count >= (current.total || 0) ? undefined : count;
    },
    enabled: !!search || !!recipient_uid,
  });
};
