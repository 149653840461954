import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import type { ResizeCallbackData } from 'react-resizable';
import { useNavigate } from 'react-router-dom';

import { useLocalStorage } from 'usehooks-ts';

import { NSKeys } from '@app/i18n';
import { TableParams } from '@app/models/interfaces';
import { ViewProductSchema } from '@app/models/products';
import { MATERIALS_TABLE_COLUMNS } from '@app/services/appSettings';
import { Paper } from '@components/Paper';
import { TableDnDResizable } from '@components/TableDnDResizable';
import { TableEditMenu } from '@components/TableEditMenu';
import { useGetProductsQueryTable } from '@pages/Products/hooks';

import { DropDownMenu } from '../../../../components/DropDownMenu';

import { BASE_COLUMNS } from './constants';

const INITIAL_TABLE_PARAMS = {
  pagination: {
    current: 1,
    pageSize: 10,
  },
};

interface IProps {
  filterSearchString: string;
  filterCategories: string[];
  filterTags: string[];
}

export const Table: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const commonT = useTranslation(NSKeys.common);
  const nomenclatureInfoT = useTranslation(NSKeys.nomenclatureInfo);
  const [tableParams, setTableParams] = useState<TableParams>(INITIAL_TABLE_PARAMS);
  const [localStorageColumns, setLocalStorageColumns] = useLocalStorage(
    MATERIALS_TABLE_COLUMNS,
    BASE_COLUMNS.map(({ render, ...rest }) => rest)
  );

  const handleTableHeaderChange = (checkedValues: any[]) => {
    setLocalStorageColumns(BASE_COLUMNS.filter((item) => checkedValues.includes(item.key as string)));
  };

  const { data: tableData, isLoading } = useGetProductsQueryTable({
    offset:
      ((tableParams.pagination?.current || INITIAL_TABLE_PARAMS.pagination.current) - 1) *
      (tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize),
    limit: tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize,
    q: props.filterSearchString,
    category_id: props.filterCategories,
    tag_id: props.filterTags,
  });

  const handleResize =
    (index: number) =>
    (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
      const newColumns = [...localStorageColumns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width > 20 ? size.width : 20,
      };
      setLocalStorageColumns(newColumns);
    };

  return (
    <Paper style={{ margin: '24px', padding: '24px 24px 0 24px' }}>
      <TableDnDResizable<ViewProductSchema>
        localStorageName={MATERIALS_TABLE_COLUMNS}
        columns={[
          ...localStorageColumns.map((el, index) => ({
            ...el,
            title: nomenclatureInfoT.t(el.title as any),
            onHeaderCell: (column: any) => ({
              width: column.width,
              onResize: handleResize(index) as React.ReactEventHandler<any>,
            }),
            render: BASE_COLUMNS.find((baseCol) => baseCol.key === el.key)?.render,
          })),
        ]}
        actionColumn={[
          {
            title: (
              <TableEditMenu
                handleOnChange={handleTableHeaderChange}
                defaultValue={localStorageColumns?.map((el) => el.key as string)}
                options={BASE_COLUMNS?.map((el) => ({
                  value: el.key as string,
                  label: nomenclatureInfoT.t(el.title as any),
                }))}
              />
            ),
            key: 'action',
            align: 'center',
            width: 40,
            fixed: 'right',
            render: (_, product) => (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}>
                <DropDownMenu
                  uid={product.uid || ''}
                  editable={!!product?.$permissions?.update}
                  addToCatalog={!!product?.$permissions?.add_to_catalog}
                />
              </div>
            ),
          },
        ]}
        key="uid"
        onColumnsChange={setLocalStorageColumns}
        rowClassName="cursorPointer"
        className="tableClassname"
        loading={isLoading}
        dataSource={tableData?.items}
        scroll={{ x: 'max-content' }}
        pagination={{
          ...tableParams.pagination,
          total: tableData?.total,
          showQuickJumper: true,
          showSizeChanger: true,
          position: ['bottomCenter'],
          showTotal: (total, range) => `${range[0]}-${range[1]} ${commonT.t('paginationOf')} ${total}`,
        }}
        onRow={(data) => {
          return {
            onClick: () => navigate(`${data.uid}`),
          };
        }}
        onChange={(pagination, filters, sorter) => {
          setTableParams({
            pagination,
            filters,
            ...sorter,
          });
        }}
      />
    </Paper>
  );
};
