import styled from 'styled-components';

export const controlContainerStyles = styled.div`
  .label {
    padding: 5px 12px;
  }

  .scrolledContent {
    max-height: 350px;
    min-height: 100px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
  }
`;
