import React, { Fragment, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

interface IProps {
  onDelete: () => void;
}

export const DeleteButton: React.FC<IProps> = (props) => {
  const [open, setOpen] = useState(false);
  const commonT = useTranslation(NSKeys.common);
  const chainsT = useTranslation(NSKeys.orderApprovalChains);

  React.useEffect(() => {
    if (open) {
      setOpen(true);
      onWarning();
    }
  }, [open]);

  const onWarning = () => {
    Modal.confirm({
      title: chainsT.t('deleteModal.title'),
      centered: true,
      content: chainsT.t('deleteModal.description'),
      okText: commonT.t('buttonRemove'),
      cancelText: commonT.t('buttonCancel'),
      onOk: props.onDelete,
      onCancel: () => setOpen(false),
    });
  };

  return <Button size="small" type="link" icon={<DeleteOutlined />} onClick={onWarning} />;
};
