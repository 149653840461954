import React from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Layout, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useLocalStorage } from 'usehooks-ts';

import { NSKeys } from '@app/i18n';
import { MATERIALS_LIST_TYPE } from '@app/services/appSettings';
import { useLocalDispatch, useLocalSelector } from '@app/services/hooks/useRedux';
import { resetFilters } from '@app/store/productsReducer';
import { HeaderTitle } from '@components/HeaderTitle';
import { InfiniteScroll } from '@components/InfiniteScroll';
import { CardComponent as Card, NotFound, SearchControls, SkeletonImage } from '@components/products';

import { DropDownMenu } from '../../components/DropDownMenu';
import { useGetProductsQuery } from '../../hooks';

import { Table } from './components';
import { CardsContainer } from './styles';

export const List: React.FC = () => {
  const dispatch = useLocalDispatch();
  const navigate = useNavigate();
  const filterSearchString = useLocalSelector((state) => state.products.filterSearchString);
  const filterTags = useLocalSelector((state) => state.products.filterTags);
  const filterCategories = useLocalSelector((state) => state.products.filterCategories);
  const currentSession = useLocalSelector((state) => state.currentSession);
  const nomenclatureListT = useTranslation(NSKeys.nomenclatureList);
  const [listType] = useLocalStorage(MATERIALS_LIST_TYPE, 'grid');

  React.useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  const { data, hasNextPage, fetchNextPage, status, isLoading } = useGetProductsQuery({
    limit: 10,
    category_id: filterCategories,
    tag_id: filterTags,
    q: filterSearchString,
  });

  const dataSource = React.useMemo(() => {
    return data?.pages?.map((data) => data.items || []).flat() || [];
  }, [data]);

  return (
    <Layout>
      <Layout.Header>
        <Row justify="space-between" align="middle">
          <HeaderTitle title={nomenclatureListT.t('title')} />
          <Space size="middle">
            {!!currentSession?.productsContext?.scope?.$permissions?.create_products && (
              <Button type="primary" icon={<PlusOutlined />} onClick={() => navigate('add')} size="middle">
                {nomenclatureListT.t('titleAdd')}
              </Button>
            )}
          </Space>
        </Row>
        <SearchControls isLoading={status === 'loading'} />
      </Layout.Header>
      {listType === 'table' ? (
        <Table filterCategories={filterCategories} filterSearchString={filterSearchString} filterTags={filterTags} />
      ) : (
        <Layout className="site-layout">
          <Layout.Content style={{ padding: '24px 0 24px 24px' }}>
            <CardsContainer>
              {dataSource.length === 0 && status === 'success' && (
                <NotFound handleResetClick={() => dispatch(resetFilters())} />
              )}
              {!!dataSource.length &&
                dataSource.map((product, i) => (
                  <Card
                    key={i}
                    product={product}
                    navigateToCardOnClick
                    onCartCountChange={(value) => console.log(value)}
                    extra={
                      <DropDownMenu
                        uid={product.uid}
                        editable={!!product?.$permissions?.update}
                        addToCatalog={!!product?.$permissions?.add_to_catalog}
                      />
                    }
                  />
                ))}
              {isLoading && <SkeletonImage />}
              {hasNextPage && (
                <InfiniteScroll key={data?.pages?.length || 0} next={fetchNextPage}>
                  <SkeletonImage />
                </InfiniteScroll>
              )}
            </CardsContainer>
          </Layout.Content>
        </Layout>
      )}
    </Layout>
  );
};
