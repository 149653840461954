import { ColumnsType } from 'antd/es/table';

import { RetrieveWarehouseSchema } from '@app/models/wms';

import { Status } from '../../components/Status';

export const BASE_COLUMNS_WIP: ColumnsType<RetrieveWarehouseSchema> = [
  {
    title: 'tableColumns.name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
  },
  {
    title: 'tableColumns.is_active',
    dataIndex: 'is_active',
    key: 'is_active',
    ellipsis: true,
    render: (value, record) => <Status status={record.is_active} />,
  },
];
