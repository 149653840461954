import { FieldType } from '@app/models/interfaces';

export const getDisplayAdditionalValue = ({ type, alias, data }: { type: FieldType; alias: string; data: any }) => {
  if (type === 'contact') {
    const phone = data?.[alias]?.phone;
    if (phone) {
      return [{ title: 'phone', description: phone }];
    }
  }
  if (type === 'recipient') {
    const address = data?.[alias]?.address?.['as_string'];
    if (address) {
      return [{ title: 'address', description: address }];
    }
  }
  return [];
};
