import { useInfiniteQuery } from '@tanstack/react-query';

import { IUserGroupUsersParams } from '@app/models/interfaces';
import Api from '@app/services/api';

export const useGetGroupUsersQuery = (params: IUserGroupUsersParams) => {
  return useInfiniteQuery({
    queryKey: ['useGetGroupUsersQuery', params.user_group_uid],
    queryFn: async ({ pageParam = 0 }) => {
      return await Api.users.getUserGroupUsers({ offset: pageParam, ...params });
    },
    getNextPageParam: (current, allPages) => {
      const count = allPages.length * (params.limit || 1);
      return count >= (current.total || 0) ? undefined : count;
    },
  });
};
