import React from 'react';

import { Flex, Form, Input, List, Row, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { ListItem } from '@components/ListItem';
import { useGetWarehouseQuery, useGetWarehouseSettingsQuery, useUpdateWarehouseQuery } from '@pages/Warehouses/hooks';
import { useWarehouse } from '@pages/Warehouses/pages/Single/WarehouseProvider';

import { IFormValues } from './interfaces';
import { ValidationSchema } from './validations';

export const Settings: React.FC = () => {
  const [form] = Form.useForm<IFormValues>();
  const params = useParams<{ id: string }>();
  const warehousesT = useTranslation(NSKeys.warehouses);
  const validationT = useTranslation(NSKeys.validation);
  const { isEditing, setEditDisabled, setEditing } = useWarehouse();

  const { data, isLoading } = useGetWarehouseQuery(params?.id || '');
  const { mutate, status: updateStatus } = useUpdateWarehouseQuery();
  const { data: settings, isLoading: isSettingsLoading } = useGetWarehouseSettingsQuery(params?.id || '');

  React.useEffect(() => {
    if (data?.name) {
      form.setFieldValue('name', data?.name);
    }
    setEditDisabled(!data?.$permissions?.update);
  }, [data, isEditing]);

  React.useEffect(() => {
    if (settings) {
      form.setFieldsValue({ wms_base_url: settings?.api_url, wms_api_key: settings?.api_key });
    }
  }, [settings, isEditing]);

  React.useEffect(() => {
    if (updateStatus === 'success' || updateStatus === 'error') {
      setEditing(false);
    }
  }, [updateStatus]);

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  if (isLoading || isSettingsLoading) {
    return (
      <Flex justify={'center'} style={{ padding: '40px 0' }}>
        <Spin />
      </Flex>
    );
  }

  return (
    <React.Fragment>
      {isEditing ? (
        <Form<IFormValues>
          form={form}
          name="createWarehouse"
          id="settingsWarehouse"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          onFinish={(values) => mutate({ uid: params.id || '', data: { name: values.name } })}>
          <Form.Item<IFormValues> name="name" required label={warehousesT.t('name')} rules={[yupSync]}>
            <Input placeholder={warehousesT.t('namePlaceholder')} />
          </Form.Item>
          <Form.Item name="wms_base_url" label={warehousesT.t('wms_base_url')} rules={[yupSync]}>
            <Input disabled placeholder={warehousesT.t('wms_api_keyPlaceholder')} />
          </Form.Item>
          <Form.Item name="wms_api_key" label={warehousesT.t('wms_api_key')} rules={[yupSync]}>
            <Input disabled placeholder={warehousesT.t('wms_api_keyPlaceholder')} />
          </Form.Item>
          <Row></Row>
        </Form>
      ) : (
        <List style={{ maxWidth: 600 }} loading={isLoading}>
          <ListItem title={warehousesT.t('name')} description={data?.name} />
          <ListItem title={warehousesT.t('wms_base_url')} description={settings?.api_url} />
          <ListItem title={warehousesT.t('wms_api_key')} description={settings?.api_key} />
        </List>
      )}
    </React.Fragment>
  );
};
