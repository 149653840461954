import React, { createContext, ReactNode, useContext, useState } from 'react';

import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { CreateContactSchema, CreateOrLinkContactSchema } from '@app/models/dictionaries';
import Api from '@app/services/api';

import { NEW_UID } from '../data/contants';

interface IContext {
  data: CreateOrLinkContactSchema[];
  setData: (data: CreateOrLinkContactSchema[]) => void;
  setEditingUid: (uid: string) => void;
  createContact: (data: CreateContactSchema) => Promise<void>;
  editingUid: string;
  loading: boolean;
}

const RecipientContactContext = createContext<IContext>(null!);

function RecipientContactsProvider({ children }: { children: ReactNode }) {
  const [data, setData] = useState<CreateOrLinkContactSchema[]>([]);
  const [editingUid, setEditingUid] = useState('');
  const [loading, setLoading] = useState(false);
  const validationT = useTranslation(NSKeys.validation);
  const commonT = useTranslation(NSKeys.common);

  const createContact = async (newData: CreateContactSchema) => {
    return Api.dictionaries
      .postContact(newData)
      .then((res) => {
        setData([
          ...data.filter((el) => el.uid !== NEW_UID),
          { uid: res.uid, phone: res.phone, email: res.email, name: res.name },
        ]);
        setEditingUid('');
        message.success(commonT.t('dataAddSuccess'));
      })
      .catch((e) => {
        message.error(commonT.t('error400'));
      })
      .finally(() => setLoading(false));
  };

  const value = {
    data,
    loading,
    setData,
    editingUid,
    setEditingUid,
    createContact,
  };

  return <RecipientContactContext.Provider value={value}>{children}</RecipientContactContext.Provider>;
}

const useRecipientContacts = () => {
  const context = useContext(RecipientContactContext);

  if (context === undefined) {
    throw new Error('useRecipientContacts must be used within a RecipientContactsProvider');
  }

  return context;
};

export { RecipientContactContext, RecipientContactsProvider, useRecipientContacts };
