import React, { Fragment } from 'react';

import { EditOutlined, IdcardTwoTone, ProfileTwoTone, SettingTwoTone } from '@ant-design/icons';
import { theme as antTheme, Button, Layout, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { useLocalDispatch, useLocalSelector } from '@app/services/hooks/useRedux';

import { getScope } from '@app/store/currentSessionReducer';

import { useSpace } from './SpaceSettingsProvider';
import { BrandContainer } from './components/BrandContainer';
import { RegionalContainer } from './components/RegionalContainer';
import { RolesContainer, RolesProvider } from './components/RolesContainer';
import { TABS } from './interfaces';
import { Container, Tabs as StyledTabs } from './styles';

export const getFromName = (tab: TABS) => {
  switch (tab) {
    case TABS.BRAND:
      return 'brand_form';
    case TABS.REGIONAL:
      return 'region_form';
    case TABS.ROLES:
      return 'roles_form';
    default:
      return '';
  }
};

export const SpaceSettings: React.FC = () => {
  const { scopeTitle, data } = useLocalSelector((state) => state.currentSession);
  const { token } = antTheme.useToken();
  const commonT = useTranslation(NSKeys.common);
  const spaceT = useTranslation(NSKeys.spaceSettings);
  const { isEditing, setEditing, activeTab, setActiveTab, disabledEdit, setDisabledEdit } = useSpace();
  const dispatch = useLocalDispatch();

  React.useEffect(() => {
    dispatch(getScope());
  }, []);

  return (
    <Layout>
      <Layout.Header style={{ border: 0 }}>
        <Row justify="space-between" align="middle">
          <Typography.Title level={2} className="title" style={{ marginBottom: 0 }}>
            {spaceT.t('header')} {scopeTitle}
          </Typography.Title>
          <Space size="middle">
            {isEditing ? (
              <Fragment>
                <Button type="primary" size="middle" form={getFromName(activeTab)} key="submit" htmlType="submit">
                  {commonT.t('buttonSave')}
                </Button>
                <Button onClick={() => setEditing(false)} size="middle">
                  {commonT.t('buttonCancel')}
                </Button>
              </Fragment>
            ) : (
              <Button
                disabled={disabledEdit}
                icon={<EditOutlined />}
                onClick={() => setEditing(true)}
                size="middle"
                type="primary">
                {commonT.t('edit')}
              </Button>
            )}
          </Space>
        </Row>
      </Layout.Header>
      <Layout.Content>
        <StyledTabs
          destroyInactiveTabPane={true}
          defaultActiveKey={activeTab}
          items={[
            ...(data?.scope?.$permissions?.view
              ? [
                  {
                    key: TABS.REGIONAL,
                    label: (
                      <Space>
                        <SettingTwoTone twoToneColor={token?.colorPrimary} />
                        {spaceT.t('tabRegion')}
                      </Space>
                    ),
                    children: (
                      <Container>
                        <RegionalContainer />
                      </Container>
                    ),
                  },
                ]
              : []),
            ...(data?.scope?.$permissions?.view_roles
              ? [
                  {
                    key: TABS.ROLES,
                    label: (
                      <Space>
                        <IdcardTwoTone twoToneColor={token?.colorPrimary} />
                        {spaceT.t('tabRoles')}
                      </Space>
                    ),
                    children: (
                      <RolesProvider>
                        <RolesContainer />
                      </RolesProvider>
                    ),
                  },
                ]
              : []),
            ...(data?.scope?.$permissions?.view
              ? [
                  {
                    key: TABS.BRAND,
                    label: (
                      <Space>
                        <ProfileTwoTone twoToneColor={token?.colorPrimary} />
                        {spaceT.t('tabBrand')}
                      </Space>
                    ),
                    children: (
                      <Container>
                        <BrandContainer />
                      </Container>
                    ),
                  },
                ]
              : []),
          ]}
          onChange={(e) => {
            setActiveTab(e as TABS);
            setEditing(false);
          }}
          $colorBgBase={token.colorBgBase}
        />
      </Layout.Content>
    </Layout>
  );
};
