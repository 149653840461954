import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import dagre from 'dagre';
import { Edge, Node } from 'reactflow';

import { RetrieveOrderApprovalChainSchema } from '@app/models/users';

import { transformTreeToReactFlow } from '../data/services';

import { COMMON_EDGE_PARAMS, HEIGHT, WIDTH, ZERO_POSITION } from './constants';
import { NODE_TYPE } from './interfaces';

interface IContext {
  localNodes: Node[];
  localEdges: Edge[];
  onAdd: (newUid: string, parentUid: string) => void;
  onAddFirst: (uid: string) => void;
  onDelete: (uid: string) => void;
  onInitByTree: (uid: RetrieveOrderApprovalChainSchema) => void;
}

const ConfirmationContext = createContext<IContext>(null!);

function ConfirmationProvider({ children }: { children: ReactNode }) {
  const [localNodes, setNodes] = useState<Node[]>([]);
  const [localEdges, setEdges] = useState<Edge[]>([]);
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: 'RR' });

  const getLayoutedElements = (nodes: Node[], edges: Edge[]) => {
    nodes.map((node) => {
      dagreGraph.setNode(node.id, { width: WIDTH, height: HEIGHT });
    });

    edges.map((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);

      node.position = {
        x: nodeWithPosition.x + WIDTH * 2,
        y: nodeWithPosition.y + HEIGHT * 2,
      };

      return node;
    });

    setNodes(nodes);
    setEdges(edges);
  };

  // useEffect(() => {
  //   getLayoutedElements([], []);
  // }, []);

  const handleAddFirst = (uid: string) => {
    setNodes([
      {
        id: uid,
        type: NODE_TYPE.GROUP,
        position: ZERO_POSITION,
        data: {},
      },
    ]);
  };

  const handleAdd = async (newUid: string, parentUid: string) => {
    const newNode = {
      id: newUid,
      type: NODE_TYPE.GROUP,
      position: ZERO_POSITION,
      data: {},
    };

    const newEdge = {
      id: `${parentUid}->${newUid}`,
      source: parentUid,
      target: newUid,
      ...COMMON_EDGE_PARAMS,
    };
    getLayoutedElements([...localNodes, newNode], [...localEdges, newEdge]);
  };

  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const handleDelete = (id: string) => {
    const newNodes = localNodes.filter((el) => el.id !== id);
    const removedEdge = localEdges.find((el) => el.target === id);
    const mewEdges = localEdges
      .filter((el) => el.target !== id)
      .map((el) => {
        return { ...el, source: el.source === id && removedEdge?.source ? removedEdge.source : el.source };
      });
    getLayoutedElements(newNodes, mewEdges);
  };

  const handleParseTree = (tree: RetrieveOrderApprovalChainSchema) => {
    const { nodes, edges } = transformTreeToReactFlow(tree);
    getLayoutedElements(nodes, edges);
  };

  const value = {
    localNodes,
    localEdges,
    onAdd: handleAdd,
    onDelete: handleDelete,
    onAddFirst: handleAddFirst,
    onInitByTree: handleParseTree,
  };
  return <ConfirmationContext.Provider value={value}>{children}</ConfirmationContext.Provider>;
}

const useConfirmation = () => {
  const context = useContext(ConfirmationContext);

  if (context === undefined) {
    throw new Error('useConfirmationState must be used within a ConfirmationProvider');
  }

  return context;
};

export { ConfirmationContext, ConfirmationProvider, useConfirmation };
