import React from 'react';

import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Col, message, Space, Typography, Upload } from 'antd';
import type { UploadProps } from 'antd/es/upload';
import ImgCrop from 'antd-img-crop';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { useLocalDispatch, useLocalSelector } from '@app/services/hooks/useRedux';
import { TOKEN_KEY_NAME } from '@app/services/httpClient';
import { refreshUserData } from '@app/store/currentSessionReducer';

export const AvatarUpload: React.FC = () => {
  const [apiToken, setApiToken] = React.useState(localStorage.getItem(TOKEN_KEY_NAME));
  const [isEditing, setIsEditing] = React.useState(false);
  const commonT = useTranslation(NSKeys.common);
  const currentSession = useLocalSelector((state) => state.currentSession);
  const dispatch = useLocalDispatch();

  const handleChange: UploadProps['onChange'] = (info) => {
    const status = info.file.status;

    if (status === 'done') {
      setIsEditing(false);
      dispatch(refreshUserData());
    } else if (status === 'error') {
      message.error(commonT.t('error400'));
    }
  };

  return (
    <Col offset={8} style={{ marginBottom: '16px' }}>
      {isEditing ? (
        <Space>
          <ImgCrop cropShape="round">
            <Upload
              headers={{ Authorization: `Bearer ${apiToken}` }}
              action={`${process.env.REACT_APP_API_HOST}/users/users/${currentSession.data?.pk}/upload-avatar`}
              name="media"
              onChange={handleChange}
              showUploadList={{ showPreviewIcon: false, showDownloadIcon: false }}
              beforeUpload={() => setApiToken(localStorage.getItem(TOKEN_KEY_NAME))}
              listType="picture-circle"
              maxCount={1}>
              <PlusOutlined />
            </Upload>
          </ImgCrop>
          <Typography.Link onClick={() => setIsEditing(false)}>{commonT.t('buttonCancel')}</Typography.Link>
        </Space>
      ) : (
        <Space size={20}>
          <Avatar src={currentSession.data?.avatar?.variants.medium?.url} size={90} icon={<UserOutlined />} />
          <Typography.Link onClick={() => setIsEditing(true)}>{commonT.t('edit')}</Typography.Link>
        </Space>
      )}
    </Col>
  );
};
