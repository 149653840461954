import styled from 'styled-components';

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 24px;
  height: 75px;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div<{
  $colorBgBase: string;
  $colorBgLayout: string;
  $colorPrimary: string;
  $boxShadow: string;
  $colorBorder: string;
}>`
  width: 100%;

  .ant-layout {
    background-color: ${({ $colorBgLayout }) => $colorBgLayout};
    height: 100%;

    .ant-layout-sider {
      background-color: ${({ $colorBgBase }) => $colorBgBase};
      box-shadow: ${({ $boxShadow }) => $boxShadow};
      overflow: auto;
      height: 100%;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 19;
      //padding-bottom: 48px;

      .ant-menu {
        &.ant-menu-root {
          border-inline-end: 0;
        }

        .ant-menu-item,
        .ant-menu-submenu-title {
          border-radius: 0;
          margin-inline: 0;
          margin-block: 0;
          margin-bottom: 8px;
          width: 100%;
        }

        .multiple .ant-menu-submenu-title {
          display: flex;
        }

        .ant-menu-item-disabled,
        .ant-menu-submenu-disabled {
          display: none;
        }

        .ant-menu-submenu-selected[role='menuitem'] {
          border-inline-end: 3px solid ${({ $colorPrimary }) => $colorPrimary};
          border-radius: 0;
        }

        .ant-menu-item-selected {
          border-inline-end: 3px solid ${({ $colorPrimary }) => $colorPrimary};
        }

        .ant-menu-inline {
          border-inline-end: 0;
        }

        .ant-menu-title-content:not(:first-of-type) {
          white-space: initial;
          line-height: 22px;
        }
      }
    }

    .site-layout {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .ant-layout-header {
      background-color: ${({ $colorBgBase }) => $colorBgBase};
      position: sticky;
      top: 0;
      z-index: 18;
      min-height: 84px;
      padding-inline: 24px;
      display: inline-table;
      border-bottom: 1px solid ${({ $colorBorder }) => $colorBorder};

      .ant-row {
        height: 100%;
        width: 100%;
        display: inline-flex;
      }
    }
  }
`;
