import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { useCreateField } from '../../../../../../../../hooks';
import { FieldForm } from '../../../FieldForm';

interface IProps {
  field_group_uid: string;
}

export const AddFieldModal: React.FC<IProps> = ({ field_group_uid }) => {
  const ordersT = useTranslation(NSKeys.orders);
  const commonT = useTranslation(NSKeys.common);
  const [open, setOpen] = useState(false);
  const { mutate, status } = useCreateField();

  React.useEffect(() => {
    if (status === 'success') {
      setOpen(false);
    }
  }, [status]);

  return (
    <div style={{ display: 'inline-block' }}>
      <Button type="dashed" icon={<PlusOutlined />} onClick={() => setOpen(true)} size="middle">
        {ordersT.t('addFieldButton')}
      </Button>
      <Modal
        title={
          <Space align="center">
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
              {ordersT.t('addFieldTitle')}
            </Typography.Title>
          </Space>
        }
        open={open}
        destroyOnClose
        okButtonProps={{
          form: 'create_field',
          htmlType: 'submit',
          loading: status === 'loading',
        }}
        okText={commonT.t('buttonAdd')}
        confirmLoading={status === 'loading'}
        onCancel={() => setOpen(false)}>
        <div style={{ margin: '40px 0' }}>
          <FieldForm
            onSubmit={(values) => mutate({ field_group_uid, ...values })}
            initialValues={{ is_required: false }}
          />
        </div>
      </Modal>
    </div>
  );
};
