import * as React from 'react';

import { ReactNode } from 'react';

import { TruckOutlined } from '@ant-design/icons';
import { Flex, Image, Space, theme, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { useHover } from 'usehooks-ts';

import { NSKeys } from '@app/i18n';

import { ImageFault } from '@components/ImageFault';

import { CardStyled, Wrapper } from './styles';

interface IProps {
  onClick: () => void;
  title: string;
  warehouseName?: string;
  image?: string;
  disabled?: boolean;
  extra?: ReactNode;
}

export const Card: React.FC<IProps> = (props) => {
  const { token } = theme.useToken();
  const catalogsT = useTranslation(NSKeys.catalogs);
  const hoverRef = React.useRef(null);
  const isHover = useHover(hoverRef);

  return (
    <Wrapper ref={hoverRef}>
      <CardStyled
        $ishover={isHover}
        hoverable={!props.disabled}
        onClick={() => !props.disabled && props.onClick()}
        actions={[]}
        $disabled={props.disabled}
        extra={<div onClick={(e) => e.stopPropagation()}>{props.extra}</div>}
        cover={
          props.image ? (
            <Image
              height={155}
              width={250}
              style={{ objectFit: 'cover', borderRadius: 0 }}
              src={props.image}
              preview={false}
            />
          ) : (
            <Space>
              <ImageFault />
            </Space>
          )
        }>
        <Flex justify="space-between">
          <Typography.Title level={5} ellipsis={{ tooltip: props.title }}>
            {props.title}
          </Typography.Title>
          {props.warehouseName && (
            <Tooltip title={`${catalogsT.t('form.warehouseLabel')}: ${props.warehouseName}`}>
              <TruckOutlined style={{ color: token.colorPrimary, fontSize: 18 }} />
            </Tooltip>
          )}
        </Flex>
      </CardStyled>
    </Wrapper>
  );
};
