import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { CreateCatalogSchema } from '@app/models/products';
import Api from '@app/services/api';

interface IProps {
  data: CreateCatalogSchema;
  id: string;
}

export const useUpdateCatalogQuery = () => {
  const client = useQueryClient();
  const catalogsT = useTranslation(NSKeys.catalogs);

  return useMutation({
    mutationFn: async ({ data, id }: IProps) => {
      await Api.products.putCatalog(id, data);
      await client.invalidateQueries(['getCatalogs']);
      await client.invalidateQueries(['getCatalog']);
      await client.invalidateQueries(['getCatalogSettings']);
    },
    onError: () => {
      message.open({
        type: 'error',
        content: catalogsT.t('errors.putError'),
      });
    },
    onSuccess: async () => {
      message.open({
        type: 'success',
        content: catalogsT.t('form.toastEditSuccess'),
      });
    },
  });
};
