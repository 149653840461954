import { Space } from 'antd';
import styled from 'styled-components';

export const Container = styled(Space)<{ $summaryBgColor: string }>`
  margin: 24px;

  .ant-layout {
    .tableClassname {
      .ant-table-summary > tr > td {
        background-color: ${({ $summaryBgColor }) => $summaryBgColor};
      }
    }
  }
`;
