import { Edge, Node } from 'reactflow';

import { RetrieveOrderApprovalChainSchema } from '@app/models/users';

import { COMMON_EDGE_PARAMS } from '../providers/constants';
import { NODE_TYPE } from '../providers/interfaces';

export const buildTree = (nodes: Node[], edges: Edge[]) => {
  const nodeMap: any = {};

  // Заполняем nodeMap узлами из массива nodes
  nodes.forEach((node) => {
    nodeMap[node.id] = { user_group: { uid: node.id }, children: [] };
  });

  // Строим древовидную структуру, используя информацию из массива edges
  edges.forEach((edge) => {
    const sourceNode = nodeMap[edge.source];
    const targetNode = nodeMap[edge.target];

    if (sourceNode && targetNode) {
      sourceNode.children.push(targetNode);
    }
  });

  // Находим корневой узел(ы)
  const rootNodes = nodes.filter((node) => !edges.some((edge) => edge.target === node.id));

  return rootNodes.map((rootNode) => nodeMap[rootNode.id]);
};

export const transformTreeToReactFlow = (tree: RetrieveOrderApprovalChainSchema) => {
  const nodes: Node[] = [];
  const edges: Edge[] = [];

  const traverse = (node: any, parentId = null) => {
    const nodeId = node.user_group.uid;

    const newNode = {
      id: nodeId,
      type: NODE_TYPE.GROUP,
      data: { ...node.user_group },
      position: { x: 0, y: 0 }, // You can set the initial position as needed
    };

    nodes.push(newNode);

    if (parentId) {
      edges.push({ id: `${parentId}-${nodeId}`, source: parentId, target: nodeId, ...COMMON_EDGE_PARAMS });
    }

    node.children.forEach((child: any) => {
      traverse(child, nodeId);
    });
  };

  traverse(tree.head);

  return { nodes, edges };
};
