import React, { FC, useState } from 'react';

import { EditTwoTone, PlusOutlined } from '@ant-design/icons';
import { Button, Layout, Row, Table, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';

import { TableParams } from '@app/models/interfaces';

import { SearchControls } from '../../components/SearchControls';
import { useGetContactsQuery } from '../../hooks';

import { BASE_COLUMNS_WIP } from './data/constants';
import { Container } from './styles';

const INITIAL_TABLE_PARAMS = {
  pagination: {
    current: 1,
    pageSize: 10,
  },
};
export const List: FC = () => {
  const navigate = useNavigate();
  const contactsT = useTranslation(NSKeys.contacts);
  const commonT = useTranslation(NSKeys.common);
  const [searchString, setSearchString] = useState('');
  const [tableParams, setTableParams] = useState<TableParams>(INITIAL_TABLE_PARAMS);
  const { data, isLoading } = useGetContactsQuery({
    offset:
      ((tableParams.pagination?.current || INITIAL_TABLE_PARAMS.pagination.current) - 1) *
      (tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize),
    limit: tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize,
    search: searchString,
  });
  const { token } = theme.useToken();

  const handleSearch = (value: string) => {
    setTableParams(INITIAL_TABLE_PARAMS);
    setSearchString(value);
  };

  return (
    <>
      <Layout.Header>
        <Row justify="space-between" align="middle">
          <Typography.Title level={2} className="title" style={{ marginBottom: 0 }}>
            {contactsT.t('title')}
          </Typography.Title>
          <Button type="primary" icon={<PlusOutlined />} onClick={() => navigate('add')} size="middle">
            {contactsT.t('newButton')}
          </Button>
        </Row>
        <SearchControls
          isLoading={false}
          placeholder={commonT.t('search')}
          searchValue={searchString}
          onSearchClick={handleSearch}
        />
      </Layout.Header>

      <Container>
        <Table
          loading={isLoading}
          dataSource={data?.items}
          rowKey="uid"
          className="tableClassname"
          style={{ marginTop: 24 }}
          columns={[
            ...BASE_COLUMNS_WIP.map((el) => ({ ...el, title: contactsT.t(el.title as any) })),
            {
              key: 'action',
              width: 60,
              fixed: 'right',
              render: (_, cell) => (
                <Button
                  type="link"
                  icon={<EditTwoTone twoToneColor={token.colorPrimary} />}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`edit/${cell.uid}`);
                  }}
                />
              ),
            },
          ]}
          onChange={(pagination, filters, sorter) => {
            setTableParams({
              pagination,
              filters,
              ...sorter,
            });
          }}
          rowClassName={(row) => (row?.$permissions?.view ? 'cursorPointer' : '')}
          onRow={(row) => {
            return {
              onClick: () => !!row?.$permissions?.view && navigate(`${row.uid}`),
            };
          }}
          scroll={{ x: true }}
          pagination={{
            ...tableParams.pagination,
            total: data?.total,
            showQuickJumper: true,
            showSizeChanger: true,
            position: ['bottomCenter'],
            showTotal: (total, range) => `${range[0]}-${range[1]} ${commonT.t('paginationOf')} ${total}`,
          }}
        />
      </Container>
    </>
  );
};
