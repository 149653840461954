import React from 'react';

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { theme as antTheme, Drawer, Flex, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { ArtifactItemSchema, FieldGroupItemSchema } from '@app/models/orders';

import { useFieldGroupReorderQuery, useGetProcessQuery } from '../../../../hooks';

import { AddGroupFieldModal } from './components/AddGroupFieldModal';
import { CardRender } from './components/CardRender';

interface IProcessSettingsProps {
  open: boolean;
  onClose: () => void;
}

export const OrderStructure: React.FC<IProcessSettingsProps> = ({ open, onClose }) => {
  const [groups, setGroups] = React.useState<(ArtifactItemSchema | FieldGroupItemSchema)[]>([]);
  const [initialGroups, setInitialGroups] = React.useState<(ArtifactItemSchema | FieldGroupItemSchema)[]>([]);

  const { token } = antTheme.useToken();
  const ordersT = useTranslation(NSKeys.orders);
  const params = useParams<{ processId: string }>();
  const { data: processData, status: loadingStatus } = useGetProcessQuery(params.processId);
  const { mutate, status } = useFieldGroupReorderQuery();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  React.useEffect(() => {
    if (loadingStatus === 'success' && processData.order_structure) {
      setGroups(processData.order_structure?.items);
      setInitialGroups(processData.order_structure?.items);
    }
    if (loadingStatus === 'error' && initialGroups.length) {
      setGroups(initialGroups);
    }
  }, [loadingStatus, processData, setInitialGroups]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const newIndex = groups.findIndex((item) => item.data.uid === over?.id);

    if (over && active.id !== over.id && typeof newIndex === 'number' && active.id && over.id) {
      mutate({ field_group_uid: active.id.toString(), data: { uid: active.id.toString(), position: newIndex + 1 } });
      setGroups((groups) => {
        const oldIndex = groups.findIndex((item) => item.data.uid === active.id);
        const newIndex = groups.findIndex((item) => item.data.uid === over.id);

        return arrayMove(groups, oldIndex, newIndex);
      });
    }
  };

  const renderContent = () =>
    loadingStatus === 'loading' ? (
      <Flex justify="center" align="center" style={{ minHeight: 300 }}>
        <Spin />
      </Flex>
    ) : (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}>
        <Flex vertical gap={0}>
          <SortableContext items={groups?.map((el) => el.data.uid) || []} strategy={verticalListSortingStrategy}>
            {processData?.order_structure?.items
              ?.filter((el) => el.type === 'field_group')
              .map((group, i) => (
                <CardRender group={group as FieldGroupItemSchema} key={i} />
              ))}
          </SortableContext>
          {processData?.order_structure?.uid && (
            <AddGroupFieldModal order_structure_uid={processData.order_structure.uid} />
          )}
        </Flex>
      </DndContext>
    );

  return (
    <Drawer
      destroyOnClose
      title={
        <Flex vertical justify={'start'} style={{ marginLeft: 16 }}>
          <Typography.Title level={4} style={{ margin: 0, fontWeight: 800 }}>
            {processData?.name}
          </Typography.Title>
          <Typography.Text type="secondary" style={{ margin: 0 }}>
            {ordersT.t('orderStructure')}
          </Typography.Text>
        </Flex>
      }
      styles={{ body: { backgroundColor: token.colorBgLayout } }}
      placement="right"
      size="large"
      onClose={onClose}
      open={open}>
      {renderContent()}
    </Drawer>
  );
};
