import React, { createContext, ReactNode, useContext, useState } from 'react';

import { RetrieveUserGroupSchema } from '@app/models/users';

import { defaultColumns, NEW_GROUP_ID } from './constants';

interface IContext {
  newGroups: RetrieveUserGroupSchema[];
  columns: any[];
  expandedRowKeys: React.Key[];
  selectedRows: React.Key[];
  setExpandedRowKeys: (rows: React.Key[]) => void;
  setSelectedRows: (rows: React.Key[]) => void;
  onAddRow: () => void;
}

const GroupContext = createContext<IContext>(null!);

function GroupProvider({ children }: { children: ReactNode }) {
  const [newGroups, setNewGroups] = useState<RetrieveUserGroupSchema[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<React.Key[]>([]);

  const handleAddRow = () => {
    const newData: RetrieveUserGroupSchema = {
      uid: NEW_GROUP_ID,
      name: '',
      total_users: 0,
      users: [],
      $permissions: {},
    };
    setNewGroups([newData, ...newGroups]);
  };

  const handleSave = () => {
    setNewGroups([]);
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: RetrieveUserGroupSchema) => ({
        record,
        editable: col.editable && (record.$permissions.update || record.uid === 'temprary'),
        dataIndex: col.dataIndex,
        title: col.title,
        onSaveSuccess: handleSave,
      }),
    };
  });

  const value = {
    newGroups,
    columns,
    onAddRow: handleAddRow,
    expandedRowKeys,
    setExpandedRowKeys,
    selectedRows,
    setSelectedRows,
  };
  return <GroupContext.Provider value={value}>{children}</GroupContext.Provider>;
}

const useGroups = () => {
  const context = useContext(GroupContext);

  if (context === undefined) {
    throw new Error('useProviderState must be used within a GroupProvider');
  }

  return context;
};

export { GroupContext, GroupProvider, useGroups };
