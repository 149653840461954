import { Namespace, TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { CatalogVisibilityRule, OrderApprovalRule } from '@app/models/products';

import { IFormValues } from '../interfaces';

export const ValidationSchema = (t: TFunction<Namespace<'validation'>>): Yup.SchemaOf<IFormValues> =>
  Yup.object().shape({
    name: Yup.string().required(t('required')),
    warehouse_uid: Yup.string(),
    process_uid: Yup.string(),
    media: Yup.array(),

    visibility_rule: Yup.mixed<CatalogVisibilityRule>().required(t('required')),
    visible_to_user_group_uids: Yup.array().required(t('required')),
    visible_to_user_ids: Yup.array().required(t('required')),
    order_approval_rule: Yup.mixed<OrderApprovalRule>().required(t('required')),
    // order_approval_chain_id: Yup.string().test('isRequired', '123', (val, context) => {
    //   return context.parent.order_approval_rule === OrderApprovalRule.OrderApprovalChain && !val;
    // }),
    order_approval_chain_uid: Yup.string(),
    order_approval_chain_name: Yup.string(),
  });
