import React, { PropsWithChildren } from 'react';

import { theme } from 'antd';

import { Container, Shadow } from './styles';

interface IProps {
  padding?: string;
  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const Paper: React.FC<PropsWithChildren<IProps>> = (props) => {
  const { token } = theme.useToken();

  return (
    <Container
      padding={props.padding}
      className={props.className}
      style={props.style}
      $colorBgBase={token.colorBgBase}
      $borderColor={token.colorBorderSecondary}>
      {props.disabled && <Shadow style={{ backgroundColor: token.colorBgBase }} />}
      {props.children}
    </Container>
  );
};
