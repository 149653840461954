import React, { Fragment } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { useWarehouse } from '../../WarehouseProvider';

export const SettingsControl: React.FC = () => {
  const commonT = useTranslation(NSKeys.common);
  const { isEditing, setEditing, editDisabled } = useWarehouse();

  return (
    <Flex justify="space-between" gap={16} wrap="wrap">
      {isEditing ? (
        <Fragment>
          <Button type="primary" size="middle" form="settingsWarehouse" key="submit" htmlType="submit">
            {commonT.t('buttonSave')}
          </Button>
          <Button onClick={() => setEditing(false)} size="middle">
            {commonT.t('buttonCancel')}
          </Button>
        </Fragment>
      ) : (
        <Button
          icon={<EditOutlined />}
          onClick={() => setEditing(true)}
          size="middle"
          type="primary"
          disabled={editDisabled}>
          {commonT.t('edit')}
        </Button>
      )}
    </Flex>
  );
};
