import { Card } from 'antd';

import styled from 'styled-components';

export const Wrapper = styled.div<{
  $showActions: boolean;
}>`
  cursor: pointer;
  position: relative;
  float: left;
  margin: 0 24px 24px 0;
  padding-top: 1px;
  width: 254px;
  height: ${({ $showActions }) => `calc(438px - ${$showActions ? '0px' : '30px'})`};

  .ant-card-body {
    padding: 16px;
  }

  .ant-card-cover {
    position: relative;
    width: calc(100% - 2px);
    margin-left: 1px;

    .menu {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    .ant-dropdown-trigger {
      width: 100%;
      justify-content: end;
    }
  }
`;

export const CardStyled = styled(Card)<{
  $navigateToCardOnClick: boolean;
  $ishover: boolean;
}>`
  cursor: ${({ $navigateToCardOnClick }) => ($navigateToCardOnClick ? 'pointer' : 'cursor')};
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 254px;
  z-index: ${({ $ishover }) => $ishover && '17'};

  .ant-card-head {
    border-bottom: none;
    padding: 0 8px;
    min-height: 34px;
  }

  .ant-card-body {
    min-height: 200px;
    height: ${({ $ishover }) => ($ishover ? '100%' : '200px')};
    display: ${({ $ishover }) => $ishover && 'block'};
    overflow: ${({ $ishover }) => ($ishover ? 'auto' : 'hidden')};
  }

  .ant-card-actions {
    padding: 0 24px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const CarouselContainer = styled.div<{ $color: string }>`
  .ant-carousel {
    .slick-list {
      .ant-image {
        //cursor: default;
      }
    }
    .carousel {
      li button {
        background: ${({ $color }) => $color};
      }
    }

    .imageContainer {
      width: 253px;
      height: 350px;
      display: flex;
      border-radius: 0;
    }

    .image {
      object-fit: contain;
      border-radius: 0;
    }
  }
`;
