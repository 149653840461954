import React from 'react';

import { Form } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { useLocalSelector } from '@app/services/hooks/useRedux';

import { LAYOUT } from '../../constants';

import { Container } from './styles';

export const Info: React.FC = () => {
  const { data, lang, dictionaryDump } = useLocalSelector((state) => state.currentSession);
  const profileT = useTranslation(NSKeys.profileSettings);

  const langDumb = (dictionaryDump?.langs && dictionaryDump?.langs[lang])?.title || lang;

  return (
    <Container>
      <Form {...LAYOUT}>
        <Form.Item label={profileT.t('firstNameLabel')}>{data?.first_name}</Form.Item>
        <Form.Item label={profileT.t('lastNameLabel')}>{data?.last_name}</Form.Item>
        <Form.Item label={profileT.t('titleLabel')}>{data?.title}</Form.Item>
        <Form.Item label={profileT.t('phoneLabel')}>{data?.phone}</Form.Item>
        <Form.Item label={profileT.t('langLabel')}>{langDumb}</Form.Item>
      </Form>
    </Container>
  );
};
