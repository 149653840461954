import React, { useEffect, useRef, useState } from 'react';

import { Form, type InputRef, Space, theme, Tooltip } from 'antd';

import { ListRolesSchema } from '@app/models/users';
import { useLocalSelector } from '@app/services/hooks/useRedux';

import { useSpace } from '../../../../SpaceSettingsProvider';
import { useRoles } from '../../RolesProvider';

import { AddNewTag } from './components/AddNewTag';
import { DeleteButton } from './components/DeleteButton';
import { Modal } from './components/Modal';
import { Tag, TagInput } from './styles';

const isLongName = (name: string) => name.length > 20;

export const Tabs: React.FC = () => {
  const { token } = theme.useToken();
  const [tags, setTags] = useState<ListRolesSchema[]>([]);
  const newTagInputRef = useRef<InputRef>(null);
  const { data: currentSessionData } = useLocalSelector((state) => state.currentSession);

  const { isEditing } = useSpace();
  const { selectedRole, roles, setAlertOpen, onRoleSelect, isFormFieldsTouched, setNextRoleId, nextRoleId } =
    useRoles();

  useEffect(() => {
    if (roles?.length) {
      setTags(roles);
    }
  }, [roles]);

  const handleSelectTag = (role: ListRolesSchema) => {
    if (isFormFieldsTouched) {
      setNextRoleId(role.pk);
      setAlertOpen(true);
    } else {
      onRoleSelect(role);
    }
  };

  const handleOk = () => {
    const tag = tags.find((tag) => tag.pk === nextRoleId);
    if (tag) {
      onRoleSelect(tag);
    }
  };

  return (
    <Space size={[0, 8]} wrap>
      <Modal onOk={handleOk} />
      {tags.map((tag, index) => (
        <Tooltip title={isLongName(tag.name) && tag.name} key={index}>
          {selectedRole?.pk === tag.pk && isEditing ? (
            <Form.Item name="name" noStyle>
              <TagInput
                $colorPrimary={token.colorPrimary}
                ref={newTagInputRef}
                type="text"
                size="small"
                value={selectedRole?.name}
              />
            </Form.Item>
          ) : (
            <Tag
              $active={tag.pk === selectedRole?.pk}
              $colorBgContainer={token.colorBgContainer}
              $colorPrimary={token.colorPrimary}
              $colorFillAlter={token.colorFillAlter}
              $colorTextLightSolid={token.colorTextLightSolid}
              key={index}
              style={{ userSelect: 'none' }}
              onClick={() => tag.pk !== selectedRole?.pk && handleSelectTag(tag)}>
              <Space
                style={{ height: '26px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                size={5}>
                <div style={{ height: '26px', position: 'relative' }}>
                  {isLongName(tag.name) ? `${tag.name.slice(0, 20)}...` : tag.name || '-'}
                </div>

                {tag.$permissions.delete ? <DeleteButton name={tag.name} role_id={tag.pk.toString()} /> : null}
              </Space>
            </Tag>
          )}
        </Tooltip>
      ))}
      {currentSessionData?.scope?.$permissions?.create_roles && <AddNewTag />}
    </Space>
  );
};
