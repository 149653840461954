import axios, { AxiosInstance, AxiosResponse } from 'axios';

import { SignInResponseSchema } from '@app/models/users';

export const TOKEN_KEY_NAME = 'ORBIS_TOKEN';
export const REFRESH_TOKEN_KEY_NAME = 'ORBIS_REFRESH_TOKEN';
export const TOKEN_KEY_REMEMBER = 'ORBIS_TOKEN_REMEMBER';

export const getHostName = (): string => {
  return window.localStorage.getItem('orbis_app_api_host') || process.env.REACT_APP_API_HOST || '';
};

export const handleRequest = async <T>(callback: Promise<AxiosResponse>): Promise<T> => {
  const result = await callback.then(({ data }: AxiosResponse) => data);

  const isValidResponse =
    result instanceof Array ||
    result instanceof Object ||
    typeof result === 'string' ||
    typeof result === 'number' ||
    result === null;

  if (isValidResponse) {
    return result;
  }

  throw new Error('Invalid data structure');
};

export const daDataClient: AxiosInstance = axios.create({
  baseURL: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Token ${process.env.REACT_APP_DADATA_API_KEY}`,
  },
});

export const httpClient: AxiosInstance = axios.create({
  baseURL: getHostName(),
  headers: {
    'Content-Type': 'application/json',
    // Authorization: `Bearer ${window.localStorage.getItem(TOKEN_KEY_NAME)}`,
  },
});

httpClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(TOKEN_KEY_NAME);
    if (token && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if ((error?.response?.status === 401 || error?.response?.status === 403) && !originalRequest._isRetry) {
      originalRequest._isRetry = true;

      try {
        const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY_NAME);
        const isRemember = localStorage.getItem(TOKEN_KEY_REMEMBER);

        if (isRemember === null || isRemember === 'false') {
          throw new Error();
        }
        const response = await axios.post<SignInResponseSchema>(`${getHostName()}/users/auth/refresh-token`, {
          refresh_token: refreshToken,
        });
        const { refresh_token, access_token } = response.data;

        localStorage.setItem(REFRESH_TOKEN_KEY_NAME, refresh_token);
        localStorage.setItem(TOKEN_KEY_NAME, access_token);
        originalRequest.headers.Authorization = `Bearer ${access_token}`;

        return httpClient.request(originalRequest);
      } catch (e) {
        if (!['/login', '/registration'].some((str) => window.location.pathname.includes(str))) {
          window.location.replace('/login');
        }
      }
    }

    return Promise.reject(error);
  }
);
