import React from 'react';

import { Table, Typography } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { ProcessAccessSettingsSchema, RetrieveProcessSchema } from '@app/models/orders';

import { getColumns } from './data/getColumns';

interface IProps {
  activeSettingsUid: string;
  processData: RetrieveProcessSchema;
  processDataAccessSettings: ProcessAccessSettingsSchema[];
}

export const SettingsTable: React.FC<IProps> = (props) => {
  const dataSource = props.processDataAccessSettings.find((el) => el.uid === props.activeSettingsUid);
  const ordersT = useTranslation(NSKeys.orders);

  return (
    <Typography>
      <Table
        size="small"
        dataSource={dataSource?.policies || []}
        columns={getColumns({ settingsData: dataSource?.policies || [], processData: props.processData, t: ordersT.t })}
        pagination={false}
      />
    </Typography>
  );
};
