import React from 'react';

import { SystemSlugs } from '@app/models/dictionaries';
import { useLocalSelector } from '@app/services/hooks/useRedux';
import {
  selectCurrencyTranslation,
  selectDimensionUnitTranslation,
  selectMassUnitTranslation,
} from '@app/store/currentSessionReducer';

interface IProps {
  dictionaryType: SystemSlugs;
  cubicVolume?: boolean;
}

export const DictionaryPostfix: React.FC<IProps> = (props) => {
  const currentSession = useLocalSelector((state) => state.currentSession);

  const massUnit = selectMassUnitTranslation(currentSession);
  const dimensionUnit = selectDimensionUnitTranslation(currentSession);
  const currencies = selectCurrencyTranslation(currentSession);

  const renderValue = (): React.ReactNode => {
    switch (props.dictionaryType) {
      case 'mass_units':
        return massUnit;
      case 'dimension_units':
        return props.cubicVolume ? (
          <>
            {dimensionUnit}
            <span style={{ verticalAlign: 'super', fontSize: '0.6rem' }}>3</span>
          </>
        ) : (
          dimensionUnit
        );
      case 'currencies':
        return currencies;
      default:
        return '-';
    }
  };

  return <>{renderValue()}</>;
};
