import React from 'react';

import { Typography } from 'antd';
import { FormInstance } from 'antd/es/form';

import { ArtifactItemSchema, FieldGroupItemSchema } from '@app/models/orders';
import { RenderFieldByType } from '@components/RenderFieldByType';

interface IProps {
  items: (ArtifactItemSchema | FieldGroupItemSchema)[];
  form: FormInstance;
  order_uid?: string;
}

export const FieldGroupRender: React.FC<IProps> = ({ items, order_uid, form }) => {
  const render = () =>
    items.map((el) => {
      if (el.type === 'field_group') {
        return (
          <div key={el.data.uid}>
            {!!el.data.fields.length && el.data.fields.some((el) => !el.read_only) && (
              <Typography.Title level={5} style={{ margin: '0 0 24px 0' }}>
                {el.data.name}
              </Typography.Title>
            )}
            {el.data.fields
              .filter((el) => !el.read_only)
              .map((field) => (
                <RenderFieldByType
                  form={form}
                  name={['field_groups', `${el.data.alias || el.data.uid}`, `${field.alias || field.uid}`]}
                  type={field.type}
                  label={field.name || field.alias || ''}
                  key={field.uid}
                  required={field.is_required}
                  disabled={field.read_only}
                />
              ))}
          </div>
        );
      }
    });

  return <div>{render()}</div>;
};
