import React, { useRef } from 'react';

import { Spin } from 'antd';

import { useIsVisible } from '@app/services/hooks/useIsVisible';

import { SpinnerContainer } from './styles';

interface IProps {
  next: () => void;
  children?: React.ReactNode;
}

export const InfiniteScroll: React.FC<IProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const isIntersecting = useIsVisible(ref);

  React.useEffect(() => {
    if (isIntersecting) {
      props.next();
    }
  }, [isIntersecting]);

  return (
    <div ref={ref}>
      {props.children || (
        <SpinnerContainer align="middle" justify="center">
          <Spin size="small" />
        </SpinnerContainer>
      )}
    </div>
  );
};
