import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';

interface IProps {
  user_group_uid: string;
}

// WIP
export const useDeleteGroupQuery = () => {
  const client = useQueryClient();
  const commonT = useTranslation(NSKeys.common);

  return useMutation({
    mutationFn: async ({ user_group_uid }: IProps) => {
      await Api.users.deleteUserGroup(user_group_uid);
      await client.invalidateQueries(['getGroups']);
    },
    onSuccess: async () => {
      message.open({
        type: 'success',
        content: commonT.t('dataPatchSuccess'),
      });
    },
  });
};
