import styled from 'styled-components';

import { Paper } from '@components/Paper';

export const Container = styled(Paper)`
  margin: 24px;
  padding: 0 24px 0 24px;

  .ant-table-row {
    cursor: pointer;
  }
`;
