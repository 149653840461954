import { FC } from 'react';

import { Button, Checkbox, Flex, Form, Input, message, Row, Space, Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';
import { useLocalDispatch } from '@app/services/hooks/useRedux';
import { REFRESH_TOKEN_KEY_NAME, TOKEN_KEY_NAME, TOKEN_KEY_REMEMBER } from '@app/services/httpClient';
import { getAppInitialData } from '@app/store/currentSessionReducer';
import { AuthorizationFormLayout } from '@layouts/AuthorizationFormLayout';

import { ValidationSchema } from './data/validation';
import { IFormValues } from './interfaces';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { xs: { span: 24 }, sm: { offset: 5, span: 14 } },
};

export const Login: FC = () => {
  const dispatch = useLocalDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const loginT = useTranslation(NSKeys.login);
  const validationT = useTranslation(NSKeys.validation);

  const from = location.state?.from?.pathname || '/dashboard';

  const handleSubmit = async (values: IFormValues): Promise<void> => {
    await Api.users
      .signIn({ username: values.username, password: values.password })
      .then((res) => {
        localStorage.setItem(REFRESH_TOKEN_KEY_NAME, res.refresh_token);
        localStorage.setItem(TOKEN_KEY_NAME, res.access_token);
        localStorage.setItem(TOKEN_KEY_REMEMBER, `${!values?.remember}`);
        dispatch(getAppInitialData(() => navigate(from, { replace: true })));
      })
      .catch((e) => {
        message.error(validationT.t('passwordWrong'));
      });
  };

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  return (
    <AuthorizationFormLayout title={loginT.t('title')} description={loginT.t('description')}>
      <Form
        name="login_form"
        initialValues={{ remember: false }}
        onFinish={handleSubmit}
        size="large"
        autoComplete="off"
        {...layout}>
        <Form.Item required label={loginT.t('emailDefaultLabel')} name="username" rules={[yupSync]}>
          <Input placeholder={loginT.t('emailPlaceholder')} />
        </Form.Item>

        <Form.Item required label={loginT.t('passwordDefaultLabel')} name="password" rules={[yupSync]}>
          <Input.Password placeholder={loginT.t('passwordPlaceholder')} />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Flex style={{ width: '100%' }} justify="space-between">
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>{loginT.t('checkboxText')}</Checkbox>
            </Form.Item>
            <Space />
            <Typography.Link onClick={() => navigate('/reset-password')}>{loginT.t('forgotLink')}</Typography.Link>
          </Flex>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Row style={{ width: '100%' }} justify="start">
            <Button type="primary" htmlType="submit" size="large" style={{ minWidth: 114 }}>
              {loginT.t('btnSubmit')}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </AuthorizationFormLayout>
  );
};
