import React from 'react';

import { Form, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { SystemSlugs } from '@app/models/dictionaries';
import Api from '@app/services/api';
import { getFormErrorsFromResponse } from '@app/services/getFormErrorsFromResponse';
import { useLocalDispatch, useLocalSelector } from '@app/services/hooks/useRedux';
import { getAppInitialData } from '@app/store/currentSessionReducer';
import { DictionarySelector, PhoneInput } from '@components/AntFields';

import { ValidationSchema } from './data/validation';
import { IFormValues } from './interfaces';

interface IProps {
  onSubmit: () => void;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

export const InfoForm: React.FC<IProps> = (props) => {
  const validationT = useTranslation(NSKeys.validation);
  const profileT = useTranslation(NSKeys.profileSettings);
  const commonT = useTranslation(NSKeys.common);
  const { data, lang } = useLocalSelector((state) => state.currentSession);

  const dispatch = useLocalDispatch();
  const [form] = Form.useForm<IFormValues>();

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  const handleFormSubmit = async (values: IFormValues) => {
    await Api.users
      .putUserAuth({
        first_name: values.first_name,
        last_name: values.last_name,
        email: data?.email || '',
        phone: values.phone,
        title: values.title,
        settings: {
          lang: values.lang,
        },
      })
      .then(() => {
        message.success(commonT.t('dataPatchSuccess'));
        dispatch(getAppInitialData());
      })
      .catch((error) => {
        const { pointerErrors } = getFormErrorsFromResponse(data, error, validationT.t);
        if (pointerErrors.length) {
          form.setFields(pointerErrors);
        }
        message.error(commonT.t('error400'));
      })
      .finally(() => props.onSubmit());
  };

  return (
    <Form
      {...layout}
      name="profile_form"
      id="profile_form"
      form={form}
      onFinish={handleFormSubmit}
      initialValues={{
        first_name: data?.first_name,
        last_name: data?.last_name,
        lang: lang,
        title: data?.title,
        phone: data?.phone,
      }}>
      <Form.Item required name="first_name" label={profileT.t('firstNameLabel')} rules={[yupSync]}>
        <Input placeholder={profileT.t('firstPwdPlaceholder')} />
      </Form.Item>
      <Form.Item required name="last_name" label={profileT.t('lastNameLabel')} rules={[yupSync]}>
        <Input placeholder={profileT.t('lastNamePlaceholder')} />
      </Form.Item>
      <Form.Item name="phone" required label={profileT.t('phoneLabel')} rules={[yupSync]}>
        <PhoneInput />
      </Form.Item>
      <Form.Item name="title" required label={profileT.t('titleLabel')} rules={[yupSync]}>
        <Input placeholder={profileT.t('titlePlaceholder')} />
      </Form.Item>

      <DictionarySelector
        required
        name="lang"
        label={profileT.t('langLabel')}
        dictionaryName={SystemSlugs.Langs}
        rules={[yupSync]}
        placeholder={profileT.t('langPlaceholder')}
      />
    </Form>
  );
};
