import React from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Button, Layout, List, Row, Space, Table, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';

import { Breadcrumbs } from '@components/Breadcrumbs';
import { HeaderTitle } from '@components/HeaderTitle';

import { ListItem } from '@components/ListItem';
import { Paper } from '@components/Paper';

import { useGetRecipient } from '../../hooks';

const defaultColumns: ColumnsType<any> = [
  {
    title: 'tableContacts.name',
    dataIndex: 'name',
    width: '30%',
  },
  {
    title: 'tableContacts.phone',
    dataIndex: 'phone',
  },
  {
    title: 'tableContacts.email',
    dataIndex: 'email',
  },
];

export const Single: React.FC = () => {
  const navigate = useNavigate();
  const recipientT = useTranslation(NSKeys.recipient);
  const recipientsT = useTranslation(NSKeys.recipients);
  const commonT = useTranslation(NSKeys.common);
  const params = useParams<{ id: string }>();

  const { data, isLoading } = useGetRecipient(params?.id || '');

  const goBack = () => navigate('/dashboard/recipients');

  return (
    <Layout>
      <Layout.Header style={{ borderBottom: 'none' }}>
        <Breadcrumbs apiTitles={[{ id: ':recipientId', value: data?.name }]} />
        <Row justify="space-between" align="middle" style={{ minHeight: 64 }}>
          <HeaderTitle loading={isLoading} title={`${data?.name || params.id}`} onClick={goBack} />

          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => navigate(`/dashboard/recipients/edit/${params?.id}`)}
            size="middle">
            {commonT.t('edit')}
          </Button>
        </Row>
      </Layout.Header>
      <Paper style={{ margin: '24px', padding: '24px 24px' }}>
        <Typography.Title level={3}>{recipientT.t('title')}</Typography.Title>
        <List style={{ maxWidth: 600, margin: '20px 0' }} loading={isLoading}>
          <ListItem title={recipientT.t('name')} description={data?.name} />
          <ListItem title={recipientT.t('code')} description={data?.code} />
          <ListItem title={recipientT.t('retailer')} description={data?.retailer?.title} />
          <ListItem title={recipientT.t('type')} description={data?.type?.title} />
          <ListItem title={recipientT.t('tags')} description=" ">
            <Space size={10} wrap>
              {data?.tags?.map((el, i) => (
                <Tag key={i} style={{ margin: 0 }}>
                  {el.title}
                </Tag>
              ))}
            </Space>
          </ListItem>
          <ListItem title={recipientT.t('address')} description={data?.address?.as_string} />
        </List>

        <Space direction="vertical" style={{ width: '100%' }} size={24}>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {recipientsT.t('form.titleContacts')}
          </Typography.Title>
          <Table
            loading={isLoading}
            rowKey="uid"
            className="tableClassname"
            bordered
            pagination={false}
            dataSource={data?.contacts || []}
            columns={defaultColumns.map((el) => ({ ...el, title: recipientsT.t(el.title as any) }))}
            rowClassName={(row) => (row?.$permissions?.view ? 'cursorPointer' : '')}
            onRow={(row) => {
              return {
                onClick: () => !!row?.$permissions?.view && navigate(`/dashboard/contacts/${row.uid}`),
              };
            }}
          />
        </Space>
      </Paper>
    </Layout>
  );
};
