import React from 'react';

import { Typography } from 'antd';

import { Container } from './styles';

interface IProps {
  title: string;
  description?: string | number | null;
}

export const ListItem: React.FC<IProps> = (props) => {
  return (
    <Container>
      <Typography.Text type="secondary" className="listItemTitle">
        {props.title}
      </Typography.Text>
      <Typography.Text type="secondary" ellipsis={{ tooltip: props.description }}>
        {props.description || '—'}
      </Typography.Text>
    </Container>
  );
};
