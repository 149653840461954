import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TRootState } from '@app/store';

export interface ProductsState {
  filterCategories: string[];
  filterSearchString: string;
  filterTags: string[];
}

const initialState: ProductsState = {
  filterCategories: [],
  filterSearchString: '',
  filterTags: [],
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setValue(state, { payload }: PayloadAction<Partial<ProductsState>>) {
      Object.assign(state, payload);
    },
  },
});

export const setFilters = createAsyncThunk<void, Partial<ProductsState>>(
  'SET_FILTERS',
  async (payload, { dispatch, getState }) => {
    const { products } = getState() as TRootState;
    dispatch(
      setValue({
        ...initialState,
        filterSearchString:
          payload.filterSearchString === '' || payload.filterSearchString
            ? payload.filterSearchString
            : products.filterSearchString,
        filterTags: payload.filterTags || products.filterTags,
        filterCategories: payload.filterCategories || products.filterCategories,
      })
    );
  }
);

export const resetFilters = createAsyncThunk<void>('RESET_FILTERS', async (_, { dispatch }) => {
  dispatch(
    setValue({
      ...initialState,
      filterSearchString: '',
      filterTags: [],
      filterCategories: [],
    })
  );
});

export const {
  reducer,
  actions: { setValue },
} = productsSlice;

export default reducer;
