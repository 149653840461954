import React, { FC } from 'react';

import { Col, Row } from 'antd';

import i18n from 'i18next';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import backGroundImage from '@app/assets/authBackGroundImage.png';
import { useLocalDispatch, useLocalSelector } from '@app/services/hooks/useRedux';

import { getLanguage } from '@app/services/i18n';
import { resetData } from '@app/store/currentSessionReducer';
import { Paper } from '@components/Paper';

import { Layout } from './styles';

export const AuthLayout: FC = () => {
  const currentSession = useLocalSelector((state) => state.currentSession);
  const location = useLocation();
  const dispatch = useLocalDispatch();

  React.useEffect(() => {
    if (!currentSession.isAuthenticated) {
      i18n.changeLanguage(getLanguage());
      dispatch(resetData());
    }
  }, []);

  if (currentSession.isAuthenticated) {
    return <Navigate to="/dashboard/profile" state={{ from: location }} replace />;
  }

  return (
    <Layout>
      <Row gutter={[24, 24]} justify="center">
        <Col xs={24} sm={24} md={12} xxl={8}>
          <Paper style={{ height: '100%' }}>
            <Outlet />
          </Paper>
        </Col>
        <Col sm={0} xs={0} md={12} xxl={8} className="imgContainer">
          <img className="backGroundImg" src={backGroundImage} />
        </Col>
      </Row>
    </Layout>
  );
};
