import React from 'react';

import { List } from 'antd';

import countries from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { useLocalSelector } from '@app/services/hooks/useRedux';
import { geti18nLang } from '@app/services/i18n';
import { ListItem } from '@components/ListItem';

interface IProps {
  test?: string;
}

export const RegionalInfo: React.FC<IProps> = () => {
  const { massUnit, currency, dimensionUnit, dictionaryDump, lang, dataScope } = useLocalSelector(
    (state) => state.currentSession
  );
  const spaceT = useTranslation(NSKeys.spaceSettings);

  // const timeZoneDumb = (dictionaryDump?.timezones && dictionaryDump?.timezones[timeZone])?.title || timeZone;
  const currencyDumb = (dictionaryDump?.currencies && dictionaryDump?.currencies[`${currency}`])?.title || currency;
  const dimensionUnitDumb =
    (dictionaryDump?.dimension_units && dictionaryDump?.dimension_units[`${dimensionUnit}`])?.title || dimensionUnit;
  const massUnitDumb = (dictionaryDump?.mass_units && dictionaryDump?.mass_units[`${massUnit}`])?.title || massUnit;

  return (
    <List style={{ maxWidth: 600, margin: '28px 0' }}>
      {/* <ListItem title={spaceT.t('timeZoneLabel')} description={timeZoneDumb.toString()} />*/}
      <ListItem title={spaceT.t('currencyLabel')} description={currencyDumb} />
      <ListItem title={spaceT.t('dimensionLabel')} description={dimensionUnitDumb} />
      <ListItem title={spaceT.t('massLabel')} description={massUnitDumb} />
      <ListItem
        title={spaceT.t('countryLabel')}
        description={
          dataScope?.settings?.country_code
            ? countries.getName(dataScope?.settings?.country_code, geti18nLang(lang), {
                select: 'official',
              })
            : ''
        }
      />
    </List>
  );
};
