import { PermissionScope, ScopeConfig } from '@app/models/users';

import { IFormValues } from '../interfaces';

export const ValidateTableValuesAndChnageErrors = ({
  config,
  formValues,
  setFieldValue,
}: {
  config: ScopeConfig[];
  formValues: IFormValues;
  setFieldValue: (name: any, value: string) => void;
}) => {
  // const activeRolePermissions = roles?.find((el) => el.pk === selectedRole?.id)?.permissions;
  // console.log('config:', config, '\n', 'formValues:', formValues);
  (Object.keys(PermissionScope) as Array<keyof typeof PermissionScope>).map((key) => {
    const formValueOfRowTable = formValues?.[PermissionScope[key]];
    if (formValueOfRowTable) {
      for (const [formValueOfRowTableKey, formValueOfRowTableValue] of Object.entries(formValueOfRowTable)) {
        const configForRow = config?.find((el) => el.scope === PermissionScope[key]);
        const scopeConfigActionForRow = configForRow?.actions?.find((el) => el.action === formValueOfRowTableKey);
        const levelRestrictions = scopeConfigActionForRow?.restrictions?.filter((restriction) =>
          restriction.levels.some((level) => level === formValueOfRowTableValue)
        );

        if (scopeConfigActionForRow) {
          if (!scopeConfigActionForRow.levels.find((el) => el === formValueOfRowTableValue)) {
            // В конфиге нет такого поля - ставим первый из списка
            // console.log(
            //   'NOT FOUND: ',
            //   `${[PermissionScope[key], formValueOfRowTableKey]}`,
            //   formValueOfRowTableValue,
            //   'new:',
            //   scopeConfigActionForRow.levels[0]
            // );
            setFieldValue([PermissionScope[key], formValueOfRowTableKey], scopeConfigActionForRow.levels[0]);
          } else {
            // if (PermissionScope[key] === 'orders') {
            // console.log(
            //   'строка: ',
            //   PermissionScope[key],
            //   '\n',
            //   'колонка: ',
            //   formValueOfRowTableKey,
            //   '\n',
            //   'значение: ',
            //   formValueOfRowTableValue,
            //   '\n',
            //   'конфиг: ',
            //   scopeConfigActionForRow,
            //   '\n',
            //   'конфиг Restrictions: ',
            //   levelRestrictions
            // );

            if (levelRestrictions?.length) {
              const result = levelRestrictions.map((restriction) => {
                const restrictionResult = restriction.rules?.map((rule) => {
                  const ruleLevels = rule.levels
                    .map((ruleLevel) => {
                      const isRuleWorkForTableValue = rule.levels.some(
                        (el) => el === formValues?.[rule.scope]?.[rule.action]
                      );
                      // console.log('rule...', rule, '\n', 'table value: ', formValues?.[rule.scope]?.[rule.action]);
                      // console.log('isRuleWorkForTableValue...', isRuleWorkForTableValue);
                      if (!isRuleWorkForTableValue) {
                        // console.log('UPDATE FORM');
                        setFieldValue(
                          [PermissionScope[key], formValueOfRowTableKey],
                          scopeConfigActionForRow.levels[0]
                        );
                      }
                      return isRuleWorkForTableValue;
                    })
                    .every((el) => el);
                  return ruleLevels;
                });
                // console.log('restrictionResult:', restrictionResult);
                return restrictionResult.every((el) => el);
              });

              // console.log('result', result);
            }
          }
        } else {
          // console.log('В конфиге ничего нет для: ', PermissionScope[key], formValueOfRowTableKey);
        }
      }
    }
  });
};
