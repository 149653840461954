import React from 'react';

import { ColProps, Form, Select, SelectProps } from 'antd';

import countries from 'i18n-iso-countries';

import { useLocalSelector } from '@app/services/hooks/useRedux';
import { geti18nLang } from '@app/services/i18n';

interface IProps extends SelectProps {
  name: string;
  placeholder: string;
  label: string;
  required: boolean;
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  rules?: any;
}

export const CountrySelector: React.FC<IProps> = (props) => {
  const lang = useLocalSelector((state) => state.currentSession.lang);

  // Returns an object not a list
  const countryObj = countries.getNames(geti18nLang(lang), { select: 'official' });
  const countryArr = Object.entries(countryObj).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  });

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Form.Item
      name={props.name}
      labelCol={props.labelCol}
      wrapperCol={props.wrapperCol}
      label={props.label}
      required={props.required}
      rules={props.rules}>
      <Select showSearch filterOption={filterOption} placeholder={props.placeholder} options={countryArr} />
    </Form.Item>
  );
};
