import { useQuery } from '@tanstack/react-query';

import { IContactsParams } from '@app/models/interfaces';
import Api from '@app/services/api';

export const useGetContactsQuery = ({ limit, offset, search }: IContactsParams) => {
  return useQuery({
    queryKey: ['getContacts', limit, offset, search],
    queryFn: () => Api.dictionaries.getContacts({ limit, offset, search }),
  });
};
