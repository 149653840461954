import { useQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

export const useGetCart = (id: string, include_items = false) => {
  return useQuery({
    queryKey: ['getCart', id],
    queryFn: () => Api.products.getCart(id || '', include_items),
    enabled: !!id,
  });
};
