import { Namespace, TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { IFormValues } from '../interfaces';

const MIN_PASSWORD_LENGTH = 1;

export const ValidationSchema = (t: TFunction<Namespace<'validation'>>): Yup.SchemaOf<IFormValues> =>
  Yup.object().shape({
    username: Yup.string().email(t('emailFormat')).required(t('emailFormat')),
    password: Yup.string()
      .required(t('required'))
      .test(
        'len',
        t('passwordMinLength', { length: MIN_PASSWORD_LENGTH }),
        (val) => (val?.length || 0) >= MIN_PASSWORD_LENGTH
      )
      .matches(/^[^а-яёА-ЯЁ]+$/, t('passwordLetters')),
    remember: Yup.boolean().required(t('required')),
  });
