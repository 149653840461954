import { ColumnsType } from 'antd/es/table';

import { RetrieveOrderApprovalChainSchema } from '@app/models/users';

export const baseColumns: ColumnsType<RetrieveOrderApprovalChainSchema> = [
  {
    title: 'list.table.chainName',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
  },
];
