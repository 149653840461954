import { useQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

export const useGetOrderApprovalChainQuery = (approval_chain_uid: string) => {
  return useQuery({
    queryKey: ['getOrderApprovalChain', approval_chain_uid],
    queryFn: () => Api.users.getOrderApprovalChain(approval_chain_uid),
    enabled: !!approval_chain_uid,
  });
};
