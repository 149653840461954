import { useQuery } from '@tanstack/react-query';

import { IRecipientsParams } from '@app/models/interfaces';
import Api from '@app/services/api';

export const useGetRecipientsQuery = ({ limit, offset, search }: IRecipientsParams) => {
  return useQuery({
    queryKey: ['getRecipients', limit, offset, search],
    queryFn: () => Api.dictionaries.getRecipients({ limit, offset, search }),
  });
};
