import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { CreateRecipientSchema } from '@app/models/dictionaries';
import Api from '@app/services/api';

export const useCreateRecipientQuery = () => {
  const commonT = useTranslation(NSKeys.common);

  return useMutation({
    mutationFn: async (data: CreateRecipientSchema) => await Api.dictionaries.postRecipient(data),
    onSuccess: async () => {
      message.open({
        type: 'success',
        content: commonT.t('dataAddSuccess'),
      });
    },
  });
};
