import { Namespace, TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { IFormValues } from '../interfaces';

const MIN_PASSWORD_LENGTH = 8;

export const ValidationSchema = (t: TFunction<Namespace<'validation'>>): Yup.SchemaOf<IFormValues> => {
  return Yup.object().shape({
    first_name: Yup.string().required(t('required')),
    last_name: Yup.string().required(t('required')),
    phone: Yup.string().required(t('required')),
    title: Yup.string().required(t('required')),
    email: Yup.string().email(t('emailFormat')).required(t('required')),
    password: Yup.string()
      .required(t('required'))
      .test(
        'len',
        t('passwordMinLength', { length: MIN_PASSWORD_LENGTH }),
        (val) => (val?.length || 0) >= MIN_PASSWORD_LENGTH
      )
      .matches(/^[^а-яёА-ЯЁ]+$/, t('passwordLetters')),
    second_pwd: Yup.string()
      .required(t('required'))
      .test(
        'len',
        t('passwordMinLength', { length: MIN_PASSWORD_LENGTH }),
        (val) => (val?.length || 0) >= MIN_PASSWORD_LENGTH
      )
      .matches(/^[^а-яёА-ЯЁ]+$/, t('passwordLetters')),
  });
};
