import React, { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';
import type { ResizeCallbackData } from 'react-resizable';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { NSKeys } from '@app/i18n';
import { TableParams } from '@app/models/interfaces';
import { RetrieveOrderSchema } from '@app/models/orders';
import { ORDERS_TABLE_COLUMNS } from '@app/services/appSettings';
import { TableDnDResizable } from '@components/TableDnDResizable';
import { ChangeStatus } from '@pages/Processes/pages/Orders/components/ChangeStatus';

import { useGetOrdersByProcessQuery, useGetProcessQuery } from '../../../../../../hooks';

import { RowsActions } from './components/RowsActions';

import { BASE_COLUMNS_WIP } from './data/constants';

import { Container } from './styles';

const INITIAL_TABLE_PARAMS = {
  pagination: {
    current: 1,
    pageSize: 10,
  },
};

export const TableContainer: FC = () => {
  const navigate = useNavigate();
  const [tableParams, setTableParams] = useState<TableParams>(INITIAL_TABLE_PARAMS);
  const commonT = useTranslation(NSKeys.common);
  const ordersT = useTranslation(NSKeys.orders);
  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
  const [localStorageColumns, setLocalStorageColumns] = useLocalStorage(
    ORDERS_TABLE_COLUMNS,
    BASE_COLUMNS_WIP.map(({ render, ...rest }) => rest)
  );
  const params = useParams<{ processId: string }>();
  const { data: processData, isLoading: isProcessLoading } = useGetProcessQuery(params.processId);
  const { data: tableData, isLoading } = useGetOrdersByProcessQuery({
    offset:
      ((tableParams.pagination?.current || INITIAL_TABLE_PARAMS.pagination.current) - 1) *
      (tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize),
    limit: tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize,
    process_uid: params.processId,
  });

  const handleResize =
    (index: number) =>
    (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
      const newColumns = [...localStorageColumns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width > 20 ? size.width : 20,
      };
      setLocalStorageColumns(newColumns);
    };

  return (
    <Container style={{ position: 'relative' }}>
      {!!selectedRows.length && <RowsActions selectedRows={selectedRows} onDone={() => setSelectedRows([])} />}
      <TableDnDResizable<RetrieveOrderSchema>
        localStorageName={ORDERS_TABLE_COLUMNS}
        dataSource={tableData?.items}
        columns={[
          ...localStorageColumns.map((el, index) => ({
            ...el,
            title: ordersT.t(el?.title as any),
            onHeaderCell: (column: any) => ({
              width: column.width,
              onResize: handleResize(index) as React.ReactEventHandler<any>,
            }),
            render:
              el.key === 'status_uid'
                ? (value: any, { current_status_uid, $permissions, $available_statuses, uid }: RetrieveOrderSchema) => (
                    <ChangeStatus
                      processLoading={isProcessLoading}
                      processData={processData}
                      moveAvailable={!!$permissions?.move_to_status}
                      orderUid={uid}
                      statusUid={current_status_uid}
                      available_statuses_uids={$available_statuses?.map((el) => el.uid)}
                    />
                  )
                : BASE_COLUMNS_WIP.find((baseCol) => baseCol.key === el.key)?.render,
          })),
        ]}
        onChange={(pagination, filters, sorter) => {
          setSelectedRows([]);
          setTableParams({
            pagination,
            filters,
            ...sorter,
          });
        }}
        pagination={{
          ...tableParams.pagination,
          total: tableData?.total,
          showQuickJumper: true,
          showSizeChanger: true,
          position: ['bottomCenter'],
          showTotal: (total, range) => `${range[0]}-${range[1]} ${commonT.t('paginationOf')} ${total}`,
        }}
        key="uid"
        rowKey="uid"
        onColumnsChange={setLocalStorageColumns}
        rowClassName="cursorPointer"
        className="tableClassname"
        loading={isLoading}
        style={{ marginTop: 24 }}
        scroll={{ x: 'max-content' }}
        onRow={(data) => {
          return {
            onClick: (e) => data?.$permissions?.view && navigate(`${data.uid}`),
          };
        }}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedRows,
          renderCell: (value, record, index, originNode) => (
            <div onClick={(e) => e.stopPropagation()}>{originNode}</div>
          ),
          onChange: (selectedRowKeys) => {
            setSelectedRows(selectedRowKeys as string[]);
          },
        }}
      />
    </Container>
  );
};
