import React from 'react';

import { Flex, Skeleton, Typography } from 'antd';

import { useUpdateStageQuery } from '../../../../../../hooks';

interface IProps {
  name: string;
  stage_uid: string;
}

export const StageText: React.FC<IProps> = ({ name, stage_uid }) => {
  const [editableStr, setEditableStr] = React.useState(name);

  React.useEffect(() => {
    setEditableStr(name);
  }, [name]);

  const { mutate, status } = useUpdateStageQuery();

  const onEdit = (value: string) => {
    if (name !== value) {
      mutate({ name: value, stage_uid });
    }
  };

  return (
    <Flex style={{ marginBottom: 4, width: '100%' }} align="center">
      {status === 'loading' ? (
        <Skeleton.Input active />
      ) : (
        <Typography.Text type="secondary" editable={{ onChange: onEdit }} style={{ width: '100%', marginTop: 0 }}>
          {editableStr}
        </Typography.Text>
      )}
    </Flex>
  );
};
