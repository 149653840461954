import React, { useEffect, useRef } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Form, type InputRef, Space, theme } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { useSpace } from '../../../../../../SpaceSettingsProvider';
import { useRoles } from '../../../../RolesProvider';

import { AddButton, TagInput } from './styles';

export const AddNewTag: React.FC = () => {
  const { token } = theme.useToken();
  const spaceT = useTranslation(NSKeys.spaceSettings);
  const validationT = useTranslation(NSKeys.validation);
  const newTagInputRef = useRef<InputRef>(null);

  const { onAddRole, newRole } = useRoles();
  const { isEditing } = useSpace();

  useEffect(() => {
    if (newRole) {
      newTagInputRef.current?.focus();
    }
  }, [newRole]);

  if (isEditing && !newRole) {
    return null;
  }

  return (
    <Space size={[0, 8]} wrap>
      {newRole ? (
        <Form.Item
          name="newRoleName"
          noStyle
          rules={[
            { min: 3, message: validationT.t('passwordMinLength', { length: 3 }) },
            { required: true, message: validationT.t('required') },
          ]}>
          <TagInput className="newRole" ref={newTagInputRef} type="text" size="small" $colorError={token.colorError} />
        </Form.Item>
      ) : (
        <AddButton
          type="dashed"
          size="small"
          $colorBgContainer={token.colorBgContainer}
          icon={<PlusOutlined />}
          onClick={onAddRole}>
          {spaceT.t('roleAddBtn')}
        </AddButton>
      )}
    </Space>
  );
};
