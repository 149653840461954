import { useQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

interface IParams {
  catalog_uid: string;
  product_uid: string;
}

export const useGetProductQuery = ({ catalog_uid, product_uid }: IParams) => {
  return useQuery({
    queryKey: ['getProducts', { catalog_uid, product_uid }],
    queryFn: () => Api.products.getProductFromCatalog(catalog_uid, product_uid),
  });
};
