import React, { FC } from 'react';

import logoFull from '@app/assets/logoFull.png';

import { Container, Description, HeadContainer, Title } from './styles';

interface IProps {
  title?: string;
  description?: string;
  children?: JSX.Element | null;
}

export const AuthorizationFormLayout: FC<IProps> = ({ title, children, description }) => {
  return (
    <Container>
      <HeadContainer>
        <img height={35} src={logoFull} />
        {title && (
          <Title level={2} style={{ marginBottom: 0 }}>
            {title}
          </Title>
        )}
        {description && <Description type="secondary">{description}</Description>}
      </HeadContainer>
      {children}
    </Container>
  );
};
