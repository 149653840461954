import React from 'react';

import { Select, SelectProps } from 'antd';

import { useGetCatalogProductsQuery } from './hooks/useGetCatalogProductsQuery';

interface IProps extends SelectProps {
  placeholder: string;
  catalogUid: string;
  disableValues?: string[] | number[];
}

export const CatalogProductsSelect: React.FC<IProps> = ({ placeholder, catalogUid, disableValues = [], ...props }) => {
  const [search, setSearch] = React.useState('');
  const { data, hasNextPage, fetchNextPage } = useGetCatalogProductsQuery({
    limit: 10,
    catalog_uid: catalogUid,
    q: search,
  });

  const dataSource = data?.pages?.map((data) => data.items || []).flat() || [];

  return (
    <Select
      mode={props.mode}
      allowClear={props.allowClear}
      placeholder={placeholder}
      style={{ minWidth: '200px' }}
      dropdownStyle={{ position: 'fixed' }}
      onPopupScroll={(e) => {
        e.persist();
        const target = e.currentTarget;
        if (target?.scrollTop + target?.offsetHeight === target?.scrollHeight && hasNextPage) {
          fetchNextPage();
        }
      }}
      showSearch
      filterOption={false}
      onSearch={setSearch}
      notFoundContent={null}
      options={dataSource?.map((el) => ({
        data: el,
        label: `${el.title}`,
        value: el.uid,
        disabled: disableValues.some((disableEl) => disableEl === el.uid),
      }))}
      {...props}
    />
  );
};
