import * as React from 'react';

import { ReactNode } from 'react';

import { Carousel, Image, Row, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useHover } from 'usehooks-ts';

import { NSKeys } from '@app/i18n';
import { SystemSlugs } from '@app/models/dictionaries';
import { XOR } from '@app/models/interfaces';
import { ViewProductInCatalogSchema, ViewProductSchema } from '@app/models/products';
import { AddRemoveControl } from '@components/AddRemoveContol';
import { DictionaryPostfix } from '@components/DictionaryPostfix';
import { ImageFault } from '@components/ImageFault';

import { Info } from './components/Info';

import { CardStyled, CarouselContainer, Wrapper } from './styles';

interface IProps {
  product: XOR<ViewProductSchema, ViewProductInCatalogSchema>;
  onCartCountChange: (value: number) => void;
  addButtonTitle?: string;
  showEdit?: boolean;
  showCount?: boolean;
  addToCardAvailable?: boolean;
  navigateToCardOnClick?: boolean;
  extra?: ReactNode;
}

export const CardComponent: React.FC<IProps> = ({
  navigateToCardOnClick = true,
  addButtonTitle,
  showCount = true,
  addToCardAvailable = false,
  onCartCountChange,
  product,
  extra,
}) => {
  const navigate = useNavigate();
  const nomenclatureListT = useTranslation(NSKeys.nomenclatureList);
  const nomenclatureAddT = useTranslation(NSKeys.nomenclatureAdd);
  const { token } = theme.useToken();
  const hoverRef = React.useRef(null);

  // useHover пока не используем
  // const isHover = useHover(hoverRef);
  const isHover = false;

  const redirectToSingleProduct = () => navigate(`${product.uid}`);

  return (
    <Wrapper ref={hoverRef} $showActions={addToCardAvailable}>
      <CardStyled
        $ishover={isHover}
        hoverable
        $navigateToCardOnClick={navigateToCardOnClick}
        onClick={redirectToSingleProduct}
        actions={[
          <Row key="add" onClick={(e) => e.stopPropagation()} justify="center">
            {addToCardAvailable && (
              <AddRemoveControl
                value={product?.cart_item?.quantity || 0}
                onInputChange={onCartCountChange}
                title={addButtonTitle || nomenclatureAddT.t('addToCart')}
              />
            )}
          </Row>,
        ]}
        extra={<div onClick={(e) => e.stopPropagation()}>{extra}</div>}
        cover={
          <React.Fragment>
            <CarouselContainer $color={token.colorPrimary}>
              {product.images?.length ? (
                <Carousel
                  dots={{ className: 'carousel' }}
                  fade
                  lazyLoad="ondemand"
                  style={{ border: 0 }}
                  appendDots={(dots) => <div onClick={(e) => e.stopPropagation()}>{dots}</div>}>
                  {product.images.map((image, index) => (
                    <Image
                      key={index}
                      src={image.variants.thumbnail?.url}
                      height={150}
                      alt={product.title}
                      preview={false}
                      wrapperClassName="imageContainer"
                      className="image"
                    />
                  ))}
                </Carousel>
              ) : (
                <ImageFault />
              )}
            </CarouselContainer>
          </React.Fragment>
        }>
        <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
          <Typography.Title
            level={5}
            style={{ margin: 0, maxWidth: '80%' }}
            ellipsis={{ rows: 1, tooltip: product.title }}>
            {product.title}
          </Typography.Title>
        </Row>
        <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {product.price || 0} <DictionaryPostfix dictionaryType={SystemSlugs.Currencies} />
          </Typography.Title>
          {showCount && (
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {product.package_amount}
              {nomenclatureListT.t('pcs')}
            </Typography.Text>
          )}
        </Row>
        <Info data={product} />
      </CardStyled>
    </Wrapper>
  );
};
