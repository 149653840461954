import React from 'react';

import { useSpace } from '../../SpaceSettingsProvider';

import { RegionalForm } from './components/RegionalForm';
import { RegionalInfo } from './components/RegionalInfo';

export const RegionalContainer: React.FC = () => {
  const { isEditing } = useSpace();

  return <React.Fragment>{isEditing ? <RegionalForm /> : <RegionalInfo />}</React.Fragment>;
};
