import React, { useState } from 'react';

import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';

import { useDeleteOrderApprovalChainQuery, useGetOrderApprovalChainUsageQuery } from '../../../../hooks';

import { Container, LinkList } from './styles';

interface IProps {
  title?: string;
  hidden: boolean;
  uid: string;
  onRemove: () => void;
}

export const TableRemoveButton: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const commonT = useTranslation(NSKeys.common);
  const orderApprovalChainsT = useTranslation(NSKeys.orderApprovalChains);
  const [activeUid, setActiveUid] = useState('');
  const [open, setOpen] = useState(false);

  const { token } = theme.useToken();

  const { mutate, status: deleteStatus } = useDeleteOrderApprovalChainQuery();
  const { data, status: usageStatus, isFetching: usageFetching } = useGetOrderApprovalChainUsageQuery(activeUid);

  const handleRemove = () => {
    setActiveUid(props.uid);
  };

  const handleCloseModal = () => {
    setActiveUid('');
    setOpen(false);
  };

  React.useEffect(() => {
    if (data && usageStatus === 'success') {
      if (data?.catalogs.length === 0) {
        mutate(activeUid);
        handleCloseModal();
      } else {
        setOpen(true);
      }
    }
  }, [data]);

  React.useEffect(() => {
    if (deleteStatus === 'success') {
      props.onRemove();
    }
  }, [deleteStatus]);

  return (
    <Container $hidden={props.hidden} $colorPrimary={token.colorPrimary} $colorText={token.colorText}>
      <Button type="link" icon={<DeleteOutlined />} onClick={handleRemove} loading={usageFetching}>
        {props.title || commonT.t('removeSelected')}
      </Button>
      <Modal
        centered
        style={{ maxWidth: 416 }}
        open={open}
        onCancel={handleCloseModal}
        onOk={handleCloseModal}
        okText={commonT.t('buttonCancel')}>
        <Row>
          <Col sm={3}>
            <ExclamationCircleOutlined style={{ fontSize: 22, color: token.colorWarning }} />
          </Col>
          <Col sm={21}>
            <Typography.Title level={5}>{orderApprovalChainsT.t('deleteModal.title')}</Typography.Title>
            <Typography.Text style={{ marginBottom: '20px' }}>
              {orderApprovalChainsT.t('deleteModal.description')}{' '}
              <LinkList>
                {data?.catalogs?.map((el) => (
                  <li key={el.uid}>
                    <Typography.Link onClick={() => navigate(`/dashboard/catalogs/edit/${el.uid}`)}>
                      {el.name}
                    </Typography.Link>
                  </li>
                ))}
              </LinkList>
            </Typography.Text>
          </Col>
        </Row>
      </Modal>
    </Container>
  );
};
