import { useMutation, useQueryClient } from '@tanstack/react-query';

import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { UpdateOrderParamsDTO } from '@app/models/orders';
import Api from '@app/services/api';

interface IParams {
  order_uid: string;
  data: UpdateOrderParamsDTO;
}

export const useUpdateQuery = () => {
  const client = useQueryClient();
  const commonT = useTranslation(NSKeys.common);

  return useMutation({
    mutationFn: async ({ order_uid, data }: IParams) => {
      await Api.orders.updateOrder(order_uid, data);
    },
    onSuccess: async (_, { order_uid }) => {
      await client.invalidateQueries(['getOrder', order_uid]);
      await client.invalidateQueries(['getHistory', order_uid]);
      message.open({
        type: 'success',
        content: commonT.t('dataPatchSuccess'),
      });
    },
    onError: () => {
      message.open({
        type: 'error',
        content: commonT.t('error400'),
      });
    },
  });
};
