import React, { PropsWithChildren } from 'react';

import { Typography } from 'antd';

interface IProps {
  title?: string;
}

export const FormTitle: React.FC<PropsWithChildren<IProps>> = (props) => {
  return (
    <Typography.Title level={5} style={{ marginBottom: 28 }}>
      {props.title}
    </Typography.Title>
  );
};
