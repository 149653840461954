import React from 'react';

import { Button, Form, Layout, Row, Skeleton, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { CreateProductSchema } from '@app/models/products';
import { HeaderTitle } from '@components/HeaderTitle';
import { ProductForm } from '@components/products';

import { useGetProductQuery, useUpdateProductQuery } from '../../hooks';

export const EditProduct: React.FC = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [form] = Form.useForm<CreateProductSchema>();
  const commonT = useTranslation(NSKeys.common);
  const nomenclatureEditT = useTranslation(NSKeys.nomenclatureEdit);

  const { data, isLoading: isProductLoading, status: getStatus } = useGetProductQuery(params?.id || '');
  const { mutate: updateProduct, status: updateStatus } = useUpdateProductQuery();
  const goBack = (): void => {
    navigate('/dashboard/products');
  };

  const onFinish = async (formValues: CreateProductSchema): Promise<void> => {
    const image_uids = formValues.image_uids?.map((el: any) => (el?.url ? el.uid : el?.response?.uid));
    updateProduct({ data: { ...formValues, image_uids }, id: params.id || '' });
  };

  React.useEffect(() => {
    if (updateStatus === 'success') {
      navigate(`/dashboard/products/${params.id}`);
    }
  }, [updateStatus]);

  React.useEffect(() => {
    if (getStatus === 'success') {
      form.setFieldsValue({
        ...data,
        title: data?.title || '',
        code: data?.code || '',
        tags_ids: data?.tags.map((el) => el.id),
        category_id: data?.category?.id,
        brand_id: data?.brand?.id,
        image_uids: data?.images.map(
          (el) =>
            ({
              uid: el.uid,
              status: 'done',
              url: el.variants.thumbnail?.url,
            } as any)
        ),
      });
    }
  }, [getStatus]);

  return (
    <Layout>
      <Layout.Header>
        <Row justify="space-between" align="middle">
          <HeaderTitle title={nomenclatureEditT.t('title')} />
          <Space size="middle">
            <Button onClick={goBack} size="middle">
              {commonT.t('buttonCancel')}
            </Button>
            <Button
              disabled={isProductLoading || updateStatus === 'loading'}
              type="primary"
              loading={updateStatus === 'loading'}
              onClick={() => form.submit()}
              size="middle">
              {nomenclatureEditT.t('titleSave')}
            </Button>
          </Space>
        </Row>
      </Layout.Header>
      <Layout.Content style={{ padding: 24 }}>
        {isProductLoading && data ? (
          <Skeleton active={true} />
        ) : (
          <ProductForm form={form} onFinish={onFinish} submitting={updateStatus === 'loading'} />
        )}
      </Layout.Content>
    </Layout>
  );
};
