import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { CreateRecipientSchema } from '@app/models/dictionaries';
import Api from '@app/services/api';

export const useAddRecipientQuery = () => {
  const client = useQueryClient();
  const commonT = useTranslation(NSKeys.common);
  const validationT = useTranslation(NSKeys.validation);

  return useMutation({
    mutationFn: async (data: CreateRecipientSchema) => {
      const res = await Api.dictionaries.postRecipient(data);
      await client.invalidateQueries(['getRecipients']);
      return res;
    },
    onSuccess: async () => {
      message.open({
        type: 'success',
        content: commonT.t('dataAddSuccess'),
      });
    },
    onError: () => {
      message.open({
        type: 'error',
        content: commonT.t('error400'),
      });
    },
  });
};
