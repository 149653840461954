import React from 'react';

import { Select, SelectProps } from 'antd';

import { useProcessQuery } from './hooks/useProcessQuery';

interface IProps extends SelectProps {
  placeholder: string;
}

export const ProcessSelect: React.FC<IProps> = ({ placeholder, ...props }) => {
  const { data, hasNextPage, fetchNextPage, isLoading } = useProcessQuery();

  const dataSource = data?.pages?.map((data) => data.items || []).flat() || [];

  return (
    <Select
      placeholder={placeholder}
      onPopupScroll={(e) => {
        e.persist();
        const target = e.currentTarget;
        if (target?.scrollTop + target?.offsetHeight === target?.scrollHeight && hasNextPage) {
          fetchNextPage();
        }
      }}
      loading={isLoading}
      notFoundContent={null}
      options={dataSource?.map((el) => ({
        data: el,
        label: el.name,
        value: el.uid,
      }))}
      {...props}
    />
  );
};
