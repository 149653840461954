import React from 'react';

import { Form, Modal } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { RetrieveContactSchema } from '@app/models/dictionaries';
import { FormContact, IFormValues } from '@components/contacts';

import { useCreateContactQuery } from '../hooks';

interface IProps {
  initialValue: string;
  isModalOpen: boolean;
  onAdd: (values: RetrieveContactSchema) => void;
  onCancel: () => void;
}

export const Create: React.FC<IProps> = (props) => {
  const breadcrumbsT = useTranslation(NSKeys.breadcrumbs);
  const commonT = useTranslation(NSKeys.common);
  const [form] = Form.useForm<IFormValues>();
  const { mutate: createContact, status, data } = useCreateContactQuery();

  React.useEffect(() => {
    if (props.initialValue) {
      form.setFieldValue('name', props.initialValue);
    }
  }, []);

  React.useEffect(() => {
    if (status === 'success') {
      props.onAdd(data);
      form.resetFields();
    }
  }, [status, data]);

  const handleCancel = () => {
    form.resetFields();
    props.onCancel();
  };

  const handleSubmit = (formValues: IFormValues): void => {
    createContact({
      ...formValues,
      email: formValues.email || null,
      recipients: [],
    });
  };

  return (
    <Modal
      title={breadcrumbsT.t('contactsAdd')}
      open={props.isModalOpen}
      centered
      destroyOnClose={true}
      width="75%"
      style={{ maxWidth: '900px' }}
      cancelText={commonT.t('cancel')}
      okButtonProps={{ form: 'contactForm', htmlType: 'submit' }}
      okText={commonT.t('create')}
      onCancel={handleCancel}>
      <FormContact form={form} initialValues={{ name: props.initialValue }} onFinish={handleSubmit} />
    </Modal>
  );
};
