import React from 'react';

import { Layout, Skeleton } from 'antd';

import { InfiniteScroll } from '@components/InfiniteScroll';
import { SkeletonImage } from '@components/products';
import { Card } from '@pages/Catalogs/components';

import { useGetCatalogsQuery } from '../../../../hooks';

import { CardsContainer } from './styles';

interface IProps {
  current_catalog_uid?: string;
  onSelect: (catalog_uid: string) => void;
}

export const CatalogList: React.FC<IProps> = (props) => {
  const { data, hasNextPage, fetchNextPage, isLoading } = useGetCatalogsQuery({ limit: 10 });

  const dataSource = React.useMemo(() => {
    return data?.pages?.map((data) => data.items || []).flat() || [];
  }, [data]);

  return (
    <Layout.Content style={{ padding: '24px 0' }}>
      <CardsContainer>
        {!!dataSource.length &&
          dataSource?.map((catalog) => (
            <Card
              disabled={props.current_catalog_uid === catalog.uid || !catalog?.$permissions?.manage_products}
              image={catalog?.cover?.variants?.thumbnail?.url}
              key={catalog.uid}
              title={catalog.name}
              warehouseName={catalog.warehouse?.name}
              onClick={() => props.onSelect(catalog.uid)}
            />
          ))}
        {isLoading && <SkeletonImage size="m" />}
        {hasNextPage && (
          <InfiniteScroll key={data?.pages?.length || 0} next={fetchNextPage}>
            <SkeletonImage size="m" />
          </InfiniteScroll>
        )}
      </CardsContainer>
    </Layout.Content>
  );
};
