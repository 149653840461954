import { Row } from 'antd';
import styled from 'styled-components';

export const Container = styled(Row)<{ $hidden: boolean; $colorText: string; $colorPrimary: string }>`
  display: flex;
  justify-content: flex-end;
  margin: ${({ $hidden }) => ($hidden ? '0' : '0 0 16px')};
  min-height: ${({ $hidden }) => ($hidden ? '0' : '32px')};
  transition: all 0.3s ease;
  height: ${({ $hidden }) => ($hidden ? '0' : '32px')};
  opacity: ${({ $hidden }) => ($hidden ? '0' : '1')};

  .ant-btn-icon {
    color: ${({ $colorPrimary }) => $colorPrimary};
  }

  .ant-btn-link {
    color: ${({ $colorText }) => $colorText};
  }
`;

export const LinkList = styled.ul`
  padding-left: 0;
  text-transform: capitalize;
  display: contents;

  li {
    display: inline;
  }

  li:first-child:before {
    content: normal;
  }

  li:before {
    content: ',\\0000a0';
  }
`;
