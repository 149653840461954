import React, { FC, useState } from 'react';

import { Button, Form, Input, message, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';
import { AuthorizationFormLayout } from '@layouts/AuthorizationFormLayout';

import { ValidationSchema } from './data/validation';
import { IFormValues } from './interfaces';
import { Description } from './styles';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 14 },
};

export const ResetPassword: FC = () => {
  const [submittedUsername, setSubmittedUsername] = useState('');
  const pageT = useTranslation(NSKeys.resetPassword);
  const validationT = useTranslation(NSKeys.validation);

  const handleSubmit = async (values: IFormValues): Promise<void> => {
    await Api.users
      .postResets(values)
      .then(() => {
        setSubmittedUsername(values.username);
      })
      .catch(() => {
        message.error(validationT.t('emailWrong'));
      });
  };

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  return (
    <AuthorizationFormLayout
      title={pageT.t('title')}
      description={submittedUsername ? validationT.t('checkEmailTitle') : pageT.t('description')}>
      {submittedUsername ? (
        <Description>
          <Typography.Text>{validationT.t('checkEmail', { email: submittedUsername })}</Typography.Text>
          <Button size="large" type="primary" onClick={() => setSubmittedUsername('')}>
            {pageT.t('btnAgain')}
          </Button>
        </Description>
      ) : (
        <Form
          name="reset_password_form"
          initialValues={{ username: '' }}
          onFinish={handleSubmit}
          size="large"
          autoComplete="off"
          {...layout}>
          <Form.Item label={pageT.t('emailDefaultLabel')} name="username" rules={[yupSync]}>
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 5, span: 3 }}>
            <Button type="primary" htmlType="submit">
              {pageT.t('btnSubmit')}
            </Button>
          </Form.Item>
        </Form>
      )}
    </AuthorizationFormLayout>
  );
};
