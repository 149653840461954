import React, { FC, useEffect, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form, Radio, Row, Skeleton, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { ItemSchema, SystemSlugs } from '@app/models/dictionaries';

import Api from '@app/services/api';

import { useLocalSelector } from '@app/services/hooks/useRedux';

import { LAYOUT, TAIL_LAYOUT } from '../../constants';
import { IStep6 } from '../../interfaces';

interface IProps {
  initialValues: IStep6;
  submitting: boolean;
  onNext: (values: IStep6) => void;
  onPrev: () => void;
}

const LIMIT = 15;

export const Step6: FC<IProps> = (props) => {
  const registrationT = useTranslation(NSKeys.registration);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<ItemSchema[]>([]);
  const [offset, setOffset] = useState(0);
  const lang = useLocalSelector((state) => state.currentSession.lang);

  const fetchSelectLists = (): void => {
    setLoading(true);
    Api.dictionaries
      .getList({ name: SystemSlugs.EmployeesCount, limit: LIMIT, offset, lang })
      .then((res) => {
        const newOptions = [...options, ...res.items];
        if (newOptions.length < (res.total || 0)) {
          setOffset(offset + LIMIT);
        }
        setOptions(newOptions);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchSelectLists();
  }, [offset]);

  if (loading) {
    return (
      <Form.Item>
        <Skeleton.Input active />
      </Form.Item>
    );
  }

  return (
    <Form name="login_form_step6" initialValues={props.initialValues} onFinish={props.onNext} size="large" {...LAYOUT}>
      <Form.Item name="worker_count" style={{ marginLeft: '20%' }}>
        <Radio.Group>
          <Space direction="vertical">
            {options.map((el) => (
              <Radio key={el.value} value={el.value}>
                {el.title}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>

      <Form.Item {...TAIL_LAYOUT}>
        <Row justify="space-between">
          <Button icon={<ArrowLeftOutlined />} onClick={props.onPrev} disabled={props.submitting}>
            {registrationT.t('btnBack')}
          </Button>
          <Button type="primary" htmlType="submit" loading={props.submitting}>
            {registrationT.t('btnFinal')}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};
