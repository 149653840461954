import React from 'react';

import { Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Namespace, TFunction } from 'react-i18next';

import { OrderItemSchema } from '@app/models/orders';
import { AddRemoveControl } from '@components/AddRemoveContol';

const renderParagraphWitTooltip = (data: string): JSX.Element => (
  <Typography.Paragraph
    className="paragraphWitTooltip"
    ellipsis={{ tooltip: data }}
    style={{ wordWrap: 'break-word', wordBreak: 'break-word', margin: 0, maxWidth: 200 }}>
    {data}
  </Typography.Paragraph>
);

export const columns = (
  t: TFunction<Namespace<'order'>>,
  onCountChange: (value: number, cartItem: OrderItemSchema) => void
): ColumnsType<OrderItemSchema> => [
  {
    title: '№',
    width: 50,
    dataIndex: 'num',
    render: (_, __, index) => index + 1,
    ellipsis: true,
  },
  {
    title: t('itemsTable.name'),
    dataIndex: 'name',
    render: (_, el) => renderParagraphWitTooltip(el.product.title),
    sorter: (a, b) => a.product.title.localeCompare(b.product.title),
    ellipsis: true,
  },
  {
    title: t('itemsTable.articul'),
    dataIndex: 'articul',
    render: (_, el) => renderParagraphWitTooltip(el.product.code || ''),
    // sorter: (a, b) => a.product.code.localeCompare(b.product.code || ''),
    ellipsis: true,
  },
  // {
  //   title: t('tableColumnMultiplicity'),
  //   dataIndex: 'multiplicity',
  //   align: 'right',
  //   ellipsis: true,
  // },
  // {
  //   title: (
  //     <>
  //       {t('itemsTable.volume')} <DictionaryPostfix dictionaryType={DictionarySlug.DimensionUnits} cubicVolume />
  //     </>
  //   ),
  //   dataIndex: 'volume',
  //   align: 'right',
  //   ellipsis: true,
  // },
  // {
  //   title: (
  //     <>
  //       {t('itemsTable.weight')} <DictionaryPostfix dictionaryType={DictionarySlug.MassUnits} />
  //     </>
  //   ),
  //   dataIndex: 'weight',
  //   align: 'right',
  //   ellipsis: true,
  // },
  {
    title: t('itemsTable.count'),
    dataIndex: 'cart_count',
    key: 'cart_count',
    width: '190px',
    align: 'center',
    render: (_, el) => (
      <AddRemoveControl value={el.quantity} isSmall={true} onInputChange={(value) => onCountChange(value, el)} />
    ),
  },
  // {
  //   title: (
  //     <>
  //       {t('itemsTable.price')} (<DictionaryPostfix dictionaryType={DictionarySlug.Currencies} />)
  //     </>
  //   ),
  //   dataIndex: 'price',
  //   align: 'right',
  //   ellipsis: true,
  // },
];
