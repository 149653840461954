import { MarkerType, Node } from 'reactflow';

import { NODE_TYPE } from './interfaces';

export const WIDTH = 260;
export const HEIGHT = 282;
export const COMMON_EDGE_PARAMS = {
  type: 'smoothstep',
  markerEnd: {
    width: 25,
    height: 25,
    type: MarkerType.Arrow,
  },
};

export const ZERO_POSITION = { x: 0, y: 0 };

export const FIRST_NODE_BUTTON: Node = {
  id: '1',
  type: NODE_TYPE.EMPTY,
  position: ZERO_POSITION,
  data: {},
};
