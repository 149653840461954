import { IParsedAddressValues } from './interface';

export const getAddressObject = (address_components?: google.maps.GeocoderAddressComponent[]): IParsedAddressValues => {
  const ShouldBeComponent: any = {
    street_number: ['street_number'],
    postal_code: ['postal_code'],
    street_name: ['street_address', 'route'],
    region: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
    ],
    country: ['country'],
  };

  const address: any = {
    street_number: '',
    postal_code: '',
    street_name: '',
    region: '',
    city: '',
    country: '',
  };

  address_components?.forEach((component) => {
    for (const shouldBe in ShouldBeComponent) {
      if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        address[shouldBe] = component.long_name;
      }
    }
  });

  return address;
};
