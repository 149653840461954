import React from 'react';

import { Button, Form, Input, message, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';

import { ValidationSchema } from './data/validation';
import { IFormValues } from './interfaces';

interface IProps {
  test?: string;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const UpdatePassword: React.FC<IProps> = (props) => {
  const profileT = useTranslation(NSKeys.profileSettings);
  const validationT = useTranslation(NSKeys.validation);
  const commonT = useTranslation(NSKeys.common);
  const [form] = Form.useForm();

  const handleSubmit = async (values: IFormValues): Promise<void> => {
    await Api.users
      .putPassword({ current_password: values.old_pwd, new_password: values.second_pwd })
      .then((res) => {
        message.success(commonT.t('dataPatchSuccess'));
        form.resetFields();
      })
      .catch(() => {
        message.error(validationT.t('passwordWrong'));
      });
  };

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  return (
    <Form
      form={form}
      name="changePassword_form"
      initialValues={{ remember: true }}
      onFinish={handleSubmit}
      size="large"
      autoComplete="off"
      {...layout}>
      <Form.Item required label={profileT.t('oldPwdLabel')} name="old_pwd" rules={[yupSync]}>
        <Input.Password placeholder={profileT.t('oldPwdPlaceholder')} />
      </Form.Item>
      <Form.Item
        required
        label={profileT.t('firstPwdLabel')}
        name="first_pwd"
        rules={[
          yupSync,
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('old_pwd') !== value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(validationT.t('passwordReapeated')));
            },
          }),
        ]}>
        <Input.Password placeholder={profileT.t('firstPwdPlaceholder')} />
      </Form.Item>
      <Form.Item
        required
        label={profileT.t('secondPwdLabel')}
        name="second_pwd"
        rules={[
          yupSync,
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('first_pwd') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(validationT.t('passwordNotMatch')));
            },
          }),
        ]}>
        <Input.Password placeholder={profileT.t('secondPwdPlaceholder')} />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Row style={{ width: '100%' }} justify="start">
          <Button type="primary" htmlType="submit">
            {profileT.t('btnChangePwd')}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};
