import { get, merge } from 'lodash';

export const getInitialValues = (order: any, process: any) => {
  const keys: string[] = [];
  const res = {};
  const parsedProcess = process?.order_structure?.items?.filter(
    (item: any) =>
      item?.type === 'field_group' &&
      !!item?.data?.fields?.length &&
      item?.data?.fields?.some((field: any) => !field.read_only)
  );

  if (parsedProcess?.length) {
    parsedProcess.forEach((el: any) => {
      if (el?.data?.fields?.length) {
        el?.data?.fields.forEach((field: any) => {
          merge(res, {
            field_groups: {
              [el?.data?.alias || el?.data?.uid]: {
                [field?.alias || field?.uid]: get(
                  order,
                  `field_groups.${el?.data?.alias || el?.data?.uid}.${field?.alias || field?.uid}`
                ),
              },
            },
          });
          keys.push(`field_groups.${el?.data?.alias || el?.data?.uid}.${field?.alias || field?.uid}`);
        });
      }
    });
  }

  // keys.forEach((key) => merge(res, { [key]: get(order, key) }));
  // console.log(
  //   order,
  //   process?.order_structure?.items?.filter(
  //     (item: any) =>
  //       item?.type === 'field_group' &&
  //       !!item?.data?.fields?.length &&
  //       item?.data?.fields?.some((field: any) => !field.read_only)
  //   )
  // );
  return res;
};
