import React from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Typography } from 'antd';
import { ButtonType } from 'antd/es/button/buttonHelpers';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { DropDownMenuButton } from '@components/DropDownMenuButton';

import { useDeleteProductQuery } from '../../hooks';
import { CatalogsModal } from '../CatalogsModal';

interface IProps {
  product_uid: string;
  catalog_uid: string;
  type?: ButtonType;
  manageable?: boolean;
}

export const ProductDropDownMenu: React.FC<IProps> = (props) => {
  const [catalogModalOpen, setCatalogModalOpen] = React.useState(false);
  const catalogsT = useTranslation(NSKeys.catalogs);
  const commonT = useTranslation(NSKeys.common);
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();

  const { mutate, isLoading, status: deleteStatus } = useDeleteProductQuery();

  const handleDelete = () => {
    modal.confirm({
      title: catalogsT.t('modalTitleDelete'),
      centered: true,
      icon: <ExclamationCircleOutlined />,
      onOk: (e) => {
        mutate({ catalog_uid: props.catalog_uid, product_uid: props.product_uid });
      },
      content: catalogsT.t('modalContentDelete'),
      okText: commonT.t('buttonRemove'),
      okButtonProps: { disabled: isLoading },
      cancelText: commonT.t('cancel'),
    });
  };

  React.useEffect(() => {
    if (deleteStatus === 'success' || deleteStatus === 'error') {
      Modal.destroyAll();
    }
  }, [deleteStatus]);

  const handleAddToCatalogFinish = (uid: string) => {
    setCatalogModalOpen(false);
  };

  return (
    <React.Fragment>
      <DropDownMenuButton
        type={props.type || 'link'}
        menu={[
          {
            label: <Typography.Text>{catalogsT.t('list.cardMenu.edit')}</Typography.Text>,
            key: 'editProduct',
            onClick: () => navigate(`/dashboard/catalogs/${props.catalog_uid}/edit/${props.product_uid}`),
          },
          {
            label: <Typography.Text>{catalogsT.t('list.cardMenu.addToAnother')}</Typography.Text>,
            key: 'addToCatalog',
            onClick: () => setCatalogModalOpen(true),
          },
          ...(props?.manageable
            ? [
                {
                  label: <Typography.Text>{catalogsT.t('list.cardMenu.remove')}</Typography.Text>,
                  key: 'removeFromCatalog',
                  onClick: handleDelete,
                },
              ]
            : []),
        ]}
        placement="bottomRight"
      />
      {contextHolder}
      <CatalogsModal
        title={catalogsT.t('modalTitleAdd')}
        open={catalogModalOpen}
        current_catalog_uid={props.catalog_uid}
        product_uid={props.product_uid}
        onCancel={() => setCatalogModalOpen(false)}
        onFinish={handleAddToCatalogFinish}
      />
    </React.Fragment>
  );
};
