import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { CartSchema, RemoveCartItemsDTO } from '@app/models/products';
import Api from '@app/services/api';

interface IProps {
  cart_uid: string;
  catalog_uid: string;
  data: RemoveCartItemsDTO;
}

export const usePostCartRemoveItems = () => {
  const client = useQueryClient();
  const commonT = useTranslation(NSKeys.common);

  return useMutation({
    mutationFn: async ({ data, cart_uid }: IProps) => {
      const res = await Api.products.postRemoveItems(cart_uid, data);
      return res;
    },
    onSuccess: async (response, { catalog_uid, data }) => {
      await client.setQueryData<CartSchema>(['getCart', catalog_uid], (oldData) => {
        return oldData
          ? {
              ...oldData,
              items: oldData.items
                ? oldData.items.filter((item) => !data.item_uids.some((removeId) => removeId === item.uid))
                : null,
              total: response.cart_total,
              items_total: response.cart_items_total,
            }
          : oldData;
      });
      return response;
    },
    onError: () => {
      message.open({
        type: 'error',
        content: commonT.t('error400'),
      });
    },
  });
};
