import { Card } from 'antd';

import styled from 'styled-components';

export const StyledCard = styled(Card)`
  .ant-card-head {
    padding: 12px 16px;
    font-size: 14px;
    line-height: 22px;
    min-height: initial;
    height: 46px;
  }
  .ant-card-body {
    padding: 12px 16px;
    height: 150px;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  .ant-space-item {
    overflow: hidden;
    max-width: 180px;
    line-height: 20px;
  }
`;
