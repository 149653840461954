import { useQuery } from '@tanstack/react-query';

import { IProductsParams } from '@app/models/interfaces';
import Api from '@app/services/api';

interface IParams extends IProductsParams {
  catalog_uid?: string;
}

export const useGetCatalogProductsQueryTable = ({
  catalog_uid,
  limit,
  category_id,
  offset,
  tag_id,
  q,
  ...params
}: IParams) => {
  return useQuery({
    queryKey: ['getCatalogProductsTable', category_id, tag_id, q, offset, limit],
    queryFn: () =>
      Api.products.getCatalogProducts({ offset, category_id, tag_id, q, limit, ...params }, catalog_uid || ''),
  });
};
