import React from 'react';

import { CheckOutlined, MinusOutlined } from '@ant-design/icons';
import { Flex, Typography } from 'antd';

import { Container } from './styles';

interface IProps {
  title: string;
  isColumn?: boolean;
  description?: boolean | string | number | null;
  additionalData?: { title: string; description: string }[];
  descriptionPostfix?: string | number | null | React.ReactNode;
  children?: React.ReactNode;
}

export const ListItem: React.FC<IProps> = (props) => {
  const renderBoolean = (value: boolean) => (value ? <CheckOutlined /> : <MinusOutlined />);

  return (
    <Container $isColumn={props.isColumn}>
      <Flex style={{ width: '100%' }} vertical justify="space-between">
        <Flex justify="space-between">
          <Typography.Text type="secondary" className="listItemTitle">
            {props.title}
          </Typography.Text>
          <Flex vertical={true} justify={'end'}>
            {props.description !== null && typeof props.description !== 'object' && (
              <Typography.Text
                style={{ textAlign: 'end', whiteSpace: 'pre-line', maxHeight: 200, overflow: 'auto' }}
                ellipsis={props.isColumn ? false : { tooltip: props.description }}>
                {typeof props.description === 'boolean' ? (
                  renderBoolean(props.description)
                ) : (
                  <>
                    {props.description || props.description === 0 ? props.description : '—'}{' '}
                    {props.description || props.description === 0 ? props.descriptionPostfix : ''}
                  </>
                )}
              </Typography.Text>
            )}
            {typeof props.description === 'object' && props.description}
            {props.children}
          </Flex>
        </Flex>
        {!!props.additionalData?.length &&
          props.additionalData.map((data, i) => (
            <Flex justify="left" key={i} style={{ margin: '16px 0 0 16px' }}>
              <Typography.Text type="secondary" className="listItemTitle">
                {data.title}:
              </Typography.Text>
              <Typography.Text>{data.description}</Typography.Text>
            </Flex>
          ))}
      </Flex>
    </Container>
  );
};
