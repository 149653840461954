import { Namespace, TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { IFormValues } from '../interfaces';

export const ValidationSchema = (t: TFunction<Namespace<'validation'>>): Yup.SchemaOf<IFormValues> =>
  Yup.object().shape({
    name: Yup.string()
      .required(t('required'))
      .test('len', t('minLength', { length: 3 }), (val) => (val?.length || 0) >= 3),
  });
