import React from 'react';

import { Form, Input } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { ContactsSelect, PhoneInput } from '@components/AntFields';

import { useRecipientContacts } from '../../../../../../providers';
import { ValidationSchema } from '../../validations';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: string;
  children: React.ReactNode;
  onContactSelect?: (options: any, value: string) => void;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  onContactSelect,
  ...restProps
}) => {
  const validationT = useTranslation(NSKeys.validation);
  const { data: contacts } = useRecipientContacts();

  const inputNode = (): React.ReactNode => {
    switch (inputType) {
      case 'contactSelect':
        return (
          <ContactsSelect
            mode="tags"
            maxCount={1}
            placeholder={title}
            disableValues={contacts.map((el) => el.uid || '')}
            onSelect={(value, opt) => {
              onContactSelect && onContactSelect(opt, value);
            }}
          />
        );
      case 'phone':
        return <PhoneInput />;
      default:
        return <Input />;
    }
  };

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  return (
    <td {...restProps}>
      {editing ? (
        <>
          {dataIndex === 'name' && (
            <Form.Item name="uid" style={{ display: 'none' }}>
              <Input />
            </Form.Item>
          )}
          <Form.Item name={dataIndex} rules={[yupSync]}>
            {inputNode()}
          </Form.Item>
        </>
      ) : (
        children
      )}
    </td>
  );
};
