import React from 'react';

import { List } from 'antd';

import { useTranslation } from 'react-i18next';
import Scrollbar from 'react-perfect-scrollbar';

import { NSKeys } from '@app/i18n';
import { ViewProductSchema } from '@app/models/products';

import { ListItem } from './components/ListItem';

interface IProps {
  data: ViewProductSchema;
}

export const Info: React.FC<IProps> = ({ data }) => {
  const nomenclatureInfoT = useTranslation(NSKeys.nomenclatureInfo);

  return (
    <Scrollbar>
      <List>
        <ListItem title={nomenclatureInfoT.t('articul')} description={data.code} />
        <ListItem title={nomenclatureInfoT.t('description')} description={data.description || '—'} />
        <ListItem title={nomenclatureInfoT.t('brand')} description={`${data.brand?.title || '—'}`} />
        {/* <ListItem title={nomenclatureInfoT.t('multiplicity')} description={data.package_amount || '—'} />*/}
        {/* <ListItem*/}
        {/*  title={nomenclatureInfoT.t('dimensions')}*/}
        {/*  description={`${data?.length || '—'}х${data?.width || '—'}х${data?.height || '—'}`}*/}
        {/* />*/}
        {/* <ListItem title={nomenclatureInfoT.t('weight')} description={`${data.weight || '—'}`} />*/}
        {/* {!!data.categories?.length && (*/}
        {/*  <ListItem title={nomenclatureInfoT.t('categories')} description={data.categories?.join(', ')} />*/}
        {/* )}*/}
        {/* {!!data.tags?.length && (*/}
        {/*  <ListItem title={nomenclatureInfoT.t('tags')} description={data.tags?.map((el) => el.title).join(', ')} />*/}
        {/* )}*/}
      </List>
    </Scrollbar>
  );
};
