import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { GroupModal } from '@pages/Groups';

interface IProps {
  onAdd: (uid: string) => void;
  selectedNodes: string[];
}

export const PlusButton: React.FC<IProps> = (props) => {
  const [open, setOpen] = useState(false);

  const handleOk = (uid: string) => {
    setOpen(false);
    props.onAdd(uid);
  };

  return (
    <React.Fragment>
      <Button
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => setOpen(true)}
        style={{ position: 'absolute', width: '30px', left: 'calc(50% - 15px)', zIndex: 1 }}
      />
      <GroupModal open={open} onOk={handleOk} onCancel={() => setOpen(false)} disabledRows={props.selectedNodes} />
    </React.Fragment>
  );
};
