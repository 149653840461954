import React from 'react';

import { theme, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { StyledTag } from './styles';

interface IProps {
  status?: boolean;
}

export const Status: React.FC<IProps> = (props) => {
  const warehousesT = useTranslation(NSKeys.warehouses);
  const { token } = theme.useToken();

  return props.status ? (
    <Tooltip placement="top" title={warehousesT.t('statusActive')}>
      <StyledTag $color={token.green4}>{warehousesT.t('statusActive')}</StyledTag>
    </Tooltip>
  ) : (
    <Tooltip placement="top" title={warehousesT.t('statusInActive')}>
      <StyledTag $color={token.red5}>{warehousesT.t('statusInActive')}</StyledTag>
    </Tooltip>
  );
};
