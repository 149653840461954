import { FC, ReactNode } from 'react';

import { DRAG_HANDLER_CLASSNAME } from './constants';
import { Container } from './styles';

interface IProps {
  children?: ReactNode;
  fixed?: boolean;
}

export const DraggableTitleContainer: FC<IProps> = ({ children, fixed }) => (
  <Container $fixed={fixed} className={DRAG_HANDLER_CLASSNAME}>
    {children}
  </Container>
);
