import React, { FC } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Layout, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { useLocalSelector } from '@app/services/hooks/useRedux';
import { DropDownMenuButton } from '@components/DropDownMenuButton';
import { HeaderTitle } from '@components/HeaderTitle';
import { InfiniteScroll } from '@components/InfiniteScroll';
import { SkeletonImage } from '@components/products';
import { Card } from '@pages/Catalogs/components';

import { CardsContainer } from '@pages/Catalogs/pages/List/styles';

import { useGetCatalogsQuery } from '../../hooks';

export const List: FC = () => {
  const navigate = useNavigate();
  const catalogsT = useTranslation(NSKeys.catalogs);
  const { data, hasNextPage, fetchNextPage, status, isLoading } = useGetCatalogsQuery({ limit: 10 });

  const currentSession = useLocalSelector((state) => state.currentSession);

  const dataSource = React.useMemo(() => {
    return data?.pages?.map((data) => data.items || []).flat() || [];
  }, [data]);

  return (
    <Layout>
      <Layout.Header>
        <Row justify="space-between" align="middle">
          <HeaderTitle title={catalogsT.t('list.title')} />
          <Space size="middle">
            {!!currentSession?.productsContext?.scope?.$permissions?.create_catalogs && (
              <Button type="primary" icon={<PlusOutlined />} onClick={() => navigate('add')} size="middle">
                {catalogsT.t('list.addBtn')}
              </Button>
            )}
          </Space>
        </Row>
      </Layout.Header>
      <Layout className="site-layout">
        <Layout.Content style={{ padding: '24px 0 24px 24px' }}>
          <CardsContainer>
            {!!dataSource.length &&
              dataSource?.map((catalog) => (
                <Card
                  image={catalog?.cover?.variants?.thumbnail?.url}
                  key={catalog.uid}
                  title={catalog.name}
                  warehouseName={catalog?.warehouse?.name}
                  onClick={() => navigate(`${catalog.uid}`)}
                  extra={
                    catalog?.$permissions?.view_settings && (
                      <DropDownMenuButton
                        type="link"
                        menu={[
                          ...(catalog.$permissions.update
                            ? [
                                {
                                  label: <Typography.Text>{catalogsT.t('list.settings')}</Typography.Text>,
                                  key: catalog.uid,
                                  onClick: () => navigate(`edit/${catalog.uid}`),
                                },
                              ]
                            : []),
                        ]}
                        placement="bottomRight"
                      />
                    )
                  }
                />
              ))}
            {isLoading && <SkeletonImage size="m" />}
            {hasNextPage && (
              <InfiniteScroll key={data?.pages?.length || 0} next={fetchNextPage}>
                <SkeletonImage size="m" />
              </InfiniteScroll>
            )}
          </CardsContainer>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};
