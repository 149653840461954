import React, { FC } from 'react';

import { Layout, message, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';

import { HeaderTitle } from '@components/HeaderTitle';

import { useGetProcessesQuery } from '../../hooks';

import { ContainerStyles } from './styles';

export const Container: FC = () => {
  const commonT = useTranslation(NSKeys.common);
  const navigate = useNavigate();
  const { data, status } = useGetProcessesQuery();

  React.useEffect(() => {
    if (data?.items.length && status === 'success') {
      // console.log('redirect', data.items[0].uid);
      navigate(`/dashboard/processes/${data.items[0].uid}/orders`);
    }
    if (status === 'error') {
      navigate('/dashboard');
      message.open({
        type: 'error',
        content: commonT.t('error400'),
      });
    }
  }, [data, status]);

  return (
    <>
      <Layout.Header>
        <Row justify="space-between" align="middle">
          <HeaderTitle title="" loading />
        </Row>
      </Layout.Header>
      <ContainerStyles>
        <Table loading />
      </ContainerStyles>
    </>
  );
};
