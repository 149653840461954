import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';

interface IProps {
  user_group_uid: string;
  user_id: number;
}

export const useDeleteUserQuery = () => {
  const client = useQueryClient();
  const commonT = useTranslation(NSKeys.common);

  return useMutation({
    mutationFn: async ({ user_group_uid, user_id }: IProps) => {
      await Api.users.deleteUsersFromUserGroup(user_group_uid, user_id);
      await client.invalidateQueries(['useGetGroupUsersQuery']);
      await client.invalidateQueries(['getGroups']);
    },
    onError: () => {
      message.open({
        type: 'error',
        content: commonT.t('error400'),
      });
    },
    onSuccess: async () => {
      message.open({
        type: 'success',
        content: commonT.t('dataPatchSuccess'),
      });
    },
  });
};
