import { Typography } from 'antd';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;

  form {
    width: 100%;
  }
`;

export const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
`;

export const Title = styled(Typography.Title)`
  padding: 24px 0 8px 0;
  margin-bottom: 0;
`;

export const Description = styled(Typography.Paragraph)`
  font-size: 14px;
  line-height: 150%;
  padding: 8px 0 32px 0;
  max-width: 291px;
`;
