import { Tabs as AntTabs } from 'antd';
import styled from 'styled-components';

import { Paper } from '@components/Paper';

export const Tabs = styled(AntTabs)<{ $colorBgBase: string }>`
  .ant-tabs-nav {
    background-color: ${({ $colorBgBase }) => $colorBgBase};
    padding: 0 24px;
    margin: 0;
  }

  .ant-tabs-tab {
    svg {
      opacity: 0.5;
    }
  }

  .ant-tabs-tab-active {
    svg {
      opacity: 1;
    }
  }
`;

export const Container = styled(Paper)`
  margin: 24px;
  padding: 20px 24px;
`;
