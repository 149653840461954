import { Tag as AntTag, Input } from 'antd';
import styled from 'styled-components';

export const TagInput = styled(Input)<{
  $colorPrimary: string;
}>`
  height: 28px;
  width: 120px;
  margin-inline-end: 8px;
  vertical-align: top;
  font-size: 12px;
  border-color: ${({ $colorPrimary }) => $colorPrimary};
`;

export const Tag = styled(AntTag)<{
  $colorBgContainer: string;
  $colorPrimary: string;
  $colorTextLightSolid: string;
  $colorFillAlter: string;
  $active: boolean;
}>`
  height: 28px;
  cursor: pointer;
  color: ${({ $colorTextLightSolid, $active }) => $active && $colorTextLightSolid};
  background-color: ${({ $colorBgContainer, $active, $colorPrimary }) => ($active ? $colorPrimary : $colorBgContainer)};
  line-height: 28px;

  :hover {
    background-color: ${({ $colorFillAlter, $active }) => !$active && $colorFillAlter};
  }

  svg {
    fill: ${({ $colorTextLightSolid, $active }) => $active && $colorTextLightSolid};
  }
`;
