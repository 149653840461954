import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { UpdateUserGroupSchema } from '@app/models/users';
import Api from '@app/services/api';

interface IProps {
  data: UpdateUserGroupSchema;
  id: string;
}

export const useUpdateGroupQuery = () => {
  const client = useQueryClient();
  const commonT = useTranslation(NSKeys.common);

  return useMutation({
    mutationFn: async ({ data, id }: IProps) => {
      await Api.users.putUserGroups(id, data);
      await client.invalidateQueries(['getGroups']);
    },
    onError: () => {
      message.open({
        type: 'error',
        content: commonT.t('error400'),
      });
    },
    onSuccess: async () => {
      message.open({
        type: 'success',
        content: commonT.t('dataPatchSuccess'),
      });
    },
  });
};
