import React, { FC } from 'react';

import { Button, Flex, Layout, message, theme, Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';

import { Container, Label } from './styles';

export const Analytics: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(NSKeys.analytics);
  const commonT = useTranslation(NSKeys.common);
  const { token } = theme.useToken();

  return (
    <Layout style={{ backgroundColor: token.colorBgBase }}>
      <Container $colorBgBase={token.colorBgBase} $colorBorder={token.colorBorder} $colorText={token.colorText}>
        <Flex vertical justify="center" align="center" gap={20}>
          <Flex>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              role="presentation"
              width="50"
              height="50"
              viewBox="0 0 1600 1600"
              fill="none">
              <mask
                id="mask0_8592:56198"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="200"
                y="0"
                width="1200"
                height="1600">
                <path
                  d="M1333.25 0C1370.11 0 1400 29.8849 1400 66.75V1533.25C1400 1570.11 1370.11 1600 1333.25 1600H266.667C229.848 1600 200 1570.15 200 1533.33V866.667C200 829.848 229.848 800 266.667 800H525V466.667C525 429.848 554.848 400 591.667 400H850V66.75C850 29.885 879.885 0 916.75 0H1333.25Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_8592:56198)">
                <path
                  d="M1400 66.75L1400 1533.25C1400 1570.11 1370.11 1600 1333.25 1600H916.75C879.885 1600 850 1570.11 850 1533.25L850 66.75C850 29.885 879.885 0 916.75 0H1333.25C1370.12 0 1400 29.8849 1400 66.75Z"
                  fill="url(#paint0_linear_8592:56198)"
                />
                <g filter="url(#filter0_dd_8592:56198)">
                  <path
                    d="M1075 466.667V1600H525V466.667C525 429.848 554.848 400 591.667 400H1008.33C1045.15 400 1075 429.848 1075 466.667Z"
                    fill="url(#paint1_linear_8592:56198)"
                  />
                </g>
                <path
                  d="M200 866.667V1533.33C200 1570.15 229.848 1600 266.667 1600H750V866.667C750 829.848 720.152 800 683.333 800H266.667C229.848 800 200 829.848 200 866.667Z"
                  fill="url(#paint2_linear_8592:56198)"
                />
              </g>
              <defs>
                <filter
                  id="filter0_dd_8592:56198"
                  x="391.667"
                  y="300"
                  width="816.667"
                  height="1466.67"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="6.33333" />
                  <feGaussianBlur stdDeviation="6.33333" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8592:56198" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="33.3333" />
                  <feGaussianBlur stdDeviation="66.6667" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0" />
                  <feBlend mode="normal" in2="effect1_dropShadow_8592:56198" result="effect2_dropShadow_8592:56198" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_8592:56198" result="shape" />
                </filter>
                <linearGradient
                  id="paint0_linear_8592:56198"
                  x1="758.333"
                  y1="-1.49632e-05"
                  x2="1447.82"
                  y2="1507.15"
                  gradientUnits="userSpaceOnUse">
                  <stop stopColor="#E6AD10" />
                  <stop offset="1" stopColor="#C87E0E" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_8592:56198"
                  x1="524.955"
                  y1="400"
                  x2="1105.79"
                  y2="1561.67"
                  gradientUnits="userSpaceOnUse">
                  <stop stopColor="#F6D751" />
                  <stop offset="1" stopColor="#E6AD10" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_8592:56198"
                  x1="199.955"
                  y1="800"
                  x2="519.784"
                  y2="1581.68"
                  gradientUnits="userSpaceOnUse">
                  <stop stopColor="#F9E589" />
                  <stop offset="1" stopColor="#F6D751" />
                </linearGradient>
              </defs>
            </svg>
            <Label>Power BI</Label>
          </Flex>
          <Typography.Text type="secondary" style={{ marginBottom: 10 }}>
            {t('description')}
          </Typography.Text>
          <Flex gap={18}>
            <Button
              type="primary"
              onClick={() => {
                message.open({
                  type: 'error',
                  content: commonT.t('error400'),
                });
              }}>
              {t('loginBtn')}
            </Button>
            <Button
              type="text"
              onClick={() =>
                window.open(
                  'https://app.powerbi.com/singleSignOn?pbi_source=websignup_getstarted_hero&culture=en-us&country=us&ru=https%3A%2F%2Fapp.powerbi.com%2F%3Fpbi_source%3Dwebsignup_getstarted_hero%26culture%3Den-us%26country%3Dus%26noSignUpCheck%3D1'
                )
              }>
              {t('loginBInNewTabBtn')}
            </Button>
          </Flex>
        </Flex>
      </Container>
    </Layout>
  );
};
