import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { RetrieveUserSchema } from '@app/models/users';
import { UserStatus } from '@components/Status/components/UserStatus';

export const baseColumns: ColumnsType<RetrieveUserSchema> = [
  {
    title: '',
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    render: (value, record) => <Avatar src={record?.avatar?.variants?.small?.url || ''} icon={<UserOutlined />} />,
    width: 50,
  },
  {
    title: 'tableColumns.surname',
    dataIndex: 'last_name',
    key: 'last_name',
    ellipsis: true,
  },
  {
    title: 'tableColumns.name',
    dataIndex: 'first_name',
    key: 'first_name',
    ellipsis: true,
  },
  {
    title: 'tableColumns.phone',
    dataIndex: 'phone',
    key: 'phone',
    ellipsis: true,
  },
  {
    title: 'tableColumns.email',
    dataIndex: 'email',
    key: 'email',
    ellipsis: true,
  },
  {
    title: 'tableColumns.jobtitle',
    dataIndex: 'title',
    key: 'title',
    width: 120,
    render: (_, cell) => cell.title,
    ellipsis: true,
  },
  {
    title: 'tableColumns.role',
    dataIndex: 'role',
    key: 'role',
    render: (_, cell) => cell.role?.name,
    ellipsis: true,
  },
  {
    title: 'tableColumns.status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true,
    render: (_, cell) => <UserStatus isActive={cell.is_active} />,
  },
];
