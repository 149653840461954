import React from 'react';

import { Button, Divider, Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { MultipleChangeStatus } from './components/MultipleChangeStatus';

interface IProps {
  selectedRows: string[];
  onDone: () => void;
}

export const RowsActions: React.FC<IProps> = ({ selectedRows, onDone }) => {
  const commonT = useTranslation(NSKeys.common);
  // console.log(selectedRows);

  return (
    <Flex align="center" justify={'flex-start'} style={{ marginTop: 24, position: 'sticky' }}>
      <Typography.Text>
        {selectedRows.length} {commonT.t('selected')}
      </Typography.Text>
      <Divider type="vertical" />
      <MultipleChangeStatus ordersUid={selectedRows} onDone={onDone} />
    </Flex>
  );
};
