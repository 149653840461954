import React from 'react';

import { MoreOutlined } from '@ant-design/icons';
import { Checkbox, Dropdown, theme } from 'antd';

import { CheckboxGroup } from './styles';

interface IProps {
  options: { label: string; value: string }[];
  defaultValue?: string[];
  handleOnChange: (e: any[]) => void;
}

export const TableEditMenu: React.FC<IProps> = (props) => {
  const { token } = theme.useToken();

  return (
    <Dropdown
      trigger={['click']}
      placement="bottomLeft"
      dropdownRender={() => (
        <div
          style={{
            backgroundColor: token.colorBgElevated,
            borderRadius: token.borderRadiusLG,
            boxShadow: token.boxShadowSecondary,
          }}>
          <CheckboxGroup onChange={props.handleOnChange} defaultValue={props?.defaultValue || []}>
            {props.options.map((option, index) => (
              <Checkbox value={option.value} key={index}>
                {option.label}
              </Checkbox>
            ))}
          </CheckboxGroup>
        </div>
      )}>
      <a onClick={(e) => e.preventDefault()}>
        <MoreOutlined style={{ cursor: 'pointer' }} />
      </a>
    </Dropdown>
  );
};
