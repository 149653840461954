import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';

interface IProps {
  approval_chain_uid: string;
  name: string;
}

export const useCloneChainQuery = () => {
  const client = useQueryClient();
  const orderApprovalChainsT = useTranslation(NSKeys.orderApprovalChains);

  return useMutation({
    mutationFn: async ({ approval_chain_uid, name }: IProps) => {
      const res = await Api.users.cloneOrderApprovalChains(approval_chain_uid, name);
      await client.invalidateQueries(['getOrderApprovalChains']);
      return res;
    },
    onError: () => {
      message.open({
        type: 'error',
        content: orderApprovalChainsT.t('errors.cloneError'),
      });
    },
  });
};
