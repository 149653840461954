import React, { FC, useState } from 'react';

import { Button, Form, Input, message, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';
import { AuthorizationFormLayout } from '@layouts/AuthorizationFormLayout';

import { ValidationSchema } from './data/validation';
import { IFormValues } from './interfaces';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 13 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 13 },
};

export const ChangePassword: FC = () => {
  const navigate = useNavigate();
  const [passwordIsUpdated, setPasswordIsUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams<{ userId: string }>();
  const pageT = useTranslation(NSKeys.changePassword);
  const validationT = useTranslation(NSKeys.validation);

  const handleSubmit = async (values: IFormValues): Promise<void> => {
    if (params?.userId) {
      setIsLoading(true);
      await Api.users
        .postResetsUuid(params.userId, { password: values.first_pwd, repeated_password: values.second_pwd })
        .then(() => {
          setPasswordIsUpdated(true);
        })
        .catch(() => {
          message.error(validationT.t('passwordWrong'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  if (isLoading) {
    return (
      <AuthorizationFormLayout title={pageT.t('title')}>
        <Spin />
      </AuthorizationFormLayout>
    );
  }

  if (passwordIsUpdated) {
    return (
      <AuthorizationFormLayout title={pageT.t('title')} description={pageT.t('descriptionSuccess')}>
        <Form.Item {...tailLayout}>
          <Row style={{ width: '100%' }} justify="center">
            <Button type="primary" onClick={() => navigate('/login')}>
              {pageT.t('btnContinue')}
            </Button>
          </Row>
        </Form.Item>
      </AuthorizationFormLayout>
    );
  }

  return (
    <AuthorizationFormLayout title={pageT.t('title')} description={pageT.t('description')}>
      <Form
        name="changePassword_form"
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
        size="large"
        autoComplete="off"
        {...layout}>
        <Form.Item required label={pageT.t('passwordNewPlaceholder')} name="first_pwd" rules={[yupSync]}>
          <Input.Password placeholder={pageT.t('passwordNewLabel')} />
        </Form.Item>
        <Form.Item
          required
          label={pageT.t('passwordRepeatPlaceholder')}
          name="second_pwd"
          rules={[
            yupSync,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('first_pwd') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(validationT.t('passwordNotMatch')));
              },
            }),
          ]}>
          <Input.Password placeholder={pageT.t('passwordRepeatLabel')} />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Row style={{ width: '100%' }} justify="start">
            <Button type="primary" htmlType="submit">
              {pageT.t('btnSubmit')}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </AuthorizationFormLayout>
  );
};
