import React, { useRef } from 'react';

import ReactFlow, { Controls, MiniMap, useNodesInitialized, useReactFlow } from 'reactflow';

import { useConfirmation } from '../../providers/confirmationProvider';
import { FIRST_NODE_BUTTON } from '../../providers/constants';
import { NODE_TYPE } from '../../providers/interfaces';

import { EmptyNode } from './components/EmptyNode';
import { UsersGroupNode } from './components/UsersGroupNode';

const nodeTypes = { [NODE_TYPE.GROUP]: UsersGroupNode, [NODE_TYPE.EMPTY]: EmptyNode };

export const ConfirmationChain: React.FC = () => {
  const { localNodes, localEdges } = useConfirmation();
  const nodesInitialized = useNodesInitialized({});
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const { fitView } = useReactFlow();

  React.useEffect(() => {
    if (nodesInitialized) {
      fitView();
    }
  }, [nodesInitialized]);

  return (
    <div style={{ width: '100%', height: '100%' }} ref={reactFlowWrapper}>
      <ReactFlow
        fitView
        nodes={localNodes.length > 0 ? localNodes : [FIRST_NODE_BUTTON]}
        nodeTypes={nodeTypes}
        edges={localEdges}>
        <Controls />
        <MiniMap />
      </ReactFlow>
    </div>
  );
};
