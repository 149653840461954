import { useQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

export const useGetHistoryQuery = (order_uid: string) => {
  return useQuery({
    queryKey: ['getHistory', order_uid],
    queryFn: () => Api.orders.getHistory(order_uid),
    enabled: !!order_uid,
  });
};
