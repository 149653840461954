import React, { useState } from 'react';

import { Select, SelectProps } from 'antd';

import { useOrderApprovalChainQuery } from './hooks/useOrderApprovalChainQuery';

interface IOption {
  label: string;
  value: string;
}

interface IProps extends SelectProps {
  placeholder: string;
  initialOptions?: IOption[];
}

export const OrderApprovalChainSelect: React.FC<IProps> = ({ placeholder, initialOptions = [], ...props }) => {
  const [searchString, setSearchString] = useState('');
  const [options, setOptions] = useState<IOption[]>(initialOptions);
  const { data, hasNextPage, fetchNextPage, isLoading, isFetching } = useOrderApprovalChainQuery({
    limit: 10,
    name: searchString,
  });

  React.useEffect(() => {
    setOptions(
      data?.pages.flatMap((page) =>
        page.items?.map((el) => ({
          label: `${el.name}`,
          value: el.uid,
        }))
      ) || []
    );
  }, [data]);

  return (
    <Select
      placeholder={placeholder}
      options={options}
      filterOption={false}
      loading={isLoading}
      showSearch
      onSearch={(e) => {
        setOptions([]);
        setSearchString(e);
      }}
      dropdownStyle={{ position: 'fixed' }}
      onPopupScroll={(e: any) => {
        const { target } = e;
        if (target?.scrollTop + 10 + target?.offsetHeight > target?.scrollHeight && hasNextPage && !isFetching) {
          fetchNextPage();
        }
      }}
      {...props}
    />
  );
};
