import { useQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

export const useGetWarehouseSettingsQuery = (uid: string) => {
  return useQuery({
    queryKey: ['getWarehouseSettings', uid],
    queryFn: () => Api.wms.getWarehouseSettings(uid),
    enabled: !!uid,
  });
};
