import React, { FC, useState } from 'react';

import { theme, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useMatches } from 'react-router-dom';

import { NSKeys } from '@app/i18n';

import { Breadcrumb } from './styles';

interface IFetchedTitles {
  // handle name id1, id2...
  id: string;
  value?: string;
}

interface IProps {
  // TODO: use react loader for passing data to crumb
  // Если в пути есть данные с апи, то на странице с компонентом получать эти данные и передавать в id1, id2...
  apiTitles?: IFetchedTitles[];
}

const MAX_LENGTH = 30;

export const Breadcrumbs: FC<IProps> = (props) => {
  const [crumbs, setCrumbs] = useState<any[]>([]);
  const matches = useMatches();
  const { t } = useTranslation(NSKeys.breadcrumbs);
  const { token } = theme.useToken();

  React.useEffect(() => {
    const matchesCrumbs = matches.filter((match) => Boolean(match?.handle));
    setCrumbs(matchesCrumbs);
  }, []);

  const getName = (crumbName: string): string => {
    const filteredCrumb = props.apiTitles?.find((api) => api.id === crumbName);

    return filteredCrumb?.value || crumbName;
  };

  const renderText = (text: string) => {
    if (text.length > MAX_LENGTH) {
      return (
        <Tooltip title={text}>
          <Typography.Text type="secondary">{text.slice(0, MAX_LENGTH)}...</Typography.Text>
        </Tooltip>
      );
    }
    return <Typography.Text type="secondary">{text}</Typography.Text>;
  };

  return (
    <Breadcrumb
      style={{}}
      $colorText={token.colorText}
      items={crumbs.map((crumb) => ({
        title: <Link to={crumb?.pathname}>{renderText(t(getName(crumb.handle?.name) as any))}</Link>,
      }))}
    />
  );
};
