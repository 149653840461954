import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { CartSchema, UpdateCartItemDTO } from '@app/models/products';
import Api from '@app/services/api';

interface IProps {
  catalog_uid: string;
  data: UpdateCartItemDTO;
}

export const usePostUpdateCartItem = () => {
  const client = useQueryClient();
  const commonT = useTranslation(NSKeys.common);

  return useMutation({
    mutationFn: async ({ data, catalog_uid }: IProps) => {
      const res = await Api.products.postUpdateCartItem(catalog_uid, data);
      return res;
    },
    onSuccess: async (response, { catalog_uid }) => {
      await client.setQueryData<CartSchema>(['getCart', catalog_uid], (oldData) => {
        return oldData
          ? {
              ...oldData,
              items: oldData.items
                ? [
                    ...oldData.items.map((el) => {
                      if (el.uid === response.cart_item.uid) {
                        return { ...response.cart_item };
                      } else return el;
                    }),

                    ...(response && oldData?.items?.every((el) => el.product.uid !== response.cart_item.product.uid)
                      ? [response.cart_item]
                      : []),
                  ]
                : null,
              total: response.cart_total,
              items_total: response.cart_items_total,
            }
          : oldData;
      });
      return response;
    },
    onError: () => {
      message.open({
        type: 'error',
        content: commonT.t('error400'),
      });
    },
  });
};
