import React from 'react';

import { Tooltip } from 'antd';

import { StyledTag } from './styles';

interface IProps {
  text: string;
  color: string;
}

export const Status: React.FC<IProps> = ({ text, color }) => {
  return (
    <Tooltip placement="top" title={text}>
      <StyledTag $color={color}>{text}</StyledTag>
    </Tooltip>
  );
};
