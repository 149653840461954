import { combineReducers, configureStore } from '@reduxjs/toolkit';

import currentSession from './currentSessionReducer';
import orders from './ordersReducer';
import products from './productsReducer';

const rootReducer = combineReducers({
  currentSession,
  orders,
  products,
});

const store = configureStore({
  reducer: rootReducer,
});

export type TRootState = ReturnType<typeof rootReducer>;
export type TDispatch = typeof store.dispatch;

export default store;
