import React, { createContext, ReactNode, useContext } from 'react';

import { useLocalStorage } from 'usehooks-ts';

import { ACCOUNT_SETTINGS_ACTIVE_TAB } from '@app/services/appSettings';
import { useLocalSelector } from '@app/services/hooks/useRedux';

import { TABS } from './interfaces';

interface SpaceSettingsType {
  activeTab: TABS;
  isEditing: boolean;
  disabledEdit: boolean;
  setActiveTab: (tab: TABS) => void;
  setEditing: (flag: boolean) => void;
  setDisabledEdit: (flag: boolean) => void;
}

const SpaceContextType = createContext<SpaceSettingsType>(null!);

function SpaceProvider({ children }: { children: ReactNode }) {
  const { data } = useLocalSelector((state) => state.currentSession);
  const [activeTab, setActiveTab] = useLocalStorage(ACCOUNT_SETTINGS_ACTIVE_TAB, TABS.REGIONAL);
  const [isEditing, setEditing] = React.useState(false);
  const [disabledEdit, setDisabledEdit] = React.useState(false);

  const value = {
    activeTab,
    isEditing,
    disabledEdit,
    setActiveTab,
    setEditing,
    setDisabledEdit,
  };

  React.useEffect(() => {
    if (activeTab === TABS.BRAND || activeTab === TABS.REGIONAL) {
      setDisabledEdit(!data?.scope?.$permissions?.update);
    } else {
      setDisabledEdit(false);
    }
  }, [activeTab]);

  return <SpaceContextType.Provider value={value}>{children}</SpaceContextType.Provider>;
}

const useSpace = () => {
  const context = useContext(SpaceContextType);

  if (context === undefined) {
    throw new Error('useRolesState must be used within a RolesProvider');
  }

  return context;
};

export { SpaceContextType, SpaceProvider, useSpace };
