import React from 'react';

import { Carousel, Col, Image, List, Row, Space, Tag, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { SystemSlugs } from '@app/models/dictionaries';
import { ViewProductSchema } from '@app/models/products';
import { DictionaryPostfix } from '@components/DictionaryPostfix';
import { ImageFault } from '@components/ImageFault';
import { ListItem } from '@components/ListItem';
import { Paper } from '@components/Paper';

import { CarouselContainer } from './styles';

interface IProps {
  product: ViewProductSchema;
}

export const SingleProductInfo: React.FC<IProps> = ({ product }) => {
  const nomenclatureInfoT = useTranslation(NSKeys.nomenclatureInfo);
  const { token } = theme.useToken();

  return (
    <Row style={{ padding: 24 }}>
      <Col span={12} style={{ paddingRight: '24px', minHeight: '300px' }}>
        <Paper padding="0" style={{ minHeight: '400px' }}>
          {product.images?.length ? (
            <CarouselContainer $color={token.colorPrimary}>
              <Carousel dots={{ className: 'carousel' }} fade lazyLoad="ondemand" style={{ minHeight: 647 }}>
                {product.images.map((image, index) => (
                  <Image
                    key={index}
                    alt={product.title}
                    src={image.variants.fullscreen?.url}
                    style={{ height: 647, objectFit: 'contain' }}
                  />
                ))}
              </Carousel>
            </CarouselContainer>
          ) : (
            <ImageFault height={647} />
          )}
        </Paper>
      </Col>
      <Col span={12}>
        <Paper>
          <Row justify="space-between" align="middle" style={{ paddingBottom: '18px' }}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {product.title}
            </Typography.Title>
            <Typography.Title level={4} style={{ margin: 0 }}>
              {product.price} <DictionaryPostfix dictionaryType={SystemSlugs.Currencies} />
            </Typography.Title>
          </Row>
          <List>
            <ListItem title={nomenclatureInfoT.t('articul')} description={product.code} />
            <ListItem title={nomenclatureInfoT.t('categories')} description={product.category?.title} />
            {/* <ListItem title={nomenclatureInfoT.t('tags')} description={data.tags?.join(', ')} />*/}
            <ListItem
              title={nomenclatureInfoT.t('dimensions')}
              description={`${product?.length || '—'}х${product?.width || '—'}х${product?.height || '—'}`}
              descriptionPostfix={<DictionaryPostfix dictionaryType={SystemSlugs.DimensionUnits} />}
            />
            <ListItem
              title={nomenclatureInfoT.t('weight')}
              description={product.weight}
              descriptionPostfix={<DictionaryPostfix dictionaryType={SystemSlugs.MassUnits} />}
            />
            <ListItem title={nomenclatureInfoT.t('multiplicity')} description={product.package_amount} />
            <ListItem title={nomenclatureInfoT.t('brand')} description={product?.brand?.title || '—'} />
            <ListItem title={nomenclatureInfoT.t('categories')} description={product?.category?.title || '—'} />
            <ListItem
              title={nomenclatureInfoT.t('volume')}
              description={Number(product.length) * Number(product.height) * Number(product.width)}
              descriptionPostfix={<DictionaryPostfix dictionaryType={SystemSlugs.DimensionUnits} cubicVolume />}
            />
            <ListItem title={nomenclatureInfoT.t('tags')} description=" ">
              <Space size={10} wrap align="end" style={{ justifyContent: 'end' }}>
                {product?.tags?.map((el, i) => (
                  <Tag key={i} style={{ margin: 0 }}>
                    {el.title}
                  </Tag>
                ))}
              </Space>
            </ListItem>
            {/* <ListItem title={nomenclatureInfoT.t('count')} description={product.count} />*/}
            <ListItem title={nomenclatureInfoT.t('description')} description={product.description || ''} isColumn />
          </List>
        </Paper>
      </Col>
    </Row>
  );
};
