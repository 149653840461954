import { FC } from 'react';

import { Button, Form, Input, Row, Typography } from 'antd';
import { Namespace, TFunction, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { NSKeys } from '@app/i18n';

import { LAYOUT, TAIL_LAYOUT } from '../../constants';
import { useCheckEmail } from '../../hooks';
import { IStep1 } from '../../interfaces';

interface IProps {
  initialValues: IStep1;
  onNext: (values: IStep1) => void;
}

const ValidationSchema = (t: TFunction<Namespace<'validation'>>): Yup.SchemaOf<IStep1> =>
  Yup.object().shape({
    email: Yup.string().email(t('emailFormat')).required(t('emailFormat')),
    name: Yup.string().required(t('required')),
    surname: Yup.string().required(t('required')),
    phone: Yup.string().required(t('required')),
  });

export const Step1: FC<IProps> = (props) => {
  const registrationT = useTranslation(NSKeys.registration);
  const validationT = useTranslation(NSKeys.validation);
  const navigate = useNavigate();
  const [form] = Form.useForm<IStep1>();

  const { mutateAsync, isLoading } = useCheckEmail();

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  const handleSubmit = async (data: IStep1) => {
    await mutateAsync(data.email).then((res) => {
      if (res.is_occupied) {
        form.setFields([{ name: 'email', errors: [validationT.t('emailOccupied')] }]);
      } else {
        props.onNext(data);
      }
    });
  };

  return (
    <Form<IStep1>
      name="login_form_step1"
      initialValues={props.initialValues}
      onFinish={handleSubmit}
      size="large"
      form={form}
      {...LAYOUT}>
      <Form.Item required label={registrationT.t('form.username')} name="name" rules={[yupSync]}>
        <Input placeholder={registrationT.t('form.usernamePlaceholder')} />
      </Form.Item>

      <Form.Item required label={registrationT.t('form.surname')} name="surname" rules={[yupSync]}>
        <Input placeholder={registrationT.t('form.surnamePlaceholder')} />
      </Form.Item>

      <Form.Item required label={registrationT.t('form.email')} name="email" rules={[yupSync]}>
        <Input placeholder={registrationT.t('form.emailPlaceholder')} disabled={isLoading} />
      </Form.Item>

      {/* <Form.Item required label={registrationT.t('form.phone')} name="phone" rules={[yupSync]}>*/}
      {/*  <Input placeholder={registrationT.t('form.phonePlaceholder')} />*/}
      {/* </Form.Item>*/}

      <Form.Item {...TAIL_LAYOUT}>
        <Row style={{ width: '100%', paddingBottom: 50 }} justify="end">
          <Button type="primary" htmlType="submit">
            {registrationT.t('btnNext')}
          </Button>
        </Row>
      </Form.Item>

      <Row justify="center" align="middle" style={{ position: 'absolute', bottom: 0, width: '100%' }}>
        <Typography.Text>{registrationT.t('goToLoginText')}</Typography.Text>
        <Button type="link" onClick={() => navigate('/login')}>
          {registrationT.t('goToLoginBtn')}
        </Button>
      </Row>
    </Form>
  );
};
