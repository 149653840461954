import React from 'react';

import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { useAddProductQuery } from '../../hooks';

import { CatalogList } from './components/CatalogList';

interface IProps {
  title: string;
  open: boolean;
  product_uid: string;
  current_catalog_uid?: string;
  onCancel: () => void;
  onFinish: (catalogUid: string) => void;
}

export const CatalogsModal: React.FC<IProps> = (props) => {
  const [selectedCatalog, setSelectedCatalog] = React.useState('');
  const commonT = useTranslation(NSKeys.common);

  const { mutate, isLoading: isProductAdding, status } = useAddProductQuery();

  const handleSelect = (catalog_uid: string) => {
    setSelectedCatalog(catalog_uid);
    if (isProductAdding) {
      return;
    }
    mutate({ catalog_uid, product_uid: props.product_uid });
  };

  React.useEffect(() => {
    if (status === 'success') {
      props.onFinish(selectedCatalog);
    }
  }, [status]);

  return (
    <Modal
      width="75%"
      title={props.title}
      open={props.open}
      cancelText={commonT.t('buttonCancel')}
      okText={commonT.t('confirm')}
      onCancel={props.onCancel}
      styles={{ body: { maxHeight: '70vh', overflow: 'auto' } }}
      footer={[
        <Button key="cancel" onClick={props.onCancel}>
          {commonT.t('cancel')}
        </Button>,
      ]}>
      <React.Fragment>
        <CatalogList onSelect={handleSelect} current_catalog_uid={props.current_catalog_uid} />
      </React.Fragment>
    </Modal>
  );
};
