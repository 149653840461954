import React from 'react';

import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { ButtonType } from 'antd/es/button/buttonHelpers';
import { ItemType, MenuItemType } from 'antd/es/menu/interface';

interface IProps {
  menu: ItemType<MenuItemType>[];
  type?: ButtonType;
  placement?: 'bottomLeft' | 'topLeft' | 'topCenter' | 'topRight' | 'bottomCenter' | 'bottomRight' | 'top' | 'bottom';
}

export const DropDownMenuButton: React.FC<IProps> = ({ menu, type = 'default', placement = 'bottomLeft' }) => {
  if (!menu.length) {
    return null;
  }

  return (
    <Dropdown trigger={['click']} placement={placement} menu={{ items: menu }} openClassName="active">
      <Button icon={<MoreOutlined size={16} />} style={{ width: 32, height: 32 }} type={type} />
    </Dropdown>
  );
};
