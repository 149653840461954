import React, { FC, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Layout, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { useLocalSelector } from '@app/services/hooks/useRedux';
import { HeaderTitle } from '@components/HeaderTitle';

import { GroupTable } from './components/GroupTable';
import { SearchControls } from './components/SearchControls';
import { useGroups } from './providers';
import { Container } from './styles';

export const Groups: FC = () => {
  const { onAddRow, newGroups } = useGroups();
  const { data: currentSessionData } = useLocalSelector((state) => state.currentSession);
  const [searchString, setSearchString] = useState('');
  const commonT = useTranslation(NSKeys.common);
  const groupsT = useTranslation(NSKeys.groups);

  return (
    <>
      <Layout.Header>
        <Row justify="space-between" align="middle">
          <HeaderTitle title={groupsT.t('title')} />
          <Space size="middle">
            {currentSessionData?.scope?.$permissions?.create_user_groups && (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={onAddRow}
                disabled={!!newGroups.length}
                size="middle">
                {commonT.t('buttonAdd')}
              </Button>
            )}
          </Space>
        </Row>
        <SearchControls
          isLoading={false}
          placeholder={commonT.t('search')}
          onSearchChange={setSearchString}
          searchValue={searchString}
          onSearchClick={() => console.log(searchString)}
        />
      </Layout.Header>

      <Container>
        <GroupTable searchString={searchString} />
      </Container>
    </>
  );
};
