import React from 'react';

import { Layout, message, Row, Skeleton, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { SystemSlugs } from '@app/models/dictionaries';
import products from '@app/services/api/products';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { DictionaryPostfix } from '@components/DictionaryPostfix';
import { HeaderTitle } from '@components/HeaderTitle';
import { SingleProductInfo } from '@components/products';
import { useGetCatalogQuery, useGetProductQuery } from '@pages/Catalogs/hooks';

import { ProductDropDownMenu } from '../../../../components/ProductDropDownMenu';

export const SingleProduct: React.FC = () => {
  const params = useParams<{ id: string; productId: string }>();
  const navigate = useNavigate();
  const commonT = useTranslation(NSKeys.common);

  const { data: catalog, isLoading: isCatalogLoading } = useGetCatalogQuery(params.id || '');
  const { data, status, isLoading } = useGetProductQuery({
    catalog_uid: params.id || '',
    product_uid: params?.productId || '',
  });

  React.useEffect(() => {
    if (status === 'error') {
      message.error(commonT.t('dataNotFound'));
      navigate(`/dashboard/catalogs/${params.id}`);
    }
  }, [status]);

  return (
    <Layout>
      <Layout.Header>
        <Breadcrumbs
          apiTitles={[
            { id: ':catalogId', value: catalog?.name },
            { id: ':productId', value: data?.title },
          ]}
        />
        <Row justify="space-between" align="middle">
          <HeaderTitle
            loading={isLoading || isCatalogLoading}
            title={data?.title || ''}
            titleSpan={
              <>
                {data?.price || 0} <DictionaryPostfix dictionaryType={SystemSlugs.Currencies} />
              </>
            }
            onClick={() => navigate(`/dashboard/catalogs/${params.id}`)}
          />
          <Space size="middle">
            <ProductDropDownMenu type="primary" product_uid={params.productId || ''} catalog_uid={params.id || ''} />
          </Space>
        </Row>
      </Layout.Header>
      <Layout.Content>
        {isLoading ? (
          <Skeleton style={{ padding: 24 }} active={true} />
        ) : data ? (
          <SingleProductInfo product={data} />
        ) : null}
      </Layout.Content>
    </Layout>
  );
};
