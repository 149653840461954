import { useInfiniteQuery } from '@tanstack/react-query';

import { IProductsParams } from '@app/models/interfaces';
import Api from '@app/services/api';

interface IParams extends IProductsParams {
  catalog_uid?: string;
}

export const useGetCatalogProductsQuery = ({ catalog_uid, limit, category_id, tag_id, q, ...params }: IParams) => {
  return useInfiniteQuery({
    queryKey: ['getCatalogProducts', { category_id, tag_id, q }],
    queryFn: async ({ pageParam = 0 }) => {
      return await Api.products.getCatalogProducts(
        { offset: pageParam, category_id, tag_id, q, limit, ...params },
        catalog_uid || ''
      );
    },
    getNextPageParam: (current, allPages) => {
      const count = allPages.length * (limit || 1);
      return count >= (current.total || 0) ? undefined : count;
    },
  });
};
