import React from 'react';

import { Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Namespace, TFunction } from 'react-i18next';

import { SystemSlugs } from '@app/models/dictionaries';
import { CartItemSchema } from '@app/models/products';
import { AddRemoveControl } from '@components/AddRemoveContol';
import { DictionaryPostfix } from '@components/DictionaryPostfix';

const renderParagraphWitTooltip = (data: string): JSX.Element => (
  <Typography.Paragraph
    className="paragraphWitTooltip"
    ellipsis={{ tooltip: data }}
    style={{ wordWrap: 'break-word', wordBreak: 'break-word', margin: 0, maxWidth: 200 }}>
    {data}
  </Typography.Paragraph>
);

export const columns = (
  t: TFunction<Namespace<'nomenclatureCart'>>,
  onCountChange: (value: number, cartItem: CartItemSchema) => void
): ColumnsType<CartItemSchema> => [
  {
    title: '№',
    dataIndex: 'uid',
    render: (_, __, index) => index + 1,
    ellipsis: true,
  },
  {
    title: t('tableColumnName'),
    dataIndex: 'name',
    render: (_, el) => renderParagraphWitTooltip(el.product.title),
    sorter: (a, b) => a.product.title.localeCompare(b.product.title),
    ellipsis: true,
  },
  {
    title: t('tableColumnArticul'),
    dataIndex: 'articul',
    render: (_, el) => (el.product.code ? renderParagraphWitTooltip(el.product.code) : '–'),
    ellipsis: true,
  },
  // {
  //   title: t('tableColumnMultiplicity'),
  //   dataIndex: 'multiplicity',
  //   align: 'right',
  //   ellipsis: true,
  // },
  {
    title: (
      <>
        {t('tableColumnVolume')} <DictionaryPostfix dictionaryType={SystemSlugs.DimensionUnits} cubicVolume />
      </>
    ),
    dataIndex: 'volume',
    align: 'right',
    ellipsis: true,
  },
  {
    title: (
      <>
        {t('tableColumnWeight')} <DictionaryPostfix dictionaryType={SystemSlugs.MassUnits} />
      </>
    ),
    dataIndex: 'weight',
    align: 'right',
    ellipsis: true,
  },
  {
    title: t('tableColumnCartCount'),
    dataIndex: 'cart_count',
    key: 'cart_count',
    width: '190px',
    align: 'center',
    render: (_, el) => (
      <AddRemoveControl value={el.quantity} isSmall={true} onInputChange={(value) => onCountChange(value, el)} />
    ),
  },
  {
    title: (
      <>
        {t('tableColumnPrice')} (<DictionaryPostfix dictionaryType={SystemSlugs.Currencies} />)
      </>
    ),
    dataIndex: 'price',
    align: 'right',
    ellipsis: true,
  },
];
