import { Card } from 'antd';

import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  float: left;
  margin: 0 24px 24px 0;
  padding-top: 1px;
  width: 254px;
  height: 254px;

  .ant-card-body {
    padding: 16px;
  }

  .ant-card-cover {
    position: relative;
    width: calc(100% - 2px);
    margin-left: 1px;

    .ant-dropdown-trigger {
      width: 100%;
      justify-content: end;
    }
  }
`;

export const CardStyled = styled(Card)<{
  $ishover: boolean;
  $disabled?: boolean;
}>`
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 254px;
  z-index: ${({ $ishover }) => $ishover && '17'};

  opacity: ${({ $disabled }) => $disabled && '0.5'};
  cursor: ${({ $disabled }) => $disabled && 'not-allowed'};

  .ant-card-head {
    border-bottom: none;
    padding: 0 8px;
    min-height: 34px;
  }

  .ant-card-body {
    display: ${({ $ishover }) => $ishover && 'block'};
    overflow: ${({ $ishover }) => ($ishover ? 'auto' : 'hidden')};
  }

  .ant-card-actions {
    padding: 0 24px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;
