import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  line-height: 60px;
`;

export const Actions = styled.div`
  margin-left: 14px;

  svg {
    cursor: pointer;
  }
`;
