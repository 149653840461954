import React, { FC } from 'react';

import { Form, FormInstance, Input, Radio, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { CatalogVisibilityRule, OrderApprovalRule } from '@app/models/products';
import { stringToBoolean } from '@app/services/stringToBoolean';
import {
  GroupsSelect,
  OrderApprovalChainSelect,
  ProcessSelect,
  Upload,
  UsersSelect,
  WarehouseSelect,
} from '@components/AntFields';
import { FormTitle } from '@components/FormTitle';
import { Paper } from '@components/Paper';

import { ValidationSchema } from './data/validation';
import { IFormValues } from './interfaces';

interface IProps {
  initialValues?: Partial<IFormValues>;
  initial_user_group_options?: { label: string; value: string }[];
  initial_order_approval_chain_options?: { label: string; value: string }[];
  onFinish: (formValues: IFormValues) => void;
  form: FormInstance;
}

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export const FormCatalog: FC<IProps> = (props) => {
  const validationT = useTranslation(NSKeys.validation);
  const catalogsT = useTranslation(NSKeys.catalogs);

  const orderApprovalRule = Form.useWatch('order_approval_rule', props.form);
  const visibilityRule = Form.useWatch('visibility_rule', props.form);

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  return (
    <Paper style={{ margin: 24, padding: '20px 24px' }}>
      <FormTitle title={catalogsT.t('form.groupInfo')} />
      <Form<IFormValues>
        form={props.form}
        initialValues={props?.initialValues}
        name="catalog_form"
        id="catalogForm"
        onFinish={props.onFinish}
        autoComplete="off"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}>
        <Form.Item<IFormValues> name="name" required label={catalogsT.t('form.nameLabel')} rules={[yupSync]}>
          <Input placeholder={catalogsT.t('form.namePlaceholder')} />
        </Form.Item>

        <Form.Item<IFormValues> name="process_uid" label={catalogsT.t('form.processLabel')} rules={[yupSync]}>
          <ProcessSelect placeholder={catalogsT.t('form.processPlaceholder')} allowClear />
        </Form.Item>

        <Form.Item<IFormValues>
          name="media"
          label={catalogsT.t('form.coverLabel')}
          valuePropName="fileList"
          getValueFromEvent={normFile}>
          <Upload
            apiPath="products/catalogs/upload-cover"
            listType="picture-card"
            name="media"
            multiple={false}
            showUploadList={{ showRemoveIcon: false }}
            maxCount={1}
          />
        </Form.Item>

        {stringToBoolean(process.env.REACT_APP_FEATURE_WAREHOUSES) && (
          <>
            <FormTitle title={catalogsT.t('form.groupWarehouse')} />
            <Form.Item<IFormValues> name="warehouse_uid" label={catalogsT.t('form.warehouseLabel')} rules={[yupSync]}>
              <WarehouseSelect placeholder={catalogsT.t('form.warehousePlaceholder')} />
            </Form.Item>
          </>
        )}

        <FormTitle title={catalogsT.t('form.groupInfo')} />
        <Form.Item<IFormValues> name="visibility_rule" label={catalogsT.t('form.visibilityLabel')} rules={[yupSync]}>
          <Select
            placeholder={catalogsT.t('form.visibilityPlaceholder')}
            options={(Object.keys(CatalogVisibilityRule) as Array<keyof typeof CatalogVisibilityRule>).map((key) => ({
              label: catalogsT.t(`form.visibility.${CatalogVisibilityRule[key]}` as any),
              value: CatalogVisibilityRule[key],
            }))}
          />
        </Form.Item>
        {visibilityRule === CatalogVisibilityRule.VisibleToUsers && (
          <Form.Item<IFormValues>
            name="visible_to_user_ids"
            label={catalogsT.t('form.visibilityUsersLabel')}
            rules={[yupSync]}
            required>
            <UsersSelect mode="multiple" placeholder={catalogsT.t('form.visibilityUsersPlaceholder')} />
          </Form.Item>
        )}

        {visibilityRule === CatalogVisibilityRule.VisibleToGroups && (
          <Form.Item<IFormValues>
            name="visible_to_user_group_uids"
            label={catalogsT.t('form.visibilityGroupsLabel')}
            rules={[yupSync]}
            required>
            <GroupsSelect
              mode="multiple"
              initialOptions={props.initial_user_group_options}
              placeholder={catalogsT.t('form.visibilityGroupsPlaceholder')}
            />
          </Form.Item>
        )}

        <FormTitle title={catalogsT.t('form.groupOrder')} />
        <Form.Item<IFormValues> name="order_approval_rule" label={catalogsT.t('form.orderApprovalRuleLabel')}>
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={OrderApprovalRule.OrderApprovalChain}>
              {catalogsT.t('form.orderApprovalRule.order_approval_chain')}
            </Radio.Button>
            <Radio.Button value={OrderApprovalRule.NotNeeded}>
              {catalogsT.t('form.orderApprovalRule.not_needed')}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        {orderApprovalRule === OrderApprovalRule.OrderApprovalChain && (
          <Form.Item<IFormValues>
            name="order_approval_chain_uid"
            label={catalogsT.t('form.orderApprovalChainLabel')}
            rules={[yupSync]}
            required>
            <OrderApprovalChainSelect
              placeholder={catalogsT.t('form.orderApprovalChainPlaceholder')}
              initialOptions={props.initial_order_approval_chain_options}
            />
          </Form.Item>
        )}
      </Form>
    </Paper>
  );
};
