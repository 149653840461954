import { Namespace, TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { IFormValues } from './interfaces';

export const ValidationSchema = (t: TFunction<Namespace<'validation'>>): Yup.SchemaOf<IFormValues> =>
  Yup.object().shape({
    name: Yup.mixed().required(t('required')),
    code: Yup.string().required(t('required')),
    retailer_uid: Yup.string(),
    type_uid: Yup.string(),
    tags_uids: Yup.array(),
  });
