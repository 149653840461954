import React, { FC, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';

import { Button, Layout, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { TableParams } from '@app/models/interfaces';
import { useLocalSelector } from '@app/services/hooks/useRedux';
import { HeaderTitle } from '@components/HeaderTitle';
import { Paper } from '@components/Paper';

import { useGetWarehousesQuery } from '../../hooks';

import { BASE_COLUMNS_WIP } from './constants';

const INITIAL_TABLE_PARAMS = {
  pagination: {
    current: 1,
    pageSize: 10,
  },
};

export const List: FC = () => {
  const navigate = useNavigate();
  const warehousesT = useTranslation(NSKeys.warehouses);
  const [tableParams, setTableParams] = useState<TableParams>(INITIAL_TABLE_PARAMS);
  const commonT = useTranslation(NSKeys.common);
  const currentSession = useLocalSelector((state) => state.currentSession);

  const { data, isLoading } = useGetWarehousesQuery({
    offset:
      ((tableParams.pagination?.current || INITIAL_TABLE_PARAMS.pagination.current) - 1) *
      (tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize),
    limit: tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize,
  });

  return (
    <>
      <Layout.Header>
        <Row justify="space-between" align="middle">
          <HeaderTitle title={warehousesT.t('title')} />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate('add')}
            size="middle"
            disabled={!currentSession?.warehousesContext?.scope?.$permissions?.create_warehouses}>
            {warehousesT.t('addButton')}
          </Button>
        </Row>
      </Layout.Header>

      <Paper style={{ margin: '24px', padding: '16px 24px 0 24px' }}>
        <Table
          dataSource={data?.items}
          rowKey="uid"
          className="tableClassname"
          columns={[...BASE_COLUMNS_WIP.map((el) => ({ ...el, title: warehousesT.t(el.title as any) }))]}
          rowClassName="cursorPointer"
          style={{ overflow: 'auto' }}
          onChange={(pagination) => {
            setTableParams({
              pagination,
            });
          }}
          onRow={(row) => {
            return {
              onClick: () => !!row?.$permissions?.view && navigate(`${row.uid}`),
            };
          }}
          loading={isLoading}
          scroll={{ x: true }}
          pagination={{
            ...tableParams.pagination,
            total: data?.total,
            showQuickJumper: true,
            showSizeChanger: true,
            position: ['bottomCenter'],
            showTotal: (total, range) => `${range[0]}-${range[1]} ${commonT.t('paginationOf')} ${total}`,
          }}
        />
      </Paper>
    </>
  );
};
