import React, { FC } from 'react';

import { Button, Layout, theme, Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';

import { Container } from './styles';

export const NotFound: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(NSKeys.notFound);
  const { token } = theme.useToken();

  return (
    <Layout style={{ backgroundColor: token.colorBgBase }}>
      <Container $colorBgBase={token.colorBgBase} $colorBorder={token.colorBorder} $colorText={token.colorText}>
        <div className="boo">
          <div className="face" />
        </div>
        <div className="shadow" />
        <Typography.Title level={1}>{t('title')}</Typography.Title>
        <Typography.Title level={3}>{t('description')}</Typography.Title>
        <Button onClick={() => navigate('/dashboard')}>{t('btnBack')}</Button>
      </Container>
    </Layout>
  );
};
