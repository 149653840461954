import { Layout as AntLayout } from 'antd';
import styled from 'styled-components';

export const Layout = styled(AntLayout)`
  padding: 24px;

  .imgContainer {
    height: calc(100vh - 48px);
  }

  .backGroundImg {
    position: relative;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }
`;
