import React from 'react';

import { CloseOutlined } from '@ant-design/icons';

import { Button, Popconfirm, theme } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { useDeleteAccessSettingsGroup } from '../../../../../../hooks';

interface IDeleteGroupProps {
  group_uid: string;
  isActive?: boolean;
}

export const DeleteAccessSettingsGroup: React.FC<IDeleteGroupProps> = ({ group_uid, isActive }) => {
  const { token } = theme.useToken();
  const commonT = useTranslation(NSKeys.common);

  const { mutate, status } = useDeleteAccessSettingsGroup();

  return (
    <Popconfirm
      title={commonT.t('deleteConfirm')}
      onConfirm={(e) => {
        e?.stopPropagation();
        mutate({ group_uid });
      }}
      okText={commonT.t('yes')}>
      <Button
        loading={status === 'loading'}
        type={'text'}
        size="small"
        icon={<CloseOutlined style={{ color: isActive ? 'white' : token.colorText, fontSize: 12 }} />}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </Popconfirm>
  );
};
