import React, { FC, useState } from 'react';

import { Button, Form, message, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';
import { useLocalDispatch, useLocalSelector } from '@app/services/hooks/useRedux';
import { getAppInitialData } from '@app/store/currentSessionReducer';
import { AuthorizationFormLayout } from '@layouts/AuthorizationFormLayout';

const tailLayout = {
  wrapperCol: { offset: 6, span: 13 },
};

export const Confirm: FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const pageT = useTranslation(NSKeys.confirmation);
  const commonT = useTranslation(NSKeys.common);
  const dispatch = useLocalDispatch();
  const currentSession = useLocalSelector((state) => state.currentSession);
  const [isEmailConfirmed, setEmailConfirmed] = useState(false);

  const handleConfirm = async (id: string) => {
    await Api.users
      .postConfirmationId(id)
      .then(() => {
        setEmailConfirmed(true);
        navigate('/dashboard/profile');
        dispatch(getAppInitialData());
        message.success(pageT.t('success'), 300);
      })
      .catch(() => {
        message.error(commonT.t('error400'));
      });
  };

  React.useEffect(() => {
    if (params.id) {
      handleConfirm(params.id);
    }
  }, [params.id]);

  if (isEmailConfirmed) {
    return (
      <AuthorizationFormLayout title={pageT.t('title')} description={pageT.t('success')}>
        <Form.Item {...tailLayout}>
          <Row style={{ width: '100%' }} justify="center">
            <Button type="primary" onClick={() => navigate('/login')}>
              {pageT.t('btnSubmit')}
            </Button>
          </Row>
        </Form.Item>
      </AuthorizationFormLayout>
    );
  }

  return (
    <AuthorizationFormLayout title={pageT.t('title')}>
      <Form.Item {...tailLayout}>
        <Row justify="center" style={{ marginTop: '30px' }}>
          <Spin />
        </Row>
      </Form.Item>
    </AuthorizationFormLayout>
  );
};
