import React from 'react';

import { Col, Form, FormInstance, Input, InputNumber, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { SystemSlugs } from '@app/models/dictionaries';
import { SystemDictionarySlugs } from '@app/models/products';
import { Upload } from '@components/AntFields';
import { DictionaryPostfix } from '@components/DictionaryPostfix';
import { Paper } from '@components/Paper';

import { CreatedSelect } from '../CreatedSelect';

import { ValidationSchema } from './data/validation';
import type { IFormValues } from './interfaces';
import { FiledColDivider } from './styles';

interface IProps {
  onFinish: (formValues: IFormValues) => void;
  submitting: boolean;
  initialValues?: IFormValues;
  form: FormInstance<IFormValues>;
}
const normFile = (e: any) => {
  console.log(e);
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
export const ProductForm: React.FC<IProps> = (props) => {
  const nomenclatureFormT = useTranslation(NSKeys.nomenclatureForm);
  const validationT = useTranslation(NSKeys.validation);

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  return (
    <Form
      form={props.form}
      initialValues={props.initialValues}
      name="product_form"
      onFinish={props.onFinish}
      autoComplete="off"
      labelCol={{ sm: 5 }}
      wrapperCol={{ sm: 13 }}>
      <Row gutter={24}>
        <Col sm={24} md={8}>
          <Form.Item name="image_uids" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
            <Upload
              apiPath="products/products/upload-image"
              listType="picture-card"
              name="media"
              multiple={false}
              maxCount={10}
            />
          </Form.Item>

          <Form.Item
            name="description"
            label={nomenclatureFormT.t('descriptionLabel')}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ minWidth: '100%' }}
            rules={[yupSync]}>
            <Input.TextArea
              rows={4}
              showCount
              placeholder={nomenclatureFormT.t('descriptionPlaceholder')}
              maxLength={1000}
            />
          </Form.Item>
        </Col>
        <Col sm={24} md={16} className="formContainer">
          <Paper>
            <Form.Item required name="title" label={nomenclatureFormT.t('nameLabel')} rules={[yupSync]}>
              <Input.TextArea rows={2} showCount placeholder={nomenclatureFormT.t('namePlaceholder')} maxLength={64} />
            </Form.Item>
            <Form.Item name="code" label={nomenclatureFormT.t('articulLabel')} rules={[yupSync]}>
              <Input placeholder={nomenclatureFormT.t('articulPlaceholder')} />
            </Form.Item>
            <Form.Item name="price" label={nomenclatureFormT.t('priceLabel')} rules={[yupSync]}>
              <InputNumber
                placeholder={JSON.stringify(nomenclatureFormT.t('pricePlaceholder'))}
                addonAfter={<DictionaryPostfix dictionaryType={SystemSlugs.Currencies} />}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item label={nomenclatureFormT.t('dimensionsLabel')}>
              <Row justify="start" style={{ gap: 7 }}>
                <Form.Item name="length" style={{ marginBottom: 0 }} rules={[yupSync]}>
                  <InputNumber
                    placeholder={JSON.stringify(nomenclatureFormT.t('dimensionsPlaceholder'))}
                    addonAfter={<DictionaryPostfix dictionaryType={SystemSlugs.DimensionUnits} />}
                    style={{ maxWidth: '120px' }}
                  />
                </Form.Item>
                <FiledColDivider>х</FiledColDivider>
                <Form.Item name="width" style={{ marginBottom: 0 }} rules={[yupSync]}>
                  <InputNumber
                    placeholder={JSON.stringify(nomenclatureFormT.t('dimensionsPlaceholder'))}
                    addonAfter={<DictionaryPostfix dictionaryType={SystemSlugs.DimensionUnits} />}
                    style={{ maxWidth: '120px' }}
                  />
                </Form.Item>
                <FiledColDivider>х</FiledColDivider>
                <Form.Item name="height" style={{ marginBottom: 0 }} rules={[yupSync]}>
                  <InputNumber
                    placeholder={JSON.stringify(nomenclatureFormT.t('dimensionsPlaceholder'))}
                    addonAfter={<DictionaryPostfix dictionaryType={SystemSlugs.DimensionUnits} />}
                    style={{ maxWidth: '120px' }}
                  />
                </Form.Item>
              </Row>
            </Form.Item>
            <Form.Item name="weight" label={nomenclatureFormT.t('dimensionsWeightLabel')} rules={[yupSync]}>
              <InputNumber
                placeholder={JSON.stringify(nomenclatureFormT.t('dimensionsWeightPlaceholder'))}
                addonAfter={<DictionaryPostfix dictionaryType={SystemSlugs.MassUnits} />}
                style={{ width: '120px' }}
              />
            </Form.Item>
            <Form.Item
              name="package_amount"
              label={nomenclatureFormT.t('dimensionsMultiplicityLabel')}
              rules={[yupSync]}>
              <InputNumber
                placeholder={JSON.stringify(nomenclatureFormT.t('dimensionsMultiplicityPlaceholder'))}
                formatter={(value) => `${value ? Math.round(Number(value)) : value}`}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <CreatedSelect
              form={props.form}
              removable
              required={false}
              rules={[yupSync]}
              name="brand_id"
              dictionaryName={SystemDictionarySlugs.Brands}
              label={nomenclatureFormT.t('brandLabel')}
              placeholder={nomenclatureFormT.t('brandPlaceholder')}
            />
            <CreatedSelect
              form={props.form}
              removable
              required={false}
              rules={[yupSync]}
              name="category_id"
              dictionaryName={SystemDictionarySlugs.Categories}
              label={nomenclatureFormT.t('categoryLabel')}
              placeholder={nomenclatureFormT.t('categoryPlaceholder')}
            />
            <CreatedSelect
              form={props.form}
              removable
              required={false}
              rules={[yupSync]}
              mode="multiple"
              name="tags_ids"
              dictionaryName={SystemDictionarySlugs.Tags}
              label={nomenclatureFormT.t('tagLabel')}
              placeholder={nomenclatureFormT.t('tagPlaceholder')}
            />
          </Paper>
        </Col>
      </Row>
    </Form>
  );
};
