import { useQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

export const useGetRecipient = (uid: string) => {
  return useQuery({
    queryKey: ['getRecipient', uid],
    queryFn: () => Api.dictionaries.getRecipient(uid),
    onSuccess: (data) => {
      return {
        uid: data.uid,
        name: data.name,
        code: data.code,
        address: {
          as_string: data?.address?.as_string,
        },
        retailer: data?.retailer,
        type: data?.type,
        $permissions: data?.$permissions,
      };
    },
    enabled: !!uid,
  });
};
