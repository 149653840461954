import React from 'react';

import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { Col, Modal, Row, Typography } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { useDeleteRoleQuery } from '../../../../../../hooks';
import { useRoles } from '../../../../RolesProvider';

import { Button } from './styles';

interface IProps {
  name: string;
  role_id: string;
}

export const DeleteButton: React.FC<IProps> = (props) => {
  const commonT = useTranslation(NSKeys.common);
  const spaceT = useTranslation(NSKeys.spaceSettings);
  const [isModalOpen, setModalOpen] = React.useState(false);
  const { fetchData } = useRoles();
  const { mutate, status, isLoading } = useDeleteRoleQuery();

  const handleCancel = () => {
    setModalOpen(false);
  };
  const handleOk = () => {
    mutate({ role_id: props.role_id });
  };

  React.useEffect(() => {
    if (status === 'success') {
      fetchData();
      setModalOpen(false);
    }
  }, [status]);

  return (
    <React.Fragment>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setModalOpen(true);
        }}
        type="text"
        icon={<CloseOutlined style={{ fontSize: '10px' }} />}
        size="small"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '-5px' }}
      />
      <Modal
        confirmLoading={isLoading}
        open={isModalOpen}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {commonT.t('buttonCancel')}
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk} disabled={isLoading}>
            {commonT.t('buttonRemove')}
          </Button>,
        ]}>
        <Row style={{ paddingBottom: 24, paddingTop: 32 }}>
          <Col span={3}>
            <ExclamationCircleOutlined style={{ color: '#FAAD14', fontSize: 25, margin: '0 16px 0 8px' }} />
          </Col>
          <Col span={21}>
            <Row style={{ marginBottom: 10 }}>
              <Typography.Title level={4}>{spaceT.t('alertTitleRemove')}</Typography.Title>
            </Row>
            <Row>
              <Typography.Text type="secondary">
                {spaceT.t('alertDescriptionRemove')} {props.name}?
              </Typography.Text>
            </Row>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  );
};
