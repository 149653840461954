import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 40px;
  margin-right: 17px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 10px;
  max-height: 230px;
  overflow: auto;

  .ant-space-item {
    line-height: 20px;
    overflow: hidden;
  }
`;
