import React, { FC } from 'react';

import { Form, FormInstance, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { PhoneInput } from '@components/AntFields';
import { Paper } from '@components/Paper';

import { IFormValues } from '../../interfaces';

import { RolesSelect } from './components/RolesSelect';
import { ValidationSchema } from './data/validation';

interface IProps {
  initialValues?: Partial<IFormValues>;
  onFinish: (formValues: IFormValues) => void;
  form: FormInstance;
}

export const UserForm: FC<IProps> = (props) => {
  const validationT = useTranslation(NSKeys.validation);
  const employeesT = useTranslation(NSKeys.employees);

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  return (
    <Paper style={{ margin: 24, padding: '20px 24px' }}>
      {/* <FormTitle title={employeesT.t('form.groupEdit')} />*/}
      <Form
        form={props.form}
        initialValues={props?.initialValues}
        name="edit_user_form"
        onFinish={props.onFinish}
        autoComplete="off"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}>
        <Form.Item name="first_name" required label={employeesT.t('form.nameLabel')} rules={[yupSync]}>
          <Input placeholder={employeesT.t('form.namePlaceholder')} />
        </Form.Item>
        <Form.Item name="last_name" required label={employeesT.t('form.surnameLabel')} rules={[yupSync]}>
          <Input placeholder={employeesT.t('form.surnamePlaceholder')} />
        </Form.Item>
        <Form.Item name="email" required label={employeesT.t('form.emailLabel')} rules={[yupSync]}>
          <Input placeholder={employeesT.t('form.emailPlaceholder')} />
        </Form.Item>
        <Form.Item name="phone" required label={employeesT.t('form.phoneLabel')} rules={[yupSync]}>
          <PhoneInput />
        </Form.Item>
        <Form.Item name="title" required label={employeesT.t('form.jobtitleLabel')} rules={[yupSync]}>
          <Input placeholder={employeesT.t('form.jobtitlePlaceholder')} />
        </Form.Item>
        <RolesSelect name="role_pk" />
      </Form>
    </Paper>
  );
};
