import React from 'react';

import { AppstoreFilled, BarsOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Form, Input, Radio, Row, theme } from 'antd';
import { useTranslation } from 'react-i18next';

import { useLocalStorage } from 'usehooks-ts';

import { NSKeys } from '@app/i18n';
import { SystemDictionarySlugs } from '@app/models/products';
import { MATERIALS_LIST_TYPE } from '@app/services/appSettings';
import { useLocalDispatch, useLocalSelector } from '@app/services/hooks/useRedux';
import { setFilters } from '@app/store/productsReducer';
import { TableControlsContainer } from '@components/TableControlsContainer';

import { DictionarySelect } from './components/DictionarySelect';
import { FilterEditMenu } from './components/FilterEditMenu';

import { Filters } from './interfaces';
import { Container } from './styles';

interface IProps {
  isLoading?: boolean;
}

export const SearchControls: React.FC<IProps> = (props) => {
  const filterSearchString = useLocalSelector((state) => state.products.filterSearchString);
  const filterTags = useLocalSelector((state) => state.products.filterTags);
  const filterCategories = useLocalSelector((state) => state.products.filterCategories);
  const { token } = theme.useToken();

  const [search, setSearchState] = React.useState(filterSearchString);
  const [selectedFilters, setSelectedFilters] = React.useState<Filters[]>([]);
  const dispatch = useLocalDispatch();
  const nomenclatureListT = useTranslation(NSKeys.nomenclatureList);
  const commonT = useTranslation(NSKeys.common);
  const FILTERS = [
    { value: Filters.CATEGORIES, label: nomenclatureListT.t('searchCategories') },
    { value: Filters.TAGS, label: nomenclatureListT.t('searchTags') },
  ];
  const [listType, setListType] = useLocalStorage(MATERIALS_LIST_TYPE, 'grid');

  React.useEffect(
    () => () => {
      dispatch(setFilters({ filterSearchString: '', filterTags: [], filterCategories: [] }));
    },
    []
  );

  React.useEffect(() => setSearchState(filterSearchString), [filterSearchString]);

  const handleSearchClick = (): void => {
    dispatch(
      setFilters({
        filterSearchString: search,
        ...(!selectedFilters.find((el) => el === Filters.CATEGORIES) && { filterCategories: [] }),
        ...(!selectedFilters.find((el) => el === Filters.TAGS) && { filterTags: [] }),
      })
    );
  };

  const handleReset = () => {
    setSelectedFilters([]);
    if (filterTags.length || filterCategories.length || filterSearchString) {
      setSearchState('');
      dispatch(setFilters({ filterSearchString: '', filterTags: [], filterCategories: [] }));
    }
  };

  return (
    <TableControlsContainer showBadge={!!filterTags.length || !!filterCategories.length || !!filterSearchString}>
      <React.Fragment>
        <Flex justify="end" wrap="wrap" gap={16} style={{ marginBottom: 16 }}>
          <FilterEditMenu filters={FILTERS} selectedFilters={selectedFilters} onChange={setSelectedFilters} />

          <Input.Search
            style={{ maxWidth: 240 }}
            size="middle"
            value={search}
            onChange={(e) => {
              setSearchState(e.target.value);
            }}
            placeholder={nomenclatureListT.t('searchButton')}
            enterButton
            disabled={props.isLoading}
            onSearch={handleSearchClick}
          />

          <Radio.Group defaultValue={listType} buttonStyle="solid" onChange={(e) => setListType(e.target.value)}>
            <Radio.Button value="table">
              <BarsOutlined />
            </Radio.Button>
            <Radio.Button value="grid">
              <AppstoreFilled twoToneColor={token.colorPrimary} />
            </Radio.Button>
          </Radio.Group>
        </Flex>
        {!!selectedFilters.length && (
          <Flex gap={16} wrap="wrap">
            {selectedFilters.map((filter, index) => (
              <Col style={{ width: 250 }} key={`filter-${index}`}>
                <Form.Item style={{ marginBottom: 0 }}>
                  {filter === Filters.CATEGORIES && (
                    <DictionarySelect
                      value={filterCategories}
                      placeholder={nomenclatureListT.t('searchCategories')}
                      dictionaryName={SystemDictionarySlugs.Categories}
                      onSelect={(data) => dispatch(setFilters({ filterCategories: data }))}
                    />
                  )}
                  {filter === Filters.TAGS && (
                    <DictionarySelect
                      value={filterTags}
                      placeholder={nomenclatureListT.t('searchTags')}
                      dictionaryName={SystemDictionarySlugs.Tags}
                      onSelect={(data) => dispatch(setFilters({ filterTags: data }))}
                    />
                  )}
                </Form.Item>
              </Col>
            ))}

            <Form.Item style={{ marginBottom: 10 }}>
              <Button type="link" icon={<DeleteOutlined />} onClick={handleReset}>
                {commonT.t('buttonReset')}
              </Button>
            </Form.Item>
          </Flex>
        )}
      </React.Fragment>
    </TableControlsContainer>
  );
};
