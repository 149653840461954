import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';

import { RetrieveUserSchema } from '@app/models/users';
import Api from '@app/services/api';

interface EventContextType {
  data?: RetrieveUserSchema;
  loading: boolean;
}

const EmployeeContext = createContext<EventContextType>(null!);

function EmployeeProvider({ children }: { children: ReactNode }) {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<RetrieveUserSchema>();
  const value = { loading, data };
  const commonT = useTranslation(NSKeys.common);

  const goBack = () => navigate('/dashboard/events');

  useEffect(() => {
    if (params.id) {
      setLoading(true);
      Api.users
        .getUser(params.id)
        .then((res) => {
          setData(res);
        })
        .catch(() => {
          message.error(commonT.t('dataNotFound'));
          goBack();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      message.error(commonT.t('dataNotFound'));
      goBack();
    }
  }, [location.pathname]);

  return <EmployeeContext.Provider value={value}>{children}</EmployeeContext.Provider>;
}

const useEmployee = () => {
  const context = useContext(EmployeeContext);

  if (context === undefined) {
    throw new Error('useEmployeeState must be used within a EventProvider');
  }

  return context;
};

export { EmployeeContext, EmployeeProvider, useEmployee };
