import React from 'react';

import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';

import { CountrySelector, DictionarySelector } from '@app/components/AntFields';
import { NSKeys } from '@app/i18n';

import { SystemSlugs } from '@app/models/dictionaries';
import Api from '@app/services/api';
import { useLocalDispatch, useLocalSelector } from '@app/services/hooks/useRedux';
import { getAppInitialData } from '@app/store/currentSessionReducer';

import { useSpace } from '../../../../SpaceSettingsProvider';

import { ValidationSchema } from './data/validation';
import { IFormValues } from './interfaces';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

export const RegionalForm: React.FC = () => {
  const validationT = useTranslation(NSKeys.validation);
  const spaceSettingsT = useTranslation(NSKeys.spaceSettings);
  const { dimensionUnit, massUnit, timeZone, currency } = useLocalSelector((state) => state.currentSession);
  const { dataScope } = useLocalSelector((state) => state.currentSession);
  const [form] = Form.useForm<IFormValues>();
  const commonT = useTranslation(NSKeys.common);
  const { setEditing } = useSpace();
  const dispatch = useLocalDispatch();
  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  const handleSubmit = async () => {
    await Api.users
      .putScopes({
        title: dataScope?.title || '',
        settings: {
          ...dataScope?.settings,
          // timezone: form.getFieldValue('timezones'),
          currency: form.getFieldValue('currencies'),
          mass_unit: form.getFieldValue('mass_units'),
          dimension_unit: form.getFieldValue('dimension_units'),
          country_code: form.getFieldValue('country_code'),
        },
        company: {
          ...dataScope?.company,
        },
        logo_uid: dataScope?.logo?.uid,
        favicon_uid: dataScope?.favicon?.uid,
      })
      .then(() => {
        dispatch(getAppInitialData());
        message.success(commonT.t('dataPatchSuccess'));
      })
      .catch(() => {
        message.error(commonT.t('error400'));
      })
      .finally(() => setEditing(false));
  };

  return (
    <Form<IFormValues>
      initialValues={{
        // timezones: timeZone,
        currencies: currency,
        mass_units: massUnit,
        dimension_units: dimensionUnit,
        country_code: dataScope?.settings?.country_code,
      }}
      {...layout}
      form={form}
      id="region_form"
      name="region_form"
      onFinish={handleSubmit}>
      {/* <DictionarySelector*/}
      {/*  name={DictionarySlug.Timezones}*/}
      {/*  dictionaryName={DictionarySlug.Timezones}*/}
      {/*  label={spaceSettingsT.t('timeZoneLabel')}*/}
      {/*  placeholder={spaceSettingsT.t('timeZonePlaceholder')}*/}
      {/*  required*/}
      {/*  rules={[yupSync]}*/}
      {/* />*/}
      <DictionarySelector
        name={SystemSlugs.Currencies}
        dictionaryName={SystemSlugs.Currencies}
        label={spaceSettingsT.t('currencyLabel')}
        placeholder={spaceSettingsT.t('currencyPlaceholder')}
        required
        rules={[yupSync]}
      />
      <DictionarySelector
        name={SystemSlugs.MassUnits}
        dictionaryName={SystemSlugs.MassUnits}
        label={spaceSettingsT.t('massLabel')}
        placeholder={spaceSettingsT.t('massPlaceholder')}
        required
        rules={[yupSync]}
      />
      <DictionarySelector
        name={SystemSlugs.DimensionUnits}
        dictionaryName={SystemSlugs.DimensionUnits}
        label={spaceSettingsT.t('dimensionLabel')}
        placeholder={spaceSettingsT.t('dimensionPlaceholder')}
        required
        rules={[yupSync]}
      />
      <CountrySelector
        name="country_code"
        label={spaceSettingsT.t('countryLabel')}
        placeholder={spaceSettingsT.t('countryLabel')}
        required
      />
    </Form>
  );
};
