import { useMutation, useQueryClient } from '@tanstack/react-query';

import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { RearrangeFieldDTO } from '@app/models/orders';
import Api from '@app/services/api';

interface IParams {
  data: RearrangeFieldDTO;
  field_group_uid: string;
}

export const useFieldGroupReorderQuery = () => {
  const client = useQueryClient();
  const commonT = useTranslation(NSKeys.common);

  return useMutation({
    mutationFn: async ({ data, field_group_uid }: IParams) => {
      return await Api.orders.postRearrangeFieldGroup(field_group_uid, data);
    },
    onError: () => {
      message.open({
        type: 'error',
        content: commonT.t('error400'),
      });
    },
    onSuccess: async (data) => {
      await client.invalidateQueries(['getProcess']);
      message.open({
        type: 'success',
        content: commonT.t('dataAddSuccess'),
      });
      return data;
    },
  });
};
