import React from 'react';

import { CheckOutlined, CloseOutlined, DisconnectOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Row, Space, Table, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { CreateOrLinkContactSchema } from '@app/models/dictionaries';

import { NEW_UID } from '../../../../data/contants';
import { useContactRecipients } from '../../../../providers';

import { EditableCell } from './components/EditableCell';
import { Container } from './styles';

interface IProps {
  title: string;
}
type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export const RecipientsTable: React.FC<IProps> = (props) => {
  const { token } = theme.useToken();
  const contactsT = useTranslation(NSKeys.contacts);
  const [form] = Form.useForm<CreateOrLinkContactSchema>();
  const { data: dataSource, setData: setDataSource, setEditingUid, editingUid } = useContactRecipients();

  const isEditing = (record: CreateOrLinkContactSchema) => record.uid === editingUid;

  const handleAdd = () => {
    const newData = {
      uid: NEW_UID,
      name: '',
      code: '',
    };
    form.setFieldValue('uid', NEW_UID);
    setDataSource([...dataSource, newData]);
    setEditingUid(NEW_UID);
  };

  const handleCancel = async () => {
    if (editingUid === NEW_UID) {
      setDataSource(dataSource.filter((el) => el.uid !== NEW_UID));
    }
    setEditingUid('');
    form.resetFields();
  };

  const handleRecipientSelect = (option: any, value: string) => {
    if (option?.data) {
      form.setFieldValue('name', option?.data?.name || '');
      form.setFieldValue('uid', option?.data?.uid || '');
      form.setFieldValue('code', option?.data?.code || '');
    }
    form.setFieldValue('name', option?.data?.name || value);
    form.setFieldValue('uid', option?.data?.uid || NEW_UID);
  };

  const handleSave = async () => {
    try {
      const row = (await form.validateFields()) as any;
      if (editingUid === NEW_UID) {
        // добавление существующего контакта
        setDataSource([...dataSource.filter((el) => el.uid !== NEW_UID), row]);
        setEditingUid('');
        form.resetFields();
      }
    } catch (errInfo) {
      console.log('Validate Failed:');
    }
  };

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
    inputType?: string;
    onRecipientSelect?: (option: any, value: string) => void;
  })[] = [
    {
      title: 'tableRecipients.name',
      dataIndex: 'name',
      editable: true,
      // width: '200px',
      inputType: editingUid === NEW_UID ? 'recipientSelect' : 'input',
      onRecipientSelect: handleRecipientSelect,
    },
    {
      title: 'tableRecipients.code',
      dataIndex: 'code',
      editable: true,
      inputType: 'input',
    },
    {
      title: '',
      dataIndex: 'operation',
      width: '90px',
      fixed: 'right',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Button type="link" icon={<CheckOutlined />} onClick={handleSave} />
            <Button type="link" icon={<CloseOutlined />} onClick={handleCancel} />
          </Space>
        ) : (
          <Space>
            <Button
              type="link"
              icon={<DisconnectOutlined twoToneColor={token.colorPrimary} />}
              disabled={editingUid !== ''}
              onClick={(e) => {
                setDataSource(dataSource.filter((el) => el.uid !== record.uid));
              }}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <Form form={form} component={false}>
      <Container>
        <Row justify="space-between" style={{ padding: '16px 0', marginBottom: 8 }}>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {props.title}
          </Typography.Title>
          <Button
            onClick={handleAdd}
            disabled={!!editingUid}
            icon={<PlusCircleOutlined style={{ color: token.colorPrimary }} />}>
            {contactsT.t('form.newRecipient')}
          </Button>
        </Row>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowClassName="editable-row"
          rowKey="uid"
          bordered
          pagination={false}
          dataSource={dataSource}
          scroll={{ x: 600 }}
          columns={
            defaultColumns
              .map((el) => ({ ...el, title: contactsT.t(el.title as any) }))
              .map((col) => {
                if (!col.editable) {
                  return col;
                }
                return {
                  ...col,
                  onCell: (record: CreateOrLinkContactSchema) => ({
                    record,
                    inputType: col.inputType,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    onRecipientSelect: col.onRecipientSelect,
                    editing: isEditing(record),
                  }),
                };
              }) as ColumnTypes
          }
        />
      </Container>
    </Form>
  );
};
