import React from 'react';

import { FileImageOutlined } from '@ant-design/icons';
import { theme, UploadProps } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { TOKEN_KEY_NAME } from '@app/services/httpClient';

import { Container, Uploader } from './styles';

interface IProps extends UploadProps {
  name?: string;
  apiPath: string;
}

export const Upload: React.FC<IProps> = ({ name = 'media', apiPath, ...props }) => {
  const { token } = theme.useToken();
  const [apiToken, setApiToken] = React.useState(localStorage.getItem(TOKEN_KEY_NAME));
  const commonT = useTranslation(NSKeys.common);

  const DEFAULT_PARAMS: UploadProps = {
    multiple: true,
    accept: 'image/gif, image/jpeg, image/png',
    listType: 'picture-card',
    beforeUpload: () => setApiToken(localStorage.getItem(TOKEN_KEY_NAME)),
    onPreview: () => false,
  };

  return (
    <Container $colorPrimary={token.colorPrimary}>
      <Uploader
        headers={{ Authorization: `Bearer ${apiToken}` }}
        name={name}
        action={`${process.env.REACT_APP_API_HOST}/${apiPath}`}
        {...DEFAULT_PARAMS}
        {...props}>
        <p className="ant-upload-drag-icon">
          <FileImageOutlined size={28} />
        </p>
        <p className="ant-upload-text">{commonT.t('uploadImageTitle')}</p>
        <p className="ant-upload-hint">{commonT.t('uploadImageDescription')}</p>
      </Uploader>
    </Container>
  );
};
