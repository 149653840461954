import { useMutation } from '@tanstack/react-query';

import { CreateOrderDTO } from '@app/models/orders';
import Api from '@app/services/api';

interface IProps {
  data: CreateOrderDTO;
  cart_uid: string;
}

export const useCreateOrder = () => {
  return useMutation({
    mutationFn: async ({ data, cart_uid }: IProps) => {
      return await Api.orders.postOrder(cart_uid, data);
    },
  });
};
