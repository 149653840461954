import React, { useState } from 'react';

import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import type { ResizeCallbackData } from 'react-resizable';
import { useNavigate, useParams } from 'react-router-dom';

import { useLocalStorage } from 'usehooks-ts';

import { NSKeys } from '@app/i18n';
import { TableParams } from '@app/models/interfaces';
import { CatalogSchema, ViewProductInCatalogSchema } from '@app/models/products';
import { CATALOG_MATERIALS_TABLE_COLUMNS } from '@app/services/appSettings';
import { AddRemoveControl } from '@components/AddRemoveContol';
import { Paper } from '@components/Paper';
import { TableDnDResizable } from '@components/TableDnDResizable';
import { TableEditMenu } from '@components/TableEditMenu';
import { ProductDropDownMenu } from '@pages/Catalogs/components/ProductDropDownMenu';

import { useGetCatalogProductsQueryTable } from '../../../../hooks';

import { BASE_COLUMNS } from './constants';

const INITIAL_TABLE_PARAMS = {
  pagination: {
    current: 1,
    pageSize: 10,
  },
};

interface IProps {
  catalog?: CatalogSchema;
  onCartCountChange: (value: number, product: ViewProductInCatalogSchema) => void;
  filterSearchString: string;
  addButtonTitle?: string;
  addToCardAvailable?: boolean;
  filterCategories: string[];
  filterTags: string[];
}

export const Table: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const commonT = useTranslation(NSKeys.common);
  const nomenclatureInfoT = useTranslation(NSKeys.nomenclatureInfo);
  const [tableParams, setTableParams] = useState<TableParams>(INITIAL_TABLE_PARAMS);
  const [localStorageColumns, setLocalStorageColumns] = useLocalStorage(
    CATALOG_MATERIALS_TABLE_COLUMNS,
    BASE_COLUMNS.map(({ render, ...rest }) => rest)
  );
  const { id } = useParams<{ id: string }>();
  const nomenclatureAddT = useTranslation(NSKeys.nomenclatureAdd);

  const handleTableHeaderChange = (checkedValues: any[]) => {
    setLocalStorageColumns(BASE_COLUMNS.filter((item) => checkedValues.includes(item.key as string)));
  };

  const { data: tableData, isLoading } = useGetCatalogProductsQueryTable({
    offset:
      ((tableParams.pagination?.current || INITIAL_TABLE_PARAMS.pagination.current) - 1) *
      (tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize),
    limit: tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize,
    catalog_uid: id,
    q: props.filterSearchString,
    category_id: props.filterCategories,
    tag_id: props.filterTags,
  });

  const handleResize =
    (index: number) =>
    (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
      const newColumns = [...localStorageColumns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width > 20 ? size.width : 20,
      };
      setLocalStorageColumns(newColumns);
    };

  return (
    <Paper style={{ margin: '24px', padding: '24px 24px 0 24px' }}>
      <TableDnDResizable<ViewProductInCatalogSchema>
        localStorageName={CATALOG_MATERIALS_TABLE_COLUMNS}
        columns={[
          ...localStorageColumns.map((el, index) => ({
            ...el,
            title: nomenclatureInfoT.t(el.title as any),
            onHeaderCell: (column: any) => ({
              width: column.width,
              onResize: handleResize(index) as React.ReactEventHandler<any>,
            }),
            render: BASE_COLUMNS.find((baseCol) => baseCol.key === el.key)?.render,
          })),
        ]}
        actionColumn={[
          {
            title: (
              <TableEditMenu
                handleOnChange={handleTableHeaderChange}
                defaultValue={localStorageColumns?.map((el) => el.key as string)}
                options={BASE_COLUMNS?.map((el) => ({
                  value: el.key as string,
                  label: nomenclatureInfoT.t(el.title as any),
                }))}
              />
            ),
            key: 'action',
            align: 'right',
            // fixed: 'right',
            render: (_, product) => (
              <Space
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                {props?.addToCardAvailable && (
                  <AddRemoveControl
                    isSmall
                    value={product?.cart_item?.quantity || 0}
                    onInputChange={(value) => props.onCartCountChange(value, product)}
                    title={props.addButtonTitle || nomenclatureAddT.t('addToCart')}
                  />
                )}
                <ProductDropDownMenu
                  catalog_uid={id || ''}
                  product_uid={product.uid}
                  manageable={props.catalog?.$permissions?.manage_products}
                />
              </Space>
            ),
          },
        ]}
        key="uid"
        onColumnsChange={setLocalStorageColumns}
        rowClassName="cursorPointer"
        className="tableClassname"
        loading={isLoading}
        dataSource={tableData?.items}
        scroll={{ x: 'max-content' }}
        pagination={{
          ...tableParams.pagination,
          total: tableData?.total,
          showQuickJumper: true,
          showSizeChanger: true,
          position: ['bottomCenter'],
          showTotal: (total, range) => `${range[0]}-${range[1]} ${commonT.t('paginationOf')} ${total}`,
        }}
        onRow={(data) => {
          return {
            onClick: () => navigate(`${data.uid}`),
          };
        }}
        onChange={(pagination, filters, sorter) => {
          setTableParams({
            pagination,
            filters,
            ...sorter,
          });
        }}
      />
    </Paper>
  );
};
