import React, { useState } from 'react';

import { Form, Input, message, Modal, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import Api from '@app/services/api';
import { getFormErrorsFromResponse } from '@app/services/getFormErrorsFromResponse';
import { useLocalSelector } from '@app/services/hooks/useRedux';

import { ValidationSchema } from './data/validation';
import { IFormValues } from './interfaces';

interface IProps {
  open: boolean;
  onClose: () => void;
  onDone: () => void;
}

export const ChangeModal: React.FC<IProps> = (props) => {
  const { data } = useLocalSelector((state) => state.currentSession);
  const validationT = useTranslation(NSKeys.validation);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm<IFormValues>();
  const { token } = theme.useToken();
  const profileT = useTranslation(NSKeys.profileSettings);
  const commonT = useTranslation(NSKeys.common);

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  const handleSubmit = async (values: IFormValues) => {
    setConfirmLoading(true);
    await Api.users
      .putUserAuth({
        first_name: data?.first_name || '',
        last_name: data?.last_name || '',
        phone: data?.phone || '',
        title: data?.title || '',
        email: values.email,
        settings: { ...data?.settings },
      })
      .then(() => {
        props.onDone();
      })
      .catch((err) => {
        const { pointerErrors } = getFormErrorsFromResponse(data, err, validationT.t);
        if (pointerErrors.length) {
          form.setFields(pointerErrors);
        } else {
          message.error(commonT.t('error400'));
          props.onClose();
        }
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  };

  return (
    <Modal
      title={profileT.t('emailModalTitle')}
      open={props.open}
      onOk={() => form.submit()}
      okText={commonT.t('confirm')}
      confirmLoading={confirmLoading}
      onCancel={props.onClose}>
      <Form
        form={form}
        onFinish={handleSubmit}
        name="email_reset"
        layout="vertical"
        style={{
          border: `1px solid ${token.colorBorder}`,
          margin: '20px -24px',
          padding: 25,
          borderLeft: 'none',
          borderRight: 'none',
        }}>
        <Form.Item
          name="email"
          label={<Typography.Text type="secondary">{profileT.t('emailModalDescription')}</Typography.Text>}
          rules={[yupSync]}>
          <Input placeholder={profileT.t('emailPlaceholder')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
