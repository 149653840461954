import React from 'react';

import { ColorPicker, Flex, Skeleton, Typography } from 'antd';

import { useUpdateStatuseQuery } from '../../../../../../hooks';

interface IProps {
  name: string;
  color: string;
  status_uid: string;
}

export const StatusBlock: React.FC<IProps> = ({ name, color, status_uid }) => {
  const [editableStr, setEditableStr] = React.useState(name);
  const [editableColor, setEditableColor] = React.useState(color);

  React.useEffect(() => {
    setEditableStr(name);
  }, [name]);

  React.useEffect(() => {
    setEditableColor(color);
  }, [color]);

  const { mutate, status } = useUpdateStatuseQuery();

  const onEditName = (value: string) => {
    if (name !== value) {
      mutate({ status_uid, data: { name: value } });
    }
  };
  const handleColorChange = (value: boolean) => {
    if (!value && color !== editableColor) {
      mutate({ status_uid, data: { color: editableColor } });
    }
  };

  return (
    <Flex style={{ marginBottom: 4, width: '100%' }} align="center">
      {status === 'loading' ? (
        <Skeleton.Input active block={true} />
      ) : (
        <Flex gap={16} style={{ width: '100%' }} align="center">
          <ColorPicker
            size="small"
            value={editableColor}
            onChangeComplete={(color) => setEditableColor(color.toHex())}
            onOpenChange={handleColorChange}
          />
          <Typography.Text editable={{ onChange: onEditName }} style={{ width: '100%', marginTop: 0 }}>
            {editableStr}
          </Typography.Text>
        </Flex>
      )}
    </Flex>
  );
};
