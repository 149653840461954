import { useQuery } from '@tanstack/react-query';

import { IOrderApprovalChainsParams } from '@app/models/interfaces';
import Api from '@app/services/api';

export const useGetOrderApprovalChainsQuery = ({ limit, offset, name }: IOrderApprovalChainsParams) => {
  return useQuery({
    queryKey: ['getOrderApprovalChains', offset, limit, name],
    queryFn: () => Api.users.getOrderApprovalChains({ offset, order_by: 'name', name, limit }),
  });
};
