import { FC, Fragment } from 'react';

import { Avatar, Row, Space, Spin, Typography } from 'antd';
import { Handle, NodeProps, Position } from 'reactflow';

import { useGetGroupQuery } from '../../../../hooks';
import { useConfirmation } from '../../../../providers/confirmationProvider';
import { DeleteButton } from '../DeleteButton';
import { PlusButton } from '../PlusButton';

import { List, StyledCard } from './styles';

interface IProps extends NodeProps {
  id: string;
  isConnectable: boolean;
}

export const UsersGroupNode: FC<IProps> = (props) => {
  const { onAdd, onDelete, localEdges, localNodes } = useConfirmation();
  const { data, isLoading } = useGetGroupQuery(props?.data?.uid || props.id);

  return (
    <Fragment>
      <Handle type="target" position={Position.Top} isConnectable={props.isConnectable} style={{ opacity: 0 }} />
      <StyledCard
        hoverable
        title={
          <Row justify="space-between" align="middle">
            {data?.name}
            {!localEdges.some((el) => el.source === props.id) && <DeleteButton onDelete={() => onDelete(props.id)} />}
          </Row>
        }
        bordered={false}
        style={{ width: 260, maxHeight: 246 }}
        actions={[
          <Space key="footer" style={{ display: 'flex', margin: '0 20px' }}>
            <Typography.Text>Всего:</Typography.Text>
            <Typography.Link href="https://ant.design" target="_blank">
              {data?.total_users}
            </Typography.Link>
          </Space>,
        ]}>
        {isLoading && !data && <Spin />}
        {(!isLoading || data) && (
          <List>
            {data?.users?.slice(0, 5).map((user, index: any) => (
              <Space key={index}>
                <Avatar size={20} src={user.avatar?.variants?.small?.url}>
                  {user?.name?.slice(0, 1)}
                </Avatar>
                <Typography.Text ellipsis>{user?.name}</Typography.Text>
              </Space>
            ))}
          </List>
        )}
      </StyledCard>
      <PlusButton onAdd={(newUid) => onAdd(newUid, props.id)} selectedNodes={localNodes.map((el) => el.id)} />
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        isConnectable={props.isConnectable}
        style={{ bottom: '-10px', opacity: 0 }}
      />
    </Fragment>
  );
};
