import React from 'react';

import { Button, Form, Input, Layout, message, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';

import { NSKeys } from '@app/i18n';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { HeaderTitle } from '@components/HeaderTitle';

import { useConfirmation } from '@pages/OrderApprovalChains/providers/confirmationProvider';

import { ConfirmationChain } from '../../components/ConfirmationChain';
import { buildTree } from '../../data/services';
import { useCreateChainQuery } from '../../hooks';

import { ValidationSchema } from './data/validation';
import { IFormValues } from './interfaces';

export const Add: React.FC = () => {
  const navigate = useNavigate();
  const commonT = useTranslation(NSKeys.common);
  const orderApprovalChainsT = useTranslation(NSKeys.orderApprovalChains);
  const goBack = () => navigate('/dashboard/orderApprovalChains');
  const { localNodes, localEdges } = useConfirmation();
  const { mutate, status, failureReason } = useCreateChainQuery();
  const validationT = useTranslation(NSKeys.validation);
  const [form] = Form.useForm<IFormValues>();

  const handleSubmit = async (formValues: IFormValues): Promise<void> => {
    if (localNodes.length === 0) {
      message.error(orderApprovalChainsT.t('errors.nodesEmpty'));
    } else {
      mutate({ name: formValues.name, head: buildTree(localNodes, localEdges)[0] } as any);
    }
  };

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  React.useEffect(() => {
    if (status === 'success') {
      goBack();
    }
  }, [status]);

  return (
    <React.Fragment>
      <Layout.Header>
        <Breadcrumbs />
        <Row justify="space-between" align="middle">
          <HeaderTitle title={orderApprovalChainsT.t('new.title')} />
          <Space size="middle">
            <Button onClick={goBack} size="middle" name="catalog_form" id="catalog_form">
              {commonT.t('buttonCancel')}
            </Button>
            <Button
              disabled={status === 'loading'}
              type="primary"
              size="middle"
              form="catalogForm"
              htmlType="submit"
              onClick={form.submit}>
              {commonT.t('buttonSave')}
            </Button>
          </Space>
        </Row>
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item name="name" rules={[yupSync]}>
            <Input style={{ maxWidth: 230 }} placeholder={orderApprovalChainsT.t('new.namePlaceholder')} />
          </Form.Item>
        </Form>
      </Layout.Header>
      <ReactFlowProvider>
        <ConfirmationChain />
      </ReactFlowProvider>
    </React.Fragment>
  );
};
