import styled from 'styled-components';

export const Container = styled.div<{ padding?: string; $colorBgBase: string; $borderColor: string }>`
  background-color: ${(props) => props.$colorBgBase};
  border: 1px solid ${({ $borderColor }) => $borderColor};
  margin-bottom: 24px;
  padding: ${({ padding }) => padding || '32px 24px'};
  border-radius: 12px;
  position: relative;
`;

export const Shadow = styled.div`
  position: absolute;
  cursor: not-allowed;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  z-index: 2;
  left: 0;
  top: 0;
`;
