import React from 'react';

import { StyledTag } from './styles';

interface IProps {
  color: string;
  name: string;
}

export const StatusTag: React.FC<IProps> = ({ color, name }) => {
  return <StyledTag $color={color}>{name}</StyledTag>;
};
