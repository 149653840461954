import React, { Fragment, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, theme } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { OrderItemSchema } from '@app/models/orders';
import { CatalogProductsSelect } from '@components/AntFields';

// import { usePostUpdateCartItem } from '../../../../../../hooks/usePostUpdateCartItem';

import { ModalContainer } from './styles';

interface IProps {
  catalogUid: string;
  cartProducts?: string[];
  onSelectItem: (data: OrderItemSchema) => void;
}

export const AddProductModal: React.FC<IProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<any>();

  const cartTableT = useTranslation(NSKeys.cartTable);
  const commonT = useTranslation(NSKeys.common);
  const { token } = theme.useToken();

  const handleAdd = () => {
    if (value?.data) {
      props.onSelectItem({
        uid: value.data?.cart_item?.uid,
        quantity: 1,
        product: {
          uid: value.data?.uid,
          title: value.data?.title,
          code: value.data?.code,
          price: value.data?.price,
          package_amount: value.data?.package_amount,
        },
        volume: '',
        price: '',
        weight: '',
      });
    }
    setValue(null);
    setOpen(false);
  };

  const handleSelect = (_: any, data: any) => {
    setValue(data);
  };

  return (
    <Fragment>
      <ModalContainer
        title={`${cartTableT.t('productModal.title')}`}
        centered
        destroyOnClose
        open={open}
        onCancel={() => setOpen(false)}
        colorBorderSecondary={token.colorBorderSecondary}
        cancelText={commonT.t('buttonClear')}
        okText={commonT.t('buttonAdd')}
        onOk={handleAdd}
        okButtonProps={{ disabled: !value || status === 'loading' }}
        cancelButtonProps={{ onClick: () => setValue(undefined), disabled: !value }}>
        <CatalogProductsSelect
          catalogUid={props.catalogUid}
          disableValues={props.cartProducts}
          value={value}
          size="large"
          style={{ width: '100%' }}
          labelInValue={true}
          onChange={(opt, data) => handleSelect(opt, data)}
          placeholder={commonT.t('search')}
        />
      </ModalContainer>

      <Button icon={<PlusOutlined />} onClick={() => setOpen(true)} size="middle">
        {commonT.t('buttonAdd')}
      </Button>
    </Fragment>
  );
};
