import React from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Button, Layout, List, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { HeaderTitle } from '@components/HeaderTitle';
import { ListItem } from '@components/ListItem';

import { useEmployee } from '../../providers';

import { Container } from './styles';

export const SingleEmployee: React.FC = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const commonT = useTranslation(NSKeys.common);
  const pageT = useTranslation(NSKeys.employees);
  const employee = useEmployee();

  return (
    <Layout>
      <Layout.Header>
        <Breadcrumbs
          apiTitles={[{ id: ':employeeId', value: `${employee.data?.last_name} ${employee.data?.first_name}` }]}
        />
        <Row justify="space-between" align="middle">
          <HeaderTitle
            title={`${employee.data?.last_name} ${employee.data?.first_name}`}
            onClick={() => navigate('/dashboard/employees')}
            loading={employee.loading}
          />
          <Space size="middle">
            <Button
              icon={<EditOutlined />}
              onClick={() => navigate(`/dashboard/employees/edit/${params?.id}`)}
              size="middle"
              type="primary">
              {commonT.t('edit')}
            </Button>
          </Space>
        </Row>
      </Layout.Header>
      <Layout.Content>
        <Container>
          <List style={{ maxWidth: 600, margin: '28px 0' }} loading={employee.loading}>
            <ListItem title={pageT.t('form.surnameLabel')} description={employee.data?.last_name} />
            <ListItem title={pageT.t('form.nameLabel')} description={employee.data?.first_name} />
            <ListItem title={pageT.t('form.emailLabel')} description={employee.data?.email} />
            <ListItem title={pageT.t('form.phoneLabel')} description={employee.data?.phone} />
            <ListItem title={pageT.t('form.jobtitleLabel')} description={employee.data?.title} />
            <ListItem title={pageT.t('form.role_idLabel')} description={employee.data?.role?.name} />
          </List>
        </Container>
      </Layout.Content>
    </Layout>
  );
};
