import { Locale } from 'antd/lib/locale';

import enUS from 'antd/locale/en_US';
import ptBr from 'antd/locale/pt_BR';
import ruRU from 'antd/locale/ru_RU';

export const getLanguage = (): 'en' | 'ru' | 'pt' => {
  const lang = navigator?.language || window.localStorage?.i18nextLng || 'en';
  if (lang.includes('ru')) {
    return 'ru';
  }
  if (lang.includes('pt')) {
    return 'pt';
  } else return 'en';
};

export const geti18nLang = (spaceLang: string): 'en' | 'ru' | 'pt' => {
  switch (spaceLang.toLowerCase()) {
    case 'ru':
      return 'ru';
    case 'pt':
      return 'pt';
    default:
      return 'en';
  }
};

export const getAntLanguage = (): Locale => {
  switch (getLanguage()) {
    case 'ru':
      return ruRU;
    case 'pt':
      return ptBr;
    default:
      return enUS;
  }
};

export const getPhoneInputLanguage = (): string => {
  switch (getLanguage()) {
    case 'ru':
      return 'ru';
    case 'pt':
      return 'br';
    default:
      return 'us';
  }
};
