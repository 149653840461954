import { useQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

export const useGetContact = (uid: string) => {
  return useQuery({
    queryKey: ['getContact', uid],
    queryFn: () => Api.dictionaries.getContact(uid),
    onSuccess: (data) => {
      return {
        uid: data?.uid,
        name: data?.name,
        email: data?.email,
        phone: data?.phone,
        $permissions: data?.$permissions,
      };
    },
    enabled: !!uid,
  });
};
