import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';

import { Button, Form, Input, Modal, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { GroupsSelect } from '@components/AntFields';

import { useCreateProcessAccessSettingsQuery } from '../../../../../../hooks';

interface IProps {
  disabledOptions?: string[];
  process_uid: string;
}

export const AddSettingsModal: React.FC<IProps> = ({ disabledOptions, process_uid }) => {
  const ordersT = useTranslation(NSKeys.orders);
  const commonT = useTranslation(NSKeys.common);
  const [open, setOpen] = useState(false);
  const { mutate, status } = useCreateProcessAccessSettingsQuery();

  React.useEffect(() => {
    if (status === 'success') {
      setOpen(false);
    }
  }, [status]);

  return (
    <div style={{ display: 'inline-block' }}>
      <Button type="dashed" icon={<PlusOutlined />} onClick={() => setOpen(true)} size="small">
        {ordersT.t('addFieldButton')}
      </Button>
      <Modal
        title={
          <Space align="center">
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
              {ordersT.t('addGroupTitle')}
            </Typography.Title>
          </Space>
        }
        open={open}
        destroyOnClose
        okButtonProps={{
          form: 'add_settings',
          htmlType: 'submit',
          loading: status === 'loading',
        }}
        okText={commonT.t('buttonAdd')}
        confirmLoading={status === 'loading'}
        onCancel={() => setOpen(false)}>
        <div style={{ margin: '40px 0' }}>
          <Form name="add_settings" onFinish={(data) => mutate({ process_uid, data })}>
            <Form.Item name={['user_group', 'uid']} label={ordersT.t('fieldGroup')} rules={[{ required: true }]}>
              <GroupsSelect placeholder="" disabledOptions={disabledOptions} />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};
