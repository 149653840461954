import React from 'react';

import { FileImageOutlined } from '@ant-design/icons';
import { theme } from 'antd';

import { Container } from './styles';

interface IProps {
  height?: number;
}

export const ImageFault: React.FC<IProps> = (props) => {
  const { token } = theme.useToken();

  return (
    <Container $height={props.height} $colorBgBase={token.colorBgBase}>
      <FileImageOutlined style={{ fontSize: '33px', color: token.colorText }} />
    </Container>
  );
};
