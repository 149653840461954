import React, { Fragment, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, theme } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { CatalogProductsSelect } from '@components/AntFields';

import { usePostUpdateCartItem } from '../../../../../../hooks/usePostUpdateCartItem';

import { ModalContainer } from './styles';

interface IProps {
  catalogUid: string;
  cartProducts?: string[];
}

export const AddProductModal: React.FC<IProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string | undefined>();

  const { mutateAsync: updateCart, status } = usePostUpdateCartItem();

  // const groupsT = useTranslation(NSKeys.groups);
  const cartTableT = useTranslation(NSKeys.cartTable);
  const commonT = useTranslation(NSKeys.common);
  const { token } = theme.useToken();

  const handleAdd = async () => {
    if (value) {
      // for (const prodictPk of values) {
      await updateCart({ catalog_uid: props.catalogUid, data: { product_uid: value, quantity: 1 } });
      // }
    }
  };

  React.useEffect(() => {
    if (status === 'success') {
      setOpen(false);
      setValue(undefined);
    }
  }, [status]);

  return (
    <Fragment>
      <ModalContainer
        title={`${cartTableT.t('productModal.title')}`}
        centered
        destroyOnClose
        open={open}
        onCancel={() => setOpen(false)}
        colorBorderSecondary={token.colorBorderSecondary}
        cancelText={commonT.t('buttonClear')}
        okText={commonT.t('buttonAdd')}
        onOk={handleAdd}
        okButtonProps={{ disabled: !value || status === 'loading' }}
        cancelButtonProps={{ onClick: () => setValue(undefined), disabled: !value }}>
        <CatalogProductsSelect
          catalogUid={props.catalogUid}
          disableValues={props.cartProducts}
          value={value}
          size="large"
          style={{ width: '100%' }}
          onChange={setValue}
          placeholder={commonT.t('search')}
        />
      </ModalContainer>

      <Button icon={<PlusOutlined />} onClick={() => setOpen(true)} size="middle">
        {commonT.t('buttonAdd')}
      </Button>
    </Fragment>
  );
};
