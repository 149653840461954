import React, { useState } from 'react';

import { CloseOutlined, PlusOutlined } from '@ant-design/icons';

import {
  Button,
  ColProps,
  Divider,
  Form,
  FormInstance,
  Input,
  InputRef,
  message,
  Popconfirm,
  Select,
  Space,
  theme,
} from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { CommonDictionariesSlugs } from '@app/models/dictionaries';
import { DictionaryItemSchema, SystemDictionarySlugs } from '@app/models/products';

import Api from '@app/services/api';
import { useLocalSelector } from '@app/services/hooks/useRedux';

import { RowOption } from './styles';

interface IProps {
  name: string;
  dictionaryName: SystemDictionarySlugs;
  label: string;
  placeholder: string;
  required: boolean;
  removable?: boolean;
  rules: any;
  mode?: 'multiple';
  form: FormInstance;
  labelCol?: ColProps;
  wrapperCol?: ColProps;
}

const { Option } = Select;

export const CreatedSelect: React.FC<IProps> = ({ labelCol = { span: 5 }, wrapperCol = { span: 13 }, ...props }) => {
  const [list, setList] = React.useState<DictionaryItemSchema[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [newElement, setNewElement] = React.useState('');
  const inputRef = React.useRef<InputRef>(null);
  const commonT = useTranslation(NSKeys.common);
  const nomenclatureFormT = useTranslation(NSKeys.nomenclatureForm);
  const validationT = useTranslation(NSKeys.validation);
  const [addedNew, setAddedNew] = useState(false);
  const { token } = theme.useToken();
  const dictionariesData = useLocalSelector((state) => state.currentSession.dictionariesData);
  const formValue = Form.useWatch(props.name, props.form);

  const fetchList = async (): Promise<void> => {
    setLoading(true);
    await Api.products
      .getDictionaryList(props.dictionaryName)
      .then((res) => {
        setList(res);
      })
      .catch(() => setList([]))
      .finally(() => {
        setLoading(false);
        setAddedNew(false);
      });
  };

  const postPOSM = async (title: string): Promise<void> => {
    // setList([]);
    setLoading(true);
    await Api.products
      .postDictionaryTitle(props.dictionaryName, title)
      .then(() => {
        setList([]);
        setAddedNew(true);
      })
      .catch((e) => {
        message.error(validationT.t(e?.response?.data?.code, { defaultValue: commonT.t('error400') }));
      })
      .finally(() => {
        inputRef.current?.focus();
        setNewElement('');
      });
  };

  const handleDeleteItem = async (id: number): Promise<void> => {
    setLoading(true);
    await Api.products
      .deleteDictionaryItem(id)
      .then(() => {
        props.form.setFieldValue(
          props.name,
          Array.isArray(formValue) ? formValue?.filter((el: any) => el !== id) : undefined
        );
        fetchList();
      })
      .catch((e) => {
        message.error(commonT.t('error400'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    fetchList();
  }, []);

  React.useEffect(() => {
    if (addedNew) {
      fetchList();
    }
  }, [addedNew]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewElement(event.target.value);
  };

  return (
    <Form.Item
      required={props.required}
      name={props.name}
      label={props.label}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      rules={props.rules}>
      <Select
        placeholder={props.placeholder}
        mode={props.mode}
        allowClear
        loading={loading}
        optionLabelProp="label"
        dropdownRender={(menu) => (
          <div>
            {menu}
            {!!dictionariesData?.find((dict) => dict?.slug === props.dictionaryName)?.$permissions?.create_item && (
              <>
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{ padding: '0 8px 4px' }}>
                  <Input
                    placeholder={nomenclatureFormT.t('selectAddPlaceholder')}
                    ref={inputRef}
                    value={newElement}
                    onChange={onInputChange}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                  <Button
                    disabled={!newElement}
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      postPOSM(newElement);
                    }}>
                    {nomenclatureFormT.t('selectAdd')}
                  </Button>
                </Space>
              </>
            )}
          </div>
        )}>
        {list.map((item) => (
          <Option key={item.id} label={item.title} value={item.id}>
            <RowOption justify="space-between">
              {item.title}
              {props.removable && item?.$permissions?.delete && (
                <Popconfirm
                  title={commonT.t('deleteConfirm')}
                  onConfirm={(e) => {
                    e?.stopPropagation();
                    item?.id && handleDeleteItem(item.id);
                  }}
                  okText={commonT.t('yes')}>
                  <CloseOutlined
                    style={{ color: token.colorTextSecondary }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </Popconfirm>
              )}
            </RowOption>
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};
