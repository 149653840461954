import React from 'react';

import { Resizable, ResizeCallbackData } from 'react-resizable';

export const ResizableTitle: React.FC<
  React.HTMLAttributes<any> & {
    onResize: (e: React.SyntheticEvent<Element>, data: ResizeCallbackData) => void;
    width: number;
  }
> = (props) => {
  const [isResing, setResing] = React.useState(false);
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className={`react-resizable-handle ${isResing ? 'active' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResizeStart={() => setResing(true)}
      onResizeStop={() => setResing(false)}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}>
      <th {...restProps} />
    </Resizable>
  );
};
