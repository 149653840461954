import styled from 'styled-components';

export const CarouselContainer = styled.div<{ $color: string }>`
  .ant-carousel {
    .carousel {
      li button {
        background: ${({ $color }) => $color};
      }
    }

    .ant-image {
      display: flex;

      img {
        height: 647px;
        object-fit: contain;
      }
    }
  }
`;
