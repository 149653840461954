import { FC, Fragment, useState } from 'react';

import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';
import { getFormErrorsFromResponse } from '@app/services/getFormErrorsFromResponse';
import { useLocalDispatch } from '@app/services/hooks/useRedux';
import { REFRESH_TOKEN_KEY_NAME, TOKEN_KEY_NAME, TOKEN_KEY_REMEMBER } from '@app/services/httpClient';
import { getAppInitialData } from '@app/store/currentSessionReducer';
import { AuthorizationFormLayout } from '@layouts/AuthorizationFormLayout';

import { Step1 } from './components/Step1';
import { Step2 } from './components/Step2';
import { Step3 } from './components/Step3';
import { Step4 } from './components/Step4';
import { Step5 } from './components/Step5';
import { Step6 } from './components/Step6';
import { IResultFormValues, IStep6 } from './interfaces';

const INITIAL_FORM_VALUES: IResultFormValues = {
  name: '',
  surname: '',
  email: '',
  first_pwd: '',
  second_pwd: '',
  sector: '',
  jobtitle: '',
  company_name: '',
  worker_count: '1',
};

export const Registration: FC = () => {
  const dispatch = useLocalDispatch();
  const navigate = useNavigate();
  const registrationT = useTranslation(NSKeys.registration);
  const commonT = useTranslation(NSKeys.common);
  const validationT = useTranslation(NSKeys.validation);

  const [step, setStep] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [formValues, setFormValues] = useState<IResultFormValues>(INITIAL_FORM_VALUES);

  const handleSubmit = async (count: IStep6): Promise<void> => {
    setSubmitting(true);
    const commonFormValues = { ...formValues, ...count };
    try {
      const signInData = await Api.users.postAuthRegister({
        username: commonFormValues.email,
        password: commonFormValues.first_pwd,
        first_name: commonFormValues.name,
        last_name: commonFormValues.surname,
        title: '',
        email: commonFormValues.email,
      });
      localStorage.setItem(REFRESH_TOKEN_KEY_NAME, signInData?.refresh_token);
      localStorage.setItem(TOKEN_KEY_NAME, signInData.access_token);
      localStorage.setItem(TOKEN_KEY_REMEMBER, 'true');

      await Api.users.putScopes({
        settings: { theme: 'light' },
        title: commonFormValues.company_name,
        company: {
          employees_count: commonFormValues.worker_count,
          industry: commonFormValues.sector,
          owner_title: commonFormValues.jobtitle,
        },
      });

      dispatch(getAppInitialData(() => navigate('/dashboard', { replace: true })));
    } catch (err) {
      const { pointerErrors } = getFormErrorsFromResponse(
        {
          email: '',
        },
        err,
        validationT.t
      );
      if (pointerErrors.length) {
        setStep(1);
      }
      message.error(pointerErrors.length ? pointerErrors?.[0]?.errors?.join(', ') : commonT.t('somethingWentWrong'));
    } finally {
      setSubmitting(false);
    }
  };

  const handleNext = (values: Partial<IResultFormValues>) => {
    setFormValues((formValues) => ({ ...formValues, ...values }));
    setStep(step + 1);
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  const renderSwitch = (step: number) => {
    switch (step) {
      case 2:
        return (
          <Step2
            onNext={handleNext}
            onPrev={handlePrev}
            initialValues={{ first_pwd: formValues.first_pwd, second_pwd: formValues.second_pwd }}
          />
        );
      case 3:
        return <Step3 onNext={handleNext} onPrev={handlePrev} initialValues={{ sector: formValues.sector }} />;
      case 4:
        return <Step4 onNext={handleNext} onPrev={handlePrev} initialValues={{ jobtitle: formValues.jobtitle }} />;
      case 5:
        return (
          <Step5 onNext={handleNext} onPrev={handlePrev} initialValues={{ company_name: formValues.company_name }} />
        );
      case 6:
        return (
          <Step6
            onNext={handleSubmit}
            onPrev={handlePrev}
            submitting={submitting}
            initialValues={{ worker_count: formValues.worker_count }}
          />
        );
      default:
        return (
          <Step1
            onNext={handleNext}
            initialValues={{
              surname: formValues.surname,
              name: formValues.name,
              email: formValues.email,
            }}
          />
        );
    }
  };

  return (
    <AuthorizationFormLayout
      title={registrationT.t(`title${step}` as any)}
      description={registrationT.t(`description${step}` as any)}>
      <Fragment>{renderSwitch(step)}</Fragment>
    </AuthorizationFormLayout>
  );
};
