import React from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Input, Modal, Row } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { useGroups } from '../../providers';
import { GroupTable } from '../GroupTable';

interface IProps {
  open: boolean;
  onOk: (uid: string) => void;
  onCancel: () => void;
  disabledRows?: string[];
}

export const GroupModal: React.FC<IProps> = (props) => {
  const [searchValue, setSearchValue] = React.useState('');
  const [searchResult, setSearchResult] = React.useState('');
  const { onAddRow, selectedRows, setSelectedRows } = useGroups();
  const commonT = useTranslation(NSKeys.common);
  const groupsT = useTranslation(NSKeys.groups);

  return (
    <Modal
      width="75%"
      title={groupsT.t('chainModal.title')}
      open={props.open}
      cancelText={commonT.t('cancel')}
      okText={commonT.t('confirm')}
      onOk={() => {
        if (selectedRows.length) {
          setSelectedRows([]);
          props.onOk(selectedRows[0] as any);
        }
      }}
      okButtonProps={{ disabled: !selectedRows.length }}
      onCancel={props.onCancel}>
      <React.Fragment>
        <Row justify="space-between" align="middle" style={{ padding: '16px 0' }}>
          <Col span={8}>
            <Button type="primary" icon={<PlusOutlined />} onClick={onAddRow} size="middle">
              {commonT.t('buttonAdd')}
            </Button>
          </Col>
          <Col>
            <Input.Search
              size="middle"
              value={searchValue}
              onChange={(value) => setSearchValue(value.target.value)}
              onSearch={() => setSearchResult(searchValue)}
              placeholder={groupsT.t('chainModal.searchPlaceholder')}
              enterButton
            />
          </Col>
        </Row>
        <GroupTable selectable disabledRows={props.disabledRows} searchString={searchResult} />
      </React.Fragment>
    </Modal>
  );
};
