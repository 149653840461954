import React from 'react';

import { ColorPicker, Form, Input, message, Radio } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';
import { useLocalDispatch, useLocalSelector } from '@app/services/hooks/useRedux';
import { getAppInitialData } from '@app/store/currentSessionReducer';
import { IFormValues } from '@pages/ProfileSettings/components/PersonContainer/components/InfoForm/interfaces';

import { useSpace } from '@pages/SpaceSettings/SpaceSettingsProvider';

import { FaviconUpload } from './compoents/FaviconUpload';
import { LogoUpload } from './compoents/LogoUpload';

import { ValidationSchema } from './data/validation';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

export const BrandForm: React.FC = () => {
  const validationT = useTranslation(NSKeys.validation);
  const spaceT = useTranslation(NSKeys.spaceSettings);
  const commonT = useTranslation(NSKeys.common);
  const [form] = Form.useForm<IFormValues>();
  const dispatch = useLocalDispatch();
  const { setEditing } = useSpace();
  const { dataScope } = useLocalSelector((state) => state.currentSession);

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  const handleSubmit = async () => {
    await Api.users
      .putScopes({
        title: form.getFieldValue('title'),
        settings: {
          ...dataScope?.settings,
          primary_color: form.getFieldValue('primary_color'),
          theme: form.getFieldValue('theme'),
        },
        logo_uid: form.getFieldValue('logo_uid'),
        favicon_uid: form.getFieldValue('favicon_uid'),
        company: dataScope?.company || {},
      })
      .then(() => {
        dispatch(getAppInitialData());
        message.success(commonT.t('dataPatchSuccess'));
      })
      .catch(() => {
        message.error(commonT.t('error400'));
      })
      .finally(() => setEditing(false));
  };

  return (
    <Form
      {...layout}
      form={form}
      onFinish={handleSubmit}
      id="brand_form"
      name="brand_form"
      initialValues={{
        title: dataScope?.title,
        theme: dataScope?.settings.theme,
        primary_color: dataScope?.settings.primary_color,
        favicon_uid: dataScope?.favicon?.uid,
        logo_uid: dataScope?.logo?.uid,
      }}>
      <Form.Item name="title" label={spaceT.t('titleLabel')} rules={[yupSync]}>
        <Input placeholder={spaceT.t('titlePlaceholder')} />
      </Form.Item>
      <Form.Item label={spaceT.t('colorLabel')} name="primary_color" rules={[yupSync]}>
        <ColorPicker onChange={(color) => form.setFieldValue('primary_color', color.toHexString())} />
      </Form.Item>
      <Form.Item label={spaceT.t('themeLabel')} name="theme" rules={[yupSync]}>
        <Radio.Group
          options={[
            { label: commonT.t('light'), value: 'light' },
            { label: commonT.t('dark'), value: 'dark' },
          ]}
          optionType="button"
          buttonStyle="solid"
        />
      </Form.Item>

      <LogoUpload />
      <FaviconUpload />
    </Form>
  );
};
