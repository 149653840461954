import React, { FC, useEffect, useState } from 'react';

import { EditTwoTone } from '@ant-design/icons';
import { theme as antTheme, Layout, message, Row, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { TableParams } from '@app/models/interfaces';
import { RetrieveUserSchema } from '@app/models/users';
import Api from '@app/services/api';
import { useLocalSelector } from '@app/services/hooks/useRedux';
import { useWindowSize } from '@app/services/hooks/useWindowSize';
import { HeaderTitle } from '@components/HeaderTitle';
import { TableEditMenu } from '@components/TableEditMenu';

import { InviteModal } from './components/InviteModal';

import { baseColumns } from './data/constants';
import { Container } from './styles';

const INITIAL_TABLE_PARAMS = {
  pagination: {
    current: 1,
    pageSize: 10,
  },
};

export const EmployeesList: FC = () => {
  const navigate = useNavigate();
  const [tableParams, setTableParams] = useState<TableParams>(INITIAL_TABLE_PARAMS);
  const [columns, setColumns] = useState<ColumnsType<RetrieveUserSchema>>(baseColumns);
  const currentSession = useLocalSelector((state) => state.currentSession);
  const [fetching, isFetching] = useState(false);
  const [data, setData] = useState<RetrieveUserSchema[]>([]);
  const commonT = useTranslation(NSKeys.common);
  const employeesT = useTranslation(NSKeys.employees);
  const { innerHeight } = useWindowSize();
  const { token } = antTheme.useToken();

  const handleTableHeaderChange = (checkedValues: any[]) => {
    setColumns(baseColumns.filter((item) => checkedValues.includes(item.key as string)));
  };

  const LIMIT = tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize;
  const OFFSET = ((tableParams.pagination?.current || 1) - 1) * LIMIT;

  const fetchData = () => {
    isFetching(true);
    Api.users
      .getUsers({
        limit: LIMIT,
        offset: OFFSET,
      })
      .then((res) => {
        setData(res.items);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.total,
          },
        });
      })
      .catch(() => message.error(commonT.t('error400')))
      .finally(() => isFetching(false));
  };

  useEffect(() => {
    fetchData();
  }, [tableParams.pagination?.current, tableParams.pagination?.pageSize, tableParams.field, tableParams.order]);

  return (
    <>
      <Layout.Header>
        <Row justify="space-between" align="middle">
          <HeaderTitle title={employeesT.t('title')} />
          {currentSession?.data?.scope?.$permissions?.invite_users && (
            <Space size="middle">
              <InviteModal />
            </Space>
          )}
        </Row>
      </Layout.Header>

      <Container>
        {/* <TableRemoveButton hidden={!selectedRows.length} onClick={() => setIsModalOpen(true)} />*/}

        <Table
          loading={fetching}
          dataSource={data}
          rowKey="pk"
          className="tableClassname"
          columns={[
            ...columns.map((el) => ({ ...el, title: employeesT.t(el.title as any) })),
            {
              title: (
                <TableEditMenu
                  handleOnChange={handleTableHeaderChange}
                  options={baseColumns.map((el) => ({ value: el.key as string, label: employeesT.t(el.title as any) }))}
                />
              ),
              fixed: 'right',
              key: 'action',
              align: 'center',
              width: 40,
              render: (_, cell) =>
                cell.$permissions.update && (
                  <EditTwoTone
                    twoToneColor={token.colorPrimary}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`edit/${cell.pk}`);
                    }}
                  />
                ),
            },
          ]}
          pagination={{
            ...tableParams.pagination,
            showQuickJumper: true,
            showSizeChanger: true,
            position: ['bottomRight'],
            showTotal: (total, range) => `${range[0]}-${range[1]} ${commonT.t('paginationOf')} ${total}`,
          }}
          rowClassName="cursorPointer"
          onRow={(data) => {
            return {
              onClick: () => navigate(`${data.pk}`),
            };
          }}
          onChange={(pagination) => {
            setTableParams({
              pagination,
            });
          }}
          scroll={{ y: innerHeight - 300, x: true }}
          style={{ overflow: 'auto' }}
        />
      </Container>
    </>
  );
};
